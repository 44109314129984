import React from 'react';
import { Typography } from '@mui/material';
import './Privacy.css';
import { Footer } from 'pages/commonComponents/Footer';

const Privacy = () => {
  return (
    <div className="privacy-container">
      {/* Fixed Header */}
      <div className="privacy-header">
        <Typography variant="h4" fontWeight="bold" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Last Updated: 10/30/2024
        </Typography>
      </div>

      {/* Scrollable Content */}
      <div className="privacy-content">
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          1. Introduction
        </Typography>
        <Typography paragraph>
          Sell My Rig (“we,” “us,” or “our”) is committed to protecting your privacy. 
          This Privacy Policy outlines the types of personal information we collect, how we use it, 
          and the measures we take to safeguard your information. By using our services, you agree 
          to the terms of this Privacy Policy.
        </Typography>

        <Typography variant="h6" fontWeight="bold" gutterBottom>
          2. Information We Collect
        </Typography>
        <ul>
          <li>
            <strong>Personal Information:</strong> When you interact with us, we may collect 
            personal information such as your name, email address, and mobile phone number.
          </li>
          <li>
            <strong>Automatic Data Collection:</strong> We may automatically collect information 
            related to your device and how you interact with our services, including IP address, 
            browser type, and operating system.
          </li>
        </ul>

        <Typography variant="h6" fontWeight="bold" gutterBottom>
          3. How We Use Your Information
        </Typography>
        <ul>
          <li>Provide and improve our services.</li>
          <li>Communicate with you regarding service updates or important information.</li>
          <li>Ensure compliance with legal obligations and policies.</li>
        </ul>

        <Typography variant="h6" fontWeight="bold" gutterBottom>
          4. Sharing Your Information
        </Typography>
        <Typography paragraph>
          We do not share, sell, or rent your personal information, including your mobile phone 
          number, to third parties for marketing or promotional purposes.
        </Typography>
        <Typography paragraph>
          We may share your information only as follows:
        </Typography>
        <ul>
          <li>
            <strong>With Service Providers:</strong> We may share your information with trusted 
            third-party providers who assist us in delivering our services. These providers are 
            obligated to safeguard your information.
          </li>
          <li>
            <strong>Legal Compliance:</strong> We may disclose your information if required to 
            comply with legal obligations or to protect our users and the public.
          </li>
        </ul>

        <Typography variant="h6" fontWeight="bold" gutterBottom>
          5. Data Security
        </Typography>
        <Typography paragraph>
          We take appropriate security measures to protect your information. However, no method of 
          transmission over the internet or electronic storage is completely secure.
        </Typography>

        <Typography variant="h6" fontWeight="bold" gutterBottom>
          6. Your Choices
        </Typography>
        <ul>
          <li>Access, update, or delete the personal information we hold about you.</li>
          <li>Opt out of receiving non-essential communications from us.</li>
        </ul>

        <Typography variant="h6" fontWeight="bold" gutterBottom>
          7. Contact Us
        </Typography>
        <Typography paragraph>
          If you have any questions regarding this Privacy Policy, please contact us at info@sellmyrig.com.
        </Typography>
      </div>
    </div>
  );
};

export default Privacy;