import React, { useEffect, useState } from 'react';
import { Grid, Typography, Snackbar } from '@material-ui/core';
import { CalculateEMI } from 'pages/productDetailFlow/CalculateEMI';
import { copyToClipboard } from 'utils/copyfunction';
import { currencyFormat } from 'utils';
import '../SMRR.css';
import { numberWithCommas } from 'utils/helpers/numberWithCommas';

const fontStyle = {
  fontSize: '13px',
  fontWeight: '400',
  color: '#333333',
};
const viewOptionStyle = {
  width: '64px',
  height: '24px',
  marginLeft: '20px',
  fontSize: '14px',
  color: '#0F5E91',
  border: '1px solid',
  borderBottomColor: '#0F5E91',
  borderTopColor: '#FFFFFF',
  borderLeftColor: '#FFFFFF',
  borderRightColor: '#FFFFFF',
};
const Slidercontent = (props) => {
  const [openEMI, setOpenEMI] = useState(false);
  //console.log('props', props, props?.vehicalInfo?.data, props?.vehicalInfo?.data?.quotes?.quote_price);
  //const vehicalInfodata = props?.vehicalInfo?.data;
  //const [emiModal, setEmiModal] = useState(false);
  const [description, setDescription] = useState(null);
  const [viewOption, setViewOption] = useState(null);
  //const handleEMIModal = () => {
  //setEmiModal(true);
  //};

  //description
  useEffect(() => {
    if (props?.vehicalInfo?.data?.description) {
      if (props?.vehicalInfo?.data?.description?.length > 150) {
        setDescription(props?.vehicalInfo?.data?.description.slice(0, 150));
        setViewOption('See More');
      } else {
        setDescription(props?.vehicalInfo?.data?.description);
      }
    }
  }, [props?.vehicalInfo?.data?.description]);

  //snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleSnackbar = () => {
    setSnackbarOpen(true);
  };
  //copy functionality
  const handleCopyClick = (id) => {
    copyToClipboard(id);
    handleSnackbar();
  };

  //handle viewoption click
  const handleVeiwOption = (text) => {
    if (text === 'See More') {
      setViewOption('See Less');
      setDescription(props?.vehicalInfo?.data?.description);
    }
    if (text === 'See Less') {
      setViewOption('See More');
      setDescription(props?.vehicalInfo?.data?.description.slice(0, 150));
    }
  };

  return (
    <>
      <Grid container className='SliderScontent'>
        <Grid
          item
          container
          lg={12}
          md={12}
          sm={12}
          spacing={3}
          className="md-shadow"
        >
          <Grid item lg={8} md={10} sm={12}>
            <Typography
              className="font-Inter"
              style={{
                fontSize: '17px',
                color: '#333333',
                fontWeight: '500',
              }}
            >
              {props?.vehicalInfo?.data?.title
                ? props?.vehicalInfo?.data?.title
                : 'NA'}
            </Typography>
          </Grid>
          <Grid item container lg={6} sm={5} md={10} style={{}} className='stockdiv'>
            <Grid item lg={6} sm={5} md={4}>
              <Typography
                style={{
                  fontSize: '14px',
                  color: '#357AA6',
                  fontWeight: '500',
                  fontFamily: 'Poppins',
                }}
              >
                Stock Number :
              </Typography>
            </Grid>
            <Grid item lg={3} sm={4} md={4}>
              <Typography
                style={{
                  fontSize: '14px',
                  color: '#357AA6',
                  fontWeight: '500',
                  fontFamily: 'Poppins',
                }}
              >
                {props?.vehicalInfo?.data?.unique_id
                  ? props?.vehicalInfo?.data?.unique_id
                  : 'NA'}
              </Typography>
            </Grid>
            <Grid
              item
              lg={2}
              sm={2}
              md={2}
              style={{ cursor: 'pointer', paddingRight: '10px' }}
            >
              <img
                src={'/assets/copyicon.svg'}
                alt="copy"
                style={{ height: '19px' }}
                onClick={() =>
                  handleCopyClick(props?.vehicalInfo?.data?.unique_id)
                }
              />
            </Grid>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={2000}
              onClose={() => setSnackbarOpen(false)}
              message="Vehicle Id is copied to clipboard"
            />
          </Grid>
          <Grid item container lg={8} md={10} sm={12}>
            {props?.tabType !== 'productDetails' ? (
              <>
                {props?.vehicalInfo?.data?.quotes?.quote_price ? (
                  <>
                    <Grid item>
                      <Typography
                        className="font-Inter"
                        style={{
                          color: '#333333',
                          fontSize: '23px',
                          fontWeight: '700',
                        }}
                      >
                        {currencyFormat(
                          props?.vehicalInfo?.data?.quotes?.quote_price
                        )}
                      </Typography>
                    </Grid>
                    <Grid item style={{ paddingLeft: '40px' }}>
                      <Typography
                        className="font-Inter"
                        style={{
                          color: '#757575',
                          fontSize: '21px',
                          fontWeight: '500',
                          textDecoration: 'line-through',
                        }}
                      >
                        {currencyFormat(
                          props?.vehicalInfo?.data?.selling_price
                        )}
                      </Typography>
                    </Grid>
                  </>
                ) : (
                  <Grid item>
                    <Typography
                      className="font-Inter"
                      style={{
                        color: '#333333',
                        fontSize: '23px',
                        fontWeight: '700',
                      }}
                    >
                      {currencyFormat(props?.vehicalInfo?.data?.selling_price)}
                    </Typography>
                  </Grid>
                )}
              </>
            ) : (
              <Grid item>
                <Typography
                  className="font-Inter"
                  style={{
                    color: '#333333',
                    fontSize: '23px',
                    fontWeight: '700',
                  }}
                >
                  {currencyFormat(props?.vehicalInfo?.data?.selling_price)}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid item container lg={8} sm={12} md={10}>
            {props.tabType === 'productDetails' && (
              <>
                <Grid item>
                  <Typography
                    className="font-Inter"
                    style={{
                      color: '#757575',
                      fontSize: '14px',
                      fontWeight: '700',
                    }}
                  >
                    $1000/month
                  </Typography>
                </Grid>
                <Grid
                  item
                  className="border-style"
                  style={{ marginLeft: '60px', marginTop: '-5px' }}
                  spacing={2}
                >
                  <CalculateEMI
                    data={props?.vehicalInfo?.data}
                    open={openEMI}
                    setOpen={setOpenEMI}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Grid
            item
            container
            lg={10}
            md={12}
            sm={12}
            justifyContent="space-between"
            className="!mt-1 bg-[#EDEDED] p-2 rounded-md max-w-[437px] SliderDiv"
            style={{ marginLeft: '7px' }}
          >
            <Grid item container alignItems="center" className="!w-auto">
              <Grid
                item
                style={{
                  border: '2px solid #0F5E91',
                  display: 'flex',
                  justifyContent: 'center',
                  borderRadius: '12.2px',
                }}
              >
                <img src={'/assets/Vectormiles.svg'} alt="meter" />
              </Grid>
              <Grid item>
                <Typography style={fontStyle} className="px-[5px] font-Inter">
                  {props?.vehicalInfo?.data?.vehicle?.mileage
                    ? `${numberWithCommas(
                        props?.vehicalInfo?.data?.vehicle?.mileage?.toString()
                      )} miles`
                    : 'NA'}{' '}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container alignItems="center" className="!w-auto">
              <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
                <img src={'/assets/brandLogo.svg'} alt="piston" />
              </Grid>
              <Grid item>
                <Typography style={fontStyle} className="px-[5px] font-Inter">
                  {props?.vehicalInfo?.data?.vehicle?.make
                    ? props?.vehicalInfo?.data?.vehicle?.make
                    : 'NA'}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container alignItems="center" className="!w-auto">
              <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
                <img src={'/assets/gearshift.svg'} alt="gear" />
              </Grid>
              <Grid item>
                <Typography style={fontStyle} className="px-[10px] font-Inter">
                  {props?.vehicalInfo?.data?.vehicle?.gear_type
                    ? props?.vehicalInfo?.data?.vehicle?.gear_type
                    : 'NA'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/* Vehical detials along with div */}
          <Grid
            item
            container
            lg={8}
            md={10}
            sm={12}
            style={{ marginTop: '5px' }}
          >
            <Grid item>
              <img
                className="w-[18px] h-[21px]"
                src={'/assets/location.svg'}
                alt="location"
              />
            </Grid>
            <Grid item>
              {props?.vehicalInfo?.data?.city && (
                <>
                  <Typography
                    className="font-Inter"
                    style={{
                      fontSize: '14px',
                      color: '#757575',
                      fontWeight: '500',
                      margin: '1px 5px 1px 5px',
                      fontFamily: 'Inter',
                    }}
                  >
                    {props?.vehicalInfo?.data?.city
                      ? props?.vehicalInfo?.data?.city
                      : 'NA'}
                    ,&nbsp;
                    {props?.vehicalInfo?.data?.state
                      ? props?.vehicalInfo?.data?.state
                      : 'NA'}
                    ,&nbsp;
                    {props?.vehicalInfo?.data?.zip_code
                      ? props?.vehicalInfo?.data?.zip_code
                      : 'NA'}
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>

          <Grid item container lg={10} md={12} sm={12}>
            <Grid item lg={12} md={12} sm={12}>
              <Typography
                className="font-Inter"
                style={{
                  color: '#333333',
                  fontSize: '16px',
                  fontWeight: '500',
                }}
              >
                Description
              </Typography>
            </Grid>
            <Grid
              item
              container
              style={{ marginTop: '18px' }}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography
                  className="font-Inter"
                  style={{ color: '#545454', fontSize: '14px' }}
                >
                  {description}
                  {description?.length < 150 ? (
                    ''
                  ) : (
                    <span
                      className="box"
                      style={{ ...viewOptionStyle, cursor: 'pointer' }}
                      onClick={() => handleVeiwOption(viewOption)}
                    >
                      {viewOption}
                    </span>
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default Slidercontent;
