import { Grid, Typography } from '@material-ui/core'
import React from 'react'

const AboutImg = () => {
  return (
    <Grid container className='mt-4 mb-3 lg:mb-10 pl-7 pr-7'>
        <Grid item lg={12} md={12} sm={12} xs={12} className='!mt-2 !mb-5'>
        <Typography style={{fontSize:"54px", fontWeight:"700", color:"#0F5E91", textAlign:"center" ,fontFamily: "Space Grotesk"}}
        >
                    ABOUT US
        </Typography>
       

        </Grid>
        <img src={'/aboutimg.png'} alt="About us" />

    </Grid>
  )
}

export default AboutImg