import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { FileUploader } from 'pages/commonComponents/FileUploader/FIleUploader';
import { FileUploaderReadOnly } from 'pages/commonComponents/FileUploaderReadOnly/FileUploaderReadOnly';
import { useRefContext } from 'components/Layout';
import { PreviouslyUploadedFile } from '../../../../components/PreviouslyUploadedFile';
export const CommercialDriversLicense = (props) => {
  const {
    activeStep,
    setActiveStep,
    data,
    setData,
    deleteDocumentAntiDode2,
    inputData,
    setInputData,
    allPreviouslyUploadedDocuments,
    setAllPreviouslyUploadedDocuments,
  } = props;

  const { headerRef } = useRefContext();

  // State
  const [selectedFrontFile, setSelectedFrontFile] = useState(null);
  const [selectedBackFile, setSelectedBackFile] = useState(null);

  // Methods

  const handleIsSelectedState = (fileData, state = true) => {
    let modifiedArr = [...allPreviouslyUploadedDocuments];
    modifiedArr.forEach((item) => {
      if (item.id === fileData.id) {
        item._isSelected = state;
      }
    });
  };

  const handlePreviouslyUploadedPreviewDelete = (item, type) => {
    // set _isSelected to false
    handleIsSelectedState(item, false);
    // remove from the selected state used (to show read only preview)
    if (type === 'FRONT') {
      const newData = { ...data };
      newData.documents.CommercialDriversLicense[0] = null;
      setData(newData);
      // remove inputFile cache as well
      const newInputData = { ...inputData };
      newInputData.documents.CommercialDriversLicense[0] = null;
      setInputData(newInputData);
      setSelectedFrontFile(null);
    }
    if (type === 'BACK') {
      const newData = { ...data };
      newData.documents.CommercialDriversLicense[1] = null;
      setData(newData);
      const newInputData = { ...inputData };
      newInputData.documents.CommercialDriversLicense[1] = null;
      setInputData(newInputData);
      setSelectedBackFile(null);
    }

    handleSetEnabled();
  };
  const handlePreviouslyUploadedDocumentClick = (item, type) => {
    if (type === 'FRONT') {
      if (selectedFrontFile) {
        // deselect the last selected file
        handleIsSelectedState(selectedFrontFile, false);
      }
      // set the newly selected file
      setSelectedFrontFile(item);
      // Set/OverWrite data on documents[key]
      const newData = { ...data };
      newData.documents.CommercialDriversLicense[0] = item;
    }
    if (type === 'BACK') {
      if (selectedBackFile) {
        // deselect the last selected file
        handleIsSelectedState(selectedBackFile, false);
      }
      // set the newly selected file
      setSelectedBackFile(item);
      // Set/OverWrite data on documents[key]
      const newData = { ...data };
      newData.documents.CommercialDriversLicense[1] = item;
    }

    handleSetEnabled();
  };

  const handleSetEnabled = () => {
    // setTimeout(() => {
    //   if (
    //     data.documents.CommercialDriversLicense?.filter((item) => {
    //       return item != null;
    //     }).length === 2
    //   ) {
    //     setEnableButton(false);
    //   } else {
    //     setEnableButton(true);
    //   }
    // }, 50);
  };

  const handleSuccess = (res, index) => {
    const newData = { ...data };
    newData.documents.CommercialDriversLicense[index] = res.resData?.data;

    const newInputData = { ...inputData };

    newInputData.documents.CommercialDriversLicense[index] = {
      file: res.fileData,
      description: res.attachmentDescription,
    };

    // setData - using resData and setInputData is using input elements selected files data object
    setInputData(newInputData);
    setData(newData);
  };

  const [previouslyUploadedDocsAvailable, setPreviouslyUploadedDocsAvailable] =
    useState(false);
  useEffect(() => {
    if (
      allPreviouslyUploadedDocuments.filter((item) => {
        return item.document_type === 'DRIVERS_LICENSE';
      }).length
    ) {
      setPreviouslyUploadedDocsAvailable(true);
    } else {
      console.log('Else Condition');
    }
  }, [allPreviouslyUploadedDocuments]);

  useEffect(() => {
    if (
      data?.documents?.CommercialDriversLicense[0] &&
      !inputData?.documents?.CommercialDriversLicense[0]
    ) {
      setSelectedFrontFile(data?.documents?.CommercialDriversLicense[0]);
    }
    if (
      data?.documents?.CommercialDriversLicense[1] &&
      !inputData?.documents?.CommercialDriversLicense[1]
    ) {
      setSelectedBackFile(data?.documents?.CommercialDriversLicense[1]);
    }
  }, []);

  useEffect(() => {
    handleSetEnabled();
  }, [data?.documents?.CommercialDriversLicense]);
  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const fileIcon = () => {
    return (
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="2" y="2" width="32" height="32" rx="16" fill="#EDEDED" />
        <path
          d="M19.3334 11.5126V14.2663C19.3334 14.6397 19.3334 14.8264 19.406 14.969C19.4699 15.0944 19.5719 15.1964 19.6974 15.2603C19.84 15.333 20.0267 15.333 20.4 15.333H23.1537M23.3334 16.6585V21.4663C23.3334 22.5864 23.3334 23.1465 23.1154 23.5743C22.9236 23.9506 22.6177 24.2566 22.2413 24.4484C21.8135 24.6663 21.2535 24.6663 20.1334 24.6663H15.8667C14.7466 24.6663 14.1865 24.6663 13.7587 24.4484C13.3824 24.2566 13.0764 23.9506 12.8847 23.5743C12.6667 23.1465 12.6667 22.5864 12.6667 21.4663V14.533C12.6667 13.4129 12.6667 12.8529 12.8847 12.425C13.0764 12.0487 13.3824 11.7427 13.7587 11.551C14.1865 11.333 14.7466 11.333 15.8667 11.333H18.0079C18.4971 11.333 18.7416 11.333 18.9718 11.3883C19.1759 11.4373 19.371 11.5181 19.5499 11.6277C19.7518 11.7514 19.9247 11.9244 20.2706 12.2703L22.3961 14.3957C22.742 14.7417 22.9149 14.9146 23.0386 15.1164C23.1483 15.2954 23.2291 15.4905 23.2781 15.6945C23.3334 15.9247 23.3334 16.1693 23.3334 16.6585Z"
          stroke="#757575"
          stroke-width="1.33333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <rect
          x="2"
          y="2"
          width="32"
          height="32"
          rx="16"
          stroke="#F5F5F5"
          stroke-width="4"
        />
      </svg>
    );
  };

  return (
    <div
      className="UploadVideoPS ag-grid"
      style={{
        height: 'calc(100vh - ' + headerRef?.current?.clientHeight + 'px)',
      }}
    >
      <header className="content-header">
        <div className="action-bar flex justify-between p-2">
          <div></div>
          <div className="actions"></div>
        </div>
      </header>
      <div className="main">
        <div className="stepper-content-header py-2 pb-8">
          <h3 className="!font-semibold !text-[16px] !font-[Inter] !text-[#051C2C]">
            Commercial Drivers License
          </h3>
        </div>

        <Grid container className="stepper-content">
          <Grid item lg={6} md={12} sx={12}>
            <p className=" pb-8 text-[#333333] text-[14px]  font-medium	 !font-[Inter]">
              Front
            </p>{' '}
            {selectedFrontFile ? (
              <FileUploaderReadOnly
                fileDataDefault={selectedFrontFile}
                handleDelete={(item) => {
                  handlePreviouslyUploadedPreviewDelete(item, 'FRONT');
                }}
              />
            ) : (
              <FileUploader
                document_type="DRIVERS_LICENSE"
                document_side="FRONT"
                attachmentDescription="FRONT"
                fileDataDefault={
                  inputData?.documents?.CommercialDriversLicense?.length > 0
                    ? inputData?.documents?.CommercialDriversLicense[0]?.file
                    : null
                }
                is_primary={false}
                type={'document'}
                handleSuccess={(res) => {
                  handleSetEnabled();
                  handleSuccess(res, 0);
                }}
                handleError
                handleDelete={
                  () =>
                    deleteDocumentAntiDode2(
                      'CommercialDriversLicense',
                      'DRIVERS_LICENSE',
                      'FRONT'
                    )
                  // deleteDocument('CommercialDriversLicense', 'FRONT')
                }
              />
            )}
          </Grid>
          <Grid item lg={6} md={12} sx={12}>
            <p className=" pb-8 text-[#333333] text-[14px]  font-medium	 !font-[Inter]">
              Back
            </p>{' '}
            {selectedBackFile ? (
              <FileUploaderReadOnly
                fileDataDefault={selectedBackFile}
                handleDelete={(item) => {
                  handlePreviouslyUploadedPreviewDelete(item, 'BACK');
                }}
              />
            ) : (
              <FileUploader
                document_type="DRIVERS_LICENSE"
                document_side="BACK"
                attachmentDescription="BACK"
                fileDataDefault={
                  inputData?.documents?.CommercialDriversLicense?.length > 1
                    ? inputData?.documents?.CommercialDriversLicense[1]?.file
                    : null
                }
                is_primary={false}
                type={'document'}
                handleSuccess={(res) => {
                  handleSetEnabled();
                  handleSuccess(res, 1);
                }}
                handleError
                handleDelete={
                  () =>
                    deleteDocumentAntiDode2(
                      'CommercialDriversLicense',
                      'DRIVERS_LICENSE',
                      'BACK'
                    )
                  // deleteDocument('CommercialDriversLicense', 'Back')
                }
              />
            )}
          </Grid>
        </Grid>
        {previouslyUploadedDocsAvailable ? (
          <div>
            <div className="or-section max-w-[1010px] !my-12"></div>
            <div className="stepper-content-header py-2 pb-10">
              <p className="text-[#051C2C] text-[16px]  font-medium	 !font-[Inter]">
                {' '}
                Choose a document
              </p>
            </div>
            <Grid container className="stepper-content">
              <Grid item lg={6} md={12} sx={12}>
                <p className="pb-8 text-[#333333] text-[14px]  font-medium	 !font-[Inter]">
                  Front
                </p>{' '}
                {allPreviouslyUploadedDocuments?.map((item) => {
                  if (
                    item.document_type === 'DRIVERS_LICENSE' &&
                    item.document_side === 'FRONT'
                  ) {
                    return (
                      <div className="mb-4">
                        <PreviouslyUploadedFile
                          handleSuccess={(item) => {
                            // handleSuccess(res, 0)
                            handlePreviouslyUploadedDocumentClick(
                              item,
                              'FRONT'
                            );
                          }}
                          handleDeSelect={(item) => {
                            handlePreviouslyUploadedPreviewDelete(
                              item,
                              'FRONT'
                            );
                          }}
                          document_type="DRIVERS_LICENSE"
                          document_side="FRONT"
                          fileData={item}
                          allPreviouslyUploadedDocuments={
                            allPreviouslyUploadedDocuments
                          }
                          setAllPreviouslyUploadedDocuments={
                            setAllPreviouslyUploadedDocuments
                          }
                        />
                      </div>
                    );
                  }
                })}
              </Grid>
              <Grid item lg={6} md={12} sx={12}>
                <p className=" pb-8 text-[#333333] text-[14px]  font-medium	 !font-[Inter]">
                  Back
                </p>{' '}
                {allPreviouslyUploadedDocuments?.map((item) => {
                  if (
                    item.document_type === 'DRIVERS_LICENSE' &&
                    item.document_side === 'BACK'
                  ) {
                    return (
                      <div className="mb-4">
                        <PreviouslyUploadedFile
                          handleSuccess={(item) => {
                            handlePreviouslyUploadedDocumentClick(item, 'BACK');
                            // handleSuccess(res, 1)
                          }}
                          handleDeSelect={(item) => {
                            handlePreviouslyUploadedPreviewDelete(item, 'BACK');
                          }}
                          document_type="DRIVERS_LICENSE"
                          document_side="BACK"
                          fileData={item}
                          allPreviouslyUploadedDocuments={
                            allPreviouslyUploadedDocuments
                          }
                          setAllPreviouslyUploadedDocuments={
                            setAllPreviouslyUploadedDocuments
                          }
                        />
                      </div>
                    );
                  }
                })}
              </Grid>
            </Grid>
          </div>
        ) : null}
      </div>
      <footer className="content-footer mb-[200px]">
        <div className="stepper-content-actions-footer flex justify-between">
          <div></div>
          <div className="flex flex-col gap-6">
            <Button
              style={{
                width: '275px',
                minHeight: '44px',
                height: '44px',
              }}
              // disabled={enableButton}
              onClick={handleNext}
              variant="contained"
            >
              Continue
            </Button>
          </div>
        </div>
      </footer>
    </div>
  );
};
