import React from 'react'
import { Grid, Typography } from '@material-ui/core';

const WhyChooseSellMyRig = () => {
  return (
    <>
    <Grid lg={11} md={11} sm={11} xs={11}> 
        <Grid container lg={12} md={12} sm={12} xs={12} style={{justifyContent:"center", paddingBottom:"26px"}}>
           <Grid item>
             <Typography
             style={{fontFamily: 'Space Grotesk', color:"#0F0F0F", fontSize:"32px",fontWeight:"700"}}
             >Why Choose Sell My Rig?</Typography>
           </Grid>

        </Grid>
        <Grid container style={{justifyContent:"space-evenly"}}>
            <Grid item lg={5} md={6} sm={6} xs={12}>
                <Grid>
                    <Typography style={{fontSize:"24px",fontWeight:"600", color:"#03131D",fontFamily: "Inter", paddingBottom:"15px"}}
                    >
                    Specialization in Transportation: 
                    </Typography>
                    <Typography style={{fontSize:"18px", fontWeight:"400", color:"#545454"}}
                    >
                    Specialization in Transportation: Exclusively focused on the transportation sector, tailoring our platform to your needs.
                    </Typography>
                </Grid>
                <Grid className='mt-7'>
                    <Typography style={{fontSize:"24px",fontWeight:"600", color:"#03131D",fontFamily: "Inter", paddingBottom:"15px"}}
                    >
                    Diverse Dealer Network
                    </Typography>
                    <Typography style={{fontSize:"18px", fontWeight:"400", color:"#545454"}}
                    >
                    Access a variety of dealers, providing a diverse selection of equipment.
                    </Typography>
                </Grid>

            </Grid>
            <Grid item lg={5} md={6} sm={6} xs={12} className='sm:!mt-0 !mt-7'>
            <Grid>
                    <Typography style={{fontSize:"24px",fontWeight:"600", color:"#03131D",fontFamily: "Inter", paddingBottom:"15px"}}
                    >
                    User-Friendly Experience:
                    </Typography>
                    <Typography style={{fontSize:"18px", fontWeight:"400", color:"#545454"}}
                    >
                    Navigate easily with our intuitive features and responsive interface, now accessible on iOS and Android apps
                    </Typography>
                </Grid>

                <Grid className='mt-7'>
                    <Typography style={{fontSize:"24px",fontWeight:"600", color:"#03131D",fontFamily: "Inter", paddingBottom:"15px"}}
                    >
                    Transparent Transactions:
                    </Typography>
                    <Typography style={{fontSize:"18px", fontWeight:"400", color:"#545454"}}
                    >
                    Trust is key; sellers provide detailed listings for transparency in every transaction
                    </Typography>
                </Grid>

            </Grid>

        </Grid>
        
    </Grid>
    
    </>
    
    
  )
}

export default WhyChooseSellMyRig