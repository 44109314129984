import React, { useEffect, useRef, useState } from 'react';
import { Button, Grid, Link, Typography, useMediaQuery } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import { axios } from 'utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import './FileDocumentDetail.css';
import { getLocalStorageItem } from 'hooks';
import { useNavigate } from 'react-router';
import { QueryKey } from '../../../query/queryKeys';

const FinanceDocumentDetail = (props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // State
  const [statusClass, setStatusClass] = useState('');
  const [statusString, setStatusString] = useState('');
  const [newId, setNewId] = useState(null);
  const [fileData, setFileData] = useState();
  const [uploadResponse, setUploadResponse] = useState();
  const [progress, setProgress] = React.useState(100);
  const user = getLocalStorageItem('USER_DETAILS');

  // Refs
  const fileInputRef = useRef('fileInputRef');
  const fileInputNewUploadRef = useRef('fileInputNewUploadRef');

  // let statusClass = '';
  // let statusString = '';

  // Props
  const {
    shouldHideDeleteIcon = false,
    documentDetail,
    financeId,
    myIndex,
    handleFreshUpload,
  } = props;

  // Mutations - APIs

  const documentChangeRequest = (newId) => {
    // Need these headers 'content-type': 'multipart/form-data',
    const data = {
      finance_id: financeId,
      old_document_id: documentDetail.id,
      new_document_id: newId,
    };
    const method = 'POST';
    const url = `document-change-request/`;
    return axios({
      method,
      url,
      data,
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        // console.log({ percentCompleted });
        setProgress(percentCompleted);
      },
    });
  };

  const { mutate: changeRequest, isLoading: isPatchDocumentLoading } =
    useMutation(documentChangeRequest, {
      onSuccess: (res) => {
        queryClient.invalidateQueries([QueryKey.GET_FINANCE_DETALS_DATA]);
        // console.log(res, 'uploadAttachmentMutation Success');
        setUploadResponse(res);
        let modifiedObj = {
          resData: res,
          fileData: fileInputRef.current.files[0],
        };
        // call if exist and pass the response
        // typeof handleSuccess === 'function' && handleSuccess(modifiedObj);
      },
      onError: (err) => {
        queryClient.invalidateQueries([QueryKey.GET_FINANCE_DETALS_DATA]);
        setUploadResponse(err);
        // call if exist and pass the error
        let modifiedObj = {
          errData: err,
          fileData: fileInputRef.current.files[0],
        };
        // typeof handleError === 'function' && handleError(modifiedObj);
      },
    });

  const patchDocumentFn = () => {
    // Need these headers 'content-type': 'multipart/form-data',
    const data = {
      document_type: documentDetail?.document_type,
      document_side: documentDetail?.document_side,
      document: fileInputRef.current.files[0],
    };
    const method = 'PATCH';
    const url = `users/${user.id}/documents/${documentDetail.id}/`;
    return axios({
      method,
      url,
      data,
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        // console.log({ percentCompleted });
        setProgress(percentCompleted);
      },
    });
  };

  const { mutate: patchDocuments, isLoading: isDocumentLoading } = useMutation(
    patchDocumentFn,
    {
      onSuccess: (res) => {
        setNewId(res.data.id);
        setUploadResponse(res);
        let modifiedObj = {
          resData: res,
          fileData: fileInputRef.current.files[0],
        };
        // call if exist and pass the response
        // typeof handleSuccess === 'function' && handleSuccess(modifiedObj);
        changeRequest(res.data.id);
      },
      onError: (err) => {
        queryClient.invalidateQueries([QueryKey.GET_FINANCE_DETALS_DATA]);
        setUploadResponse(err);
        // call if exist and pass the error
        let modifiedObj = {
          errData: err,
          fileData: fileInputRef.current.files[0],
        };
        // typeof handleError === 'function' && handleError(modifiedObj);
      },
    }
  );

  const postDocumentFn = () => {
    // Need these headers 'content-type': 'multipart/form-data',
    const data = {
      document_type: documentDetail?.document_type,
      document_side: documentDetail?.document_side || 'NONE',
      document: fileInputNewUploadRef.current.files[0],
    };
    const method = 'POST';
    const url = `users/${user.id}/documents/`;
    return axios({
      method,
      url,
      data,
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        // console.log({ percentCompleted });
        setProgress(percentCompleted);
      },
    });
  };

  const { mutate: postDocuments, isLoading: isPostDocumentLoading } =
    useMutation(postDocumentFn, {
      onSuccess: (res) => {
        setNewId(res.data.id);
        setUploadResponse(res);
        let modifiedObj = {
          resData: res,
          fileData: fileInputNewUploadRef.current.files[0],
        };
        // call if exist and pass the response
        // typeof handleSuccess === 'function' && handleSuccess(modifiedObj);
        // changeRequest(res.data.id);
        handleFreshUpload(res.data.id, myIndex);
      },
      onError: (err) => {
        queryClient.invalidateQueries([QueryKey.GET_FINANCE_DETALS_DATA]);
        setUploadResponse(err);
        // call if exist and pass the error
        let modifiedObj = {
          errData: err,
          fileData: fileInputRef.current.files[0],
        };
        // typeof handleError === 'function' && handleError(modifiedObj);
      },
    });

  //   Methods
  const handleClick = () => {
    fileInputRef.current.click();
  };
  const handlePostDocClick = () => {
    fileInputNewUploadRef.current.click();
  };
  const handleFileNewUploadElChange = (event) => {
    // Get the file and set its data in state
    let file = event.target.files[0];
    setFileData(file);

    // Validations

    // Call the API and upload the file using either attachment or document POST API
    postDocuments();
  };
  const handleFileElChange = (event) => {
    // Get the file and set its data in state
    let file = event.target.files[0];
    setFileData(file);
    patchDocuments();
  };
  const getStatus = () => {
    switch (documentDetail.status) {
      case 'APPROVAL_PENDING':
        setStatusClass('pending');
        setStatusString('Approval Pending');
        break;
      case 'APPROVED':
        setStatusClass('approved');
        setStatusString('Approved');
        break;
      case 'REJECTED':
        setStatusClass('rejected');
        setStatusString('Document is Rejected');
        break;
      case 'CHANGE_REQUEST_PENDING':
        setStatusClass('changeRequest');
        setStatusString('Change Requested');
        break;
      case 'DOCUMENT_CHANGED':
        setStatusClass('changed');
        setStatusString('Document Changed');
        break;
      default:
        setStatusClass('approved');
        setStatusString('Approval Pending');
    }
  };

  const handleView = () => {
    window.open(documentDetail.document, '_blank');
  };

  //   Lifecycle
  useEffect(() => {
    getStatus();
  }, [documentDetail?.status]);

  //   Components
  const fileIcon = () => {
    return (
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="1" y="1" width="32" height="32" rx="16" fill="#EDEDED" />
        <path
          d="M17.667 10.334H13.0003C12.6467 10.334 12.3076 10.4745 12.0575 10.7245C11.8075 10.9746 11.667 11.3137 11.667 11.6673V22.334C11.667 22.6876 11.8075 23.0267 12.0575 23.2768C12.3076 23.5268 12.6467 23.6673 13.0003 23.6673H21.0003C21.3539 23.6673 21.6931 23.5268 21.9431 23.2768C22.1932 23.0267 22.3337 22.6876 22.3337 22.334V15.0007M17.667 10.334L22.3337 15.0007M17.667 10.334V15.0007H22.3337"
          stroke="#757575"
          stroke-width="1.33333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <rect
          x="1"
          y="1"
          width="32"
          height="32"
          rx="16"
          stroke="#F5F5F5"
          stroke-width="2"
        />
      </svg>
    );
  };

  const uploadFileIcon = () => {
    return (
      <svg
        width="46"
        height="46"
        viewBox="0 0 46 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="3" y="3" width="40" height="40" rx="20" fill="#EDEDED" />
        <g clip-path="url(#clip0_5361_39860)">
          <path
            d="M26.3335 26.3332L23.0002 22.9999M23.0002 22.9999L19.6669 26.3332M23.0002 22.9999V30.4999M29.9919 28.3249C30.8047 27.8818 31.4467 27.1806 31.8168 26.3321C32.1868 25.4835 32.2637 24.5359 32.0354 23.6388C31.807 22.7417 31.2865 21.9462 30.5558 21.3778C29.8251 20.8094 28.9259 20.5005 28.0002 20.4999H26.9502C26.698 19.5243 26.2278 18.6185 25.5752 17.8507C24.9225 17.0829 24.1042 16.4731 23.182 16.0671C22.2597 15.661 21.2573 15.4694 20.2503 15.5065C19.2433 15.5436 18.2578 15.8085 17.3679 16.2813C16.4779 16.7541 15.7068 17.4225 15.1124 18.2362C14.518 19.05 14.1158 19.9879 13.936 20.9794C13.7563 21.9709 13.8036 22.9903 14.0746 23.961C14.3455 24.9316 14.8329 25.8281 15.5002 26.5832"
            stroke="#5B96BB"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <rect
          x="3"
          y="3"
          width="40"
          height="40"
          rx="20"
          stroke="#F5F5F5"
          stroke-width="6"
        />
        <defs>
          <clipPath id="clip0_5361_39860">
            <rect
              width="20"
              height="20"
              fill="white"
              transform="translate(13 13)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  };

  const actionTriggerIcon = () => {
    return (
      <svg
        width="4"
        height="16"
        viewBox="0 0 4 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.00033 8.83203C2.46056 8.83203 2.83366 8.45894 2.83366 7.9987C2.83366 7.53846 2.46056 7.16536 2.00033 7.16536C1.54009 7.16536 1.16699 7.53846 1.16699 7.9987C1.16699 8.45894 1.54009 8.83203 2.00033 8.83203Z"
          stroke="#B0B0B0"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.00033 2.9987C2.46056 2.9987 2.83366 2.6256 2.83366 2.16536C2.83366 1.70513 2.46056 1.33203 2.00033 1.33203C1.54009 1.33203 1.16699 1.70513 1.16699 2.16536C1.16699 2.6256 1.54009 2.9987 2.00033 2.9987Z"
          stroke="#B0B0B0"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.00033 14.6654C2.46056 14.6654 2.83366 14.2923 2.83366 13.832C2.83366 13.3718 2.46056 12.9987 2.00033 12.9987C1.54009 12.9987 1.16699 13.3718 1.16699 13.832C1.16699 14.2923 1.54009 14.6654 2.00033 14.6654Z"
          stroke="#B0B0B0"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  };

  //   Template

  return (
    <>
      {documentDetail.document ? (
        <div className="FileDocumentDetailWrapper">
          <div className="icon-wrapper">{fileIcon()}</div>
          <div className="content-area">
            <div className="meta-info">
              <div
                className="file-name"
                title={documentDetail?.document_key?.split('/')[1] || 'Unnamed'}
              >
                {documentDetail?.document_key?.split('/')[1]?.substring(0, 15) +
                  '...' || 'Unnamed'}
              </div>
              <div className="file-size">{progress}% uploaded</div>
            </div>
            <div className="status-wrapper">
              <div className={'status-chip ' + statusClass}>{statusString}</div>
            </div>
          </div>
          <div className="action-wrapper">
            <div className="action-trigger">
              {actionTriggerIcon()}
              <div className="action-menu">
                <div className="action-item" onClick={handleView}>
                  View
                </div>
                <div className="action-item" onClick={handleClick}>
                  Re Upload
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="FileDocumentDetailWrapper dashed"
          onClick={handlePostDocClick}
        >
          <div className="upload-new h-full">
            {uploadFileIcon()}
            <span
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '14px',
                color: '#0F5E91',
              }}
            >
              Click to upload
            </span>
          </div>
        </div>
      )}
      <input
        ref={fileInputRef}
        onChange={handleFileElChange}
        className="hidden"
        type="file"
      />
      <input
        ref={fileInputNewUploadRef}
        onChange={handleFileNewUploadElChange}
        className="hidden"
        type="file"
      />
    </>
  );
};

export { FinanceDocumentDetail };
