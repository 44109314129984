import { CircularProgress, Grid, Typography } from '@mui/material';
import { CustomTextField } from 'components';
import CustomButton from 'components/CustomButton/CustomButton';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PasswordStrengthBar from 'react-password-strength-bar';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { commonToastConfig } from 'utils/CommonToastConfig';
import { useResetPasswordQuery } from 'query/Auth';
import useToast from 'utils/responseToast';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRefContext } from 'components/Layout';
const PasswordSchema = yup.object().shape({
  password: yup
    .string()
    .nullable()
    .min(8, 'Password must be at least 8 characters')
    .max(32, 'Password should not exceed 32 characters')
    .matches(/([a-z])/, {
      message: 'Atleast one small letter',
      name: 'small letter',
      excludeEmptyString: true,
    })
    .matches(/[A-Z]/, 'Atleast one capital letter')
    .matches(/(\d)/, 'Atleast one number')
    .matches(/(\W)/, 'Atleast one special character')
    .required('Password is required'),
    confirm_password:yup.string().nullable().required('Please enter Confirm Password').oneOf([yup.ref('password'), null], 'Passwords must match')
});

export const SetNewPassword = ({
  prefilled,
  setforgotPassword,
  setSuccess,
  setOpenNewPasswordForm,
}) => {
  const handleOpenSomethingWentWrongDialog= useRefContext()
  
  const navigate = useNavigate();
  const [data, setData] = useState({
    password: '',
    confirm_password: '',
  });
  const {
    register,
    control,setValue,
    handleSubmit,
    formState: { errors },
    setError: setResetPasswordError,
  } = useForm({ resolver: yupResolver(PasswordSchema) });
 
  function resetPasswordOnSuccess(res) {
    setOpenNewPasswordForm(false);
    setSuccess(true);
  }
  function resetPasswordOnError(err) {
    if(err.status_code==500){
      handleOpenSomethingWentWrongDialog()
    }
    setResetPasswordError(err.errors[0].message);
  }
  const { mutate: resetPassword, isLoading } = useResetPasswordQuery(
    resetPasswordOnSuccess,
    resetPasswordOnError
  );
 
 

  const handleResetPassword = (data) => {
    resetPassword({
      [prefilled?.phone ? 'phone' : 'email']: prefilled?.phone
        ? prefilled.phone
        : prefilled.email,
      ...data,
    });
  };
  
  const handleBacktoLogin = () => {
    setforgotPassword(false);
  };
  const handleSetValue = (key, value) => {
    setValue(key, value, {
      shouldValidate: true,
    });
  };

  return (
    <>
      <Grid className="text-center m-auto" md={12} xs={12} lg={12} item>
        <img
          className="w-[56px] h-[56px] text-center"
          src="/assets/Auth/ForgotPasswordKey.svg"
          alt="icon"
        />
      </Grid>
      <Grid item>
        <Typography variant="h4" className="!font-semibold !font-[Inter] pt-4">
          Set New Password
        </Typography>
      </Grid>
      <Grid item>
        <Typography className=" text-center text-gray-60 py-4">
          Your new password must be different to previously used passwords.
        </Typography>
      </Grid>
      <form onSubmit={handleSubmit(handleResetPassword)}>
        <Grid
          justifyContent="center"
          alignItems="center"
          spacing={2}
          className="py-6"
          container
          item
        >
          <Grid lg={12} className="w-[100%]" item>
            <CustomTextField
              type="password"
              label="Password"
              placeholder="Enter Password"
              className="max-w-[400px]"
              error={Boolean(errors?.password)}
              FormHelperTextProps={{
                className: 'animateErrorValue ',
              }}
              helperText={errors?.password?.message}
              onChange={(e) => handleSetValue('password', e.target.value)}
            />
          </Grid>
          <Grid className="w-[100%]" lg={12} item>
            <CustomTextField
              type="password"
              label="Confirm Password"
              placeholder="Enter Password"
              className="max-w-[400px]"
              error={Boolean(errors?.confirm_password)}
              FormHelperTextProps={{
                className: 'animateErrorValue ',
              }}
              helperText={errors?.confirm_password?.message}
              onChange={(e) =>
                handleSetValue('confirm_password', e.target.value,true)
              }
            />
          </Grid>
          <Grid justifyContent="center" className="w-[100%]" lg={12} item>
            {isLoading ? (
              <CustomButton
                styles="max-h-[44px] w-full max-w-[360px] !text-[15px] font-medium my-5"
                fullWidth
              >
                <CircularProgress size={30} className="!text-white" />
              </CustomButton>
            ) : (
              <CustomButton styles="w-full max-w-[360px]" type="submit">
                Reset Password
              </CustomButton>
            )}
          </Grid>
          <Grid
            onClick={handleBacktoLogin}
            item
            justifyContent="center"
            container
            lg={12}
            md={12}
            xs={12}
            className="!pt-8"
          >
            <ArrowBackIcon className="px-1 text-gray-90" />
            <Typography className="text-sm  justify-center ">
              <span className="text-sm font-medium text-gray-90 cursor-pointer">
                Back to Log in
              </span>
            </Typography>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
