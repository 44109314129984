import { useGetProductDetails } from 'query/productDetails';
import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Grid,
  Button,
  Box,
  Popover,
  MenuList,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
  Divider,
} from '@mui/material';
import ImagSliderComponent from 'pages/SMRRInfo/EquipmentInfo/Imagslider';
import Slidercontent from 'pages/SMRRInfo/EquipmentInfo/SliderContent';
import TableGrid from 'pages/SMRRInfo/TableGrid';
import { getLocalStorageItem } from 'hooks';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useCreateFavourites, useDeleteFavourites } from 'query/favourites';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKey } from 'query/queryKeys';

const shareOptions = [
  { label: 'Email', icon: 'assets/icons/share_mail.svg', value: 'mail' },
  { label: 'Twitter', icon: 'assets/icons/twitter.svg', value: 'twitter' },
  {
    label: 'Instagram',
    icon: 'assets/icons/instagram.svg',
    value: 'instagram',
  },
  { label: 'LinkedIn', icon: 'assets/icons/linkedin.svg', value: 'linkedin' },
  { label: 'Facebook', icon: 'assets/icons/facebook.svg', value: 'facebook' },
  { label: 'Copy Link', icon: 'assets/icons/link.svg', value: 'copy_link' },
];

export const ProductDetail = () => {
  const queryClient = useQueryClient();
  const isAuth = getLocalStorageItem('AUTH_TOKEN');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const productId = searchParams.get('id');
  const { data: InventoryDetails } = useGetProductDetails(productId);
  const [likeStatus, setLikedStatus] = useState(false);
  const { mutate: AddToFavourites } = useCreateFavourites();
  const { mutate: RemoveFromFavourites } = useDeleteFavourites();
  const theme = useTheme();
  const XSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const isLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    setLikedStatus(InventoryDetails?.data?.is_favourite);
  }, [InventoryDetails]);

  const handleHeart = (action, id) => (e) => {
    e.stopPropagation();
    if (!isAuth || isAuth === 'undefined') {
      navigate('/login');
      return;
    }
    if (action === 'like') {
      AddToFavourites(
        {
          listing: id,
        },
        {
          onSuccess: (res) => {
            setLikedStatus(true);
            queryClient.invalidateQueries([
              QueryKey.GET_PRODUCT_DETALS_DATA,
              id,
            ]);
            queryClient.invalidateQueries([QueryKey.GET_FAVOURITE_DATA]);
          },
          onError: (err) => {},
        }
      );
    } else if (action === 'unlike') {
      RemoveFromFavourites(
        {
          id: id,
        },
        {
          onSuccess: (res) => {
            setLikedStatus(false);
            queryClient.invalidateQueries([
              QueryKey.GET_PRODUCT_DETALS_DATA,
              id,
            ]);
            queryClient.invalidateQueries([QueryKey.GET_FAVOURITE_DATA]);
          },
          onError: (err) => {},
        }
      );
    }
  };

  const [currentShareLinkDetailData, setCurrentShareLinkDetailData] = useState(null);
  const [copiedSuccess, setCopySuccess] = useState(false);
  const [OpenShare, setOpenShare] = React.useState(null);
  const handleShare = (event, data) => {
    event.stopPropagation();
    setOpenShare(event.currentTarget);
    setCurrentShareLinkDetailData(data);
  };

  const handleCloseShare = () => {
    setOpenShare(null);
  };
  const OpenShareList = Boolean(OpenShare);
  function getMailtoUrl(to, subject, body) {
    const args = [];
    if (typeof subject !== 'undefined') {
      args.push('subject=' + encodeURIComponent(subject));
    }
    if (typeof body !== 'undefined') {
      args.push('body=' + encodeURIComponent(body));
    }
    let url = 'mailto:' + encodeURIComponent(to);
    if (args.length > 0) {
      url += '?' + args.join('&');
    }
    return url;
  }
  const handleSharing = (el) => (e) => {
    e.stopPropagation();
    let link =
      process.env.REACT_APP_URL +
      'inventory-details?id=' +
      currentShareLinkDetailData.id;
    const imageUrl = currentShareLinkDetailData.attachments?.[0]?.attachment;
    const copyLinkAndImage = async () => {
      try {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.src = imageUrl + '?not-from-cache-please';

        img.onload = async () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);

          // Convert canvas image to blob (PNG format)
          canvas.toBlob(async (pngBlob) => {
            const data = [
              new ClipboardItem({
                'text/plain': new Blob([link], { type: 'text/plain' }),
                'image/png': pngBlob,
              }),
            ];

            await navigator.clipboard.write(data);
            console.log('Link and image copied successfully!');
          }, 'image/png');
        };

        img.onerror = (errorEvent) => {
          console.error('Error loading the image.', errorEvent);
        };
      } catch (error) {
        console.error('Error copying to clipboard', error);
      }
    };

    switch (el.value) {
      case 'mail':
        {
          window.location.href = getMailtoUrl(
            'no-reply@example.com',
            'Check this Rig out',
            link
          );
        }
        break;
      case 'twitter':
        {
          var url =
            'https://twitter.com/share?url=' +
            encodeURIComponent(link) +
            '&t=Share on Twitter';
          window.open(url, '_blank');
        }
        break;
      case 'instagram':
        {
          var url =
            'https://www.instagram.com/?url=' + encodeURIComponent(link);
          window.open(url, '_blank');
        }
        break;
      case 'linkedin':
        {
          var url =
            'https://www.linkedin.com/share?url=' +
            encodeURIComponent(link) +
            '&t=Share on Linked In';
          window.open(url, '_blank');
        }
        break;
      case 'facebook':
        {
          var url =
            'https://www.facebook.com/sharer/sharer.php?u=' +
            encodeURIComponent(link) +
            '&t=Share on Facebook';
          window.open(url, '_blank');
        }
        break;
      case 'copy_link': {
        copyLinkAndImage();
        setCopySuccess(true);

        setTimeout(() => {
          setCopySuccess(false);
        }, 2000);
      }
    }
  };

  const handleEmail = () => {
    let link =
      process.env.REACT_APP_URL +
      'inventory-details?id=' +
      InventoryDetails?.data?.id;
    // Using window.open to open the mailto link in a new window/tab
    window.open(
      getMailtoUrl('no-reply@example.com', 'Check this Rig out', link)
    );
  };
  return (
    <Box sx={{ padding: '10px' }}>
      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          overflowY: 'scroll',
          height: '500px',
          marginTop: '50px',
        }}
        className={`${!isMedium ? '!h-[500px] ' : '!h-auto'}`}
      >
        <Grid
          item
          container
          lg={10}
          md={12}
          className="shadow-md "
          style={{ paddingBottom: '20px', borderRadius: '15px' }}
        >
          {InventoryDetails?.data?.status === 'PRODUCT_SOLD' && (
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              style={{ paddingBottom: '10px', paddingLeft: '10px' }}
            >
              <Grid
                item
                lg={4}
                md={6}
                sm={12}
                style={{
                  marginTop: '23px',
                  textAlign: 'center',
                  paddingRight: '40px',
                }}
              >
                <Button
                  className="completeBtnStyle"
                  style={{
                    width: '380px',
                    textTransform: 'none',
                    height: '32px',
                  }}
                >
                  Product Sold
                </Button>
              </Grid>
            </Grid>
          )}
          <Grid item lg={5} md={12} sm={12}>
            <ImagSliderComponent vehicalInfo={InventoryDetails} />
          </Grid>
          <Grid
            item
            lg={6}
            md={12}
            sm={12}
            style={{ marginTop: '6px', marginLeft: '-10px' }}
            className={`${!isMedium ? '!ml-0 ' : '!ml-[-10px] '}sliderCnt`}
          >
            <Slidercontent
              vehicalInfo={InventoryDetails}
              tabType="productDetails"
            />
          </Grid>
          <Grid
            item
            container
            lg={1}
            md={2}
            sm={2}
            xs={3}
            justifyContent="right"
            gap={2}
            className={`${
              !isMedium ? '!pl-4 !justify-start' : 'pl-3 justify-start'
            }`}
          >
            <Grid item style={{ paddingTop: '7px' }}>
              <img
                src={'/assets/shareicon.svg'}
                style={{ cursor: 'pointer' }}
                alt="share"
                onClick={(e) => handleShare(e, InventoryDetails?.data)}
              />
            </Grid>

            <Grid item style={{ paddingTop: '7px' }}>
              {likeStatus ? (
                <span
                  onClick={handleHeart('unlike', InventoryDetails?.data?.id)}
                  style={{ cursor: 'pointer' }}
                >
                  <FavoriteIcon className="text-[#0F5E91]" />
                </span>
              ) : (
                <span
                  onClick={handleHeart('like', InventoryDetails?.data?.id)}
                  style={{ cursor: 'pointer' }}
                >
                  <FavoriteBorderIcon className="text-[#0F5E91]" />
                </span>
              )}
            </Grid>
            <Popover
              open={OpenShareList}
              anchorEl={OpenShare}
              onClose={handleCloseShare}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <MenuList>
                {shareOptions?.map((el, index) => {
                  return (
                    <>
                      <MenuItem
                        onClick={handleSharing(el)}
                        justifyContent="left"
                        className=" h-10 !w-[189px] flex !items-center !text-left "
                      >
                        <img className="w-6 h-5 pr-2" src={el.icon} alt="" />
                        <Typography className="!items-center flex align-center !m-0 text-left">
                          {el.value === 'copy_link' && copiedSuccess ? (
                            <span className="text-green-500">Link Copied!</span>
                          ) : (
                            el.label
                          )}
                        </Typography>
                      </MenuItem>
                      {index !== shareOptions.length - 1 && (
                        <Divider className="!p-0 !m-0 !bg-gray-10" />
                      )}
                    </>
                  );
                })}
              </MenuList>
            </Popover>
          </Grid>
          <Grid item lg={10} md={12} sm={12}>
            <div className="p-4 mb-4">
              <h2 className="text-lg font-bold mb-4 !font-Inter">
                Seller Details
              </h2>

              <div className="grid grid-cols-2 gap-4">
                {InventoryDetails?.data?.user?.name && (
                  <>
                    <span className="text-gray-600">Name:</span>
                    <span>{InventoryDetails?.data?.user?.name}</span>
                  </>
                )}

                {InventoryDetails?.data?.user?.email && (
                  <>
                    <span className="text-gray-600">E-mail:</span>
                    <div className="cursor-pointer" onClick={handleEmail}>
                      <p className="text-blue-600 underline">
                        {InventoryDetails?.data?.user?.email}
                      </p>
                    </div>
                  </>
                )}
                {InventoryDetails?.data?.user?.phone && (
                  <>
                    <span className="text-gray-600">Phone Number:</span>
                    <span>{InventoryDetails?.data?.user?.phone}</span>
                  </>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
       

        <Grid item lg={10} md={12} sm={12} className="!mt-5 ">
          <TableGrid vehicalInfo={InventoryDetails} />
        </Grid>
      </Grid>
    </Box>
  );
};
