import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { FileUploader } from 'pages/commonComponents/FileUploader/FIleUploader';
import { useRefContext } from 'components/Layout';
import { PreviouslyUploadedFile } from '../../../../components/PreviouslyUploadedFile';
import { FileUploaderReadOnly } from '../../../commonComponents/FileUploaderReadOnly/FileUploaderReadOnly';
export const TaxReturn = (props) => {
  const {
    activeStep,
    setActiveStep,
    data,
    setData,
    deleteAttachment,
    deleteAttachmentAntiDode,
    deleteDocumentAntiDode,
    inputData,
    setInputData,
    allPreviouslyUploadedDocuments,
    setAllPreviouslyUploadedDocuments,
  } = props;
  const { headerRef, navRef } = useRefContext();
  const [enableButton, setEnableButton] = useState(true);
  const handleSuccess = (res, index) => {
    const newData = { ...data };
    newData.documents.TaxReturn[index] = res.resData?.data;

    const newInputData = { ...inputData };

    newInputData.documents.TaxReturn[index] = {
      file: res.fileData,
      description: res.attachmentDescription,
    };

    // setData - using resData and setInputData is using input elements selected files data object
    setInputData(newInputData);
    setData(newData);
  };

  // State
  const [selectedFrontFile, setSelectedFrontFile] = useState(null);
  const [selectedBackFile, setSelectedBackFile] = useState(null);

  // Methods

  const handleIsSelectedState = (fileData, state = true) => {
    let modifiedArr = [...allPreviouslyUploadedDocuments];
    modifiedArr.forEach((item) => {
      if (item.id === fileData.id) {
        item._isSelected = state;
      }
    });
  };

  const handlePreviouslyUploadedPreviewDelete = (item, type) => {
    // set _isSelected to false
    handleIsSelectedState(item, false);
    // remove from the selected state used (to show read only preview)
    if (type === 'FRONT') {
      const newData = { ...data };
      newData.documents.TaxReturn[0] = null;
      setData(newData);
      // remove inputFile cache as well
      const newInputData = { ...inputData };
      newInputData.documents.TaxReturn[0] = null;
      setInputData(newInputData);
      setSelectedFrontFile(null);
    }
    if (type === 'BACK') {
      const newData = { ...data };
      newData.documents.TaxReturn[1] = null;
      setData(newData);
      const newInputData = { ...inputData };
      newInputData.documents.TaxReturn[1] = null;
      setInputData(newInputData);
      setSelectedBackFile(null);
    }

    handleSetEnabled();
  };

  const [fileSelectCount, setFileSelectCount] = useState(0);
  const [fileDeSelectCount, setFileDeSelectCount] = useState(0);
  const handleLoadBalancerSuccess = (item) => {
    setFileSelectCount(1 + fileSelectCount);
    if (fileSelectCount % 2 === 0) {
      handlePreviouslyUploadedDocumentClick(item, 'BACK');
    } else {
      handlePreviouslyUploadedDocumentClick(item, 'FRONT');
    }
  };

  const handleLoadBalancerDeSelect = (item) => {
    setFileDeSelectCount(1 + fileDeSelectCount);
    if (fileDeSelectCount % 2 === 0) {
      handlePreviouslyUploadedPreviewDelete(item, 'BACK');
    } else {
      handlePreviouslyUploadedPreviewDelete(item, 'FRONT');
    }
  };

  const handlePreviouslyUploadedDocumentClick = (item, type) => {
    if (type === 'FRONT') {
      if (selectedFrontFile) {
        // deselect the last selected file
        handleIsSelectedState(selectedFrontFile, false);
      }
      // set the newly selected file
      setSelectedFrontFile(item);
      // Set/OverWrite data on documents[key]
      const newData = { ...data };
      newData.documents.TaxReturn[0] = item;
    }
    if (type === 'BACK') {
      if (selectedBackFile) {
        // deselect the last selected file
        handleIsSelectedState(selectedBackFile, false);
      }
      // set the newly selected file
      setSelectedBackFile(item);
      // Set/OverWrite data on documents[key]
      const newData = { ...data };
      newData.documents.TaxReturn[1] = item;
    }

    handleSetEnabled();
  };

  const handleSetEnabled = () => {
    // setTimeout(() => {
    //   if (
    //     data.documents.TaxReturn?.filter((item) => {
    //       return item != null;
    //     }).length === 2
    //   ) {
    //     setEnableButton(false);
    //   } else {
    //     setEnableButton(true);
    //   }
    // }, 50);
  };

  useEffect(() => {
    handleSetEnabled();
  }, [data.attachment?.TaxReturn]);

  const [previouslyUploadedDocsAvailable, setPreviouslyUploadedDocsAvailable] =
    useState(false);
  useEffect(() => {
    if (
      allPreviouslyUploadedDocuments.filter((item) => {
        return item.document_type === 'TAX_RETURN';
      }).length
    ) {
      //setPreviouslyUploadedDocsAvailable(true);
    } else {
      console.log('Else Condition');
    }
  }, [allPreviouslyUploadedDocuments]);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };
  return (
    <div
      className="UploadVideoPS ag-grid"
      style={{
        height: 'calc(100vh - ' + headerRef?.current?.clientHeight + 'px)',
      }}
    >
      <header className="content-header">
        <div className="action-bar flex justify-between p-2">
          <div></div>
          <div className="actions"></div>
        </div>
      </header>
      <div className="main">
        <div className="stepper-content-header py-2 pb-8">
          <p className="text-[#051C2C] text-[16px]  font-medium	 !font-[Inter]">
            Tax Return
          </p>
        </div>

        <Grid container className="stepper-content">
          <Grid item lg={6} md={12} sx={12}>
            <Typography className="text-sm py-2 text-gray-50">
              2 years of most recent tax returns
            </Typography>
            <div className="mt-6">
              {selectedFrontFile ? (
                <FileUploaderReadOnly
                  fileDataDefault={selectedFrontFile}
                  handleDelete={(item) => {
                    handlePreviouslyUploadedPreviewDelete(item, 'FRONT');
                  }}
                />
              ) : (
                <FileUploader
                  attachmentDescription="document"
                  fileDataDefault={
                    inputData?.documents?.TaxReturn?.length > 0
                      ? inputData?.documents?.TaxReturn[0]?.file
                      : null
                  }
                  is_primary={false}
                  type={'document'}
                  document_type="TAX_RETURN"
                  document_side="NONE"
                  handleSuccess={(res) => {
                    handleSetEnabled();
                    handleSuccess(res, 0);
                  }}
                  handleError
                  handleDelete={() => {
                    handleSetEnabled();
                    deleteDocumentAntiDode('TaxReturn', 0);
                  }}
                />
              )}
            </div>
            <div className="mt-4">
              {selectedFrontFile ? (
                <FileUploaderReadOnly
                  fileDataDefault={selectedBackFile}
                  handleDelete={(item) => {
                    handlePreviouslyUploadedPreviewDelete(item, 'BACK');
                  }}
                />
              ) : (
                <FileUploader
                  attachmentDescription="document"
                  fileDataDefault={
                    inputData?.documents?.TaxReturn?.length > 1
                      ? inputData?.documents?.TaxReturn[1]?.file
                      : null
                  }
                  is_primary={false}
                  type={'document'}
                  document_type="TAX_RETURN"
                  document_side="NONE"
                  handleSuccess={(res) => {
                    handleSetEnabled();
                    handleSuccess(res, 1);
                  }}
                  handleError
                  handleDelete={() => {
                    handleSetEnabled();
                    deleteDocumentAntiDode('TaxReturn', 1);
                  }}
                />
              )}
            </div>
          </Grid>
        </Grid>
        {previouslyUploadedDocsAvailable ? (
          <div>
            <div className="or-section max-w-[1010px] !my-12"></div>
            <div className="stepper-content-header py-2 pb-8">
              <p className="text-[#051C2C] text-[16px]  font-medium	 !font-[Inter]">
                {' '}
                Choose a document
              </p>
            </div>
            <Grid container item lg={12} md={12} xs={12}>
              <Grid item lg={6} md={12} sx={12}>
                {allPreviouslyUploadedDocuments?.map((item, index) => {
                  if (
                    item.document_type === 'TAX_RETURN' &&
                    item.document_side === 'NONE'
                  ) {
                    return (
                      <div className="mb-4" key={index}>
                        <PreviouslyUploadedFile
                          handleSuccess={(item) => {
                            // handleSuccess(res, 0)
                            // handlePreviouslyUploadedDocumentClick(item, 'FRONT');
                            handleLoadBalancerSuccess(item);
                          }}
                          handleDeSelect={(item) => {
                            handleLoadBalancerDeSelect(item);
                          }}
                          document_type="TAX_RETURN"
                          document_side="NONE"
                          fileData={item}
                          allPreviouslyUploadedDocuments={
                            allPreviouslyUploadedDocuments
                          }
                          setAllPreviouslyUploadedDocuments={
                            setAllPreviouslyUploadedDocuments
                          }
                        />
                      </div>
                    );
                  }
                })}
              </Grid>
            </Grid>
          </div>
        ) : null}
      </div>
      <footer className="content-footer mb-[200px]">
        <div className="stepper-content-actions-footer flex justify-between">
          <div></div>
          <div className="flex flex-col gap-6">
            <Button
              style={{
                width: '275px',
                minHeight: '44px',
                height: '44px',
              }}
              // disabled={enableButton}
              onClick={handleNext}
              variant="contained"
            >
              Continue
            </Button>
          </div>
        </div>
      </footer>
    </div>
  );
};
