import React, { useMemo, useState, useEffect } from 'react';
import { Grid,Typography, Box, Skeleton } from '@mui/material';
import EquipmentCard from '../EquipmentInfo/EquipmentInfo';
import { useGetAllEquipmentData } from 'query/Equipmemnts';
import HeaderMenu from '../../SMRRInfo/HeaderMenu';
import NoDataFoundIcon  from 'components/NoDataFoundIcon';

const MyEquipment = (props) => {
  const [isPage, setIsPage] = useState(1);
  const {
    data: EquipmentList,
    refetch,
    isLoading,
  } = useGetAllEquipmentData(props.userId, isPage);
  const equipData = EquipmentList?.results || [];
  console.log('equipData', equipData);

  useEffect(() => {
    refetch(isPage);
  }, [refetch]);

  const handleReloadData = () => {
    refetch();
  };

  return (
    <Box>
        {isLoading ? (
            <Grid container lg={11} md={12} sm={12}>
                <Grid item lg={12} md={12} xs={12}>
                    {' '}
                    <Skeleton height="266px" width="100%" />{' '}
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                    {' '}
                    <Skeleton height="266px" width="100%" />{' '}
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                    {' '}
                    <Skeleton height="266px" width="100%" />{' '}
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                    {' '}
                    <Skeleton height="266px" width="100%" />{' '}
                </Grid>
            </Grid>
        ) : (
            <>
            { equipData?.length > 0 ? (
                <>
                {equipData?.map((qouteData, index) => {
                    return (
                        <Grid
                        container
                        lg={11}
                        md={12}
                        sm={12}
                        key={index}
                        sx={{ paddingBottom: '20px' }}
                        >
                        <Grid item lg={12} md={12} sm={12}>
                            <HeaderMenu
                            dateString={qouteData?.created_at}
                            listingid={qouteData?.id}
                            handleReloadData={handleReloadData}
                            />
                        </Grid>
                        <Grid
                            container
                            className="w-[100%] !shadow-sm"
                            style={{ marginTop: '10px', paddingLeft: '10px' }}
                            key={index}
                        >
                            <EquipmentCard
                            id={qouteData?.id}
                            listing_id={qouteData?.id}
                            unique_id={qouteData?.unique_id}
                            description={qouteData?.description}
                            validValue={qouteData?.price ? qouteData?.price : ''}
                            firstBtnVal={qouteData?.status}
                            country={qouteData?.country ? qouteData?.country : ''}
                            state={qouteData?.state ? qouteData?.state : ''}
                            city={qouteData?.city ? qouteData?.city : ''}
                            zipcode={qouteData?.zip_code ? qouteData?.zip_code : ''}
                            isQuoteTab={false}
                            price={qouteData?.inspection_fee}
                            title={qouteData?.title}
                            attachments={[qouteData?.attachments]}
                            />
                        </Grid>
                        </Grid>
                    );
                })}
                </>
            ) : (
                <Grid container lg={11} md={12} sm={12}>
                    <Box
                        sx={{
                        margin: '0 auto',
                        display: 'table',
                        width: '100%',
                        paddingTop: '10px',
                        justifyContent: 'center',
                        }}
                    >
                        <Box className="text-center ">
                        <Typography
                            variant="h4"
                            className="text-secondary-50 py-4 !font-bold !font-[Inter]"
                        >
                            No result found
                        </Typography>
                            <NoDataFoundIcon />
                        </Box>
                    </Box>
                </Grid>               
            )
            }
            </>
        )      
    }    
    </Box>
  );
};
export default MyEquipment;
