import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

import React, { forwardRef } from 'react';
const StyledTextField = styled(TextField)({
  input: {
    height: '1.0375em',
    width: '300px',
    padding: '14px 0px 14px 14px',    
    '&:-webkit-autofill  ': {
      WebkitBoxShadow: '0 0 0 0 100px ' + '0' + ' inset',
      backgroundColor: '#FFF !important;',
      backgroundClip: 'content-box !important',
      // marginLeft:'-5px'
    },
  },
  '& .MuiInputBase-root': {
    backgroundColor: '#fff !important',
    borderRadius: '8px',
  },
  '& label.Mui-focused': {
    // color: 'green',
  },
  '& .MuiInput-underline:after': {
    // borderBottomColor: 'green',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '8px',
      // backgroundColor: '#FFF !important;',
    },
    '&:hover fieldset': {
      // borderColor: 'yellow',
    },
    '&.Mui-focused fieldset': {
      // borderColor: 'green',
    },
  },
  '& .MuiFormLabel-root': {
    top: '-4px',
  },
});

const AddressTextField = forwardRef((props, ref) => {
  const {
    maxLength,
    label,
    fontSize,
    required,
    error,
    helperText,
    variant,
    placeholder,
    disabled,
    fullWidth,
    type,
    startAdornment,
    labelOverFlow,
    endAdornment,
    isborder,
    isMultiline,
    minrows,
    ...rest
  } = props;
  

  return (
    <div>      
      <StyledTextField
        border={isborder ? '' : 'none'}
        placeholder={placeholder}
        multiline = {isMultiline? true:false}
        minRows = {minrows? minrows :''}
        error={error}
        helperText={helperText || null}
        inputRef={ref}
        variant={variant}
        disabled={disabled}
        fullWidth={fullWidth}
        autoComplete={false}
        size="medium"
        className="p-0"
        type={type}
        InputProps={{
          startAdornment: startAdornment || null,          
        }}
        inputProps={{
          maxLength: +maxLength,
        }}
        {...rest}
      />
    </div>
  );
});

AddressTextField.displayName = 'AddressTextField';

AddressTextField.defaultProps = {
  label: '',
  required: false,
  error: false,
  helperText: '',
  variant: 'outlined',
  disabled: false,
  fullWidth: true,
  type: 'text',
};

export default AddressTextField;
