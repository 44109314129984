import { styled,IconButton, Button, Typography } from '@mui/material'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import React from 'react'
import { useState } from 'react'
import PropTypes from 'prop-types';
 
import CloseIcon from '@mui/icons-material/Close';
import { EmiCalculator } from './EmiCalculator';
 
export const CalculateEMI = ({open,setOpen,data}) => {
     
    const handleClickOpen = () => {
        setOpen(true);
      };
      const handleClose = () => {
        setOpen(false);
      };
    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        
        '& .MuiDialogContent-root': {
          padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
          padding: theme.spacing(1),
        },
      }));
      
      function BootstrapDialogTitle(props) {
        const { children, onClose, ...other } = props;
      
        return (
          <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>
        );
      }
      
      BootstrapDialogTitle.propTypes = {
        children: PropTypes.node,
        onClose: PropTypes.func.isRequired,
      };
      
  return (
    <div>
        <Typography style={{ fontSize: '14px', fontWeight: '400', color: '#0F5E91', cursor:'pointer',fontFamily:'Inter' }} onClick={handleClickOpen} variant='ghost'>Calculate EMI</Typography>
        <BootstrapDialog
          maxWidth='lg'
          fullWidth={true}
            onClose={handleClose}
            aria-labelledby="EMI Calculator"
            open={open}
        >
         
        <DialogContent dividers>
            <EmiCalculator handleClose={handleClose} data={data}/>
        </DialogContent>
        </BootstrapDialog>
    </div>
  )
}
