import React, { useState } from 'react';
import { Grid, Typography, useMediaQuery } from '@mui/material';

import { useTheme } from '@mui/material/styles';

import { FilterIndex } from './FilterIndex';
import { SellGetStarted } from './SellGetStarted';
import { getData } from 'utils/localStorage';
import { getLocalStorageItem, localStorageKeys } from 'hooks';
import { Navigate, useNavigate } from 'react-router-dom';
import { ProductSellJourneyConfirmationModal } from '../../ProductSell/ProductSellJourneyConfirmationModal';

export const HeroSection = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));
  const isBelowMedium = useMediaQuery(theme.breakpoints.down('md'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openYears = Boolean(anchorEl);

  const [currentType, setCurrentType] = useState('buy');
  const [value, setValue] = React.useState(0);
  const [isSellJourneyModal, setIsSellJourneyModal] = useState(false);
  const userToken = getLocalStorageItem('AUTH_TOKEN');

  const handleType = (name) => () => {
    if (!userToken && name === 'sell') {
      navigate('/login');
    }
    if (getData(localStorageKeys.AUTH_TOKEN) && name === 'sell') {
      // If logged in show confirmation modal to start the sell journey
      setIsSellJourneyModal(true);
    } else {
      setCurrentType(name);
    }
  };

  const handleNavigationToProductSell = () => {
    navigate('/product-sell');
  };

  return (
    <Grid
      container
      justifyContent="center"
      className=" text-center max-w-[1700px] min-h-[650px] m-auto"
    >
      <Grid className="place-content-center relative !mt-140" item>
        <Grid item container columnSpacing={1} className={`hero`}>
          <Grid
            justifyContent={isSmall ? 'center' : 'left'}
            item
            lg={6}
            md={12}
            sm={12}
            container
          >
            <Grid
              className={`${
                isBelowMedium || isXSmall ? '!p-5' : ' px-[100px] py-[50px]'
              } ${isSmall ? 'text-center' : 'text-left'} t-pad`}
              item
            >
              <Typography
                fontWeight={700}
                fontFamily={'Space Grotesk'}
                className={`text-primary-50 ${
                  isSmall || isXSmall ? '!text-[28px]' : '!text-[48px]'
                } ${isSmall ? 'text-center' : 'text-left'} t-top`}
              >
                Explore
              </Typography>
              <Typography
                style={{ fontFamily: 'Space Grotesk' }}
                className={`${
                  isSmall || isXSmall ? '!text-[22px]' : '!text-[34px]'
                }`}
              >
              Equipment solutions for 
              </Typography>

              <div className="relative">
                <Typography
                  style={{
                    fontFamily: 'Space Grotesk',
                    fontWeight: 700,
                    zIndex: 99,
                  }}
                  className={`${
                    isSmall || isXSmall ? '!text-[28px]' : '!text-[48px]'
                  } text-extrabold`}
                >
                 YOUR <span></span> Industry
                </Typography>
                <img
                  className={`max-w-[402px] absolute top-[50%] ${
                    isBelowMedium || isXSmall
                      ? 'left-[0%] !max-w-[300px] '
                      : 'left-[10%]'
                  } -z-10 `}
                  src="assets/landingPage/YellowLine.svg"
                  alt=""
                />
              </div>
            </Grid>
            <Grid
              lg={12}
              item
              className={`${
                isBelowMedium || isXSmall
                  ? '!p-5 text-center'
                  : ' px-[100px] py-[50px] text-left'
              }  pb-[50px] t-pad`}
            >
              <button
                className={`${
                  currentType == 'buy'
                    ? 'active-btn-Home-Hero  '
                    : 'inactive-btn-Home-Hero '
                } rounded-l-md rounded-l-2  cursor-pointer ${
                  isSmall || (isXSmall && '!rounded-r-md')
                }`}
                onClick={handleType('buy')}
              >
                Buy
              </button>
              {/*{getData(localStorageKeys.AUTH_TOKEN) && (*/}
              <button
                className={`${
                  currentType == 'sell'
                    ? 'active-btn-Home-Hero  '
                    : 'inactive-btn-Home-Hero '
                } rounded-r-md rounded-l-2 cursor-pointer`}
                onClick={handleType('sell')}
              >
                Sell
              </button>
              {/*)}*/}
            </Grid>
          </Grid>
          {isLarge && (
            <img
              src="assets/landingPage/Header_boys.png"
              className="absolute right-0 top-0 max-w-[720px] -z-[50]"
              alt=""
            />
          )}

          <div
            className={`text-left ${
              isMedium ? '!pl-[100px]  pb-[50px]' : '!p-5'
            } ${isSmall || isXSmall ? '!h-auto' : 'h-[80px]'} z-10 t-padl`}
          >
            {currentType == 'buy' ? (
              <FilterIndex />
            ) : (
              /*<>{getData(localStorageKeys.AUTH_TOKEN) &&*/
              <SellGetStarted />
              /*}</>*/
            )}
          </div>
        </Grid>
      </Grid>
      {isSellJourneyModal ? (
        <ProductSellJourneyConfirmationModal
          isSellJourneyModal={isSellJourneyModal}
          setIsSellJourneyModal={setIsSellJourneyModal}
        />
      ) : null}
    </Grid>
  );
};
