import React, { useState } from 'react';
import {
  Grid,
   
  Typography,
  
  useMediaQuery,
 
  Box,
  Menu,OutlinedInput, Popover, Slider 
} from '@mui/material';
 
import { useTheme } from '@mui/material/styles';
  
 
import { Years } from './Years';
import { CustomTextField } from 'components';
import { nanoid } from 'nanoid';
 


function valuetext(value) {
  return `${value}°C`;
}

const minDistance = 10;
export const Zip = ({filterValues,setFilterValues}) => {
    const theme = useTheme();
    const isLarge = useMediaQuery(theme.breakpoints.down('lg'));
    const isMedium = useMediaQuery(theme.breakpoints.up('md'));
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));
const [val,setval]= useState('')
    const [anchorEzip, setAnchorEzip] = React.useState(null);

 

    const handleClickzip = (event) => {

      setAnchorEzip(event.currentTarget);
    };
  
    const handleClosezip = () => {
      let removed=filterValues.filter((el)=>el.filter!='zip_code')
      setFilterValues([...removed,{'filter':'zip_code', label:val, key:nanoid()}])
      setAnchorEzip(null);
    };
  
    const openzip = Boolean(anchorEzip);
 
    const handleChange=(e)=>{
  
      if(e.keyCode==13){
       
        
        handleClosezip()
      }
      setval(e.target.value)
    }
  return (
    <Grid item container lg={2} md={3 } sm={12}>
    <Grid justifyContent='left' onClick={handleClickzip} className={`${isSmall ||isXSmall? 'flex justify-around !align-middle':'grid justify-start '} !p-2  min-w-[165px] cursor-pointer homeAln`} item>
 <Typography  className='cursor-pointer text-[14px]' >Zip</Typography>
 {filterValues.some((el)=> el.filter=='zip_code') &&<Typography className='text-left animateFilterValue'>{filterValues.find((el)=> el.filter=='zip_code').label}</Typography>}

</Grid>
 
  <Popover
    className='!mt-4 '
    open={openzip}
    anchorEl={anchorEzip}
    onClose={handleClosezip}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
  > 
   <Box className='p-4' width={300}>
    <label className='min-w-[165px] py-2' htmlFor="">Enter Zip</label>
    <CustomTextField value={filterValues?.zip}  className='py-2' type='number' onKeyPress={(e)=>{
      
      if(e.code=='Enter'){
        handleClosezip()
      }
    }} onChange={handleChange} placeholder='zip'/>
   
</Box>

  </Popover>
  </Grid>
  )
}
