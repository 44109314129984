import { Box } from '@mui/material';
import { useGetProductDetails } from 'query/productDetails';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { BillingDetail } from './BillingDetail';
import { BillingCardsSelection } from './BillingCardsSelection';
 

export const Billing = () => {
  const location = useLocation();
  const listing_id = location?.state?.listing_id;
  const unique_id = location?.state?.unique_id;
  const id = location?.state?.id;
 
  const tabtype = location?.state?.tabtype;

  const [serviceTax, setServiceTax] = useState(0);
  const [total, setTotal] = useState(0);
  const { data, isLoading, refetch } = useGetProductDetails(listing_id || unique_id ,true);  
  const payment_type = location?.state.payment_Type;  
  

  useEffect(() => {
    refetch(listing_id || unique_id );
  }, [listing_id || unique_id]);

  useEffect(() => {
    if (data?.data?.quotes?.quote_price || data?.data?.selling_price) {
      setServiceTax(
        (
          (Number((data?.data?.quotes?.quote_price)* 0.025 || data?.data?.inspection_fee))*
          0.03
        ).toFixed(2)
      );
    }
  }, [data?.data]);

  useEffect(() => {
    
    if (data?.data?.quotes?.quote_price || data?.data?.selling_price) {
     
      setTotal(
        (
          Number((data?.data?.quotes?.quote_price) * 0.025 || data?.data?.inspection_fee) +
          Number(serviceTax)
        ).toFixed(2)
      );
    }
  }, [data?.data, serviceTax]);
 
  return (
    <Box className="max-w-[1700px] !m-auto w-full ">
      <BillingDetail data={data} isLoading={isLoading} total={total} serviceTax={serviceTax} />
      <BillingCardsSelection payment_type={payment_type} data={data} quote_id={data?.data?.quotes?.id} list_id={data?.data?.id} total={total} />
    </Box>
  );
};
