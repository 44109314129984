import React, { useState } from 'react';
import {
  Grid,
  Typography,
  useMediaQuery,
  Box, 
  Popover,
} from '@mui/material';

import { useTheme } from '@mui/material/styles';
import { nanoid } from 'nanoid';
import { numberWithCommas } from 'utils/helpers/numberWithCommas';
import { CustomSlider } from 'components/Sliders/CustomSlider';

const valuetext = (val) => {};

const minDistance = 0;
export const Hours = ({ filterValues, setFilterValues }) => {
  const theme = useTheme(); 
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const [anchorEmileage, setAnchorEmileage] = React.useState(null);
  const [value, setValue] = React.useState([0, 1000000]);
  const [renderSliderValue, setRenderSliderValue] = useState([0, 1000000]);
  const handleChange = (event, newValue, activeThumb) => {
    setValue(newValue);

    let removed = filterValues.filter((el) => {
      if (
        el.filter !== 'hour_max' &&
        el.filter !== 'hour_min' &&
        el.filter !== 'hours' &&
        el.filter !== 'mileage_max' &&
        el.filter !== 'mileage_min' &&
        el.filter !== 'mileage' &&
        el.filter !== 'gear_type'        
      ) {
        return el;
      }
    });

    setFilterValues([
      ...removed,
      {
        label: `${newValue[0]} hours - ${newValue[1]} hours`,
        filter: 'hours',
        key: nanoid(),
      },
      { label: newValue[1], filter: 'hour_max', key: nanoid() },
      { label: newValue[0], filter: 'hour_min', key: nanoid() },
    ]);
  };

  const handleClickmileage = (event) => {
    setAnchorEmileage(event.currentTarget);
  };

  const handleClosemileage = () => {
    setAnchorEmileage(false);
  };

  const openmileage = Boolean(anchorEmileage);

  return (
    <Grid item container lg={2} md={3} sm={12}>
      <Grid
        onClick={handleClickmileage}
        className={`${
          isSmall || isXSmall
            ? 'flex justify-around !align-middle'
            : 'grid justify-start'
        } p-2 min-w-[165px] cursor-pointer`}
        item
      >
        <Typography className="cursor-pointer">Hours</Typography>
        {filterValues.some((el) => el.filter == 'hour_max') && (
          <Typography className="text-left animateFilterValue">
            {renderSliderValue[0]} - {renderSliderValue[1]}
          </Typography>
        )}
      </Grid>
      <Popover
        className="max-h[228px]  !mt-4 "
        open={openmileage}
        anchorEl={anchorEmileage}
        onClose={handleClosemileage}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box
          justifyContent="center"
          alignItems="center"
          className="p-4  m-auto items-center pt-10"
          width={300}
          height={80}
        >
          <div style={{ marginTop: '-1.5rem', marginBottom: '2.5rem' }}>
            <CustomSlider
              handleChange={handleChange}
              setRenderSliderValue={setRenderSliderValue}
              valuetext={valuetext}
              appliedFilters={filterValues}
              type={'Hours'}
              value={renderSliderValue}
            />
          </div>

          <Typography className="flex justify-between">
            <span className="box border-gray-50 px-2 py-1">
              {numberWithCommas(renderSliderValue[0])} hours
            </span>
            <span className="box border-gray-50 px-2 py-1">
              {numberWithCommas(renderSliderValue[1])} hours
            </span>
          </Typography>
        </Box>
      </Popover>
    </Grid>
  );
};
