import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from "react-router";
import { Button, Grid, Typography, useMediaQuery, useTheme, Box, Skeleton } from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';



const Status = (props) => {

    const refundedStyle= {
        width: '162px',
        height: '34px',
        fontWeight: '600',
        backgroundColor: '#BBE2CE',
        color: '#05945B',
        fontSize: '12px',
        borderRadius: '8px',
        border: '1px solid #05945B'
    }
    
    const depositStyle= {
        width: '162px',
        height: '34px',
        fontWeight: '600',
        backgroundColor: '#F2ADA5',
        color: '#B32306',
        fontSize: '12px',
        borderRadius: '8px',
        border: '1px solid #B32306'
    }
    const note = {
        fontSize: '13px',
        color: '#545454',
        fontWeight: '400',
        paddingTop:'5px'
    }

    // const classes = useStyles();
    return (
        <>
            <Grid container >
                {props?.type ==='Refunded' ? 
                
                <Grid item >
                    <Button style={refundedStyle} className='flex' justifyContent='center' >Refunded</Button>
                    <Typography  marginTop='5px' paddingLeft='5px' paddingBottom='10px' style={note}>Note: This amount is after tax</Typography>
                </Grid> 
                : props?.type ==='Debited' ?
                
                <Grid item >
                    <Button style={depositStyle} justifyContent='center' al>Debited</Button>
                    <Typography  paddingTop='5px' paddingLeft='5px' paddingBottom='10px' style={note}>Note: This amount is after tax</Typography>
                </Grid>
                :''
                
                }

            </Grid>
        </>
    )

}
export default Status;
{/* <Grid container >
<Grid item lg={12} paddingTop={1}>
    <Typography className={classes.statusbox}>Refunded</Typography>
    <Typography  marginTop='10px' marginBottom='10px' className={classes.note}>Note: This amount is after tax</Typography>
</Grid>
</Grid> */}