import { createSlotLabel } from './date';

export const convertDaysIntoSlots = (AllAvailabilityDay, type) => {
  let res = [];

  AllAvailabilityDay?.forEach((date) => {
    let arr = date?.slots;
    if (type)
      arr = arr?.filter(
        (slot) => slot?.availabilityType === type && !slot?.isClosed
      );

    const slots =
      arr?.map((slot) => {
        const startTime = new Date(`${date?.slotDate}T${slot?.startTime}`);
        const endTime = new Date(`${date?.slotDate}T${slot?.endTime}`);
        const title = `${createSlotLabel(
          startTime?.getHours(),
          startTime?.getMinutes()
        )} - ${createSlotLabel(endTime?.getHours(), endTime?.getMinutes())}`;

        return {
          title,
          availabilityType: slot?.availabilityType,
          slotType: slot?.slotType,
          isClosed: slot?.isClosed,
          isSpecialSlot: slot?.isSpecialSlot,
          isAllDayClosed: date?.isAllDayClosed,
          providerAddressId: slot?.providerAddressId ?? null,
          start: new Date(
            startTime?.getFullYear(),
            startTime?.getMonth(),
            startTime?.getDate(),
            startTime?.getHours(),
            startTime?.getMinutes(),
            0
          ),
          end: new Date(
            endTime?.getFullYear(),
            endTime?.getMonth(),
            endTime?.getDate(),
            endTime?.getHours(),
            endTime?.getMinutes(),
            0
          ),
        };
      }) ?? [];
    res = [...res, ...slots];
  });

  return res;
};
