 
import React, { useState } from 'react';
import {
  Button,
  Grid,
  TextField,
  Box,
  useMediaQuery,
} from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTheme } from '@mui/material/styles';
import { useOnboardingQuery } from 'query/Auth';
import { useForm } from 'react-hook-form';
import { setFormErrors } from 'utils';
import { useNavigate } from 'react-router';

const LoginFormSchema = yup.object().shape({
  email: yup.string().nullable().required('Required'),
  name: yup
    .string()
    .nullable()
    .required('Name ')
    .matches(/\S/, 'Name '),
    companyName: yup
    .string()
    .nullable()
    .required('Company Name ')
    .matches(/\S/, 'Company Name '),
    mobile: yup
    // .number()  
   .string()
    .required('Mobile')
});
export const SellGetStarted = () => {
 
    const theme = useTheme();
    const isLarge = useMediaQuery(theme.breakpoints.down('lg'));
     const navigate= useNavigate()
    const {mutate:onBoardUser}= useOnboardingQuery()
    const {
      register,
      handleSubmit,
      formState: { errors },
      watch,
      setError,
    } = useForm({ resolver: yupResolver(LoginFormSchema), defaultValues: {
    } });
   

    const handleOnboardingError = (error) => {
      
      switch (error.error) {
        case 'throttled':
          setError(
            'name',
            {
              type: 'custom',
              message: `You've sent too many requests. Please try again after sometime.`,
            },
            { shouldFocus: true }
          );
          break;
        default:
          setFormErrors(error, setError);
          break;
      }
    };
    
  const handleSubmitForm = (data) => {
    onBoardUser({
      phone: data?.mobile,
        email: data?.email,
       
    
      },
      {
        onSuccess: (resp) => {

          if(resp.data.intend_to=='LOGIN'){
            navigate('/login',{state:{data:data}})
          }else{
            
            navigate('/signup',{state:{data:data}})
          }
        },
        onError: handleOnboardingError,
        onSettled: () => {
         
        },
      }
    );
  }
 
 
  return (
    <form onSubmit={handleSubmit(handleSubmitForm)} >
    <Box alignItems='center'  className={`${isLarge?'block justify-center  ':'flex'} p-2 rounded-lg searchInventory  border-2 border-solid border-primary-50` }>

        <Grid   className='bg-white px-2  ' >
          <TextField 
           type='text' {...register('name')}
           error={Boolean(errors.name)}
           helperText={errors.name && errors.name.message}
           FormHelperTextProps={{
            className: '-mt-5  animateErrorValue '
          }}
          sx={{
            "& fieldset": { border: 'none' },
          }}
        
        placeholder="Name" />
          <TextField
           type='text' {...register('companyName')}
           error={Boolean(errors.companyName)}
           helperText={errors.companyName && errors.companyName.message}
           FormHelperTextProps={{
            className: '-mt-5  animateErrorValue '
           }}
          sx={{
            "& fieldset": { border: 'none' },
          }} 
          placeholder="Company Name" />

          <TextField 
           type='number'
         
           {...register('mobile')}
           error={Boolean(errors.mobile)}
           helperText={errors.mobile && errors.mobile.message}
           FormHelperTextProps={{
            className: '-mt-5  animateErrorValue '
          }}
          sx={{
            "& fieldset": { border: 'none' },
          }}  placeholder="Phone No" />

          <TextField  type='email' {...register('email')}
           error={Boolean(errors.email)}
           helperText={errors.email && errors.email.message}
           FormHelperTextProps={{
            className: '-mt-5  animateErrorValue '
          }}
          sx={{
            "& fieldset": { border: 'none' },
          }}   placeholder="Email" />
        </Grid>
         
        <Box justifyContent='center' className={`m-auto max-lg:w-[20%]  pl-2 ${isLarge?'py-2 pr-2 !w-[100%]':""}`}>
        <Button type='submit' variant='contained'>Get Started</Button>
    </Box>
   </Box>
  </form>
  )
}
