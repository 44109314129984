import React, { useEffect, useState } from 'react';
import { Button, Grid, Link, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Navbar from '../commonComponents/Navbar';
import './Financing.css';
import VerticalLinearStepper from './stepper';
import { Box } from '@material-ui/core';

// Steps Components - Content
// import { TruckCategory } from './FinancingSteps/TruckCategory';
import { useRefContext } from 'components/Layout';
import {
  useDeleteAttachmentCredentials,
  useFinanceQuery,
} from 'query/Financing/financing';
import { VehicleID } from 'pages/Financing/FinancingSteps/VehicleID';
import { CreditApplication } from 'pages/Financing/FinancingSteps/CreditApplication';
import { CommercialDriversLicense } from 'pages/Financing/FinancingSteps/CommercialDriversLicense/CommercialDriversLicense';
import { SocialSecurity } from 'pages/Financing/FinancingSteps/SocialSecurity/SocialSecurity';
import { WorkPermit } from 'pages/Financing/FinancingSteps/WorkPermit/WorkPermit';
import { BankStatement } from 'pages/Financing/FinancingSteps/BankStatement/BankStatement';
import { TaxReturn } from 'pages/Financing/FinancingSteps/TaxReturn/TaxReturn';
import { ArticlesofIncorporation } from 'pages/Financing/FinancingSteps/ArticlesofIncorporation/ArticlesofIncorporation';
import { EINLetter } from 'pages/Financing/FinancingSteps/EINLetter/EINLetter';
import { VoidedCheque } from 'pages/Financing/FinancingSteps/VoidedCheque/VoidedCheque';
import { TruckCategory } from './NonSMRFinanceAllSteps/TruckCategory/TruckCategory';
import { UploadImagesPS } from './NonSMRFinanceAllSteps/UploadImages/UploadImages';
import { BasicInfo } from 'pages/Financing/FinancingSteps/BasicInfo/BasicInfo';
import { useAllDocumentsList } from '../../query/Documents';
import { getLocalStorageItem } from '../../hooks';
const NonSmrFinancing = ({ ...props }) => {
  // JS - Definitions outside the return statement
  // const data={...props}

  // Queries
  const { data: documentsListData, isFetching: isFetchingColorsListData } =
    useAllDocumentsList({ user_pk: getLocalStorageItem('USER_DETAILS')['id'] });

  // States
  const [allPreviouslyUploadedDocuments, setAllPreviouslyUploadedDocuments] =
    useState([]);

  const theme = useTheme();
  const isXLarge = useMediaQuery(theme.breakpoints.down('xl'));
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const { headerRef } = useRefContext();
  const [navHeight, setNavHeight] = useState(headerRef?.current?.clientHeight);

  const [activeStep, setActiveStep] = React.useState(0);
  const [sub_category, setSub_category] = useState(null);
  const [category, setCategory] = useState(null);
  const [documentsList, setDocumentsList] = useState([]);

  const { mutate: deleteAttachmentQuery } = useDeleteAttachmentCredentials();
  useEffect(() => {
    setNavHeight(headerRef?.current?.clientHeight);
  }, [headerRef?.current?.clientHeight]);

  const [imagesMap, setImagesMap] = useState([
    {
      label: 'Engine Sticker',
      description: 'ENGINE_STICKER',
    },
    {
      label: 'Engine',
      description: 'ENGINE',
    },
    {
      label: 'Front',
      description: 'FRONT',
    },
    {
      label: 'Back',
      description: 'BACK',
    },
    {
      label: 'Left Drive Tire',
      description: 'LEFT_DRIVE_TYRE',
    },
    {
      label: 'Right Drive Tire',
      description: 'RIGHT_DRIVE_TYRE',
    },
    {
      label: 'Left Rear Tire',
      description: 'LEFT_REAR_TYRE',
    },
    {
      label: 'Right Rear Tire',
      description: 'RIGHT_REAR_TYRE',
    },
    {
      label: 'Left Side',
      description: 'LEFT',
    },
    {
      label: 'Right Side',
      description: 'RIGHT',
    },
    {
      label: 'Odometer',
      description: 'ODOMETER',
    },
    {
      label: 'Hours Meter',
      description: 'HOURS_METER',
    },
    {
      label: 'Full Dash',
      description: 'FULL_DASH',
    },
    {
      label: 'Sleeper Area',
      description: 'SLEEPER_AREA',
    },
    {
      label: 'Engine Family Number',
      description: 'ENGINE_FAMILY_NUMBER',
    },
    {
      label: 'Reefer Serial Number',
      description: 'REEFER_SERIAL_NUMBER',
    },
    {
      label: 'Inside Trailer',
      description: 'INSIDE_TRAILER',
    },

    /*{
      label: 'Vin Sticker',
      description: 'VIN_STICKER',
    },
    {
      label: 'Engine',
      description: 'ENGINE',
    },
    {
      label: 'Front',
      description: 'FRONT',
    },
    {
      label: 'Back',
      description: 'BACK',
    },
    {
      label: 'Left Side',
      description: 'LEFT_SIDE',
    },
    {
      label: 'Right Side',
      description: 'RIGHT_SIDE',
    },
    {
      label: 'Tires',
      description: 'TIRES',
    },
    {
      label: 'Odometer',
      description: 'ODOMETER',
    },
    {
      label: 'Full Dash',
      description: 'FULL_DASH',
    },
    {
      label: 'Sleep Area',
      description: 'SLEEP_AREA',
    },*/
  ]);

  const [data, setData] = useState({
    category: 0,
    sub_category: 0,
    status: 'UNDER_PROCESS',
    equipment_type: 'NON_SMR',
    attachment: [],
    documents: {
      CreditApplication: [null],
      CommercialDriversLicense: [null, null],
      BankStatement: [null, null, null],
      SocialSecurity: [null, null],
      WorkPermit: [null, null],
      TaxReturn: [null, null],
      VoidedCheque: [null],
      ArticlesofIncorporation: [null],
      EINLetter: [null],
    },
    name: null,
    company_name: null,
    phone: null,
    email: null,
    seller_name: null,
    seller_phone_number: null,
    seller_email: null,
  });

  const [inputData, setInputData] = useState({
    attachment: {},
    documents: {
      CreditApplication: [null],
      CommercialDriversLicense: [null, null],
      BankStatement: [null, null, null],
      SocialSecurity: [null, null],
      WorkPermit: [null, null],
      TaxReturn: [null, null],
      VoidedCheque: [null],
      ArticlesofIncorporation: [null],
      EINLetter: [null],
    },
    stock_number: '',
  });

  useEffect(() => {
    if (documentsListData) {
      let modifiedData = documentsListData.data;
      modifiedData.forEach((item) => {
        item._isSelected = false;
      });
      setAllPreviouslyUploadedDocuments(modifiedData);
    }
  }, [documentsListData]);

  useEffect(() => {}, [data]);

  useEffect(() => {}, [inputData]);

  useEffect(() => {
    let newData = { ...data };
    newData.category = category;
    newData.sub_category = sub_category;
    setData(newData);
  }, [sub_category, category]);

  const prepareData = (data) => {
    let responseData = {};
    for (let key in data) {
      if (key == 'attachment') {
        let x = [];
        /*for(let innerKey in data.attachment){
          x.push(data.attachment[innerKey])
        }
        responseData.attachments = x.flat(2).map((el)=>{
          return el.id
        });*/
        Object.keys(data.documents).forEach((key) => {
          if (Array.isArray(data.documents[key])) {
            data.documents[key]?.forEach((item) => {
              if (item?.id) {
                x.push(item.id);
              }
            });
          }
        });
        responseData.attachments = data.attachment;
      } else if (key == 'documents') {
        let x = [];
        /*for(let innerKey in data.documents){
          x.push(data.documents[innerKey].id)
        }*/
        Object.keys(data.documents).forEach((key) => {
          if (Array.isArray(data.documents[key])) {
            data.documents[key]?.forEach((item) => {
              if (item?.id) {
                x.push(item.id);
              }
            });
          }
        });
        responseData.documents = null;
        responseData.documents = x;
      } else {
        responseData[key] = data[key];
      }
    }
    // API Specific Overrides to make it work
    responseData.phone = responseData.phone
      .replaceAll(' ', '')
      .replace('(', '')
      .replace(')', '')
      .replace('+1', '')
      ?.replaceAll('-', '');
    responseData.seller_phone_number = responseData.seller_phone_number
      ?.replaceAll(' ', '')
      .replace('(', '')
      .replace(')', '')
      .replace('+1', '')
      ?.replaceAll('-', '');
    if (responseData?.equipment_type === 'NON_SMR') {
      responseData.sub_category = null;
      responseData.listing = null;
    }
    return responseData;
  };
  const handleSuccess = (res) => {
    const newData = { ...data };

    if (newData?.attachment?.EINLetter) {
      let filteredData = newData.attachment.EINLetter.filter((el) => {
        if (res.resData?.data[0].description != el.description) {
          return el;
        }
      });

      newData.attachment.EINLetter = [...filteredData, res.resData?.data[0]];
    } else {
      newData.attachment.EINLetter = [res.resData?.data[0]];
    }

    const newInputData = { ...inputData };

    if (newInputData?.attachment?.EINLetter) {
      let filteredData = newInputData.attachment.EINLetter.filter((el) => {
        if (res.attachmentDescription != el.description) {
          return el;
        }
      });

      newInputData.attachment.EINLetter = [
        ...filteredData,
        { file: res.fileData, description: res.attachmentDescription },
      ];
    } else {
      newInputData.attachment.EINLetter = [
        { file: res.fileData, description: res.attachmentDescription },
      ];
    }
    setInputData(newInputData);
    setData(newData);
  };

  const deleteAttachmentAntiDode = (key, index = null) => {
    const existing = { ...data };
    // handle API response data state
    if (index != null && existing.attachment[key].length) {
      if (existing.attachment[key][index]?.id) {
        deleteAttachmentQuery(existing.attachment[key][index]?.id);
      }
      existing.attachment[key][index] = null;
    }
    setData(existing);
    // handle Input Data state
    if (index != null && inputData.attachment[key].length) {
      inputData.attachment[key][index] = null;
    }
  };

  const deleteDocumentAntiDode2 = (step, type, side) => {
    const existing = { ...data };
    const existingInput = { ...inputData };

    // delete document response data
    let filteredData = [];

    existing.documents[step].forEach((item) => {
      if (item.document_type == type && item.document_side == side) {
        console.log('If');
      } else {
        filteredData.push(item);
      }
    });


    existing.documents[step] = [...filteredData];

    setData(existing);
    setInputData(existingInput);
  };

  const deleteDocumentAntiDode = (key, index = null) => {
    const existing = { ...data };
    // handle API response data state
    if (index != null && existing.documents[key].length) {
      /*if(existing.documents[key][index]?.id) {
        deleteAttachmentQuery(existing.documents[key][index]?.id);
      }*/
      existing.documents[key][index] = null;
    }
    setData(existing);
    // handle Input Data state
    if (index != null && inputData.documents[key].length) {
      inputData.documents[key][index] = null;
    }

  };

  const deleteAttachment = (step, type) => {
    const existing = { ...data };
    for (let key in existing?.attachment) {
      if (key == step) {
        existing.attachment = {
          ...existing?.attachment,
          [step]: existing?.attachment[key]?.filter((el) => {
            if (el.description == type) {
              deleteAttachmentQuery(el.id);
            }
            if (el.description != type) {
              return el;
            }
          }),
        };
      }
    }
    setData(existing);
  };

  const deleteDocument = (step, type) => {
    const existing = { ...data };
    const existingInput = { ...inputData };

    if (step == 'CreditApplication' || step == 'BankStatement') {
      const datafiltered = {};

      for (let key in existing.documents) {
        if (key != step) {
          datafiltered[key] = existing.documents[key];
        }
      }

      existing.documents = datafiltered;

      const inputDatafiltered = {};
      for (let key in existingInput.documents) {
        if (key != step) {
          inputDatafiltered[key] = existingInput.documents[key];
        }
      }
      existingInput.documents = inputDatafiltered;
    } else {
      let filteredData = {
        [step]: Object.entries(existing.documents).filter(([key, val]) => {
          if (key != step) {
            return { key: val };
          }
        }),
      };

      existing.documents = filteredData;

      let filteredDataInput = {
        [step]: Object.entries(existingInput.documents).filter(([key, val]) => {
          if (key != step) {
            return { key: val };
          }
        }),
      };

      existingInput.documents = filteredDataInput;
    }

    setData(existing);
    setInputData(existingInput);
  };

  const sampleMethod = () => {
    // Todo: Remove it just used it for prop handling
  };

  // Template
  return (
    <>
      <div
        className="w-full outter-most-wrapper"
        style={{
          backgroundImage:
            'linear-gradient(to right, #0F5E91 50%, #ffffff 50%)',
        }}
      >
        <Box
          style={{
            maxWidth: '1700px',
          }}
          className={`${isMedium ? '!px-4' : '!px-[100px]'} m-auto`}
        >
          <div className="main-wrapper flex">
            <div
              className="steps-wrapper !w-[330px] h-full"
              style={{
                maxHeight: 'calc(100vh - ' + navHeight + 'px)',
                overflowY: 'scroll',
              }}
            >
              <VerticalLinearStepper
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                sampleMethod={sampleMethod}
              />
            </div>
            <div className="content-wrapper max-h-[900px] bg-white w-5/6 !h-full">
              <div className="content">
                {(() => {
                  switch (activeStep) {
                    case 0:
                      return (
                        <CreditApplication
                          deleteDocument={deleteDocument}
                          setInputData={setInputData}
                          inputData={inputData}
                          deleteAttachment={deleteAttachment}
                          data={data}
                          setData={setData}
                          activeStep={activeStep}
                          setActiveStep={setActiveStep}
                        />
                      );
                    case 1:
                      return (
                        <BasicInfo
                          activeStep={activeStep}
                          setActiveStep={setActiveStep}
                          data={data}
                          setData={setData}
                          isSMR={false}
                        />
                      );

                    case 2:
                      return (
                        <CommercialDriversLicense
                          allPreviouslyUploadedDocuments={
                            allPreviouslyUploadedDocuments
                          }
                          setAllPreviouslyUploadedDocuments={
                            setAllPreviouslyUploadedDocuments
                          }
                          setInputData={setInputData}
                          inputData={inputData}
                          deleteDocument={deleteDocument}
                          deleteAttachment={deleteAttachment}
                          deleteDocumentAntiDode2={deleteDocumentAntiDode2}
                          data={data}
                          setData={setData}
                          activeStep={activeStep}
                          setActiveStep={setActiveStep}
                        />
                      );

                    case 3:
                      return (
                        <SocialSecurity
                          allPreviouslyUploadedDocuments={
                            allPreviouslyUploadedDocuments
                          }
                          setAllPreviouslyUploadedDocuments={
                            setAllPreviouslyUploadedDocuments
                          }
                          setInputData={setInputData}
                          inputData={inputData}
                          deleteAttachment={deleteAttachment}
                          deleteDocument={deleteDocumentAntiDode2}
                          data={data}
                          setData={setData}
                          activeStep={activeStep}
                          setActiveStep={setActiveStep}
                        />
                      );

                    case 4:
                      return (
                        <WorkPermit
                          allPreviouslyUploadedDocuments={
                            allPreviouslyUploadedDocuments
                          }
                          setAllPreviouslyUploadedDocuments={
                            setAllPreviouslyUploadedDocuments
                          }
                          setInputData={setInputData}
                          inputData={inputData}
                          deleteAttachment={deleteAttachment}
                          data={data}
                          setData={setData}
                          activeStep={activeStep}
                          setActiveStep={setActiveStep}
                        />
                      );

                    case 5:
                      return (
                        <BankStatement
                          allPreviouslyUploadedDocuments={
                            allPreviouslyUploadedDocuments
                          }
                          setAllPreviouslyUploadedDocuments={
                            setAllPreviouslyUploadedDocuments
                          }
                          setInputData={setInputData}
                          inputData={inputData}
                          deleteAttachment={deleteAttachment}
                          deleteDocument={deleteDocument}
                          deleteDocumentAntiDode={deleteDocumentAntiDode}
                          data={data}
                          setData={setData}
                          activeStep={activeStep}
                          setActiveStep={setActiveStep}
                        />
                      );
                    case 6:
                      return (
                        <TaxReturn
                          allPreviouslyUploadedDocuments={
                            allPreviouslyUploadedDocuments
                          }
                          setAllPreviouslyUploadedDocuments={
                            setAllPreviouslyUploadedDocuments
                          }
                          setInputData={setInputData}
                          inputData={inputData}
                          deleteAttachment={deleteAttachment}
                          deleteAttachmentAntiDode={deleteAttachmentAntiDode}
                          deleteDocumentAntiDode={deleteDocumentAntiDode}
                          data={data}
                          setData={setData}
                          activeStep={activeStep}
                          setActiveStep={setActiveStep}
                        />
                      );

                    case 7:
                      return (
                        <VoidedCheque
                          allPreviouslyUploadedDocuments={
                            allPreviouslyUploadedDocuments
                          }
                          setAllPreviouslyUploadedDocuments={
                            setAllPreviouslyUploadedDocuments
                          }
                          setInputData={setInputData}
                          inputData={inputData}
                          deleteAttachment={deleteAttachment}
                          deleteDocumentAntiDode={deleteDocumentAntiDode}
                          data={data}
                          setData={setData}
                          activeStep={activeStep}
                          setActiveStep={setActiveStep}
                        />
                      );

                    case 8:
                      return (
                        <ArticlesofIncorporation
                          allPreviouslyUploadedDocuments={
                            allPreviouslyUploadedDocuments
                          }
                          setAllPreviouslyUploadedDocuments={
                            setAllPreviouslyUploadedDocuments
                          }
                          setInputData={setInputData}
                          inputData={inputData}
                          deleteAttachment={deleteAttachment}
                          deleteDocumentAntiDode={deleteDocumentAntiDode}
                          data={data}
                          setData={setData}
                          activeStep={activeStep}
                          setActiveStep={setActiveStep}
                        />
                      );

                    case 9:
                      return (
                        <EINLetter
                          allPreviouslyUploadedDocuments={
                            allPreviouslyUploadedDocuments
                          }
                          setAllPreviouslyUploadedDocuments={
                            setAllPreviouslyUploadedDocuments
                          }
                          handleSuccess={handleSuccess}
                          prepareData={prepareData}
                          setInputData={setInputData}
                          inputData={inputData}
                          deleteAttachment={deleteAttachment}
                          deleteDocumentAntiDode={deleteDocumentAntiDode}
                          data={data}
                          setData={setData}
                          activeStep={activeStep}
                          setActiveStep={setActiveStep}
                        />
                      );
                    case 10:
                      return (
                        <TruckCategory
                          activeStep={activeStep}
                          setActiveStep={setActiveStep}
                          setCategory={setCategory}
                          sub_category={sub_category}
                          setSub_category={setSub_category}
                        />
                      );
                    case 11:
                      return (
                        <UploadImagesPS
                          imagesMap={imagesMap}
                          setImagesMap={setImagesMap}
                          prepareData={prepareData}
                          activeStep={activeStep}
                          setActiveStep={setActiveStep}
                          data={data}
                          setData={setData}
                        />
                      );

                    default:
                      return;
                  }
                })()}
              </div>
            </div>
          </div>
        </Box>
      </div>
    </>
  );
};

export { NonSmrFinancing };
