import React, { useRef, useState } from 'react';
import './ImageSelector.css';

const ImageSelector = (props) => {


  // State
  let inputFileEl = useRef('inputFileEl');
  const [inputFileElData, setInputFileElData] = useState();
  const {
    imgObj,
    updatePrimaryImage,
    handleImageRemove,
    index,
    handleParentClick,
    is_primary,
    handleImgClick
  } = props;

  // Template
  const imgIcon = () => {
    return <svg width='46' height='46' viewBox='0 0 46 46' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='3' y='3' width='40' height='40' rx='20' fill='#EDEDED' />
      <path
        d='M26.5 30.5H18.7761C18.2713 30.5 18.0189 30.5 17.902 30.4002C17.8006 30.3135 17.7467 30.1836 17.7572 30.0506C17.7693 29.8974 17.9478 29.7189 18.3047 29.3619L25.3905 22.2761C25.7205 21.9461 25.8855 21.7811 26.0758 21.7193C26.2432 21.6649 26.4235 21.6649 26.5908 21.7193C26.7811 21.7811 26.9461 21.9461 27.2761 22.2761L30.5 25.5V26.5M26.5 30.5C27.9001 30.5 28.6002 30.5 29.135 30.2275C29.6054 29.9878 29.9878 29.6054 30.2275 29.135C30.5 28.6002 30.5 27.9001 30.5 26.5M26.5 30.5H19.5C18.0999 30.5 17.3998 30.5 16.865 30.2275C16.3946 29.9878 16.0122 29.6054 15.7725 29.135C15.5 28.6002 15.5 27.9001 15.5 26.5V19.5C15.5 18.0999 15.5 17.3998 15.7725 16.865C16.0122 16.3946 16.3946 16.0122 16.865 15.7725C17.3998 15.5 18.0999 15.5 19.5 15.5H26.5C27.9001 15.5 28.6002 15.5 29.135 15.7725C29.6054 16.0122 29.9878 16.3946 30.2275 16.865C30.5 17.3998 30.5 18.0999 30.5 19.5V26.5M21.75 20.0833C21.75 21.0038 21.0038 21.75 20.0833 21.75C19.1629 21.75 18.4167 21.0038 18.4167 20.0833C18.4167 19.1629 19.1629 18.4167 20.0833 18.4167C21.0038 18.4167 21.75 19.1629 21.75 20.0833Z'
        stroke='#5B96BB' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
      <rect x='3' y='3' width='40' height='40' rx='20' stroke='#F5F5F5' stroke-width='6' />
    </svg>;
  };

  const removeImgIcon = () => {
    return <svg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='11.5' cy='11.5' r='11.5' fill='white' />
      <circle cx='11.5' cy='11.5' r='9.5' fill='#F5F5F5' />
      <path d='M7.3335 12H16.6668' stroke='#F2ADA5' stroke-linecap='round' stroke-linejoin='round' />
    </svg>;
  };

  const checkedIcon = (checked) => {
    return checked ? (<><svg width='50' height='50' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M41 1H9C4.58172 1 1 4.58172 1 9V41C1 45.4183 4.58172 49 9 49H41C45.4183 49 49 45.4183 49 41V9C49 4.58172 45.4183 1 41 1Z'
          fill='#0F5E91' stroke='#0F5E91' stroke-width='1.5' />
        <path d='M13 25L21 33L37 17' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
      </svg> <span style={{textTransform: 'none'}}>Primary</span></>) :
      <div className='un-checked'></div>
  }

  // Methods

  /*const handleImageRemove = (ev) => {
    ev.stopPropagation();
    // setInputFileElData(null);
    // inputFileEl.current.value = null;
  }*/

  const handleClick = () => {
    console.log(inputFileEl);
    inputFileEl.current.click();
  }

  const handleImageElChange = (event) => {
    console.log(event.target.value, event.target.files );
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function(e) {
      setInputFileElData(this.result);
      console.log(this.result);
    }
  }


  return (
    <div className='image-selector-wrapper'>
      { imgObj.attachment ?
        <div className='removeAction' onClick={(event) => {handleImageRemove(event, index, imgObj.id)}}>
          {removeImgIcon()}
        </div> : null
      }
      {(imgObj.attachment ) ?
       !is_primary ? null: <div className='checkbox-container' onClick={(event) => {updatePrimaryImage(event, index)}}>
          {checkedIcon(imgObj.is_primary)}
        </div> : imgObj.attachment && !is_primary?null:null
      }
      {/*<div className='image-selector-box' onClick={handleClick}>*/}
      <div className='image-selector-box'>
        {
          imgObj.attachment ? <img onClick={() => { handleImgClick(index)}} src={imgObj.attachment} alt='' /> :
            <div
              onClick={() => {
                handleParentClick();
              }}
              className='flex flex-col items-center justify-center width-full height-full'
            >
              <div className='icon'>
                {imgIcon()}
              </div>
              <div className='label'>
                Click to upload
              </div>
            </div>
        }
      </div>
      {/*<input className="hidden" onChange={handleImageElChange} ref={inputFileEl} type='file' />*/}
    </div>
  )

};

export {
  ImageSelector
}