import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Grid,
  Modal,
  Box,
  Button,
  Link,
  Typography,
  useMediaQuery,
  CircularProgress,
} from '@mui/material';
// import DownloadablePdf from 'pdf/SlateFinancialCreditApplication.pdf'
import { useTheme } from '@mui/material/styles';
import { CustomTextField } from '../../../../components';
import { useRefContext } from '../../../../components/Layout';

import { useGetListByStockNumberQuery } from 'query/Financing/financing';

const DownloadPDF = (props) => {
 
  const theme = useTheme();
  const isXLarge = useMediaQuery(theme.breakpoints.down('xl'));
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const { headerRef, navRef } = useRefContext();
  const handleDownloadPdf = () => {
    fetch('https://smrdev.s3.us-west-1.amazonaws.com/slatefinancialcreditapplication.pdf')
      .then((response) => response.blob())
      .then((blob) => {
        // Create a new object URL for the blob
        const fileURL = URL.createObjectURL(blob);
  
        // Create an anchor element
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = 'SlateFinancialCreditApplication.pdf';
  
        // Append the link to the document body
        document.body.appendChild(link);
  
        // Simulate a click on the link to start the download
        link.click();
  
        // Clean up resources
        URL.revokeObjectURL(fileURL);
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  };
  
  return (
    /*<div>*/
   
      <div className="main">
        <div className="stepper-content">
        
            <Box
              onClick={handleDownloadPdf}
              className={
                ' cursor-pointer border-2 border-primary-50 border-solid rounded-lg flex max-w-[450px] w-full text-center justify-between px-2 align-middle items-center h-[130px] '
              }
            >
              <div className="flex items-center">
                {' '}
                <img
                  className="max-w-[48px]"
                  src="/assets/icons/CreditApplication.svg"
                />
                <Typography className="font-semibold px-1">
                  Credit application.pdf
                </Typography>
              </div>
              <div>
                <img
                  className="max-w-[48px]"
                  src="/assets/icons/DownloadPDFicon.svg"
                />
              </div>
            </Box>
         
        </div>
      
 
    </div>
  );
};

export { DownloadPDF };
