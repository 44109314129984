import { Box, Divider, Grid, Typography, useMediaQuery} from '@mui/material'
import Button from 'components/LoadingButton'
import { useTheme } from '@mui/material/styles';
import React from 'react'

export const YourPartner = () => {
    const theme = useTheme();
    const isLarge = useMediaQuery(theme.breakpoints.down('lg'));
    const isMedium = useMediaQuery(theme.breakpoints.up('md'));
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Box
          item
          container
          alignItems="center"
          justifyContent='center'
          style={{ maxWidth:'1700px', margin:"auto", display:"none"}}

          className={`py-16 max-sm:py-4 md:py-6 lg:py-16 ${isMedium?'px-[10px]':'px-2'}  relative overflow-hidden `}
       >
          
          <Box
            justifyContent='center'
            sx={{margin:'auto'}}
            className={`partner-bg bg-cover rounded max-h-[484px]  max-w-[1240px] max-sm:max-h-fit max-sm:p-5 ${isMedium?'text-left bg-center':' text-center bg-right'} `}
           
          >
           
             
               
                <Typography
                variant={ isMedium?"h3":'h4'}
                className={`!font-bold max-w-[488px]   text-white`}>Your Partner For The Road 
                </Typography>
            
            
            
           
            
              <Typography className="text-white py-5 max-w-[512px] min-h-[132px] ">
              Sell My Rig is a trusted partner on the road for all your used semi truck and trailer needs. Our 
app revolutionizes the asset buying and selling process, providing a seamless platform for 
purchasers to list, sell, browse, and purchase trucks with ease. We understand the unique 
demands of the transportation industry and are committed to connecting customers with the 
perfect rig for their business. With our user-friendly interface and extensive inventory, we 
empower drivers and fleet owners alike to find the right truck that fits their specific requirements.
Count on Sell My Rig as your dedicated ally, supporting you every step of the way as you 
navigate the road to success.
              </Typography>
 
              {/* <Button variant='contained'  className="w-full !m-auto bg !py-3 !bg-primary-50 !text-black max-w-[245px]">
               Learn More
              </Button> */}
            
          </Box>
 
        
        </Box>
  )
}
