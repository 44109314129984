import React from 'react';
import { Grid, Box, Typography } from '@mui/material';

const Disclaimer = () => { 
    return(
         <Box>
           <Grid container lg={12} md={12} sm={12} xs={12} className='' justifyContent='center' padding={2}>
              
              <Grid item lg={12} md={12} sm={12} xs={12} className='discPdA'  justifyContent='center' padding={2}>
              <Box padding={3} className='discPd'>
              <Grid container lg={12} md={12} sm={12} xs={12} rowGap={2} style={{ boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.08)', borderRadius: '8px', width: '100%', height: '100%' }} justifyContent='center' padding={4}>

                  <Grid item container lg={12} md={12} sm={12} xs={12} rowGap={2}>
                     <Grid item lg={12} md={12} sm={12} xs={12} >
                        <Typography style={{ fontSize: '36px', fontWeight: '600', color: '#03131D', }}>Disclaimer</Typography>
                     </Grid>
                     <Grid item lg={12} md={12} sm={12} xs={12} >
                        
                        <Typography pb={3} style={{ fontSize: '20px', fontWeight: '400', color: '#545454' }}>
                           Sell My Rig LLC is not affiliated or associated with any of the Truck and or Trailer manufactures shown on this application or the Sell My Rig website. Sell My Rig LLC does not imply or express any association, affiliation or relationship with any commercial equipment manufacturer. Sell My Rig LLC is not trying to give any false impression of connection, approval or sponsorship by any manufacturers seen on this application.
                        </Typography>
                        <Typography pb={3} style={{ fontSize: '20px', fontWeight: '400', color: '#545454' }}>
                           The Logos and Artwork you see before you in this application are the copyrighted and registered intellectual property of its respective owners. Sell My Rig is displaying these logos only for comparative advertising and descriptive purposes. We do not use logos, names and artwork of other companies and businesses as our trademark or our property.
                        </Typography>
                        <Typography pb={3} style={{ fontSize: '20px', fontWeight: '400', color: '#545454' }}>
                           The equipment which is sold by Sell My Rig to the public or to other businesses, is not sourced directly from any manufacturer seen in this application. Sell My Rig LLC is a Used Commercial Truck Dealership we do not and can not purchase from manufacturers.
                        </Typography>
                        <Typography pb={3} style={{ fontSize: '20px', fontWeight: '400', color: '#545454' }}>
                           Sell My Rig LLC does not sell or create any financial product(s), we simple sell equipment.
                        </Typography>
                        <Typography pb={3} style={{ fontSize: '20px', fontWeight: '400', color: '#545454' }}>
                           You may choose to use our premier financial partner Slate Financial LLC to apply for financing all in one place or you may procure your own financing and simply buy a rig from us.
                        </Typography>
                     </Grid>
                  </Grid>
               </Grid>
               </Box>
              </Grid>            
             </Grid> 
         </Box>
    )
} 

export default Disclaimer;