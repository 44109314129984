export const formatResponseError = (error) => {
  return Object.assign(
    {},
    ...error.errors.map((errorData) => ({
      [errorData.field]: errorData.message,
    }))
  );
};

export const setFormErrors = (errors, setError) => {
  // setError is the form setError method
  try {
    const formatedErrors = formatResponseError(errors);

    Object.keys(formatedErrors).forEach((errorKey, errorIndex) => {
      setError(
        errorKey,
        { type: 'custom', message: formatedErrors[errorKey] },
        { shouldFocus: errorIndex === 0 }
      );
    });
  } catch (error) {
    console.log('Error trying to display error', error);
  }
};
