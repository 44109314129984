import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { localStorageKeys } from 'hooks';

import { axios } from 'utils';
import { saveData } from 'utils/localStorage';
import { QueryKey } from './queryKeys';

//post api for update password

const UpdateAccountPasword = (data) => {
  const method = 'PATCH';

  const url = 'change-password/';

  return axios({ method, url, data });
};

export const useUpdatePassword = () => {
  return useMutation(UpdateAccountPasword);
};

const GetNotification = () => {
  const url = 'notification-preference/';
  const method = 'GET';

  return axios({ method, url });
};

export const useGetNotification = () => {
  return useQuery([QueryKey.GET_NOTIFICATION], () => GetNotification(), {
    enabled: true,
  });
};

const UpdateNotification = (status) => {
  const data = { notifications: status };

  const method = 'PATCH';

  const url = 'notification-preference/';

  return axios({ method, url, data });
};

export const useUpdateNotification = () => {
  return useMutation(UpdateNotification);
};

const DeactivateAccount = () => {
  const method = 'POST';

  const url = 'auth/deactivate-account/';

  return axios({ method, url });
};

export const useDeactivateAccount = () => {
  return useMutation(DeactivateAccount);
};
