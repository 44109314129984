import { useLocation } from 'react-router';
import { useMemo } from 'react';
// import routes from '../routes/RouteConstants';
import { routes, routeKey } from '../routes/RouteConstants';

const usePageDetails = () => {
  const location = useLocation();
  // console.log({location, routes, routeKey});
  const pageConfig = useMemo(() => {
    return routes[routeKey[location.pathname] || 'DEFAULT'];
  }, [location.pathname]);
  // console.log({pageConfig});
  return pageConfig;
};
export default usePageDetails;