import {
  Box,
  Button,
  CircularProgress,
  Popover,
  Typography,
} from '@mui/material';

import React, { useState, useEffect } from 'react';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import useToast from 'utils/responseToast';
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from 'hooks';

import { useGetLocationAddress } from 'query/Home';
import {  ShareLocation } from '@mui/icons-material';
import AddressAutocompleteTextfield from 'components/AddressAutoComplete';

export const Location = ({ textColor }) => {
  const [longitude, setLongitude] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const { data, refetch, isLoading, dataUpdatedAt } = useGetLocationAddress({
    latitude,
    longitude,
  });
  const [openGoogle, setOpenGoogle] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [pin, setpin] = useState(data?.zip_code);

  const toast = useToast();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpenGoogle(false);
    setAnchorEl(false);
  };

  const open = Boolean(anchorEl);

  const getLocation = () => {
    if (!navigator.geolocation) {
      toast.toastInfo('Geolocation is not supported by your browser');
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLongitude(position.coords.longitude);
          setLatitude(position.coords.latitude);
          setLocalStorageItem('SMR-longitude', position.coords.longitude);
          setLocalStorageItem('SMR-latitude', position.coords.latitude);
        },
        () => {
          toast.toastInfo('Unable to retrieve your location');
        }
      );
    }
  };

  useEffect(() => {
    if (
      getLocalStorageItem('SMR-longitude') &&
      getLocalStorageItem('SMR-latitude')
    ) {
      setLongitude(getLocalStorageItem('SMR-longitude'));
      setLatitude(getLocalStorageItem('SMR-latitude'));
      refetch({
        latitude: getLocalStorageItem('SMR-latitude'),
        longitude: getLocalStorageItem('SMR-longitude'),
      });
    } else {
      getLocation();
    }
  }, []);

  useEffect(() => {
    if (data?.data.zip_code) {
      setpin(data?.data.zip_code);
    }
  }, [dataUpdatedAt]);  
  const handleGoogleAddress = (address) => {     
    if(address.postal_code!==''){
      setpin(address.postal_code);
      setOpenGoogle(false);
      setAnchorEl(null);
    }    
  };

  const handleManualStreetAddress = (zipCode) => {
    setOpenGoogle(true);   
  };
  const handleCurentLocation = () => {
    getLocation();
    refetch();
    setAnchorEl(null);
  };
  
  
  
  return (
    <>
      <Box
        onClick={handleClick}
        className=" items-center flex cursor-pointer px-4"
      >
        <LocationOnOutlinedIcon className="text-primary-50" />
        <Typography className={` !text-${textColor} || !text-black `}>
          {' '}
          {isLoading ? (
            <CircularProgress size={20} className="!text-secondary-50" />
          ) : (
            pin
          )}
        </Typography>
      </Box>
      <Popover
        className="mt-5 "
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box justifyContent="center" className="p-3 ">
          <AddressAutocompleteTextfield
            size="small"
            variant="standard"
            searchType="address"
            handleGoogleAddress={handleGoogleAddress}
            handleManualStreetAddress={handleManualStreetAddress}
            inputProps={{ type: 'tel' }}            
            placeholder="Search street address or zip code"
            InputLabelProps={{
              shrink: Boolean(pin),
            }}
          />
          <div className={openGoogle && 'mt-44'}>
            <Button
              onClick={handleCurentLocation}
              className="!bg-secondary-50 !border-none !w-full !text-white !my-2"
              variant="contained"
              startIcon={<ShareLocation />}
            >
              Use my Current Location
            </Button>
          </div>
        </Box>
      </Popover>
    </>
  );
};
