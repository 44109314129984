import { HassleFreeFinancing } from 'pages/LandingPage/HassleFreeFinancing';
import { YourShipment } from 'pages/LandingPage/YourShipment';
import { Favourites } from './Favourites';
import { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { useLocation } from 'react-router';

export const Favourite = () => {
const component= useRef()
const location= useLocation()

  useEffect(()=>{
    window.scrollTo({
      top:0,
      left: 0,
      behavior: "instant"
  });
  },[location.pathname])


  return (
    <Box ref={component}>
      <Favourites/>
      <HassleFreeFinancing />
      <YourShipment />
    </Box>
  );
};
