import React, { useState } from 'react';
import {
  Button,
  Divider,
  FormLabel,
  Grid,
  Input,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Typography,
  Autocomplete,
  useMediaQuery,
  Drawer,
  Link,
  Box,
} from '@mui/material';
import BrandLogo from './BrandLogo';
import TruckCard from './TruckCard';
import FinanceCard from './FinanceCard';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@mui/material/AppBar';
import Shipment from './Shipment';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Navbar from '../commonComponents/Navbar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Dropdown from 'react-multilevel-dropdown';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { HeroTags } from 'components/HeroTags/HeroTags';
import { CustomerReviewCard } from 'components/CustomerReviewCard/CustomerReviewCard';
import { HeroSection } from './HeroSection/HeroSection';
import { PopularBrands } from './PopularBrands';
import { FeatureInventory } from './FeatureInventory';
import { CustomerReviews } from './CustomerReviews';
import { YourPartner } from './YourPartner';
import { HassleFreeFinancing } from './HassleFreeFinancing';
import { YourShipment } from './YourShipment';
import { Footer } from '../commonComponents/Footer';
import { HeroTagsContainer } from './HeroSection/HeroTagsContainer';
import { NewlyAdded } from './NewlyAdded';

const LandingPage = () => {
  return (
    <Box className="!mt-100">
      {/* <Navbar isSubMenu={true}/> */}
      <HeroSection />
      <HeroTagsContainer />
      <PopularBrands />

      <FeatureInventory />
      <NewlyAdded />
      <YourPartner />

      <HassleFreeFinancing />
      <YourShipment />
      {/* <Footer/> */}
    </Box>
  );
};

export default LandingPage;
