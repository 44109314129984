import { Grid, useMediaQuery, useTheme } from '@mui/material';
import Sidebar from 'components/Sidebar/Sidebar';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

export const CommonAccountLayout = ({ header,Content }) => { 
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    })
  }, [location.pathname]);

  const current = useRef();

  useEffect(() => {
    if (isMedium) {
      current.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  }, [Content]);

  return (
    <>
      <Grid container lg={12} md={12} sm={12} xs={12}>
        <Grid
          item
          container
          lg={2.5} md={4} sm={5} xs={12}          
        >
          <Grid container item lg={12} md={12} sm={12} xs={12} style={{ boxShadow: '0px 2px 6px 0px rgba(0,0,0,0.08)'}} >
            <Sidebar />
          </Grid>
        </Grid>        
       
        <Grid
          item
          container
          lg={9.5} md={8} sm={7} xs={12}
          style={{display: 'block', flexBasis: 'auto'}}      
        >
          {header ? (
            <Grid item lg={12} md={12} sm={12} xs={12} className="pb-8" style={{ boxShadow: '0px 2px 6px 0px rgba(0,0,0,0.08)'}} paddingTop='50px' paddingLeft='40px'>
              {header}
            </Grid>
            ) : ''
          }
          <Grid
            ref={current}
            lg={12
            }
            md={12}
            sm={12}
            xs={12}
            className=""
            item
            paddingLeft='20px'            
          >
            {Content}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
