import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { Grid, Box, Typography, useMediaQuery, useTheme, Modal } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import ImgSection from './ImgHeader';
import NewsSecTab from './Popular_Library';


const IndustryNews = ()  => {

    return (
        <>
       
        <Box className='flex !h-[100%] !w-[87%]' padding={5} style={{marginLeft:'8%'}}>
              <Grid container lg={12} sm={12} xs={12} className=' !w-[80%]' columnGap='20px'>
                <Grid item  lg={8} sm={10} xs={6}  style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                   <ImgSection />
                </Grid>
                <Grid item lg={3} sm={2} xs={6} paddingTop='10px'>
                    <NewsSecTab />
                </Grid>
              </Grid>
        </Box>
        </>    
    )
}

export default IndustryNews;

// const Content = () => {


//     return(
//         <>
//          <Box className=' !h-[500px]'>
//               <Grid container lg={12} sm={12} xs={12} className=''>
//                 <Grid item lg={10} sm={10} xs={6} className=''>
                    
//                 </Grid>
//                 <Grid item lg={2} sm={2} xs={6} className=''></Grid>
//               </Grid>
//         </Box>
//         </>
//     )
// }