import React, { useRef,useEffect, useState } from 'react';
import {
  Button,
  Divider,
  FormLabel,
  Grid,
  Typography,
  useMediaQuery,
  Box,
  Menu,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Years } from './Years';
import { Mileage } from './Mileage';
import { Hours } from './Hours';
import { Make } from './Make';
import { Select_Gear_Type } from './Select_Gear_Type';
import { Zip } from './Zip';
import { useNavigate } from 'react-router';
import { routes } from 'routes/RouteConstants';
import { useRefContext } from 'components/Layout';

export const FilterIndex = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'));

  const { filterValues, setFilterValues } = useRefContext();
  const handleSeacrhInventory = () => {
    navigate(routes.INVENTORY_LISTING.pathname, {
      state: { data: filterValues },
    });
  };
  const [newArray, setNewArray] = useState([]);
  useEffect(() => {
    const array = filterValues?.filter((el) => (el.filter === 'Make') && (el.category===9));
    setNewArray(array);
  }, [filterValues]); 
  
  return (
    <Box
      container
      sx={{
        background: `${
          isLarge
            ? 'black'
            : 'linear-gradient(90deg, #000000 59.7%, rgba(0, 0, 0, 0) 77.19%)'
        }`,
      }}
      className={`${
        isLarge ? 'block' : 'flex'
      } p-2 rounded-lg z_index_adjustment   border-2 border-solid border-primary-50  `}
    >
      <Grid
        justifyContent="space-around"
        className="z_index_adjustment px-2 bg-white  "
        alignItems="center"
        container
      >
        <Years filterValues={filterValues} setFilterValues={setFilterValues} />
        
        <Make filterValues={filterValues} setFilterValues={setFilterValues} />
        
        {newArray.length===0 ?(
          <>
            <Mileage
              filterValues={filterValues}
              setFilterValues={setFilterValues}
            />
            <Select_Gear_Type
              filterValues={filterValues}
              setFilterValues={setFilterValues}
            />
          </>
        ):(
          <>
          <Hours
              filterValues={filterValues}
              setFilterValues={setFilterValues}
            />
          </>
        )}
        

        <Zip filterValues={filterValues} setFilterValues={setFilterValues} />
      </Grid>
      <Box
        justifyContent="center"
        className={`${isLarge ? 'flex' : ''} m-auto max-lg:w-[20%] pl-2 ${
          isLarge ? 'py-2 pr-2 !w-[100%]' : ''
        }`}
      >
        <Button
          onClick={handleSeacrhInventory}
          variant="contained"
          className="!w-[276px] w-full"
        >
          Search Inventory
        </Button>
      </Box>
    </Box>
  );
};
