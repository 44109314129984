import {
  CircularProgress,
  Dialog,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { CustomTextField } from 'components';
import { Grid ,IconButton} from '@mui/material';

import CustomButton from 'components/CustomButton/CustomButton';
import * as yup from 'yup';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import jwt_decode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import OTPInput from 'otp-input-react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OtpInput from 'react-otp-input';
import CloseIcon from '@mui/icons-material/Close';

import {
  useFacebookLoginQuery,
  useGoogleLoginQuery,
  useOnboardingQuery,
  useSendOtpQuery,
  useVerifyOtp,
} from 'query/Auth';
import { routes } from 'routes/RouteConstants';

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import { saveData } from 'utils/localStorage';
import GoogleLogin from 'react-google-login';
import { gapi } from 'gapi-script';
import { useRefContext } from 'components/Layout';
import { formatPhoneNumber, normalizePhone } from 'utils/phone';

const signupOnboardigSchema = yup.object().shape({
  emailPh: yup
    .string()
    .nullable()
    .required('Please enter Email or phone number').min(2,'Atleast 2 characters'),
});
const SCOPE =
  'https://www.googleapis.com/auth/userinfo.email  https://www.googleapis.com/auth/plus.me';
export const PhoneIcon = () => {
  return (
    <svg
      width="67"
      height="66"
      viewBox="0 0 67 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="5.5"
        y="5"
        width="56"
        height="56"
        rx="28"
        fill="#A8CDE5"
        fill-opacity="0.1"
      />
      <path
        d="M33.4997 39.4166H33.5113M29.0663 44.6666H37.933C39.2398 44.6666 39.8932 44.6666 40.3923 44.4123C40.8314 44.1886 41.1883 43.8316 41.412 43.3926C41.6663 42.8934 41.6663 42.24 41.6663 40.9333V25.0666C41.6663 23.7598 41.6663 23.1064 41.412 22.6073C41.1883 22.1682 40.8314 21.8113 40.3923 21.5876C39.8932 21.3333 39.2398 21.3333 37.933 21.3333H29.0663C27.7596 21.3333 27.1062 21.3333 26.607 21.5876C26.168 21.8113 25.811 22.1682 25.5873 22.6073C25.333 23.1064 25.333 23.7598 25.333 25.0666V40.9333C25.333 42.24 25.333 42.8934 25.5873 43.3926C25.811 43.8316 26.168 44.1886 26.607 44.4123C27.1062 44.6666 27.7596 44.6666 29.0663 44.6666ZM34.083 39.4166C34.083 39.7388 33.8218 39.9999 33.4997 39.9999C33.1775 39.9999 32.9163 39.7388 32.9163 39.4166C32.9163 39.0944 33.1775 38.8333 33.4997 38.8333C33.8218 38.8333 34.083 39.0944 34.083 39.4166Z"
        stroke="#0F5E91"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="5.5"
        y="5"
        width="56"
        height="56"
        rx="28"
        stroke="#357AA6"
        stroke-opacity="0.1"
        stroke-width="10"
      />
    </svg>
  );
};
export const emailIcon = () => {
  return (
    <svg
      width="66"
      height="66"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="5"
        y="5"
        width="56"
        height="56"
        rx="28"
        fill="#A8CDE5"
        fill-opacity="0.1"
      />
      <path
        d="M44.6663 26.0001C44.6663 24.7167 43.6163 23.6667 42.333 23.6667H23.6663C22.383 23.6667 21.333 24.7167 21.333 26.0001M44.6663 26.0001V40.0001C44.6663 41.2834 43.6163 42.3334 42.333 42.3334H23.6663C22.383 42.3334 21.333 41.2834 21.333 40.0001V26.0001M44.6663 26.0001L32.9997 34.1667L21.333 26.0001"
        stroke="#0F5E91"
        stroke-width="2.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="5"
        y="5"
        width="56"
        height="56"
        rx="28"
        stroke="#357AA6"
        stroke-opacity="0.1"
        stroke-width="10"
      />
    </svg>
  );
};
const Signup = () => {
  const { handleOpenSomethingWentWrongDialog } = useRefContext();
  const navigate = useNavigate();
  const location = useLocation();
  const history = useLocation();
  const prefilled = location.state?.data;

  const [OTP, setOTP] = useState('');
  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
    setError: setOnboardingError,
  } = useForm({ resolver: yupResolver(signupOnboardigSchema) });
  
const emailPh=useWatch({control,name:'emailPh'})
 
  const [resendOtpCount, setResendOtpCount] = useState(60);
  const [resendOtpCredentials, setresendOtpCredentials] = useState();
 
  const [onboardLoading, setOnboardLoading] = useState(false);
  const [otpButtonLoading, setOtpButtonLoading] = useState(false);

  function sendOtpOnSuccess(res) {
     
    setOnboardLoading(false);
    setOpen(true);
    countDown();
   
  }

  function sendOtpOnError(err) {
    if (err.status_code == 500) {
      handleOpenSomethingWentWrongDialog();
    }
    setOnboardLoading(false);
    setOtpError(err.erros[0].message);
  }

  function onSuccessOtpVerification(res) {
    clearInterval(storeInterval);
    setOtpButtonLoading(false);

    navigate(routes.CREATE_ACCOUNT, {
      state: { ...data, currentType },
    });
  }

  function onErrorOtpVerification(err) {
    setOtpButtonLoading(false);
    setOtpError(err.message);
    if (err.status_code === 500) {
      handleOpenSomethingWentWrongDialog();
    }
  }

  const [otpError, setOtpError] = useState('');
  const [storeInterval, setStoreInterval] = useState();
  const theme = useTheme();
  const XSmall = useMediaQuery(theme.breakpoints.down('xs'));
  // const { ref: emailPhRef, ...regEmailPh } = register('emailPh');
  const [open, setOpen] = useState(false);
  const [currentType, setCurrentType] = useState('email');
 
  const [blur, setBlur] = useState(false)

  const [loadingButton, setLoadingButton] = useState(false);

  const { mutate: onboarding } = useOnboardingQuery();
  const { mutate: sendOtp, isLoading: sendingOtp } = useSendOtpQuery(
    sendOtpOnSuccess,
    sendOtpOnError
  );
 
  const { mutate: verifyOtp } = useVerifyOtp(
    onSuccessOtpVerification,
    onErrorOtpVerification
  );

  const { mutate: GoogleLoginQuery } = useGoogleLoginQuery(
    loginSuccess,
    loginFailed
  );
  const { mutate: FacebookLoginQuery } = useFacebookLoginQuery(
    loginSuccess,
    loginFailed
  );
  const [data, setData] = useState();

  function loginSuccess(res) {
    setLoadingButton(false);
    navigate(routes.REDIRECTOR);
  }
  function loginFailed(err) {
    setLoadingButton(false);
    if(err.status_code==500){
      handleOpenSomethingWentWrongDialog()
    }
  }

  const handleOnboardFailed = (err) => {
    setOnboardingError('emailPh', { type: 'custom', message: err.message });
    setOpen(false);
    if(err.status_code==500){
      handleOpenSomethingWentWrongDialog()
    }
  };
  useEffect(() => {
    if (prefilled != null) {
      setOpen(true);

      formSubmitRedirected({ emailPh: prefilled.email || prefilled.mobile });
      navigate(location.pathname, {});
    }
  }, [location.pathname]);

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: SCOPE,
      });
    };
    gapi.load('client:auth2', initClient);
  });

  const secondConversion = (duration) => {
    const mins = ~~((duration % 3600) / 60);
    const secs = ~~duration % 60;

    let ret = '';
    ret += '' + `0${mins}` + ':' + (secs < 10 ? '0' : '');
    ret += '' + secs;

    return ret;
  };
  // console.log(coun)
  const formSubmitRedirected = (data) => {
    setData(data);
    if (validatePhoneNumber(data?.emailPh)) {
      setCurrentType('phone');

      setresendOtpCredentials({
        country_code: '+1',
        phone: data.emailPh,
        channel: 'PHONE_OTP',
        otp_for: 'Register',
      });
      sendOtp({
        country_code: '+1',
        phone: data.emailPh,
        channel: 'PHONE_OTP',
        otp_for: 'Register',
      });
    } else if (validateEmail(data?.emailPh)) {
      setresendOtpCredentials({
        email: data.emailPh,
        channel: 'EMAIL_OTP',
        otp_for: 'Register',
      });
      sendOtp({
        email: data.emailPh,
        channel: 'EMAIL_OTP',
        otp_for: 'Register',
      });
    } else {
      setOnboardLoading(false);
      setOnboardingError('emailPh', {
        type: 'custom',
        message: 'Please enter a valid email or phone number',
      });
      setCurrentType('');
    }
  };

  const formSubmit = (data) => {
    setOnboardLoading(true);

    setData(data);
    if (validatePhoneNumber(data?.emailPh)) {
      setCurrentType('phone');

      onboarding(
        { phone:  normalizePhone( data.emailPh) },
        {
          onError: (err) => {
            setOnboardLoading(false);
            handleOnboardFailed(err);
          },
          onSuccess: (res) => {
            if (
              res.data.intend_to == 'LOGIN' ||
              res.data.intend_to == 'REACTIVATE'
            ) {
              setOnboardLoading(false);
              setOnboardingError('emailPh', {
                type: 'custom',
                message: 'Please login as you already exist in our system',
              });

              return;
            }

            setresendOtpCredentials({
              country_code: '+1',
              phone: data.emailPh,
              channel: 'PHONE_OTP',
              otp_for: 'Register',
            });
            sendOtp({
              country_code: '+1',
              phone: data.emailPh,
              channel: 'PHONE_OTP',
              otp_for: 'Register',
            });
          },
        }
      );
    } else if (validateEmail(data?.emailPh)) {
      setCurrentType('email');

      onboarding(
        { email: data.emailPh },
        {
          onError: (err) => {
            setOnboardLoading(false);
            setOnboardingError('emailPh', {
              type: 'custom',
              message: err.message,
            });
          },
          onSuccess: (res) => {
            if (
              res.data.intend_to == 'LOGIN' ||
              res.data.intend_to == 'REACTIVATE'
            ) {
              setOnboardLoading(false);
              setOnboardingError('emailPh', {
                type: 'custom',
                message: 'Please login as you already exist in our system',
              });

              return;
            }

            setresendOtpCredentials({
              email: data.emailPh,
              channel: 'EMAIL_OTP',
              otp_for: 'Register',
            });
            sendOtp({
              email: data.emailPh,
              channel: 'EMAIL_OTP',
              otp_for: 'Register',
            });
          },
        }
      );
    } else {
      setOnboardLoading(false);
      setOnboardingError('emailPh', {
        type: 'custom',
        message: 'Please enter a valid email or phone number',
      });
      setCurrentType('');
    }
  };
  const countDown = () => {
    setResendOtpCount(60);
    let intervalId = setInterval(() => {
      if (resendOtpCount == 0) {
        clearInterval(intervalId);
        return;
      }
      setResendOtpCount((prev) => {
        if (prev > 0) {
          return prev - 1;
        } else {
          return 0;
        }
      });
    }, 1000);

    setStoreInterval(intervalId);
  };

  function validatePhoneNumber(input_str) {
    var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

    return re.test(input_str);
  }
  function validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  const handleResendOtp = () => {
    setOTP('');
    sendOtp(resendOtpCredentials, {
      onSuccess: (res) => {
        countDown();
      },
    });
  };
  const handleVerifyOtp = () => {
    if (OTP.length < 4) {
      setOtpError('Please enter OTP');
      return;
    }
    setOtpButtonLoading(true);
    if (OTP) {
      setOtpError('');

      verifyOtp({
        ...resendOtpCredentials,
        otp: OTP,
      });
    } else {
      setOtpError('Please enter a valid OTP');
    }
  };
   
  const handleBacktoLogin = () => {
    setOtpError('');
    clearInterval(storeInterval);
    navigate(routes.LOGIN.pathname);
  };
  const onSuccessGoogleLogin = (tokenResponse) => {
    var decoded = jwt_decode(tokenResponse.tokenId);
    GoogleLoginQuery({
      id_token: tokenResponse.tokenId,
    });
    saveData('user', decoded);
   
  };
  const onFailureGoogleLogin = (err) => {
  };

  const FacebookLoginCallback = (res) => {
    saveData('user', res);
    FacebookLoginQuery({ access_token: res.accessToken });
  };
  const handleSetValue = (key, value) => {
    setValue(key, value, {
      shouldValidate: true,
    });
  };
  const handleCloseOtp=()=>{
    setResendOtpCount(60);
    clearInterval(storeInterval)
    setOpen(false)
  }
  return (
    <Grid
      container
      className="h-[100%]"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item container className="h-auto !w-auto " direction="column">
        <Grid item>
          <Typography variant="h4" className="!font-[inter] font-semibold">
            Sign Up
          </Typography>
          <Typography className="text-gray-60 !font-[inter] pt-2">
            Please enter your details.
          </Typography>
        </Grid>
        <form onSubmit={handleSubmit(formSubmit)}>
          <Grid item className="py-7">
            <CustomTextField
              label="Enter Email or Phone Number"
              placeholder="Email or Phone Number"
              className="max-w-[360px]"
              error={Boolean(errors?.emailPh)}
              FormHelperTextProps={{
                className: 'animateErrorValue ',
              }}
              helperText={errors?.emailPh?.message}
              value={formatPhoneNumber(emailPh, blur)}
              onBlur={() => setBlur(true)}
              onChange={(e) => handleSetValue('emailPh', e.target.value)}
            />
          </Grid>
          <Grid item>
            {onboardLoading ? (
              <CustomButton styles=" max-h-[44px] max-w-[400px]">
                <CircularProgress size={30} className="!text-white" />
              </CustomButton>
            ) : (
              <CustomButton type="submit" styles="max-h-[44px] max-w-[400px]">
                Continue
              </CustomButton>
            )}
          </Grid>
        </form>
        <Grid
          item
          container
          className="gap-4 pt-5 justify-center "
          justifyContent={XSmall ? 'center' : 'space-between'}
        >
          <Grid item>
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              render={(renderProps) => (
                <div
                  onClick={renderProps.onClick}
                  className="border-2 border-solid border-[#D0D5DD] min-w-[172px] py-2 text-center rounded-lg cursor-pointer"
                >
                  <img
                    className="w-7"
                    src="/assets/google.svg"
                    alt="google-logo"
                  />
                </div>
              )}
              buttonText="Login"
              onSuccess={onSuccessGoogleLogin}
              onFailure={onFailureGoogleLogin}
              cookiePolicy={'single_host_origin'}
            />
          </Grid>
          <Grid item>
            {/* <FacebookLogin
              appId={process.env.REACT_APP_FACEOOK_APP_ID}
              autoLoad={false}
              fields="name,email,picture"
              callback={FacebookLoginCallback}
              cssClass="my-facebook-button-class"
              render={(renderProps) => (
                <div
                  onClick={renderProps.onClick}
                  className="border-2 border-solid border-[#D0D5DD] min-w-[172px] py-2 text-center rounded-lg cursor-pointer"
                >
                  <img
                    className="w-7"
                    src="/assets/Auth/FacebookIcon.svg"
                    alt="google-logo"
                  />
                </div>
              )}
              textButton=""
            /> */}
          </Grid>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          className="text-gray-60 pt-5"
        >
          <Typography className="font-[inter]">
            Already have an account?{' '}
            <span
              onClick={() => {
                navigate('/login');
              }}
              className="text-secondary-50 no-underline font-medium cursor-pointer"
            >
              Log In
            </span>
          </Typography>
        </Grid>
      </Grid>

      <Dialog justifyContent="center" alignItems="center" open={open}>
        <IconButton className="absolute right-4 top-4" onClick={handleCloseOtp}>
          <CloseIcon />
        </IconButton>
        <Grid item className="max-w-[456px] max-h-[618px] p-12">
          <Grid item container justifyContent="center" alignItems="center">
            {currentType === 'email' ? emailIcon() : PhoneIcon()}
          </Grid>
          <Grid item className="text-center ">
            <Typography
              variant="h5"
              className="!font-[inter] font-semibold py-2"
            >
              Check your {currentType}
            </Typography>
            <Typography variant="subtitle2" className="!font-[inter] py-5">
              A verification code was sent to{' '}
              {currentType == 'phone'
                ? formatPhoneNumber(data?.emailPh, true)
                : data?.emailPh}
            </Typography>
          </Grid>
          <Grid item container justifyContent="center">
            <Grid item justifyContent="center">
              <OtpInput
                value={OTP}
                onChange={setOTP}
                numInputs={4}
                inputType="number"
                inputStyle={{
                  height: '70px',
                  width: '70px',
                  fontSize: '30px',
                  borderRadius: '8px',
                  border: '1px solid rgba(209, 213, 219, 1)',
                  margin: '12px',
                }}
                renderSeparator={<span className="w-4 h-8"> </span>}
                renderInput={(props) => <input {...props} />}
              />
              {otpError && (
                <Typography className="animateErrorValue pt-2 text-red-500 text-center text-xs">
                  {otpError}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid item>
            {otpButtonLoading ? (
              <CustomButton
                styles="max-h-[44px] !text-[15px] font-medium my-5"
                fullWidth
              >
                <CircularProgress size={30} className="text-white" />
              </CustomButton>
            ) : (
              <CustomButton
                onClick={OTP.length == 4 ? handleVerifyOtp : () => {}}
                disabled={OTP.length == 4 ? false : true}
                styles="h-[40px] !text-[15px] font-medium my-5"
                fullWidth
              >
                Continue
              </CustomButton>
            )}
          </Grid>
          <Grid justifyContent="center" className="text-center" item>
            <Typography className="text-sm !font-[inter]">
              Please do not share this code with anyone. Sell My Rig and its
              associates will never ask for your verification code.
            </Typography>
          </Grid>
          <Typography className="!font-[inter] text-sm text-center pt-4">
            {' '}
            Didn't recieve the{' '}
            {currentType == 'email'
              ? 'mail'
              : currentType == 'phone'
              ? 'message'
              : ''}
            ?
            <span className="text-sm font-medium text-secondary-50 cursor-pointer pl-1">
              {resendOtpCount == 0 ? (
                sendingOtp ? (
                  <CircularProgress size={30} className="!text-white" />
                ) : (
                  <span onClick={handleResendOtp}>Resend OTP</span>
                )
              ) : (
                `${secondConversion(resendOtpCount)}`
              )}
            </span>
          </Typography>
        </Grid>

        <Grid>
          <Grid
            onClick={handleBacktoLogin}
            item
            justifyContent="center"
            className="pb-4 text-center"
            container
            lg={12}
            md={12}
            xs={12}
          >
            <ArrowBackIcon className="px-1 text-gray-90" />
            <Typography className="!font-[inter] text-sm pb-4 justify-center ">
              <span className="text-sm font-medium cursor-pointer text-gray-90 ">
                Back to Login
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Dialog>
    </Grid>
  );
};

export default Signup;
