import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { FileUploader } from 'pages/commonComponents/FileUploader/FIleUploader';
import { useRefContext } from 'components/Layout';
import { PreviouslyUploadedFile } from '../../../../components/PreviouslyUploadedFile';
import { FileUploaderReadOnly } from '../../../commonComponents/FileUploaderReadOnly/FileUploaderReadOnly';
export const VoidedCheque = (props) => {
    const { activeStep, setActiveStep ,data,setData, deleteAttachment, deleteDocumentAntiDode, inputData,setInputData, allPreviouslyUploadedDocuments, setAllPreviouslyUploadedDocuments} = props;
    const { headerRef, navRef } = useRefContext();
    const [enableButton,setEnableButton]= useState(true)


    // State
  const [selectedFrontFile, setSelectedFrontFile] = useState(null);
  const [selectedBackFile, setSelectedBackFile] = useState(null);

  // Methods

  const handleIsSelectedState = (fileData, state = true) => {
    let modifiedArr = [...allPreviouslyUploadedDocuments];
    modifiedArr.forEach(item => {
      if (item.id === fileData.id) {
        item._isSelected = state;
      }
    });
  }

  const handlePreviouslyUploadedPreviewDelete = (item, type) => {
    // set _isSelected to false
    handleIsSelectedState(item, false);
    // remove from the selected state used (to show read only preview)
    if (type === 'FRONT') {
      const newData = { ...data };
      newData.documents.VoidedCheque[0] = null;
      setData(newData);
      // remove inputFile cache as well
      const newInputData = { ...inputData };
      newInputData.documents.VoidedCheque[0] = null;
      setInputData(newInputData);
      setSelectedFrontFile(null);
    }
    if (type === 'BACK') {
      const newData = { ...data };
      newData.documents.VoidedCheque[1] = null;
      setData(newData);
      const newInputData = { ...inputData };
      newInputData.documents.VoidedCheque[1] = null;
      setInputData(newInputData);
      setSelectedBackFile(null)
    }

    handleSetEnabled();
  }
  const handlePreviouslyUploadedDocumentClick = (item, type) => {
    if (type === 'FRONT') {
      if (selectedFrontFile) {
        // deselect the last selected file
        handleIsSelectedState(selectedFrontFile, false);
      }
      // set the newly selected file
      setSelectedFrontFile(item);
      // Set/OverWrite data on documents[key]
      const newData = { ...data };
      newData.documents.VoidedCheque[0] = item;
    }
    if (type === 'BACK') {
      if (selectedBackFile) {
        // deselect the last selected file
        handleIsSelectedState(selectedBackFile, false);
      }
      // set the newly selected file
      setSelectedBackFile(item);
      // Set/OverWrite data on documents[key]
      const newData = { ...data };
      newData.documents.VoidedCheque[1] = item;
    }

    handleSetEnabled();
  }


  const handleSetEnabled = () => {
    // setTimeout(() => {
    //   if (data.documents.VoidedCheque?.filter(item => {
    //     return item != null;
    //   }).length === 1) {
    //     setEnableButton(false);
    //   } else {
    //     setEnableButton(true);
    //   }
    // }, 50);
  };
  const handleSuccess = (res) => {
    const newData = { ...data };

    if (newData?.documents?.VoidedCheque) {

      newData.documents.VoidedCheque = [
        res.resData?.data,
      ];
    } else {
      newData.documents.VoidedCheque = [res.resData?.data];
    }

    const newInputData = { ...inputData };

    if (newInputData?.documents?.VoidedCheque) {
      newInputData.documents.VoidedCheque = [
        { file: res.fileData, description: res.attachmentDescription },
      ];
    } else {
      newInputData.documents.VoidedCheque = [
        { file: res.fileData, description: res.attachmentDescription },
      ];
    }

    setInputData(newInputData);
    setData(newData);

  };

  const [previouslyUploadedDocsAvailable, setPreviouslyUploadedDocsAvailable] = useState(false);
  useEffect(() => {
    if (allPreviouslyUploadedDocuments.filter((item) => {
      return item.document_type === 'VOIDED_CHECK';
    }).length) {
      setPreviouslyUploadedDocsAvailable(true);
    } else {
      console.log("Else Condition");
    }
  }, [allPreviouslyUploadedDocuments]);

  useEffect(()=>{
    handleSetEnabled()
  },[data.documents.VoidedCheque])

  useEffect(() => {
    if (data?.documents?.VoidedCheque[0] && !inputData?.documents?.VoidedCheque[0]) {
      setSelectedFrontFile(data?.documents?.VoidedCheque[0]);
    }
    if (data?.documents?.VoidedCheque[1] && !inputData?.documents?.VoidedCheque[1]) {
      setSelectedBackFile(data?.documents?.VoidedCheque[1]);
    }
  }, []);

  const handleNext=()=>{
    setActiveStep(activeStep+1)
  }
  return (
    <div
      className="UploadVideoPS ag-grid"
      style={{
        height: 'calc(100vh - ' + headerRef?.current?.clientHeight + 'px)',
      }}
    >
      <header className="content-header">
        <div className="action-bar flex justify-between p-2">
          <div></div>
          <div className="actions"></div>
        </div>
      </header>
      <div className="main">
        <div className="stepper-content-header py-2 pb-8">
          <p className="text-[#051C2C] text-[16px]  font-medium	 !font-[Inter]">
            Upload Voided Check
          </p>
        </div>

        <Grid container className="stepper-content">
        <Grid item lg={6} md={12} sx={12}>
          {
            selectedFrontFile ?
              <FileUploaderReadOnly
                fileDataDefault={selectedFrontFile}
                handleDelete={(item) => {
                  handlePreviouslyUploadedPreviewDelete(item, 'FRONT');
                }}
              /> :
              <FileUploader
                attachmentDescription='VOIDED_CHECK'
                document_type='VOIDED_CHECK'
                document_side='NONE'
                fileDataDefault={inputData.documents?.VoidedCheque[0]?.file || null}
                is_primary={false}
                type={'document'}
                handleSuccess={(res) => {
                  handleSetEnabled();
                  handleSuccess(res, 0);
                }}
                handleError
                handleDelete={() => {
                  handleSetEnabled();
                  deleteDocumentAntiDode('VoidedCheque', 0);
                }}
                /*handleDelete={()=>{deleteAttachment('VoidedCheque','Image')}}*/
              />
          }
      </Grid>
      
           
        </Grid>
        {previouslyUploadedDocsAvailable ?
          <div>
            <div className='or-section max-w-[1010px] !my-12'></div>
            <div className='stepper-content-header py-2 pb-10'>
              <p className='text-[#051C2C] text-[16px]  font-medium	 !font-[Inter]'>
                {' '}
                Choose a document
              </p>
            </div>
            <Grid container className='stepper-content'>
              <Grid item lg={6} md={12} sx={12}>
                {/*<Typography className='text-sm py-2'>Front</Typography>*/}
                {allPreviouslyUploadedDocuments?.map((item) => {
                  if (item.document_type === 'VOIDED_CHECK' && item.document_side === 'NONE') {
                    return (
                      <div className='mb-4'>
                        <PreviouslyUploadedFile
                          handleSuccess={(item) => {
                            // handleSuccess(res, 0)
                            handlePreviouslyUploadedDocumentClick(item, 'FRONT');
                          }}
                          handleDeSelect={(item) => {
                            handlePreviouslyUploadedPreviewDelete(item, 'FRONT');
                          }}
                          document_type='VOIDED_CHECK'
                          document_side='NONE'
                          fileData={item}
                          allPreviouslyUploadedDocuments={allPreviouslyUploadedDocuments}
                          setAllPreviouslyUploadedDocuments={setAllPreviouslyUploadedDocuments}
                        />
                      </div>
                    );
                  }
                })}
              </Grid>
            </Grid>
          </div> : null}
      </div>
      <footer className="content-footer mb-[200px]">
        <div className="stepper-content-actions-footer flex justify-between">
          <div></div>
          <div className="flex flex-col gap-6">
            <Button
              style={{
                width: '275px',
                minHeight: '44px',
                height: '44px',
              }}
              // disabled={enableButton}
              onClick={handleNext}
              variant="contained"
            >
              Continue
            </Button>
          </div>
        </div>
      </footer>
    </div>
  );
};
