import { Box } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { FilterParentComponent } from './FilterParentComponent';

import { Divider } from '@material-ui/core';
import { AppliedFiltersComponent } from './AppliedFiltersComponent';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKey } from 'query/queryKeys';

function generateArrayOfYears() {
  var max = new Date().getFullYear();
  var min = max - 20;
  var years = [];

  for (var i = max; i >= min; i--) {
    years.push(i);
  }
  return years;
}
const extraFilters = {
  Year: generateArrayOfYears(),
  Price: ['price'],
};
const endFilters = {
  Hours: [0, 10000000],
  'Air Ride': ['Yes', 'No'],
  Scale: ['Yes', 'No'],
  'Side Skirt': ['Yes', 'No'],
};
export const Filters = ({
  isappliedFilterVisible,
  appliedFilters,
  setAppliedFilters,
  filterFilter,
  setFilterFilter,

  data,
  refetch,
  dataUpdatedAt,
}) => {
  const queryClient = useQueryClient();
  const [SeggrigatedData, setSeggrigatedData] = useState({});

  useEffect(() => {
    refetch(filterFilter);
  }, [filterFilter]);

  const latest = useRef();

  useEffect(() => {
    if (data?.data) {
      setSeggrigatedData({
        category: queryClient?.getQueriesData([
          QueryKey.GET_FILTERS,
          [],
        ])?.[0]?.[1]?.data.category,
        sub_category: data?.data?.sub_category,

        brand: queryClient?.getQueriesData([QueryKey.GET_FILTERS, []])?.[0]?.[1]
          ?.data.brand,
        Year: extraFilters.Year,
        model: data?.data?.model,
        Price: extraFilters.Price,
        mileage: data?.data?.mileage,
        horse_power: data?.data?.horse_power,
        engine_manufacturer: data?.data?.engine_manufacturer,
        transmission_manufacturer: data?.data?.transmission_manufacturer,

        ...endFilters,
      });
    }
  }, [dataUpdatedAt]);

  useEffect(() => {
    latest?.current?.focus();

    if (appliedFilters.some((el) => el.label == 'Commercial Truck')) {
      // console.log(appliedFilters, SeggrigatedData, 'sgcuysshbcjhebcfjwhe');
      setSeggrigatedData({
        category: queryClient?.getQueriesData([
          QueryKey.GET_FILTERS,
          [],
        ])?.[0]?.[1]?.data.category,
        sub_category: data?.data?.sub_category,

        brand: queryClient?.getQueriesData([QueryKey.GET_FILTERS, []])?.[0]?.[1]
          ?.data.brand,
        Year: extraFilters.Year,
        model: data?.data?.model,
        Price: extraFilters.Price,
        mileage: data?.data?.mileage,
        horse_power: data?.data?.horse_power,
        engine_manufacturer: data?.data?.engine_manufacturer,
        transmission_manufacturer: data?.data?.transmission_manufacturer,
      });

      // console.log('I am getting added');
    } else if (
      appliedFilters.some((el) => el.label == 'Trailer') ||
      appliedFilters.some((el) => el.label == 'Dry Van')
    ) {
      // console.log(appliedFilters, SeggrigatedData, 'sgcuysshbcjhebcfjwhe');
      setSeggrigatedData({
        category: queryClient?.getQueriesData([
          QueryKey.GET_FILTERS,
          [],
        ])?.[0]?.[1]?.data.category,
        sub_category: data?.data?.sub_category,

        brand: queryClient?.getQueriesData([QueryKey.GET_FILTERS, []])?.[0]?.[1]
          ?.data.brand,
        Year: extraFilters.Year,
        model: data?.data?.model,
        Price: extraFilters.Price,
        mileage: data?.data?.mileage,
        horse_power: data?.data?.horse_power,
        ...endFilters,
      });

      // console.log('I am getting added');
    } else {
      if (data?.data) {
        setSeggrigatedData({
          category: queryClient?.getQueriesData([
            QueryKey.GET_FILTERS,
            [],
          ])?.[0]?.[1]?.data.category,
          sub_category: data?.data?.sub_category,

          brand: queryClient?.getQueriesData([
            QueryKey.GET_FILTERS,
            [],
          ])?.[0]?.[1]?.data.brand,
          Year: extraFilters.Year,
          model: data?.data?.model,
          Price: extraFilters.Price,
          mileage: data?.data?.mileage,
          horse_power: data?.data?.horse_power,
          engine_manufacturer: data?.data?.engine_manufacturer,
          transmission_manufacturer: data?.data?.transmission_manufacturer,

          ...endFilters,
        });
      }
      // setSeggrigatedData({ ...removed });
    }
    // console.log({ appliedFilters });
  }, [appliedFilters, dataUpdatedAt]);

  return (
    <Box justifyContent="center" className="p-2 ">
      <Box
        sx={{ margin: '0 auto' }}
        className="!pb-2 max-w-[330px]  border-gray-30 border-solid border-[1px]"
      >
        {isappliedFilterVisible && (
          <AppliedFiltersComponent
            refetchFilters={refetch}
            setFilterFilter={setFilterFilter}
            setSeggrigatedData={setSeggrigatedData}
            filterBorder={false}
            appliedFilters={appliedFilters}
            setAppliedFilters={setAppliedFilters}
          />
        )}
        <Divider variant="middle" />
      </Box>

      <Box className="w-[330px] mt-2 px-2" sx={{ border: '1px solid #F5F5F5' }}>
        <FilterParentComponent
          ShowSearch={false}
          isSlider={false}
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
          title={'Sort'}
          data={[
            'Relevance',
            'A-Z',
            'Z-A',
            'Price high to low',
            'Price low to high',
          ]}
          key={'sort'}
        />

        {Object.entries(SeggrigatedData)?.map(([key, val]) => {
          if (key != 'id' && key != 'trailer_manufacturer') {
            return (
              <FilterParentComponent
                ShowSearch={
                  key == 'Price' ||
                  key == 'mileage' ||
                  key == 'Hours' ||
                  key == 'Air Ride' ||
                  key == 'Scale' ||
                  key == 'Side Skirt'
                    ? false
                    : true
                }
                isAnum={
                  key == 'Air Ride' || key == 'Scale' || key == 'Side Skirt'
                    ? true
                    : false
                }
                isSlider={
                  key == 'Price' || key == 'mileage' || key == 'Hours'
                    ? true
                    : false
                }
                filterFilter={filterFilter}
                setFilterFilter={setFilterFilter}
                appliedFilters={appliedFilters}
                setAppliedFilters={setAppliedFilters}
                title={key}
                data={val}
                key={key}
              />
            );
          }
        })}
        {/* </>
          
        )} */}
      </Box>
    </Box>
  );
};
