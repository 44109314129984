import { Box } from '@mui/material';

import { BillingCardsSelection } from 'pages/SMRRInfo/PaymentMethod/BillingCardsSelection';
import { BillingDetail } from 'pages/SMRRInfo/PaymentMethod/BillingDetail';
import { useGetFinanceDetails } from 'query/productDetails';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { FinancingCard } from './FinancingCard';

export const FinancingPayment = () => {
  const location = useLocation();
  const listing_id = location?.state?.listing_id;
  const unique_id = location?.state?.unique_id;
  const id = location?.state?.id;

  const tabtype = location?.state?.tabtype;

  const [serviceTax, setServiceTax] = useState(0);
  const [total, setTotal] = useState(0);
  const { data, isLoading, refetch } = useGetFinanceDetails(listing_id);
  const payment_type = location?.state.payment_Type;


  useEffect(() => {
    refetch(listing_id);
  }, [listing_id]);

  useEffect(() => {
    if (
      data?.data?.listing?.quotes?.quote_price ||
      data?.data?.listing?.selling_price
    ) {
      setServiceTax(
        (
          Number(data?.data?.listing?.quotes?.quote_price * 0.025) * 0.03
        ).toFixed(2)
      );
    }
  }, [data?.data?.listing]);

  useEffect(() => {
    if (
      data?.data?.listing?.quotes?.quote_price ||
      data?.data?.listing?.selling_price
    ) {
      setTotal(
        (
          Number(data?.data?.listing?.quotes?.quote_price * 0.025) +
          Number(serviceTax)
        ).toFixed(2)
      );
    }
  }, [data?.data?.listing, serviceTax]);

  return (
    <Box className="max-w-[1700px] !m-auto w-full ">
      <FinancingCard
        data={data}
        isLoading={isLoading}
        total={total}
        serviceTax={serviceTax}
      />
      <BillingCardsSelection
        payment_type={payment_type}
        data={data}
        quote_id={data?.data?.listing?.quotes?.id}
        list_id={data?.data?.listing?.id}
        total={total}
      />
    </Box>
  );
};
