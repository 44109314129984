import React from 'react';
import Modal from '@mui/material/Modal';
import { Button, Grid, Typography ,CircularProgress} from "@mui/material";
import { RiErrorWarningLine } from 'react-icons/ri';
import '../SMRR.css';
import { useDeleteQuote } from '../../../query/quote';
import { FinanceListDeleteAPI } from 'query/Financing/financing';
import { useEffect } from 'react';
import { setLocalStorageItem } from 'hooks';
import { useGetUserProfile } from 'query/Accounts';

const btnStyle = {
  fontFamily: 'Inter',
  color: '#FFFFFF',
  fontWeight: '500',
  fontSize: '16px',
  disableRipple: true,
  backgroundColor: '#D92D20',
  borderRadius: '8px',
};
const btnCancelStyle = {
  fontFamily: 'Inter',
  color: '#333333',
  fontWeight: '500',
  fontSize: '16px',
  disableRipple: true,
  borderRadius: '8px',
  backgroundColor: '#ffffff',
  border: '1px solid #D0D5DD',
  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
};
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  maxWidth: '420px',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow:
    '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
  borderRadius: '12px',
  padding: '20px 20px',
};
const fontStyle = {
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '20px',
  textAlign: 'center',
  color: '#545454',
  marginTop: '10px',
  marginBottom: '30px',
};
//Modal
const BasicModal = (props) => {
  console.log('props', props);
  const { open, setClose, refetchQuote,refetchListing } = props;
  const handleClose = () => props.setClose(setClose(false));
  const { mutate: DeleteQuote, isLoading: deletingQuote } = useDeleteQuote();
  const { mutate: DeleteFinanceList, isLoading: deletingFinance } =
    FinanceListDeleteAPI();
    const { data: userData, refetch, dataUpdatedAt } = useGetUserProfile()

  
    useEffect(() => {
      if (userData?.data) {
        setLocalStorageItem('USER_DETAILS', userData?.data)
      } 
    }, [dataUpdatedAt])

  const onDelete = () => {
    if (props?.type === 'FinanceList') {
      DeleteFinanceList(props?.id, {
        onSuccess: (res) => {
          refetchListing();
          refetch();
          handleClose();
        },
      });
    } else {
      DeleteQuote(props?.id, {
        onSuccess: (res) => {
          refetchQuote();
          handleClose();
        },
        onError: (err) => {},
      });
    }
  };

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={style}
          justifyContent="center"
          style={{ maxWidth: '420px' }}
        >
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className=" h-[80px] flex"
            justifyContent="center"
          >
            <div
              style={{
                height: '72px',
                width: '72px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '36px',
                backgroundColor: '#FDF3F2',
              }}
            >
              <div
                style={{
                  height: '50px',
                  width: '50px',
                  display: 'flex',
                  justifyContent: 'center',
                  borderRadius: '25px',
                  backgroundColor: '#F9D9D7',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <RiErrorWarningLine
                    style={{ height: '38px', width: '29px', color: '#DC2804' }}
                  />
                </div>
              </div>
            </div>
          </Grid>

          <Grid item justifyContent="center">
            <Typography
              style={{
                fontSize: '18px',
                fontWeight: '500',
                color: '#333333',
                textAlign: 'center',
                padding: '0 20px',
                marginTop: '10px',
              }}
            >
              {' '}
              Are you sure you want to remove this{' '}
              {props?.type === 'FinanceList' ? 'Finance' : 'Quote'} Request
            </Typography>
          </Grid>
          <Grid item justifyContent="center">
            <Typography style={fontStyle}>
              Once executed, the action is irreversible and the{' '}
              {props?.type === 'FinanceList' ? 'Finance' : 'Quote'} will be
              permanently erased.
            </Typography>
          </Grid>

          <Grid
            item
            className="flex"
            sx={{ width: '100%' }}
            justifyContent="space-between"
            alignItems="center"
          >
            {deletingQuote ? (
              <CircularProgress color="primary" />
            ) : (
              <Button
                className="detailsContainer-button-style"
                style={btnStyle}
                onClick={onDelete}
              >
                Remove
              </Button>
            )}

            <Button
              className="detailsContainer-button-style"
              style={btnCancelStyle}
              onClick={() => handleClose()}
            >
              Cancel{' '}
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
};
export default BasicModal;
