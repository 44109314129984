import React, { useState } from "react";
import { Grid, Box } from "@mui/material";
import { CommonAccountLayout } from 'pages/commonComponents/CommonAccountLayout';
import MyEquipment from "./EquipmentInfo/MyEquipment";
import SentQuoteTab from "./EquipmentInfo/SentQuoteTab";

const HeaderContent = (props) => {    
    const [currentType, setCurrentType] = useState('SentQuote');    
    const handleType = (name) => {
      setCurrentType(name);
      props.ontabChange(name);
    };

    return (
      <>
        <Grid container lg={12} md={12} sm={12} >
          <Grid item>
            <button
              className={`${
                currentType === 'SentQuote'
                  ? 'active-btn-Home-Hero  '
                  : 'inactive-btn-Home-Hero '
              } rounded-l-md rounded-l-2 cursor-pointer`}
              onClick={() => handleType('SentQuote')}
            >
              Quotes
            </button>
            <button
              className={`${
                currentType === 'MyEquip'
                  ? 'active-btn-Home-Hero  '
                  : 'inactive-btn-Home-Hero '
              } rounded-r-md rounded-l-2 cursor-pointer`}
              onClick={() => handleType('MyEquip')}
            >
              My Equipment
            </button>
          </Grid>
        </Grid>
      </>
    );
  };
  
  const EquipmentInfo = () => {    
    const userId = JSON.parse(localStorage.getItem('USER_DETAILS')).id;   
    const [tabValue,setTabVAlue] = useState('SentQuote');
    const handleTabchange= (tabValue)=> {
        setTabVAlue(tabValue);
    }
  
    return (
      <>
        <CommonAccountLayout
         header={
            <HeaderContent ontabChange = {handleTabchange}/>            
         }
          Content={
            <Box width={'100%'}>              
              <Box className="p-4" style={{ width: 'auto', marginTop: '20px', marginBottom: '40px'}}>
                {tabValue === 'SentQuote' ?(                  
                      <SentQuoteTab userId={userId} />
                    ): (
                      <MyEquipment userId={userId}  />
                    )
                  }
              </Box>
            </Box>
          }
        />
      </>
    );
  };
  
  export default EquipmentInfo;