import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';

export const isPhoneNumberValid = (phoneNumber) => {
  return Boolean(isValidPhoneNumber(`${phoneNumber}`) === true);
};

export const formatMobileNumber = (str,blur=false ) => {
  //Filter only numbers from the input
  let cleaned = ('' + str).replace(/\D/g, '');
  const srtLe = cleaned.length;

  if(srtLe===0){ return cleaned; }
  if(srtLe===1){ return cleaned; }
  if(srtLe===2){ return cleaned; }
  if(srtLe===3) { 
    let match = cleaned.match(/^(\d{3})$/);
    if (match) {
      //return '('+ match[1] +') ';
    } 
   }
  if(srtLe === 4) { 
    let match = cleaned.match(/^(\d{3})(\d{1})$/);
    if (match) {
      return '('+ match[1] +') '+ match[2];
    }  
  }
  if(srtLe === 5) { 
    let match = cleaned.match(/^(\d{3})(\d{2})$/);
    if (match) {
      return '('+ match[1] +') '+ match[2];
    }  
  }

  if(srtLe === 6) { 
    let match = cleaned.match(/^(\d{3})(\d{3})$/);
    if (match) {
      return '('+ match[1] +') '+ match[2];
    }  
  }

  if(srtLe === 7) { 
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{1})$/);
    if (match) {
      return '('+ match[1] +') '+ match[2] + '-' + match[3];
    }  
  }

  if(srtLe === 8) { 
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{2})$/);
    if (match) {
      return '('+ match[1] +') '+ match[2] + '-' + match[3];
    }  
  }

  if(srtLe === 9) { 
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{3})$/);
    if (match) {
      return '('+ match[1] +') '+ match[2] + '-' + match[3];
    }  
  }
  if(srtLe === 10) { 
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '('+ match[1] +') '+ match[2] + '-' + match[3];
    }  
  }

  if(srtLe > 10) { 
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})(\d{1})$/);
    if (match) {
      return '('+ match[1] +') '+ match[2] + '-' + match[3];
    }  
  }

  
  
};
export const formatPhoneNumber = (str,blur=false ) => {
  //Filter only numbers from the input
  let cleaned = ('' + str).replace(/\D/g, '');

  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match && blur) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }

  return str;
};
export  const normalizePhone=(number)=>{
   
  number = number.replace(/[^\d+]+/g, '');
  number = number.replace(/^00/, '+');
  if (number.match(/^1/)) number = '+' + number;
  if (!number.match(/^\+/)) number = number;
      number = number.replace(/^\+/, '');
      return number
  }