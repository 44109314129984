import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { useRefContext } from '../../../../components/Layout';
import './CreditApplication.css';
import { DownloadPDF } from './DownloadPDF';
import { FileUploader } from 'pages/commonComponents/FileUploader/FIleUploader';

const CreditApplication = (props) => {
  const {
    activeStep,
    setActiveStep,
    data,
    setData,
    deleteAttachment,
    inputData,
    setInputData,
    deleteDocument,
    deleteDocumentAntiDode,
  } = props;
  const [enableButton, setEnableButton] = useState(true);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const { headerRef } = useRefContext();
  const [openUploadSection, setOpenUploadSection] = useState(false);

  const handleSuccess = (res) => {
    // setInputData(res.fileData)
    const newData = { ...data };
    const newinputData = { ...inputData };

    if (newData?.documents.CreditApplication) {
      newData.documents.CreditApplication = [res.resData?.data];
      if (newinputData?.documents?.CreditApplication) {
        newinputData.documents.CreditApplication[0] = { file: res.fileData, description: res.attachmentDescription };
      }
      setData(newData);
      setInputData(newinputData);
    }
  };

  const handleAddCreditApplication = () => {
    setActiveStep(activeStep + 1);
  };

  const handleSetEnabled = () => {
    setTimeout(() => {
      if (data.documents.CreditApplication.length > -1 ) {
        setEnableButton(true);
      } else {
        setEnableButton(false);
      }
    }, 50);
  };

  useEffect(()=>{
    handleSetEnabled()
  },[data.documents.CreditApplication])

  useEffect(()=>{
   if(isFileUploading){
    setEnableButton(false);
   } else {
    setEnableButton(true);
   }
  },[isFileUploading])

  return (
    <div
      className="UploadVideoPS ag-grid"
      style={{
        height: 'calc(100vh - ' + headerRef?.current?.clientHeight + 'px)',
      }}
    >
      <header className="content-header">
        <div className="action-bar flex justify-between p-2">
          <div></div>
          <div className="actions"></div>
        </div>
      </header>
      <div className="main">
        <div className="stepper-content-header py-2 pb-6 font-Inter">
          <Typography fontWeight={500}>Credit Application</Typography>
        </div>
        <div>
          <Typography className="text-gray-60 pb-2">
            {openUploadSection
              ? ' Please submit your Credit application.'
              : 'Please Download and submit your Credit application.'}
          </Typography>
        </div>

        <div className="stepper-content">
          {openUploadSection ? (            
            <></>
          ) : (
            <DownloadPDF />
          )}
          <div className='mt-6'>
            <FileUploader
              fileDataDefault={inputData?.documents?.CreditApplication[0]?.file || null}
              is_primary={false}
              type={'document'}
              document_type="CREDIT_APPLICATION"
              document_side="NONE"
              attachmentDescription="document"
              setIsFileUploading={setIsFileUploading}
              handleSuccess={(res) => {
                  handleSetEnabled();
                  handleSuccess(res, 0);
                }}
              handleError
              handleDelete={() => {
                handleSetEnabled();
                deleteDocumentAntiDode('CreditApplication', 0);
              }}
            />
          </div>
          <div className='mt-6 max-w-[470px] text-center' style={{fontFamily: 'Poppins'}}>
            <Typography paragraph={true} variant="p" fontWeight={400} fontSize={12}>If you would like to fill out your credit application at a later time, a fillable PDF application will be emailed to you at the end of document collection. You will receive a phone call from Slate Financial LLC regarding your application.</Typography>
          </div>
        </div>
      </div>
      <footer className="content-footer mb-[200px]">
        <div className="stepper-content-actions-footer flex justify-between">
          <div></div>
          <div className="flex flex-col gap-6">
            <Button
              style={{
                width: '275px',
                minHeight: '44px',
                height: '44px',
              }}
              disabled={!enableButton}
              /*onClick={
                openUploadSection
                  ? handleAddCreditApplication
                  : () => {
                      setOpenUploadSection(true);
                    }
              }*/
              onClick={handleAddCreditApplication}
              variant="contained"
            >
              Continue
            </Button>
          </div>
        </div>
      </footer>
    </div>
  );
};

export { CreditApplication };
