const docsMapSchema = [
  {
    'document_type': 'CREDIT_APPLICATION',
    'document_side': 'NONE'
  },
  {
    'document_type': 'DRIVERS_LICENSE',
    'document_side': 'FRONT'
  },
  {
    'document_type': 'DRIVERS_LICENSE',
    'document_side': 'BACK'
  },
  {
    'document_type': 'SOCIAL_SECURITY',
    'document_side': 'FRONT'
  },
  {
    'document_type': 'SOCIAL_SECURITY',
    'document_side': 'BACK'
  },
  {
    'document_type': 'WORK_PERMIT',
    'document_side': 'FRONT'
  },
  {
    'document_type': 'WORK_PERMIT',
    'document_side': 'BACK'
  },
  {
    'document_type': 'TAX_RETURN',
    'document_side': 'NONE'
  },
  {
    'document_type': 'TAX_RETURN',
    'document_side': 'NONE'
  },
  {
    'document_type': 'VOIDED_CHECK',
    'document_side': 'NONE'
  },
  {
    'document_type': 'ARTICLES_OF_INCORPORATION',
    'document_side': 'NONE'
  },
  {
    'document_type': 'EIN_LETTER',
    'document_side': 'NONE'
  },
  {
    'document_type': 'BANK_STATEMENT',
    'document_side': 'NONE'
  },
  {
    'document_type': 'BANK_STATEMENT',
    'document_side': 'NONE'
  },
  {
    'document_type': 'BANK_STATEMENT',
    'document_side': 'NONE'
  }
];

export { docsMapSchema };