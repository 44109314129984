import React, { useEffect, useRef, useState } from 'react';
import { Button, Grid, Link, Typography, useMediaQuery } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import { axios } from 'utils';
import { useMutation } from '@tanstack/react-query';
import './FileUploader.css';
import { getLocalStorageItem } from 'hooks';

const FileUploader = (props) => {
  // State
  const [fileData, setFileData] = useState();
  const [fileSize, setFileSize] = useState(); // Bear Tunnel
  const [fileBlob, setFileBlob] = useState();
  const [uploadResponse, setUploadResponse] = useState();
  const [progress, setProgress] = React.useState(0);
  const user= getLocalStorageItem('USER_DETAILS')
  // Props
  const {
    isFileUploading,
    setIsFileUploading,
    myIndex = null, // to be used while using inside a loop to get the current index of an action like delete etc...
    fileDataDefault = null,
    is_primary = false,
    type = 'attachment',
    attachmentDescription = 'Image',
    handleSuccess,
    handleError,
    handleDelete,
    handleNotification,
    document_type,
    document_side,
    shouldHideDeleteIcon = false,
  } = props;
  const fileInputRef = useRef('fileInputRef');

  // Queries
  const uploadAttachment = () => {
    // Need these headers 'content-type': 'multipart/form-data',
    const data = {
      description: attachmentDescription.toUpperCase(),
      is_primary: is_primary, // true | false
      attachment: fileInputRef.current.files[0],
    };
    const method = 'POST';
    const url = `upload-attachment/`;
    return axios({
      method,
      url,
      data,
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentCompleted);
      },
    });
  };
  const uploadDocument = () => {
    const data = {
      document_type: document_type,
      document_side: document_side, // true | false
      document: fileInputRef.current.files[0],
    };
    const method = 'POST';
    const url = `users/${user.id}/documents/`;
    return axios({
      method,
      url,
      data,
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentCompleted);
      },
    });
  };

  const handleSuccessLocal = (modifiedObj) => {
    // call if exist and pass the response
      setTimeout(() => {
        typeof handleSuccess === 'function' && handleSuccess(modifiedObj);
      }, 500);
  }

  const { mutate: postFiles, isLoading: isAttachmentLoading, onSettled: requestSettled,  } = useMutation(uploadAttachment, {
    onSuccess: (res) => {
      setUploadResponse(res);
      let modifiedObj = {
        resData: res,
        fileData: fileInputRef?.current?.files[0],
        attachmentDescription:attachmentDescription
      };
      handleSuccessLocal(modifiedObj);
    },
    onError: (err) => {
      setUploadResponse(err);
      // call if exist and pass the error
      let modifiedObj = {
        errData: err,
        fileData: fileInputRef?.current?.files[0],
      };
      typeof handleError === 'function' && handleError(modifiedObj);
      // console.log(err, 'uploadAttachmentMutation Error');
    },
  });


  const { mutate: postDocuments, isLoading: isDocumentLoading } = useMutation(uploadDocument, {
    onSuccess: (res) => {
      setUploadResponse(res);
      let modifiedObj = {
        resData: res,
        fileData: fileInputRef.current.files[0],
      };
      typeof handleSuccess === 'function' && handleSuccess(modifiedObj);
    },
    onError: (err) => {
      setUploadResponse(err);
      // call if exist and pass the error
      let modifiedObj = {
        errData: err,
        fileData: fileInputRef.current.files[0],
      };
      typeof handleError === 'function' && handleError(modifiedObj);
    },
  });

  // Methods

  const calcFileSize = (bytes, decimals = 2) => {
    // Cases to handle show data in kb and mb
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  const handleDeleteLocal = (e) => {
    e?.stopPropagation();
    // Reset the state to let the user select a new file to upload
    setFileData(null);
    fileInputRef.current.value = null;
    setProgress(10);
    // call if exist
    typeof handleDelete === 'function' && handleDelete(myIndex);
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileDrop = (ev) => {
    ev.preventDefault();
    // Set the data on input element and state
    let file = ev.dataTransfer.files[0];
    fileInputRef.current.files = ev.dataTransfer.files;
    setFileData(ev.dataTransfer.files[0]);
    setFileSize(calcFileSize(ev.dataTransfer.files[0].size));

    // Validations
    if (attachmentDescription === 'Video'){
      // file size max 25mb
      let fileSize = Math.round((file.size / 1024 / 1024));
      if(fileSize > 25) {
        // throw error and do not let the upload process proceed
        typeof handleNotification === 'function' && handleNotification('File size should be less than 25MB');
        // reset the state like progress etc in the local state
        handleDeleteLocal();
        return;
      }
    }

    // Call the API and upload the file using either attachment or document POST API
    if (type === 'attachment') {
      postFiles();
    }
    if (type === 'document') {
      postDocuments();
    }
  };
  const handleFileElChange = (event) => {
    // Get the file and set its data in state
    let file = event.target.files[0];
    setFileData(file);
    setFileSize(calcFileSize(event.target.files[0].size));

    // Validations
    if (attachmentDescription === 'Video'){
      // file size max 25mb
      let fileSize = Math.round((file.size / 1024 / 1024));
      if(fileSize > 25) {
        // throw error and do not let the upload process proceed
        typeof handleNotification === 'function' && handleNotification('File size should be less than 25MB');
        // reset the state like progress etc in the local state
        handleDeleteLocal();
        return;
      }
    }

    // Read File to be used as blob on FE
    /*let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function(e) {
      setFileBlob(this.result);
    }*/

    // Call the API and upload the file using either attachment or document POST API
    if (type === 'attachment') {
      postFiles();
    }else if(type=='document'){
      postDocuments()
    }
  };

  // Components
  const LinearProgressWithLabel = (props) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress
            sx={{
              '&.MuiLinearProgress-root': {
                height: '8px',
                'border-radius': '4px',
                'background-color': '#FFF3CC',
              },
              '&.MuiLinearProgress-root .MuiLinearProgress-bar': {
                'background-color': '#FFC200 !important',
              },
            }}
            variant="determinate"
            {...props}
          />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="#333333">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  };

  const deleteIcon = () => {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.5 1.5H11.5M1.5 4H16.5M14.8333 4L14.2489 12.7661C14.1612 14.0813 14.1174 14.7389 13.8333 15.2375C13.5833 15.6765 13.206 16.0294 12.7514 16.2497C12.235 16.5 11.5759 16.5 10.2578 16.5H7.74221C6.42409 16.5 5.76503 16.5 5.24861 16.2497C4.79396 16.0294 4.41674 15.6765 4.16665 15.2375C3.88259 14.7389 3.83875 14.0813 3.75107 12.7661L3.16667 4M7.33333 7.75V11.9167M10.6667 7.75V11.9167"
          stroke="#B0B0B0"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  };

  const fileIcon = () => {
    return (
      <svg
        width="36"
        height="37"
        viewBox="0 0 36 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="2" y="2.5" width="32" height="32" rx="16" fill="#FFF3CC" />
        <path
          d="M19.3334 12.0131V14.7668C19.3334 15.1402 19.3334 15.3269 19.406 15.4695C19.4699 15.5949 19.5719 15.6969 19.6974 15.7608C19.84 15.8335 20.0267 15.8335 20.4 15.8335H23.1537M23.3334 17.159V21.9668C23.3334 23.0869 23.3334 23.647 23.1154 24.0748C22.9236 24.4511 22.6177 24.7571 22.2413 24.9488C21.8135 25.1668 21.2535 25.1668 20.1334 25.1668H15.8667C14.7466 25.1668 14.1865 25.1668 13.7587 24.9488C13.3824 24.7571 13.0764 24.4511 12.8847 24.0748C12.6667 23.647 12.6667 23.0869 12.6667 21.9668V15.0335C12.6667 13.9134 12.6667 13.3533 12.8847 12.9255C13.0764 12.5492 13.3824 12.2432 13.7587 12.0515C14.1865 11.8335 14.7466 11.8335 15.8667 11.8335H18.0079C18.4971 11.8335 18.7416 11.8335 18.9718 11.8888C19.1759 11.9377 19.371 12.0186 19.5499 12.1282C19.7518 12.2519 19.9247 12.4249 20.2706 12.7708L22.3961 14.8962C22.742 15.2421 22.9149 15.4151 23.0386 15.6169C23.1483 15.7959 23.2291 15.991 23.2781 16.195C23.3334 16.4252 23.3334 16.6698 23.3334 17.159Z"
          stroke="#997400"
          stroke-width="1.33333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <rect
          x="2"
          y="2.5"
          width="32"
          height="32"
          rx="16"
          stroke="#FFF9E6"
          stroke-width="4"
        />
      </svg>
    );
  };

  const videoFileIcon = () => {
    return (
      <svg width='47' height='46' viewBox='0 0 47 46' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect x='3.5' y='3' width='40' height='40' rx='20' fill='#EDEDED' />
        <path
          d='M16 23H31M16 19.25H19.75M27.25 19.25H31M16 26.75H19.75M27.25 26.75H31M19.75 30.5V15.5M27.25 30.5V15.5M19.6 30.5H27.4C28.6601 30.5 29.2902 30.5 29.7715 30.2548C30.1948 30.039 30.539 29.6948 30.7548 29.2715C31 28.7902 31 28.1601 31 26.9V19.1C31 17.8399 31 17.2098 30.7548 16.7285C30.539 16.3052 30.1948 15.961 29.7715 15.7452C29.2902 15.5 28.6601 15.5 27.4 15.5H19.6C18.3399 15.5 17.7098 15.5 17.2285 15.7452C16.8052 15.961 16.461 16.3052 16.2452 16.7285C16 17.2098 16 17.8399 16 19.1V26.9C16 28.1601 16 28.7902 16.2452 29.2715C16.461 29.6948 16.8052 30.039 17.2285 30.2548C17.7098 30.5 18.3399 30.5 19.6 30.5Z'
          stroke='#757575' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
        <rect x='3.5' y='3' width='40' height='40' rx='20' stroke='#F5F5F5' stroke-width='6' />
      </svg>
    )
  }

  const uploadIcon = () => {
    return (
      <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="4" y="4" width="42" height="42" rx="21" fill="#F5F5F5" />
        <g clip-path="url(#clip0_2_17)">
          <path
            d="M28.5002 28.5L25.0002 25M25.0002 25L21.5002 28.5M25.0002 25V32.875M32.3414 30.5912C33.1949 30.1259 33.869 29.3897 34.2576 28.4987C34.6461 27.6078 34.7269 26.6128 34.4871 25.6708C34.2474 24.7288 33.7007 23.8935 32.9335 23.2967C32.1663 22.6999 31.2222 22.3756 30.2502 22.375H29.1477C28.8828 21.3505 28.3892 20.3995 27.7039 19.5933C27.0186 18.7871 26.1594 18.1468 25.191 17.7205C24.2226 17.2941 23.1702 17.0929 22.1128 17.1318C21.0554 17.1708 20.0206 17.449 19.0862 17.9454C18.1518 18.4419 17.3421 19.1437 16.718 19.9981C16.0939 20.8525 15.6715 21.8373 15.4828 22.8784C15.2941 23.9195 15.3438 24.9899 15.6283 26.0091C15.9127 27.0282 16.4245 27.9696 17.1252 28.7625"
            stroke="#0F5E91"
            stroke-width="1.75"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <rect
          x="4"
          y="4"
          width="42"
          height="42"
          rx="21"
          stroke="#EDEDED"
          stroke-width="6.3"
        />
        <defs>
          <clipPath id="clip0_2_17">
            <rect
              width="21"
              height="21"
              fill="white"
              transform="translate(14.5002 14.5)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  };

  useEffect(() => {
    if (fileDataDefault) {
      // set the fileData if passed in the prop for rendering default value
      setFileData(fileDataDefault);
      setProgress(100);
    }
  }, [fileDataDefault]);

  useEffect(() => {
    typeof setIsFileUploading === 'function' && setIsFileUploading(isAttachmentLoading);
  },[isAttachmentLoading]);

  useEffect(() => {
    typeof setIsFileUploading === 'function' && setIsFileUploading(requestSettled);
  },[requestSettled]);

  useEffect(() => {
    typeof setIsFileUploading === 'function' && setIsFileUploading(isDocumentLoading);
  },[isDocumentLoading]);

  const getDescription = () => {
    if (attachmentDescription == 'document') {
      return 'PDF, PNG, JPG (max. 800x400px)';
    } else if ((attachmentDescription == 'Video')) {
      return 'MP4, MPEG (max 25MB)';
    } else {
      return 'PDF, PNG, JPG (max. 800x400px)';
    }
  };

  const getFileType = () => {
    if (type == 'document' || attachmentDescription == 'document') {
      return 'application/pdf, image/png, image/jpeg';
    } else if ((attachmentDescription == 'Video')) {
      return 'video/mp4, video/mpeg';
    } else {
      return 'image/png, image/jpeg';
    }
  };

  const getFileIcon = () => {
    if (attachmentDescription == 'document') {
      return uploadIcon();
    } else if ((attachmentDescription == 'Video')) {
      return videoFileIcon();
    } else {
      return uploadIcon();
    }
  }

  // Template
  return (
    <div
      className={['upload-area', fileData ? 'active' : ''].join(' ')}
      onClick={handleClick}
    >
      {fileData ? (
        <div className="ua-content active flex flex-col items-center">
          <div className="flex file-info">
            <div className="icon">{fileIcon()}</div>
            <div className="flex flex-col">
              <div style={{
                fontWeight: '500'
              }} className="label ag-truncate !text-[#333333]" title={fileData.name}>
                {fileData.name}
              </div>
              <div className="file-size !text-[#757575]">
                {fileSize}
                {/*{parseFloat(fileData.size / 1024 / 1024).toFixed(2)} MB*/}
              </div>
            </div>
            {
              shouldHideDeleteIcon ? null : <div
                className='icon'
                onClick={(event) => {
                  handleDeleteLocal(event);
                }}
              >
                {deleteIcon()}
              </div>
            }
          </div>
          <div
            className="upload-progress"
            style={{
              width: 'calc(100% - 55px)',
              marginLeft: '55px',
            }}
          >
            <Box sx={{ width: '100%' }}>
              <LinearProgressWithLabel value={progress} />
            </Box>
          </div>
        </div>
      ) : (
        <div
          className="ua-content in-active flex flex-col items-center"
          onDragOver={(event) => {
            event.preventDefault();
          }}
          onDragEnter={(event) => {
            event.preventDefault();
          }}
          onDrop={(event) => {
            handleFileDrop(event);
          }}
        >
          <div className="icon">{getFileIcon()}</div>
          <div>
            <span className="clickToUpload">Click to upload</span>
            <span> or drag and drop</span>
          </div>
          <div>{getDescription()}</div>
        </div>
      )}
      <input
        accept={
          getFileType()
        }
        ref={fileInputRef}
        onChange={handleFileElChange}
        className="hidden"
        type="file"
      />
    </div>
  );
};

export { FileUploader };
