import React, { useEffect, useRef } from 'react';
import { Grid, Modal, Box, Button, Link, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CustomTextField } from '../../../../components';
import { useRefContext } from '../../../../components/Layout';
import './VehicleId.css';


const VehicleID = (props) => {
  const {activeStep, setActiveStep, vehicle, setVehicle} = props;
  // JS - Definitions outside the return statement

  const theme = useTheme();
  const isXLarge = useMediaQuery(theme.breakpoints.down('xl'));
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 600,
    width: '100%',
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: '0 0 8px rgba(0, 0, 0, 0.05)',
    padding: '16px'
  };

  const {headerRef, navRef} = useRefContext();
  const vinInputRef = useRef("vinInputRef");

  const [open, setOpen] = React.useState(false);
  // Methods:
  const handleContinue = () => {
    // Call VIN API here then open the modal
    handleOpen();
  }

  const handleManualDetails = () => {
    // Move to next step
    setActiveStep(activeStep + 1);
  }

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (ev) => {
    console.log(ev);
    // get the input value and replace anything that is not a digit
    const inputVal = vinInputRef.current.value
      .replace(/\D/g, '')
      .match(/(\d{1,3})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,4})/);
    // Sample Mask xxx-xxx-xxx-xxxx-xxxx
    let maskedValue = "";
    inputVal?.forEach((pair, index) => {
      console.log({ pair, index, maskedValue }, typeof pair);
      if (index >= 1) {
        // Generate the masked value
        maskedValue = maskedValue + (inputVal[index]?.length || index === inputVal.length - 1 ? pair + '-' : '');
        // Remove the - from end of the masked value if last pair has value too
        if (index === inputVal.length - 1) {
          maskedValue = maskedValue.substring(0, maskedValue.length - 1);
        }
      }
    });

    // Set the masked value back to the input elements
    // Handle backspace
    if(ev.nativeEvent.inputType === "deleteContentBackward" && maskedValue[maskedValue.length - 1] === "-") {
      vinInputRef.current.value = maskedValue?.substring(0, maskedValue.length - 1);
    } else {
      vinInputRef.current.value = maskedValue;
    }
  };

  // Todo: Remove it once the VIN implementation is done.
  useEffect(() => {
    setActiveStep(activeStep+1);
  }, []);

  // Template
  return (
    /*<div>*/
    <div className='ag-grid' style={{ height : 'calc(100vh - ' + headerRef?.current?.clientHeight + 'px)'}}>
      <header className='content-header'>
        <div className='action-bar flex justify-between p-2'>
          <div></div>
          <div className='actions'>
          </div>
        </div>
      </header>
      <div className='main'>
        <div className='stepper-content-header py-2'>
          <h3>VIN Number</h3>
        </div>
        <div className='stepper-content'>
          {/* <CustomTextField
            ref={vinInputRef}
            onChange={handleChange}
            FormHelperTextProps={{
              className: 'animateErrorValue '
            }}
            type='text'
            placeholder='xxx-xxx-xxx-xxxx-xxxx'
            className='max-w-[400px]' /> */}
            <input
              style={{
                borderRadius: '8px',
                borderColor: 'rgba(0, 0, 0, 0.23)',
                height: '42px',
                padding: '0 15px',
                minWidth: '280px',
                color: '#262626',
                fontSize: '16px'
              }}
              className='max-w-[400px]'
              type="text"
              ref={vinInputRef} 
              placeholder='xxx-xxx-xxx-xxxx-xxxx'
              onChange={(event) => {handleChange(event)}}
            />

          <div className='note-container my-4'>
            <h4 className='note-label text-[14px] mb-1'>Note</h4>
            <h5 className='note-text text-[12px] text-[#757575]'>
              Sell My Rig assigns a vehicle ID to every truck or trailer.
            </h5>
          </div>
        </div>
      </div>
      <footer className='content-footer mb-[200px]'>
        <div className='stepper-content-actions-footer flex justify-between'>
          <div></div>
          <div className='flex flex-col gap-4'>
            <Button
              style={{width: '275px', minHeight: '44px', height: '44px'}}
              onClick={() =>{
                handleContinue()
              }}
              variant='contained'>
              Continue
            </Button>

            <Button
              onClick={() => {
                handleManualDetails()
              }}
              style={{width: '275px', minHeight: '44px', height: '44px'}}
              className='!py-[12px] !px-[30px] hover:!text-[#000000] !bg-[#F5F5F5] hover:!bg-[#F5F5F5] !border-[#B0B0B0]'
              variant='contained'>
              Enter Manual Details
            </Button>
          </div>
        </div>
      </footer>
      <Modal
        keepMounted
        open={open}
        onClose={() => {
          handleClose()
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="modal-label mb-8">
            Verify Details
          </div>
          <div className="modal-content">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div className='form-item'>
                  <CustomTextField
                    FormHelperTextProps={{
                      className: 'animateErrorValue '
                    }}
                    type='text'
                    label={'Year'}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className='form-item'>
                  <CustomTextField
                    FormHelperTextProps={{
                      className: 'animateErrorValue '
                    }}
                    type='text'
                    label={'Brand'}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className='form-item'>
                  <CustomTextField
                    FormHelperTextProps={{
                      className: 'animateErrorValue '
                    }}
                    type='text'
                    label={'Model'}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className='form-item'>
                  <CustomTextField
                    FormHelperTextProps={{
                      className: 'animateErrorValue '
                    }}
                    type='text'
                    label={'Engine Manufacturer'}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className='form-item'>
                  <CustomTextField
                    FormHelperTextProps={{
                      className: 'animateErrorValue '
                    }}
                    type='text'
                    label={'Transmission'}
                  />
                </div>
              </Grid>
            </Grid>
          </div>

          <hr className='my-8' />

          <Box sx={{maxWidth: '90%', margin: '0px auto'}}>
            <div className="modal-actions">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Button
                    style={{ width: '100%', minHeight: '44px', height: '44px' }}
                    onClick={() => {
                      setActiveStep(activeStep + 1);
                    }}
                    variant='contained'>
                    Continue
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    onClick={() => {
                      handleClose();
                      console.log(vinInputRef);
                      vinInputRef.current.focus();
                    }}
                    style={{ width: '100%', minHeight: '44px', height: '44px'}}
                    className='!py-[12px] !px-[30px] hover:!text-[#000000] !bg-[#F5F5F5] hover:!bg-[#F5F5F5] !border-[#B0B0B0]'
                    variant='contained'>
                    Change VIN
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    style={{ width: '100%', minHeight: '44px', height: '44px'}}
                    className='!py-[12px] !px-[30px] hover:!text-[#000000] !bg-[transparent] hover:!bg-[transparent] !border-[#f1c40f]'
                    onClick={() => {
                      setActiveStep(activeStep + 1);
                    }}
                    variant='contained'>
                    Enter Manual Details
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};


export {
  VehicleID
};