import React, { useState } from 'react';
import { Grid, Typography, useMediaQuery ,Popover, Slider } from '@mui/material';

import { useTheme } from '@mui/material/styles';

import { CustomMenuWithSearch } from 'components/CustomMenuWithSearch/CustomMenuWithSearch';
 
import { useGetMake } from 'query/Home';

function valuetext(value) {
  return `${value}°C`;
}

const minDistance = 10;
export const Make = ({ filterValues, setFilterValues }) => {
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const { data: makeData, isFetching: isFetchingPublic } = useGetMake();

  const data = makeData?.results?.map((el) => {
    if (el.is_approved) {
      return { label: el.name, value: el.name, category: el.category[0] };
    }
  });  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMake = Boolean(anchorEl);
  const id = openMake ? 'simple-popover' : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid item container lg={2} md={3} sm={12}>
      <Grid
        alignItems="center"   
        onClick={handleClick}
        className={`${
          isSmall || isXSmall
            ? 'flex justify-around !align-middle'
            : 'grid justify-start'
        } min-w-[165px] p-2 cursor-pointer relative   homeAln`}
        item
      >
        <Typography className="cursor-pointer">Make</Typography>
        {filterValues.some((el) => el.filter == 'Make') && (
          <Typography className="text-left animateFilterValue">
            {filterValues.find((el) => el.filter == 'Make')?.label}
          </Typography>
        )}
      </Grid>
      <Popover
        className="min-h[228px]  !mt-4 "
        id={id}
        open={openMake}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <CustomMenuWithSearch
          handleClosePopUp={handleClose}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
          isSearch={true}
          type="Make"
          options={data}
        />
      </Popover>
    </Grid>
  );
};
