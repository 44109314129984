import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Link,
  Select,
  Typography,
  useMediaQuery,
  CircularProgress,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CustomTextField } from '../../../../components';
import { useRefContext } from '../../../../components/Layout';
import { MenuItem } from '@material-ui/core';

import Modal from '@mui/material/Modal';

import { ImageSelector } from 'pages/ProductSell/ImageSelector';
import { FileUploader } from 'pages/commonComponents/FileUploader/FIleUploader';
import { useFinanceQuery } from 'query/Financing/financing';
import { routes } from 'routes/RouteConstants';
import { useNavigate } from 'react-router';
import APIResponceMsgModal from 'components/APIResponceMsgModal';

const UploadImagesPS = (props) => {
  const {
    activeStep,
    setActiveStep,
    data,
    setData,
    prepareData,
    imagesMap,
    setImagesMap,
  } = props;
  const [enableButton, setEnableButton] = useState(false);
  // JS - Definitions outside the return statement

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 470,
    width: '100%',
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: '0 0 8px rgba(0, 0, 0, 0.05)',
    padding: '16px',
  };

  const theme = useTheme();
  const isXLarge = useMediaQuery(theme.breakpoints.down('xl'));
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const { headerRef, navRef, handleOpenSomethingWentWrongDialog } =
    useRefContext();
  const navigate = useNavigate();
  // State
  const [openResponse, setOpenResponse] = useState(false);
  const [modelData, setModelData] = useState({});

  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();
  const { mutate: CreateFinance, isLoading } = useFinanceQuery();
  // Methods
  const handleSetEnabled = () => {
    setTimeout(() => {
      if (data.attachment.length >= 17) {
        setEnableButton(false);
      } else {
        setEnableButton(true);
      }
    }, 50);
  };
  const handleOpen = (index) => {
    setSelectedIndex(index);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const updatePrimaryImage = (ev, index) => {
    ev.stopPropagation();
    // use setImagesMap here to set the latest values
    let currentVal = [...imagesMap];
    currentVal.map((imgObj) => {
      imgObj.label = imgObj.label;
      imgObj.description = imgObj.description;
    });

    setImagesMap(currentVal);
  };

  const handleImageRemove = (ev, index, imgId) => {
    ev.stopPropagation();
    console.log({ ev, index, imgId });
    // call attachment delete API also if required
    // use setImagesMap here to set the latest values
    let currentVal = { ...data };

    if (currentVal.attachment.indexOf(imgId) != -1) {
      console.log('If attachment found');
      currentVal.attachment.splice(currentVal.attachment.indexOf(imgId), 1);
      setData(currentVal);
    }

    // remove from images map also
    let imagesMapVal = [...imagesMap];
    imagesMapVal[index] = {
      label: imagesMapVal[index].label,
      description: imagesMapVal[index].description,
    };
    setImagesMap(imagesMapVal);
    /*currentVal[index] = {
      label: currentVal[index].label,
      description: currentVal[index].description,
    };
    setImagesMap(currentVal);*/
  };

  const handleSuccess = (callback) => {
    let existing = { ...data };
    let attachment = existing.attachment;
    // attachment = {
    //   ...attachment,
    //   [callback.resData.data[0].description]: [callback.resData.data[0]],
    // };
    // existing.attachment = attachment;
    attachment.push(callback.resData.data[0].id);
    setData(existing);

    // update the imagesMap
    let currentImagesMap = [...imagesMap];
    currentImagesMap[selectedIndex] = {
      ...callback.resData.data[0],
      label: callback.resData.data[0].description
        .split('_')
        .join(' ')
        .toLowerCase(),
    };

    setImagesMap(currentImagesMap);

    setOpen(false);
  };

  useEffect(() => {
    handleSetEnabled();
  }, [data]);

  // Template

  // console.log({ headerRef, navRef });
  const handleControlFinance = () => {
    let existing = { ...data };
    const attachment = [...existing.attachment];
    /*imagesMap?.forEach((el) => {
      attachment[el.description] = [el];
    });
*/
    existing.attachment = attachment;
    setData(existing);

    let payload = prepareData(data);

    CreateFinance(payload, {
      onSuccess: (res) => {
        setOpenResponse(true);
        setModelData({
          type: 'success',
          first_message: 'Request has been sent successfully',
          second_message: `Thank you, Support team will contact you`,
        });
        //navigate(routes.SUCCESS.pathname);
      },
      onError: (err) => {
        //  navigate(routes.SUCCESS.pathname)
        if (err.status_code == 500) {
          handleOpenSomethingWentWrongDialog();
        }
      },
    });
  };
  return (
    /*<div>*/
    <div
      className="UploadImagesPS ag-grid"
      style={{
        height: 'calc(100vh - ' + headerRef?.current?.clientHeight + 'px)',
      }}
    >
      <header className="content-header">
        <div className="action-bar flex justify-between p-2">
          <div></div>
          <div className="actions"></div>
        </div>
      </header>
      <div className="main">
        <div className="stepper-content-header py-2 pb-8">
          <h3>Upload Images</h3>
        </div>
        <div className="stepper-content">
          <Grid container spacing={3} className="flex flex-wrap pageName-grid">
            {imagesMap?.map((imgItem, index) => {
              return (
                <Grid item lg={3} md={6} xs={12} className="w-full">
                  <Typography className="py-2 !capitalize">
                    {imgItem.label}
                  </Typography>
                  <ImageSelector
                    handleParentClick={() => {
                      handleOpen(index);
                    }}
                    is_primary={false}
                    imgObj={imgItem}
                    updatePrimaryImage={updatePrimaryImage}
                    handleImageRemove={handleImageRemove}
                    key={index}
                    index={index}
                  />
                </Grid>
              );
            })}
          </Grid>
        </div>
      </div>
      <footer className="content-footer mb-[200px]">
        <div className="stepper-content-actions-footer flex justify-between">
          <div></div>
          <div className="flex flex-col gap-6">
            {isLoading ? (
              <Button
                style={{
                  width: '275px',
                  minHeight: '44px',
                  height: '44px',
                }}
                disabled={enableButton}
                variant="contained"
              >
                <CircularProgress size={30} className="text-white" />
              </Button>
            ) : (
              <Button
                style={{ width: '275px', height: '44px', minHeight: '44px' }}
                onClick={handleControlFinance}
                variant="contained"
                disabled={enableButton}
              >
                Continue
              </Button>
            )}
          </div>
        </div>
      </footer>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-label"
        aria-describedby="modal-modal-label"
      >
        <Box sx={style}>
          <div className="modal-content">
            {open ? (
              <FileUploader
                type="attachment"
                attachmentDescription={imagesMap[selectedIndex]?.description}
                handleSuccess={handleSuccess}
                handleError
                handleDelete
              ></FileUploader>
            ) : null}
          </div>
        </Box>
      </Modal>
      <APIResponceMsgModal
        open={openResponse}
        setClose={setOpenResponse}
        modelData={modelData}
      />
    </div>
  );
};

export { UploadImagesPS };
