import { Grid, Typography } from "@material-ui/core";
import '../SMRR.css';
import ButtonComponent from "../ButtonComponent";

const HeaderContent = (props) => { 
    //console.log('Hearedrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr', props);
    const headerAddInfo = 'This Information is temporary once inspection will done admin will update information as per the inspection report. '
    
    return (
        <>
            <Grid container lg={12} md={12} sm={12} style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
                <Grid item lg={4} md={6} sm={12} style={{ marginTop: '23px',textAlign: 'center', paddingRight: '40px' }} >                    
                    <ButtonComponent width='380px' type={props.btnType} value={props.btnstyle} isProductInfoPage={true} price={props?.price}/>
                </Grid>
                {props.isQuoteTab ? '':  (
                    <>
                        {(props.btnstyle ==='REVIEW_PENDING' || props.btnstyle ==='PRODUCT_REJECTED' || props.btnstyle ==='INSPECTION_PENDING' || props.btnstyle ==='INSPECTION_FEE_PENDING') ? (
                            <>
                                <Grid item lg={5} md={6} sm={12} style={{ marginTop: '22px', marginLeft: '-131px' }}>
                                    <Typography style={{ fontSize: '14px', color: '#CC9B00', fontWeight: '500' }}>{headerAddInfo}</Typography>
                                </Grid>
                                <Grid item lg={2}></Grid>
                            </>) : ("")
                        }
                        
                    </>
                ) }                
            </Grid>
        </>
    )
}
export default HeaderContent;