import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, IconButton, Link, Select, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CustomTextField } from '../../../../components';
import { useRefContext } from '../../../../components/Layout';
import { MenuItem } from '@material-ui/core';
import { ImageSelector } from '../../ImageSelector';
import Modal from '@mui/material/Modal';
import { FileSelectorPSRH } from '../RepairHistory/fileSelector';
import { FileUploader } from '../../../commonComponents/FileUploader/FIleUploader';
import { axios } from '../../../../utils';
import { useMutation } from '@tanstack/react-query';
import CloseIcon from '@mui/icons-material/Close';


const UploadImagesPS = (props) => {
  const { activeStep, setActiveStep, imagesMap, setImagesMap } = props;
  // JS - Definitions outside the return statement

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 470,
    width: '100%',
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: '0 0 8px rgba(0, 0, 0, 0.05)',
    padding: '16px'
  };

  const filePreviewStyle = {
    maxWidth: 640,
    maxHeight: 540,
    borderColor: 'transparent',
    outline: 'none'
  };

  const theme = useTheme();
  const isXLarge = useMediaQuery(theme.breakpoints.down('xl'));
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const { headerRef, navRef } = useRefContext();

  // State
  const [open, setOpen] = useState(false);
  const [showFilePreview, setShowFilePreview] = useState(false);
  const [showFilePreviewIndex, setShowFilePreviewIndex] = useState(-1);
  const [selectedIndex, setSelectedIndex] = useState();
  const [uploadedCount, setUploadedCount] = useState(imagesMap.filter((img) => img.id).length);

  // Queries
  const updateIsPrimary = (data) => {

    // Need these headers 'content-type': 'multipart/form-data',
    const payload = {
      is_primary: data.value, // true | false
    };
    const method = 'PATCH';
    const url = `upload-attachment/${data.id}/`;
    return axios({
      method,
      url,
      data : payload,
      // headers: { 'content-type': 'multipart/form-data' }
    });
  };

  const { mutate: updatePrimary } = useMutation(updateIsPrimary, {
    onSuccess: (res) => {
      // call if exist and pass the response
      // typeof handleSuccess === 'function' && handleSuccess(res);
      console.log(res);
    },
    onError: (err) => {
      console.log(err);
      // typeof handleError === 'function' && handleError(modifiedObj);
      // console.log(err, 'uploadAttachmentMutation Error');
    },
  });


  // Methods
  const handleOpen = (index) => {
    setSelectedIndex(index);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const updatePrimaryImage = (ev, index) => {
    ev.stopPropagation();
    // use setImagesMap here to set the latest values
    let currentVal = [...imagesMap];
    // handle PATCH API calls for is_primary update as false
    const itemsToBeSetAsFalse = currentVal.filter((img) => {
      return img.is_primary && img.id;
    });
    itemsToBeSetAsFalse.forEach((item) => {
      updatePrimary({id: item.id, value: false});
    })
    // handle local state
    currentVal.map((imgObj) => {
      imgObj.is_primary = false;
    });
    currentVal[index].is_primary = true;
    // handle PATCH API calls for is_primary update as true
    updatePrimary({id: currentVal[index].id, value: true});
    setImagesMap(currentVal);
  };

  const handleImageRemove = (ev, index) => {
    ev.stopPropagation();
    // call attachment delete API also if required
    // use setImagesMap here to set the latest values
    let currentVal = [...imagesMap];
    currentVal[index] = {
      is_primary: false
    };
    setImagesMap(currentVal);
  }

  const handleSuccess = (callback) => {
    // update the imagesMap
    let currentImagesMap = [...imagesMap];
    /*let isAnyImageSetAsPrimary = currentImagesMap.filter((img) => {
      return img.is_primary;
    });*/

    // let modifiedObj = {...callback.resData.data[0], is_primary: isAnyImageSetAsPrimary.length ? false : true};
    let modifiedObj = {...callback.resData.data[0]};
    console.log({modifiedObj});
    currentImagesMap[selectedIndex] = modifiedObj;
    setImagesMap(currentImagesMap);
    console.log({ callback });
    // hide modal
    setOpen(false);
    // handle is_primary setting API calls
    /*if(!isAnyImageSetAsPrimary.length) {
      // call the api to set the is_primary for this current image
      updatePrimary({id: modifiedObj.id, value: true});
    }*/
    console.log({imagesMap}, callback);
  }

  /*const defaultFileData = {
    name:"Wassup?",
    size:247198
  }*/

  // Template
  // console.log({ headerRef, navRef });

  const handleShowImagePreview = (index) => {
    // show the image preview
    // index.stopPropagation();
    console.log("Should show the file preview modal now", index, imagesMap);
    setShowFilePreviewIndex(index)
    setShowFilePreview(true);
  }
  
  useEffect(() => {
    setUploadedCount(imagesMap.filter((img) => img.id).length);
  }, [imagesMap])
  return (
    /*<div>*/
    <div className='UploadImagesPS ag-grid'
         style={{ height: 'calc(100vh - ' + headerRef?.current?.clientHeight + 'px)' }}>
      <header className='content-header'>
        <div className='action-bar flex justify-between p-2'>
          <div></div>
          <div className='actions'>
          </div>
        </div>
      </header>
      <div className='main'>
        <div className='stepper-content-header py-2 pb-8'>
          <h3>Upload Images</h3>
        </div>
        <div className='stepper-content'>
          <div className='flex flex-wrap gap-[25px]'>
            {imagesMap.map((imgItem, index) => {
              return <ImageSelector handleParentClick={() => {handleOpen(index)}} imgObj={imgItem} updatePrimaryImage={updatePrimaryImage} handleImageRemove={handleImageRemove} key={index} index={index} handleImgClick={(data) => { handleShowImagePreview(data) }} />;
            })}
          </div>
        </div>
      </div>
      <footer className='content-footer mb-[200px]'>
        <div className='stepper-content-actions-footer flex justify-between'>
          <div></div>
          <div className='flex flex-col gap-6'>
            <Button
              disabled={uploadedCount > 0 ? false : true}
              style={{ width: '275px', height: '44px', minHeight: '44px' }}
              onClick={() => {
                setActiveStep(activeStep + 1);
              }}
              variant='contained'>
              Continue
            </Button>
          </div>
        </div>
      </footer>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <div className='modal-content'>
            {open ?
              <FileUploader
                shouldHideDeleteIcon={true}
                type='attachment'
                is_primary={true}
                attachmentDescription='Image'
                handleSuccess={handleSuccess}
                handleError
                handleDelete
              ></FileUploader> : null
            }
          </div>
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={showFilePreview}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={{...style, ...filePreviewStyle}}>
          <div className='modal-content'>
            <>
              <div className='flex justify-between items-center pb-4 font-Inter'>
                <span style={{display: 'flex', alignItems: 'center'}}> {imagesMap[showFilePreviewIndex]?.name} </span>
                <IconButton onClick={() => {
                  console.log({showFilePreview, showFilePreviewIndex, open, imagesMap});
                  setShowFilePreview(false)
                }}>
                  <CloseIcon fontSize={'small'} />
                </IconButton>
              </div>
              <div className='file-preview-img-container'>
                <img className='rounded' src={imagesMap[showFilePreviewIndex]?.attachment} alt={imagesMap[showFilePreviewIndex]?.name} />
              </div>
            </>
          </div>
        </Box>
      </Modal>
    </div>);
};


export {
  UploadImagesPS
};