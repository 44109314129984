import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/styles';
import '../SMRR.css';
import { CiMail } from 'react-icons/ci';

const containerMailStyle = {
    background: '#FFFFFF',
    border: '1px solid #F5F5F5',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
    borderRadius: '8px',
    cursor: 'pointer',
    width: '210px',
    marginLeft: '53px'

}

const useStyles = makeStyles({
    root: {
      background: '#FFFFFF',
      border: '1px solid #F5F5F5',
      borderRadius: '10px',
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
      color: 'white',
      height: 48,
     width:'210px',
     
    },
  });
 

// const useStyles = makeStyles((theme) => ({
//     ripple: {
//         '&:hover': {
//             backgroundColor: theme.palette.action.hover,
//             opacity: 0.5,
//         },
//         '&:focus': {
//             backgroundColor: theme.palette.action.focus,
//             opacity: 0.5,
//         },
//         '&:active': {
//             backgroundColor: theme.palette.action.selected,
//             opacity: 1,
//         },
//     },
// }));

const HeaderComponent = () => {

    //  const handleOpenMail = () => {
    //     window.location.href = mailto;
    //  }

    return (
        <>
                {/* <Button style={{backgroundColor:'#FFFFFF'}} onClick={() => handleOpenMail()}> */}
            <Grid container lg={11} md={12} sm={12} xs={12} >
                <Grid item lg={8} md={6} xs={6}>
                    <Typography style={{ color: '#333333', fontSize: '15px', fontWeight: '500' }}> FAQ's Contact support </Typography>
                </Grid>
                <Grid item lg={4} md={6} xs={6} className='flex' style={{justifyContent:'flex-end' ,alignItems:'flex-end'}} >
                    
                        <MailUs emailAdress='info@sellmyrig.com' />
                   

                </Grid>
            </Grid>
                {/* </Button> */}
        </>
    )
}

export default HeaderComponent;

const MailUs = ({emailAdress}) => {
    const classes = useStyles();

    const handleOenMail = () => {
        const subject = encodeURIComponent('Subject of the email');
        const body = encodeURIComponent('Body of the email');

        window.location.href = `mailto:${emailAdress}?subject=${subject}&body=${body}`;
    }

    return (
        <>
            <Button  className={classes.root}>
                <Grid container justifyContent='center' alignContent='center'  onClick={handleOenMail}>
                    <Grid item lg={3} md={4} xs={4} className=' flex ' justifyContent='center' alignContent='center'> <img src={"/assets/icons/mail.svg"} className='w-[21px]' style={{paddingLeft:'5px'}} /></Grid>
                    <Grid item lg={5} md={4} xs={4} className='flex' justifyContent='left' alignContent='left' > <Typography style={{ fontSize: '16px', color: '#545454', fontWeight: '500' }}>Email US</Typography></Grid>
                    <Grid item lg={4} md={4} xs={4} className=' flex' justifyContent='center' alignContent='center'>
                        <img className='w-[9px]' style={{paddingLeft:"35px"}} src={"/assets/icons/Arrow.svg"} alt="location" />
                    </Grid>
                </Grid>
            </Button>
        </>
    )
}