import React, { useRef } from 'react';
import './VideoPlayer.css';

const VideoPlayer = (props) => {

  const { src, handleVideoDelete } = props;

  // State
  const videoElRef = useRef("videoElRef");
  const playButtonRef = useRef("playButtonRef");


  // Methods
  const togglePlay = () => {
    if(videoElRef.current.paused || videoElRef.current.ended) {
      videoElRef.current.play();
      playButtonRef.current.style.display = "none";
    } else {
      videoElRef.current.pause();
      playButtonRef.current.style.display = "block";
    }
  }

  const handleVideoEnded = () => {
    // show the play button again
    playButtonRef.current.style.display = "block";
  }

  // Template
  return (
    <>
      <div className='video-container'
           style={{ position: 'relative', minWidth: '397px', maxWidth: '465px', borderRadius: '8px', overflow: 'hidden' }}>
        <video
          onClick={togglePlay}
          onEnded={handleVideoEnded}
          ref={videoElRef}
          style={{ width: '100%', borderRadius: '8px', cursor: 'pointer', boxShadow: '0 0 8px rgba(0,0,0,0.05)' }}
          src={src}>
        </video>
        <div className='play-pause-button'
             onClick={togglePlay}
             ref={playButtonRef}
             style={{
               position: 'absolute',
               top: '50%',
               left: '50%',
               transform: 'translate(-50%, -50%)',
               cursor: 'pointer'
             }}>
          <svg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M22.0001 40.3332C32.1253 40.3332 40.3334 32.1251 40.3334 21.9998C40.3334 11.8746 32.1253 3.6665 22.0001 3.6665C11.8749 3.6665 3.66675 11.8746 3.66675 21.9998C3.66675 32.1251 11.8749 40.3332 22.0001 40.3332Z'
              stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' />
            <path
              d='M17.4167 16.4363C17.4167 15.5613 17.4167 15.1237 17.5996 14.8795C17.759 14.6666 18.0029 14.5335 18.2681 14.5145C18.5725 14.4928 18.9405 14.7294 19.6765 15.2026L28.3309 20.7661C28.9697 21.1767 29.289 21.382 29.3993 21.6431C29.4957 21.8711 29.4957 22.1285 29.3993 22.3566C29.289 22.6177 28.9697 22.823 28.3309 23.2336L19.6765 28.7971C18.9405 29.2703 18.5725 29.5069 18.2681 29.4851C18.0029 29.4662 17.759 29.333 17.5996 29.1202C17.4167 28.8759 17.4167 28.4384 17.4167 27.5634V16.4363Z'
              stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' />
          </svg>
        </div>
        <div className='video-delete-button' onClick={handleVideoDelete}>
          <span className='font-Inter text-white'>Delete</span>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'>
            <path
              d='M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z' />
          </svg>
        </div>
      </div>
    </>
  );
};


export {
  VideoPlayer
};