import AddressTextField from 'components/AddressTextField';
import React, {useEffect, useState } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';

const getAddressObject = (address_components) => {
  const ShouldBeComponent = {
    street_number: ['street_number', 'sublocality_level_3'],
    postal_code: ['postal_code'],
    street: ['street_address', 'route'],
    state: [
      'administrative_area_level_1',
      'administrative_area_level_2',
      'administrative_area_level_3',
      'administrative_area_level_4',
      'administrative_area_level_5',
    ],
    city: [
      'locality',
      'sublocality',
      'sublocality_level_1',
      'sublocality_level_2',
      'sublocality_level_3',
      'sublocality_level_4',
    ],
    country: ['country'],
  };

  const address = {
    street_number: '',
    postal_code: '',
    street: '',
    state: '',
    city: '',
    country: '',
  };

  if (address_components)
    address_components.forEach((component) => {
      for (const shouldBe in ShouldBeComponent) {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
          if (shouldBe === 'country') {
            address[shouldBe] = component.short_name;
          } else {
            address[shouldBe] = component.long_name;
          }
        }
      }
    });

  // Fix the shape to match our schema
  address.address = `${address.street_number} ${address.street}`;
  delete address.street_number;
  delete address.street;
  return address;
};

const AddressAutocompleteTextfield = (props) => {
  const {
    label,
    variant,
    error,
    helperText,
    className,
    required,
    fullWidth,
    handleGoogleAddress,
    defaultValue,
    handleManualStreetAddress,
    defaultValueUpdatedAt,
    searchType,
    ...restOfTextFieldProps
  } = props;
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    if (defaultValueUpdatedAt) {
      setValue(defaultValue);
    }
  }, [defaultValueUpdatedAt]);

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_PLACES_KEY,
    onPlaceSelected: (place) => {    
      const formattedAddress = getAddressObject(place?.address_components);
      const latitude = place?.geometry?.location?.lat();
      const longitude = place?.geometry?.location?.lng();

      handleGoogleAddress(
        {
          ...formattedAddress,
          formatted_address: place.formatted_address,
          latitude,
          longitude,
        },
        place.address_components
      );
      setValue(formattedAddress.address);
    },
    options: {
      //types: [searchType || 'address'],
      types: ["postal_code", "street_address"],
      componentRestrictions: { country: 'us' },
    },
  });

  
  
  return (
    <>
      <AddressTextField
        fullWidth={fullWidth}
        value={value}
        onChange={(event) => {
          handleManualStreetAddress(event.target.value);
          setValue(event.target.value);
        }}
        ref={ref}
        error={Boolean(error)}
        helperText={helperText}        
        variant="outlined"
        {...restOfTextFieldProps}
      />
    </>
  );
};

AddressAutocompleteTextfield.defaultProps = {
  label: '',
  variant: 'outlined',
  type: 'text',
  error: false,
  helperText: '',
  className: null,
  required: false,
  fullWidth: true,
  size: 'small',
  disabled: false,
  defaultValue: '',
};

export default AddressAutocompleteTextfield;
