import { Grid, Typography } from '@mui/material';

import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router';
import { OTPForm } from './OTPForm';
import { EmailForm } from './EmailForm';
import { SetNewPassword } from './SetNewPassword';
import { SuccessfullMessage } from './SuccessfullMessage';

export const Forgotpassword = ({ setforgotPassword }) => {
  const [openOtp, setOpenOtp] = useState(false);
  const [openNewPasswordForm, setOpenNewPasswordForm] = useState(false);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState('');
  const [prefilled, setPrefilled] = useState();
  const [otpError, setOtpError] = useState('');
  const [currentType, setCurrentType] = useState('email');

  return (
    <Grid justifyContent="center" alignItems="center" container>
      <Grid
        justifyContent="center"
        className="bg-white max-w-[456px] !py-7 !px-12 !rounded-lg"
        item
        container
        
      >
        {openOtp ? (
          <OTPForm
            otpError={otpError}
            setOtpError={setOtpError}
            prefilled={prefilled}
            setforgotPassword={setforgotPassword}
            currentType={currentType}
            setOpenOtp={setOpenOtp}
            data={data}
            setOpenNewPasswordForm={setOpenNewPasswordForm}
          />
        ) : openNewPasswordForm ? (
          <SetNewPassword
            prefilled={prefilled}
            setforgotPassword={setforgotPassword}
            setOpenNewPasswordForm={setOpenNewPasswordForm}
            setSuccess={setSuccess}
          />
        ) : success ? (
          <SuccessfullMessage setforgotPassword={setforgotPassword} />
        ) : (
          <EmailForm
            otpError={otpError}
            setOtpError={setOtpError}
            setPrefilled={setPrefilled}
            setforgotPassword={setforgotPassword}
            setOpenOtp={setOpenOtp}
            data={data}
            setData={setData}
            setCurrentType={setCurrentType}
          />
        )}
      </Grid>
    </Grid>
  );
};
