
import React, { useState } from 'react'
import { Grid} from '@mui/material'
import { RequestQuoteForm } from './RequestQuoteForm'
import { ProductDetail } from './ProductDetail'

export const ProductDetailMain = () => {   
  const [openRequestQuote,setRequestQuote]= useState(false);
  
  return (
    <Grid container className='max-w-[1770px] m-auto'>
       <ProductDetail/>        
       <RequestQuoteForm open={openRequestQuote} setOpen={setRequestQuote} />        
    </Grid>
  )
}
