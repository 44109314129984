import * as yup from 'yup';


export const ProductSellSchema = yup.object().shape({
  category: yup.number(),
  sub_category: yup.number(),
  title: yup.string(),
  selling_price: yup.number(),
  street_address: yup.string(),
  city: yup.string(),
  state: yup.string(),
  country: yup.string(),
  zip_code: yup.string(),
  latitude: yup.string(),
  longitude: yup.string(),
  place_id: yup.string(),
  description: yup.string(),
  is_approved: yup.boolean(),
  vehicle: yup.object({
    vin_number: yup.string(),
    year: yup.number(),
    make: yup.string(),
    model: yup.string(),
    engine_manufacturer: yup.string(),
    trailer_manufacturer: yup.string(),
    horsepower: yup.string(),
    transmission_manufacturer: yup.string(),
    distance: yup.number(),
    gear_ratio: yup.string(),
    gear_type: yup.string(),
    mileage: yup.number(),
    apu: yup.string(),
    axle_ratio: yup.string(),
    front_tyre_brand: yup.string(),
    front_tyre_size: yup.string(),
    rear_tyre_brand: yup.string(),
    rear_tyre_size: yup.string(),
    type_of_fuel: yup.string(),
    color: yup.string(),
    bed_size: yup.string(),
    vehicle_price: yup.number(),
    brake_type: yup.string(),
    inverter: yup.boolean(),
    wheel_base: yup.string(),
    tire_treads: yup.string(),
    collision: yup.string(),
    bed_count: yup.string(),
    air_ride: yup.string(),
    scale: yup.string(),
    side_skirt: yup.string(),
    mitigation_system: yup.string(),
    gross_vehicle_weight_rating: yup.string(),
    hour: yup.number()
  }),
  attachment: yup.array(yup.object({
    id: yup.number(),
    name: yup.string(),
    mime_type: yup.string(),
    document_key: yup.string(),
    description: yup.string(),
    attachment: yup.string(),
    thumbnail: yup.string(),
    is_primary: yup.boolean()
  }))
});


