import React from 'react';
import { Grid, Button} from '@mui/material';
import { CustomTextField } from '../../../../components';
import { useRefContext } from '../../../../components/Layout';
import { useForm, Controller} from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { getLocalStorageItem } from 'hooks';
import MuiPhoneNumber from "material-ui-phone-number";

const infoValidator = yup.object().shape({
  stock_number: yup
    .string()
    .required('Stock number is required')

    .nullable(),
  name: yup
    .string()
    .required('Name is required')
    .min(2, 'Minimum 2 characters are required')
    .max(25, 'Maximum 25 characters are allowed')
    .nullable(),
  email: yup
    .string()
    .email('Please enter a valid email')
    .required('Email is required')
    .nullable(),
  phone: yup.string().required('Phone No. is required').nullable(),
  company_name: yup
    .string()
    // .required('Company name is required')
    // .min(2, 'Minimum 2 characters are required')
    .max(25, 'Maximum 25 characters are allowed')
    .nullable(),
});
const NonSMRinfoValidator = yup.object().shape({
  name: yup
    .string()
    .required('Name is required')
    .min(2, 'Minimum 2 characters are required')
    .max(25, 'Maximum 25 characters are allowed')
    .nullable(),
  email: yup
    .string()
    .email('Please enter a valid email')
    .required('Email is required')
    .nullable(),
  seller_email: yup.string().email('Please enter a valid email').nullable(),
  phone: yup
    .string()
    .required('Phone No. is required')

    .nullable(),
  company_name: yup
    .string()
    // .required('Company name is required')
    // .min(2, 'Minimum 2 characters are required')
    .max(25, 'Maximum 25 characters are allowed')
    .nullable(),
  seller_name: yup
    .string()
    // .required('Seller name is required')
    // .min(2, 'Minimum 2 characters are required')
    .max(25, 'Maximum 25 characters are allowed')
    .nullable(),
});
export const BasicInfo = (props) => {
  const { activeStep, setActiveStep, data, setData, isSMR } = props;

  const { headerRef } = useRefContext();
  const SMRuser = {
    name: data.name || getLocalStorageItem('USER_DETAILS').name,
    email: data.email || getLocalStorageItem('USER_DETAILS').email,
    stock_number: data.stock_number,
    company_name: data.company_name || null,
    phone: data.phone || '+1'+getLocalStorageItem('USER_DETAILS').phone,
  };
  const NON_SMRuser = {
    name: data.name || getLocalStorageItem('USER_DETAILS').name,
    email: data.email || getLocalStorageItem('USER_DETAILS').email,
    company_name: data.company_name || null,
    phone: data.phone || '+1'+getLocalStorageItem('USER_DETAILS').phone,
    seller_email: data.seller_email || null,
    seller_name: data.seller_name || null,
    seller_phone_number: data.seller_phone_number || null,
  };
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    setError: setstockError,
  } = useForm({
    resolver: yupResolver(isSMR ? infoValidator : NonSMRinfoValidator),
    defaultValues: isSMR ? SMRuser : NON_SMRuser,
    //defaultValues: deData,
  });

  const handleChange = (val) => {
    setData({ ...data, ...val });
    setActiveStep(activeStep + 1);
  };



  return (
    <div
      className="ag-grid BasicInformationFN"
      style={{
        height: 'calc(100vh - ' + headerRef?.current?.clientHeight + 'px)',
      }}
    >
      <form onSubmit={handleSubmit(handleChange)}>
        <div className="main relative h-full p-4 box mt-4 !rounded-[12px] border-gray-10 ">
          <div className="stepper-content-header !pt-2 !mb-[2rem]">
            <h3 className="!font-semibold !text-[16px] !font-[Inter] !text-[#051C2C]">
              Basic Information
            </h3>
          </div>
          <div className="stepper-content">
            <Grid spacing={4} container>
              {isSMR && (
                <Grid lg={12} md={12} xs={12} item>
                  <CustomTextField
                    className="max-w-[400px]"
                    type="text"
                    placeholder="XXX-XXX-XXX-XXXX"
                    label="Stock Number"
                    disabled={true}
                    error={Boolean(errors.stock_number)}
                    helperText={
                      errors.stock_number && errors.stock_number.message
                    }
                    FormHelperTextProps={{
                      className: 'animateErrorValue',
                    }}
                    {...register('stock_number')}
                  />
                </Grid>
              )}
              <Grid lg={6} md={12} xs={12} item>
                <CustomTextField
                  className="max-w-[400px]"
                  type="text"
                  placeholder="Enter Name"
                  label="Name"
                  maxLength="25"
                  error={Boolean(errors.name)}
                  helperText={errors.name && errors.name.message}
                  FormHelperTextProps={{
                    className: 'animateErrorValue',
                  }}
                  {...register('name')}
                />
              </Grid>
              <Grid lg={6} md={12} xs={12} item>
                <CustomTextField
                  className="max-w-[400px]"
                  type="text"
                  label="Company Name"
                  placeholder="Enter Company Name"
                  fontWeight="500"
                  color="#333333"
                  maxLength="25"
                  error={Boolean(errors.company_name)}
                  helperText={
                    errors.company_name && errors.company_name.message
                  }
                  FormHelperTextProps={{
                    className: 'animateErrorValue',
                  }}
                  {...register('company_name')}
                />
              </Grid>
              <Grid lg={6} md={12} xs={12} item>
                <div className="form-item flex flex-col max-w-[400px]">
                  <p
                    style={{ marginBottom: '10px' }}
                    className="!font-[400] !font-[Inter] !text-[14px] !text-[#545454] margin-b-"
                  >
                    Phone
                  </p>
                  <Controller
                    control={control}
                    name="phone"
                    render={({ field, fieldState }) => (
                      <MuiPhoneNumber
                        disableAreaCodes
                        defaultCountry={'us'}
                        countryCodeEditable={false}
                        value={field?.value}
                        onChange={field.onChange}
                        placeholder="Enter Phone Number" // Add the placeholder text here
                        sx={{
                          width: '100%',
                          '& .MuiInputBase-root': {
                            height: '48px',
                            borderRadius: '8px',
                          },
                          '& .MuiPhoneNumber-flagButton': {
                            display: 'none', // Hide the flag
                          },
                        }}
                        variant="outlined"
                        onBlur={field.onBlur}
                        name={field.name}
                        // error={Boolean(fieldState.error)}
                        // helperText={
                        //   fieldState.error && fieldState.error.message
                        // }
                        error={Boolean(fieldState.error)}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </div>
              </Grid>
              <Grid lg={6} md={12} xs={12} item>
                <CustomTextField
                  className="max-w-[400px]"
                  type="text"
                  placeholder="Enter Email"
                  label="Email"
                  maxLength="50"
                  error={Boolean(errors.email)}
                  helperText={errors.email && errors.email.message}
                  FormHelperTextProps={{
                    className: 'animateErrorValue',
                  }}
                  {...register('email')}
                />
              </Grid>
              {!isSMR && (
                <>
                  <Grid lg={6} md={12} xs={12} item>
                    <CustomTextField
                      className="max-w-[400px]"
                      type="text"
                      placeholder="Enter Seller Name"
                      label="Seller Name (Please add your company name if you are refinancing)"
                      maxLength="25"
                      error={Boolean(errors.seller_name)}
                      helperText={
                        errors.seller_name && errors.seller_name.message
                      }
                      FormHelperTextProps={{
                        className: 'animateErrorValue',
                      }}
                      labelOverFlow={true}
                      {...register('seller_name')}
                    />
                  </Grid>
                  <Grid lg={6} md={12} xs={12} item>
                    <div className="form-item flex flex-col max-w-[400px]">
                      <p
                        style={{ marginBottom: '10px' }}
                        className="!font-[400] !font-[Inter] !text-[14px] !text-[#545454] margin-b-"
                      >
                        Seller Phone Number (Optional)
                      </p>
                      <Controller
                        control={control}
                        name="seller_phone_number"
                        render={({ field, fieldState }) => (
                          <MuiPhoneNumber
                            disableAreaCodes
                            defaultCountry={'us'}
                            countryCodeEditable={false}
                            value={field?.value}
                            onChange={field.onChange}
                            placeholder="Enter Seller Phone Number" // Add the placeholder text here
                            sx={{
                              // "& .MuiOutlinedInput-root": {
                              //   "& fieldset": {
                              //     borderColor: "#264265",

                              //   },
                              '& .MuiInputBase-root': {
                                height: '48px',
                                width: '400px',
                                borderRadius: '8px',
                              },
                              '& .MuiPhoneNumber-flagButton': {
                                display: 'none', // Hide the flag
                              },
                            }}
                            variant="outlined"
                            onBlur={field.onBlur}
                            name={field.name}
                            error={Boolean(fieldState.error)}
                            helperText={
                              fieldState.error && fieldState.error.message
                            }
                          />
                        )}
                      />
                    </div>
                  </Grid>
                  <Grid lg={6} md={12} xs={12} item>
                    <CustomTextField
                      className="max-w-[400px]"
                      type="text"
                      placeholder="Enter Seller Email"
                      label="Seller Email (optional)"
                      maxLength="50"
                      error={Boolean(errors.seller_email)}
                      helperText={
                        errors.seller_email && errors.seller_email.message
                      }
                      FormHelperTextProps={{
                        className: 'animateErrorValue',
                      }}
                      {...register('seller_email')}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        </div>
        <footer className="content-footer mb-[200px] absolute bottom-5 right-32">
          <div className="stepper-content-actions-footer flex justify-between">
            <div></div>
            <div>
              <Button
                style={{
                  width: '275px',
                  minHeight: '44px',
                  height: '44px',
                }}
                type="submit"
                variant="contained"
              >
                Continue
              </Button>
            </div>
          </div>
        </footer>
      </form>
    </div>
  );
};
