import React from 'react';
import {
  Dialog,
  Box,
  DialogContent,
  Typography,
  Grid,
  DialogActions,
  Button,
  CircularProgress,
} from '@mui/material';
import { useState } from 'react';
import { CustomTextField } from 'components';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { getLocalStorageItem } from 'hooks';
import { useCreateReview } from 'query/Review';
import { useRefContext } from 'components/Layout';

const feedbackFormSchema = yup.object().shape({
  email: yup.string().nullable().required('Email is requird'),
  name: yup.string().nullable().required('Name is requird'),
  review: yup.string().nullable().required('Write a Review'),
});
export const FeedbackForm = ({ openForm, setOpenForm }) => {
  const handleClose = () => {
    setOpenForm(false);
  };

  const user_Details = getLocalStorageItem('USER_DETAILS');
  const SuccessCallback=()=>{
    setOpenForm(false)
  }
  const { mutate: postReviewData, isLoading: addingReview } = useCreateReview(SuccessCallback);

  const user = {
    name: user_Details?.name,
    email: user_Details?.email,
    mobile: user_Details?.mobile,
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(feedbackFormSchema),
    defaultValues: user,
  });

  const formSubmit = (formValues) => {
    postReviewData({ ...user, description: formValues?.review });
  };
  return (
    <Box>
      <Dialog maxWidth="sm" fullWidth open={openForm} onClose={handleClose}>
        <form onSubmit={handleSubmit(formSubmit)}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12}>
                <Typography className="!font-semibold !text-[18px]">
                  Customer Review Form
                </Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12}>
                <Typography className="text-gray-60">
                  Thank you for choosing SMR Company. We'd love to hear feedback
                  from our customers. Please fill out the form below:
                </Typography>
              </Grid>
            </Grid>
            <Grid spacing={2} item container className="pt-6">
              <Grid lg={12} md={12} xs={12} item>
                {' '}
                <CustomTextField
                  placeholder="Name"
                  label="Name"
                  {...register('name')}
                  error={Boolean(errors.name)}
                  helperText={errors.name && errors.name.message}
                  FormHelperTextProps={{
                    className: 'animateErrorValue ',
                  }}
                />{' '}
              </Grid>

              <Grid lg={12} md={12} xs={12} item>
                {' '}
                <CustomTextField
                  placeholder="john@gmail.com"
                  label="Email"
                  {...register('email')}
                  error={Boolean(errors.email)}
                  helperText={errors.email && errors.email.message}
                  FormHelperTextProps={{
                    className: 'animateErrorValue ',
                  }}
                />{' '}
              </Grid>
              <Grid lg={12} md={12} xs={12} item>
                {' '}
                <CustomTextField
                  multiline
                  rows={7}
                  placeholder="  Type..."
                  {...register('review')}
                  label="Write a Review"
                  error={Boolean(errors.review)}
                  helperText={errors.review && errors.review.message}
                  FormHelperTextProps={{
                    className: 'animateErrorValue ',
                  }}
                />{' '}
              </Grid>
            </Grid>
            <Grid className=" flex py-2" lg={12} md={12} sm={12}>
              <Button
                onClick={handleClose}
                className="!bg-gray-50 !w-full !text-black !mx-2"
              >
                Cancel
              </Button>
              {addingReview ? (
                <Button className="!w-full !bg-secondary-50  !text-white !mx-2 ">
                  {' '}
                  <CircularProgress size={20} className="!text-white" />
                </Button>
              ) : (
                <Button
                  type="submit"
                  className="!w-full !bg-secondary-50 !text-white !mx-2 "
                >
                  Publish
                </Button>
              )}
            </Grid>
          </DialogContent>
        </form>
        <DialogActions className="pb-4 px-4"></DialogActions>
      </Dialog>
    </Box>
  );
};
