import React, { useState, useEffect } from 'react';
import { Button,useMediaQuery, useTheme } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import { Grid, Box, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import '../SMRR.css';
import { CommonAccountLayout } from 'pages/commonComponents/CommonAccountLayout';
import SettingsMenu from './SettingsMenu';
import MenuItem from './SettingsContent';
import { routes } from 'routes/RouteConstants';
import ConfirmModal from './ConfirmationPage';
import {useGetNotification} from '../../../query/settings';
// import { Settings } from '@mui/icons-material';



const Settings = () => {

    const theme = useTheme();
    const XSmall = useMediaQuery(theme.breakpoints.down('xs'));
    const isLarge = useMediaQuery(theme.breakpoints.down('lg'));
    const isMedium = useMediaQuery(theme.breakpoints.down('md'));

   

    // console.log('data',data);
    const navigation = useNavigate();

    const handleSettingNav = () => {
        console.log('click setting')
        navigation(routes.ChangePassword);
    }

    const handleNotificationNav = () => {
        console.log('click');
        navigation(routes.Notifications);
    }

    // const [isOpen, setIsOpen] = useState(false);

    const handleConfirmation = () => {
        navigation(routes.ConfimationPage);
    }
    // const handleModalClose = () => {
    //     setIsOpen(false);
    // };

    return (
        <>
            <CommonAccountLayout
                Content={
                    <Box>
                        <Grid container lg={12} md={12} xs={12} padding={1}>
                            <Grid item container lg={11} md={12} sm={12} xs={12} paddingTop={3} rowGap={3}>
                                <Grid item lg={12} md={12} sm={12} xs={12} className=''>
                                    <Typography style={{ fontSize: '14px', color: '#333333', fontWeight: '500' }}>
                                        Settings
                                    </Typography>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12} style={{  cursor: 'pointer' }} onClick={() => handleSettingNav()} className=''>
                                    <MenuItem iconname='password.svg' description='Change Password' />
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12} style={{ cursor: 'pointer' }} onClick={() => handleNotificationNav()} className=''>
                                    <MenuItem iconname='notification.svg' description='Push Notifications' />
                                </Grid>
                                <Grid item container lg={12} md={12} sm={12} xs={11} style={{ cursor: 'pointer' }} className='' justifyContent={isMedium?'center':'right'}>
                                    <Grid item paddingTop='200px'>
                                        <Button onClick={() => handleConfirmation()} style={{backgroundColor:'#FFC200', borderRadius:'30px',height:'40px', width:'204px',fontSize: '16px', fontWeight: '600', color: '#333333' }}>
                                            Deactivate Account
                                        </Button>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>


                    </Box>} />
        </>
    )
}

export default Settings;