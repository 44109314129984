import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import BasicModal from './Modal';
import '../SMRR.css';
import { Button, Grid, Typography } from '@mui/material';
import ButtonComponent from '../ButtonComponent';
import { routes } from 'routes/RouteConstants';
import { currencyFormat } from 'utils';


const EquipmentCard = (props) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [quoteId, setQuoteId] = useState(null);
  const [paymentType, setPaymentType] = useState(null);
  const navigation = useNavigate();
  const [type, setType] = useState(null);
  //const [firstBtnVal, setFirstBtnVal] = useState(null);
  const getImgUrlFunction = (attachments) => {
    const imgArray = attachments?.filter((el) => el.description === 'IMAGE');
    const imgArrayAscending = [...imgArray].sort((a, b) =>
      a.is_primary > b.is_primary ? -1 : 1
    );
    return imgArrayAscending[0]?.attachment;
  };

  useEffect(() => {
    if (props?.firstBtnVal) {
      if (props?.firstBtnVal === 'DEPOSIT') {
        setType('pending');
        setPaymentType('Quotes');
      } else if (
        props?.firstBtnVal === 'DEPOSIT_SUCCESSFUL' ||
        props?.firstBtnVal === 'PRODUCT_ON_HOLD'
      ) {
        setType('completed');
      } else if (
        props?.firstBtnVal === 'DENIED' ||
        props?.firstBtnVal === 'PRODUCT_REJECTED' ||
        props?.firstBtnVal === 'REMOVAL_REQUESTED'
      ) {
        setType('denied');
      } else if (props?.firstBtnVal === 'AMOUNT_REFUNDED') {
        setType('denied');
      } else if (
        props?.firstBtnVal === 'QUOTE_SENT' ||
        props?.firstBtnVal === 'REVIEW_PENDING' ||
        props?.firstBtnVal === 'INSPECTION_PENDING'
      ) {
        setType('sent');
      } else if (
        props?.firstBtnVal === 'COMPLETE' ||
        props?.firstBtnVal === 'PRODUCT_LISTED' ||
        props?.firstBtnVal === 'PRODUCT_SOLD'
      ) {
        setType('financing');
      } else if (props?.firstBtnVal === 'INSPECTION_FEE_PENDING') {
        setType('inspection_fee_pending');
        setPaymentType('Inspection');
      } else if (props?.firstBtnVal === 'PRODUCT_REMOVED') {
        setType('remove');
      }
    }
  }, [type, props?.firstBtnVal]);
  const handleModal = (id) => {
    setOpen(true);
    setQuoteId(id);
  };
  const handleApplyForFinance = (id) => {
    // navigate(routes.FINANCING, { state: { id } });
    navigate(routes.FINANCING.pathname,{state:{stock_number: id}})
  };
  const handlePayment = (
    id,
    listing_id,
    unique_id,
    value,
    tabtype,
    payment_Type
  ) => {
    //console.log('id-'+id +' List-'+listing_id+' Unoke -'+unique_id+' Value -'+value+' tab type -'+tabtype+' ptype-'+payment_Type)
    navigate(routes.PAYMENT_BILLLING, {
      state: { id, listing_id, unique_id, value, tabtype, payment_Type },
    });
  };
  const handleButtonClick = (
    value,
    id,
    type,
    listing_id,
    unique_id,
    isQuoteTab,
    price
  ) => {
    //alert(isQuoteTab+'-'+id+'-'+listing_id+'='+unique_id);
    navigation(`${routes.PeoductDeatils.pathname}?id=${id}`, {
      state: {
        id: id,
        quote_status: value,
        listing_id: listing_id,
        type: type,
        unique_id: unique_id,
        isQuoteTab: isQuoteTab,
        price: price,
      },
    });
  };
  return (
    <Grid container className="max-md:flex-col" columnGap={2}>
      <Grid item lg={2} md={6} sm={3} className="">
        <div
          className="imgListinDiv"
          onClick={() =>
            handleButtonClick(
              props?.firstBtnVal,
              props?.id,
              type,
              props?.listing_id,
              props?.unique_id,
              props?.isQuoteTab,
              props?.price
            )
          }
        >
          {getImgUrlFunction(props?.attachments) && (
            <img
              src={
                getImgUrlFunction(props?.attachments)
                  ? getImgUrlFunction(props?.attachments)
                  : ' '
              }
              alt=""
              className="listImg"
            />
          )}
        </div>
      </Grid>
      <Grid item container lg={9.5} md={6} sm={12} className="">
        <Grid item container lg={12} md={12} sm={4} className="">
          <Grid item lg={12} md={12} sm={12} className="">
            <Typography
              item
              style={{
                fontSize: '18px',
                color: '#333333',
                fontWeight: '500',
                paddingTop: '8px',
                cursor: 'pointer',
              }}
              onClick={() =>
                handleButtonClick(
                  props?.firstBtnVal,
                  props?.id,
                  type,
                  props?.listing_id,
                  props?.unique_id,
                  props?.isQuoteTab,
                  props?.price
                )
              }
            >
              {props?.title ? props?.title : 'NA'}
            </Typography>
          </Grid>
          <Grid item container lg={12} md={12} sm={3} paddingBottom="16px">
            {props?.isQuoteTab ? (
              <>
                {props?.validValue ? (
                  <>
                    <Typography
                      item
                      style={{
                        fontSize: '18px',
                        color: '#333333',
                        fontWeight: '700',
                        paddingRight: '24px',
                      }}
                    >
                      {currencyFormat(
                        props?.validValue ? props?.validValue : 'null'
                      )}
                    </Typography>
                    <Typography
                      item
                      style={{
                        fontSize: '18px',
                        color: '#757575',
                        fontWeight: '500',
                        textDecoration: 'line-through',
                      }}
                    >
                      {currencyFormat(props?.cancelValue)}
                    </Typography>
                  </>
                ) : (
                  <Typography
                    item
                    style={{
                      fontSize: '18px',
                      color: '#333333',
                      fontWeight: '700',
                      paddingRight: '24px',
                    }}
                  >
                    {currencyFormat(
                      props?.cancelValue ? props?.cancelValue : 'null'
                    )}
                  </Typography>
                )}
              </>
            ) : (
              <Typography
                item
                style={{
                  fontSize: '18px',
                  color: '#333333',
                  fontWeight: '700',
                  paddingRight: '24px',
                }}
              >
                {currencyFormat(props?.validValue ? props?.validValue : 'null')}
              </Typography>
            )}
          </Grid>
          <Grid
            item
            container
            lg={12}
            md={12}
            sm={12}
            spacing={2}
            style={{ marginLeft: '.1px' }}
          >
            <img
              className="w-[20px] h-[23px]"
              src={'/assets/location.svg'}
              alt="location"
            />
            <Typography
              style={{
                fontSize: '14px',
                color: '#757575',
                fontWeight: '400',
                paddingLeft: '7px',
              }}
            >
              {props?.city && (
                <>
                  {props?.city}, {props?.state}, {props?.zipcode}
                </>
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container lg={12} md={12} sm={12}>
          <Grid
            item
            container
            lg={6}
            md={6}
            sm={12}
            className=" flex"
            justifyContent="left"
            alignItems="center"
          >
            {' '}
            <Grid
              item
              className=""
              lg={8}
              md={8}
              sm={12}
              xs={12}
              onClick={() =>
                handleButtonClick(
                  props?.firstBtnVal,
                  props?.id,
                  type,
                  props?.listing_id,
                  props?.unique_id,
                  props?.isQuoteTab,
                  props?.price
                )
              }
            >
              <ButtonComponent
                value={props?.firstBtnVal}
                type={type}
                isProducInfoPage={false}
                price={props?.price}
              />
            </Grid>
          </Grid>
          <Grid
            className=" flex"
            item
            lg={6}
            md={6}
            justifyContent="right"
            alignItems="center"
          >
            {props.firstBtnVal === 'DEPOSIT' ||
            props.firstBtnVal === 'INSPECTION_FEE_PENDING' ? (
              <Button
                className="normalButton "
                style={{
                  fontSize: '14px',
                  textTransform: 'none',
                  width: '121px',
                }}
                onClick={() =>
                  handlePayment(
                    props?.id,
                    props?.listing_id,
                    props.unique_id,
                    props?.validValue,
                    props.isQuoteTab,
                    paymentType
                  )
                }
              >
                Pay Now
              </Button>
            ) : props.firstBtnVal === 'DENIED ' ? (
              ''
            ) : props.firstBtnVal == 'COMPLETE' ? (
              ''
            ) : props.firstBtnVal === 'AMOUNT_REFUNDED' ? (
              <Typography> Amount Refunded </Typography>
            ) : props.firstBtnVal == 'DEPOSIT_SUCCESSFUL' ? (
              <>
                {props?.finance_id ? (
                  <Typography
                  className="box"
                  style={{
                    border: '1px solid',
                    borderTopColor: '#FFFFFF',
                    borderLeftColor: '#FFFFFF',
                    borderRightColor: '#FFFFFF',
                  }}
                >                  
                  Financing Under Process
                </Typography>
                ):(
                  <Typography
                  className="box"
                  style={{
                    border: '1px solid',
                    borderTopColor: '#FFFFFF',
                    borderLeftColor: '#FFFFFF',
                    borderRightColor: '#FFFFFF',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleApplyForFinance(props.unique_id)}
                >
                  {' '}
                  Apply for Finance{' '}
                </Typography>
                )}                
              </>
            ) : props.firstBtnVal == 'QUOTE_SENT' ? (
              <Typography
                className="box"
                style={{
                  border: '1px solid',
                  borderTopColor: '#FFFFFF',
                  borderLeftColor: '#FFFFFF',
                  borderRightColor: '#FFFFFF',
                  cursor: 'pointer',
                }}
                onClick={() => handleModal(props.id)}
              >
                {' '}
                Remove Quote{' '}
              </Typography>
            ) : (
              ''
            )}
          </Grid>
          <BasicModal
            open={open}
            setClose={setOpen}
            id={quoteId}
            refetchQuote={props?.refetchQuote}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default EquipmentCard;