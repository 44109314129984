import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { Grid,Box,Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import '../SMRR.css';
import { ClassNames } from '@emotion/react';
// import { PropaneSharp } from '@mui/icons-material';




const SettingsMenu = ({Content}) => {
//   const classes= useStyles();

    return (

         <Box> 
             <Grid container item lg={12} md={12} xs={12} style={{backgroundColor:'#FFFFFF',boxShadow:'0px 2px 6px rgba(0, 0, 0, 0.08)' ,borderRadius:'10px',height:'71px'}}>
                  {Content}
             </Grid>
        </Box>
    )
}
export default SettingsMenu;


