import Slider from '@mui/material/Slider';
import React from 'react';

export const CustomSlider = ({
  handleChange,
  value,
  valuetext,
  type,
  appliedFilters,
  setRenderSliderValue,
}) => {
  // const [currVal, setCurrVal] = useState([
  //   appliedFilters?.find((el)=>{
  //     if(el.filter=='price_min'){
  //       return el
  //     }
  //   })?.price_min || 0,
  //   appliedFilters?.find((el)=>{
  //     if(el.filter=='price_min'){
  //       return el
  //     }
  //   })?.price_max || 1000000,
  // ]);
  // useEffect(() => {
  //   setCurrVal([
  //     appliedFilters?.find((el)=>{
  //       if(el.filter=='price_min'){
  //         return el
  //       }
  //     })?.price_min || 0,
  //     appliedFilters?.find((el)=>{
  //       if(el.filter=='price_min'){
  //         return el
  //       }
  //     })?.price_max || 1000000,
  //   ]);
  // }, [appliedFilters]);
  // console.log(value, 'setting price');
  return (
    <>
      {' '}
      <Slider
        sx={{
          color: '#757575',

          height: '8px',
          '& .MuiSlider-track': {
            border: 'none',
            height: '8px',
            backgroundColor: '#FFC200',
          },
          '& .MuiSlider-thumb': {
            height: 24,
            width: 24,
            backgroundColor: '#fff',
            border: '2px solid #FFC200',
            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
              boxShadow: 'inherit',
            },
            '&:before': {
              display: 'none',
            },
          },
          '& .MuiSlider-valueLabel': {
            backgroundColor: 'white',
            color: 'black',
            marginTop: '75px',
          },

          markLabel: {
            transform: 'translateX(-12%)',
          },
        }}
        aria-label="pretto slider"
        valueLabelDisplay={'auto'}
        valueLabelFormat={(val) => {
          if (val < value[1]) {
            return 'Min';
          }
          return 'Max';
        }}
        value={value}
        min={0}
        max={1000000}
        onChangeCommitted={handleChange}
        onChange={(e, val) => {
          setRenderSliderValue(val);
        }}
      />
    </>
  );
};
