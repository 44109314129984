import {
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useState, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import TruckCard from './TruckCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination } from 'swiper';
import { useInventoryData } from 'query/Home';

export const FeatureInventory = () => {
  const theme = useTheme();  
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));  

  const [currentIndex, setCurrentIndex] = useState(0);
  const { data: InventoryDataResponse, refetch } = useInventoryData();
 
  const swiperRef = useRef();
  const mydata = InventoryDataResponse?.data;

  if (mydata?.length === 0 || !mydata) {
    return <></>;
  }
  return (
    <Grid style={{ background: '#FBFBFB' }} container className="">
      <Grid
        style={{ margin: 'auto' }}
        container
        className={`  ${
          isMedium ? 'px-[100px]' : 'px-0'
        } py-16  !w-[100%]  max-w-[1700px]  overflow-scroll`}
      >
        <Grid item container>
          <Grid
            justifyContent={`${isSmall || isXSmall ? 'center' : 'left'}`}
            container
            item
            className="relative"
          >
            <Typography
              variant="h4"
              className={`!font-bold  text-left px-8 !text-[40px]
              text-gray-90`}
              style={{ fontFamily: 'Space Grotesk' }}
            >
              Featured Inventory
            </Typography>
          </Grid>
        </Grid>

        <div
          className="flex justify-center  items-center align-middle m-auto !min-h-[400px] w-full py-10"
          item
          container
        >
          <IconButton
            disabled={currentIndex === 0 ? true : false}
            onClick={() => swiperRef.current?.slidePrev()}
            className="swmt-45"
          >
            <AiOutlineArrowLeft className="text-[24px] !text-secondary-50" />
          </IconButton>

          <Swiper
            pagination={{
              dynamicBullets: true,
            }}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            onRealIndexChange={(element) =>
              setCurrentIndex(element.activeIndex)
            }
            slidesPerView={'auto'}
            modules={[Pagination]}
            className="mySwiper w-full"
          >          
            {InventoryDataResponse?.data?.map((el, index) => {
              return (
                <SwiperSlide
                  key={el?.id}
                  style={{ maxWidth: '320px', justifyContent: 'center' }}
                  className="!max-w-320"
                >
                  <TruckCard refetch={refetch} {...el} />
                </SwiperSlide>
              );
            })}
          </Swiper>
          <IconButton onClick={() => swiperRef.current?.slideNext()} className="swmt-45">
            <AiOutlineArrowRight className="text-[24px] !text-secondary-50" />
          </IconButton>
        </div>        
      </Grid>
    </Grid>
  );
};
