import { Box } from '@material-ui/core';
import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Typography } from '@mui/material';

export const HeroTags = ({ value, color = 'border-primary-50' }) => {
  return (
    <Box className={`p-2  text-black `}>
      <div className="align-left justify-left flex">
        <CheckCircleIcon className="pr-2" style={{ color: '#07794C' }} />
        <Typography className=" leading-7 tracking-normal text-left" style={{fontSize:'14px',fontWeight:'500',color:'#0F0F0F',fontFamily:'Inter'}}>
          {value}
        </Typography>
      </div>
    </Box>
  );
};
