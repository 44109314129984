import {  Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import BrandLogo from './BrandLogo';
import { usePopularBrands } from 'query/Home';
import { useNavigate } from 'react-router';
import { nanoid } from 'nanoid';

export const PopularBrands = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    data: popularBrandResponse,
  } = usePopularBrands();

  const mapDataCTruck = {};
  const mapDataTrailer = {};
  popularBrandResponse?.data?.forEach((elem) => {
    if(elem.name==='Commercial Truck'){
      elem.brands.forEach((el) => {
        mapDataCTruck[el.name] = el;
      });
    }
    if(elem.name==='Trailer'){
      elem.brands.forEach((el) => {
        mapDataTrailer[el.name] = el;
      });
    }
    
  });

  return (
    <Grid
      justifyContent="center"
      item
      style={{
        borderBottom: ' 1px solid rgba(0, 0, 0, 0.1)',
        clipPath: 'polygon(0 20px, 100% 110px, 100% 100%, 0% 100%)',
      }}
      className={`!w-[100%] bg-secondary-90 ${
        isSmall ? 'py-20' : 'py-28'
      } px-5 min-h-[550px] `}
    >
      <Grid item container className="relative" justifyContent="center">
        <Typography
          style={{ fontFamily: 'Space Grotesk' }}
          variant="h4"
          className=" text-primary-50 !font-semibold"
        >
          Popular Manufacturers
        </Typography>
        <img
          className="absolute top-[80%] max-w-[373px]"
          src="assets/landingPage/WhitePopularBrandsLine.svg"
          alt=""
        />
      </Grid>
      <Grid
        className="max-w-[463px] text-center m-auto py-12 "
        justifyContent="center"
        citem
      >
        <Typography className="text-white">
        Purchase your next Rig with confidence.
        </Typography>
      </Grid>
      <Grid      
        justifyContent="center"
        className="flex gap-[70px] pt-5 max-w-[1500px] !m-auto max-sm:gap-5 max-sm:inline  t-gap"        
      >  
           
        <Grid container justifyContent="center" lg={5}  sx={12} md={12} spacing={5}>
          {Object.entries(mapDataCTruck)?.map(([key, val]) => {

            return (
              <Grid
                key={key}
                lg={6}
                sx={12}
                md={12}
                item
                className="cursor-pointer"
                onClick={() => {
                  navigate('/inventory', {
                    state: {
                      forBrand: [
                        {
                          key: nanoid(),
                          label: val.name,
                          filter: 'brand',
                        },
                      ],
                    },
                  });
                }}
              >
                <BrandLogo name={val.name} image={val.image} />
              </Grid>
            );
          })}
        </Grid>
        {/* <Grid container justifyContent="center" lg={1}  sx={12} md={12}></Grid> */}
        <Grid container justifyContent="center" lg={5}  sx={12} md={12} spacing={5}>
          {Object.entries(mapDataTrailer)?.map(([key, val]) => {
            return (
              <Grid
                key={key}
                lg={6}
                sx={12}
                md={12}
                item
                className="cursor-pointer"
                onClick={() => {
                  navigate('/inventory', {
                    state: {
                      forBrand: [
                        {
                          key: nanoid(),
                          label: val.name,
                          filter: 'brand',
                        },
                      ],
                    },
                  });
                }}
              >
                <BrandLogo name={val.name} image={val.image} />
              </Grid>
            );
          })}
        </Grid>
       
      </Grid>
    </Grid>
  );
};
