import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import {
  Grid,
  Box,
  Typography,
  CircularProgress,
  Snackbar,
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { CustomTextField } from '../../../components';
import { yupResolver } from '@hookform/resolvers/yup';
// import done from '../../../utils/gif/done.json';
import * as yup from 'yup';
import { useWatch, useForm } from 'react-hook-form';
import { useUpdatePassword } from 'query/settings';
import '../SMRR.css';
import { useNavigate } from 'react-router';
import { routes } from '../../../routes/RouteConstants';
import { ResponseStatusPage } from '../PaymentMethod/ResponseStatusPage';
import done from '../../../utils/gif/done.json';
import { nullable } from 'zod';
// import CustomButton from '../../../components/CustomButton/CustomButton';

const formSchemea = yup.object().shape({
  current_password: yup
    .string()
    .required('Current password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,}$/,
      'Current password is not valid.'
    ),

  new_password: yup
    .string()
    .nullable()
    .required('New password is required')
    .test('min characters', 'Minimum 8 characters required', (value) => {
      return value && value.length >= 8;
    })
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,}$/,
      'Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character.'
    ),

  confirm_password: yup
    .string()
    .nullable()
    .when('new_password', {
      is: (newPassword) => newPassword && newPassword.length >= 8,
      then: yup
        .string()
        .oneOf([yup.ref('new_password'), null], 'Passwords must match'),
      otherwise: yup
        .string()
        .nullable()
        .required('Confirm password is required'),
    }),
});

//lodar button
const CustomButton = ({ children }) => {
  // console.log(styles);
  return (
    <Button
      style={{
        backgroundColor: '#FFC200',
        height: '44px',
        width: '100%',
        borderRadius: '34px',
        color: '#000000',
        fontWeight: '500',
      }}
    >
      {children}
    </Button>
  );
};

const ChangePassword = () => {
  const navigate = useNavigate();
  const errorMsg = {
    fontSize: '12px',
    color: '#ff0000',
  };

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid },
    trigger,
  } = useForm({ resolver: yupResolver(formSchemea) });
  const [formfiledvalid, setFormfiledvalid] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  // const [loadingBtn, setLoadingBtn] = useState(false);

  //api call
  const {
    mutate: updatePassword,
    refetch,
    isLoading: isUpdatingPassword,
  } = useUpdatePassword();


  const [isOpen, setIsOpen] = useState(false);
  const [errmsg, setErrormsg] = useState(null);
  const [statusCode, setStatusCode] = useState(null);

  //enabling loader
  const onSubmitClick = () => {
    if (isValid) {

      // setLoadingBtn(true)
    }
  };

  const handleUpdatePassword = (formValues) => {
    // setLoadingBtn(true);


    const data = {
      current_password: formValues?.current_password,
      new_password: formValues?.new_password,
      confirm_password: formValues?.confirm_password,
    };
    // useEffect()
    updatePassword(data, {
      onSuccess: (res) => {},
      onError: (err) => {
        if (
          err?.errors[0]?.message === 'Current password is not valid.' &&
          err?.status_code === 422
        ) {
          setErrormsg(err?.errors[0]?.message);
  
        } else {
          setSnackbarOpen(true);
        }
      },
    });
  };

  const handleFieldFocus = (data) => {
    if (data) {
      setFormfiledvalid(true);
      // console.log('ok typing',formfiledvalid,data,formSchemea,formSchemea?.isValid,formSchemea?.controls);
    }
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(handleUpdatePassword)}>
        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          justifyContent="center"
          alignItems="center"
          paddingTop={5}
          className=""
        >
          <Grid
            item
            container
            lg={5}
            md={5}
            sm={6}
            paddingTop={4}
            className="flex"
            justifyContent="center"
          >
            <Grid
              item
              container
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="flex"
              paddingBottom={2}
              justifyContent="center"
            >
              <Grid
                item
                container
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="flex"
                justifyContent="center"
                paddingBottom={1}
                paddingLeft={2}
              >
                <Grid
                  item
                  lg={2}
                  md={1}
                  justifyContent="center"
                  className="flex"
                >
                  <img
                    src={'/assets/icons/keyPass.svg'}
                    style={{ height: '60px', width: '60px' }}
                    alt="password"
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                lg={12}
                md={12}
                sm={12}
                xs={12}
                justifyContent="center"
                paddingBottom={1}
                paddingLeft={3}
                className="flex"
              >
                <Grid
                  item
                  lg={5}
                  md={4}
                  className="flex"
                  justifyContent="center"
                >
                  <Typography
                    style={{
                      fontSize: '24px',
                      fontWeight: '600',
                      color: '#101828',
                    }}
                  >
                    Change Password
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              container
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className=" flex"
              justifyContent="center"
              alignItems="center"
              paddingBottom={3}
            >
              <Grid item lg={9} md={6} className="">
                <CustomTextField
                  error={Boolean(errors.current_password)}
                  style={{ isborder: false, boxShadow: 'rgba(0, 0, 0, 0.05)' }}
                  label="Current Password"
                  fontSize="16px"
                  fontWeight="500"
                  color="#333333"
                  placeholder="Password"
                  type="password"
                  className="w-[100%]"
                  FormHelperTextProps={{
                    className: 'animateErrorValue ',
                  }}
                  {...register('current_password')}
                  onInput={() => handleFieldFocus('current_password')}
                />
                {errmsg ? (
                  <span style={errorMsg}>Current password is not valid</span>
                ) : (
                  errors.current_password && (
                    <span style={errorMsg}>
                      {errors.current_password.message}
                    </span>
                  )
                )}
              </Grid>
            </Grid>

            <Grid
              item
              container
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className=" flex"
              justifyContent="center"
              alignItems="center"
              paddingBottom={3}
            >
              <Grid item lg={9} md={6}>
                <CustomTextField
                  error={Boolean(errors.current_password)}
                  style={{ isborder: false, boxShadow: 'rgba(0, 0, 0, 0.05)' }}
                  label="New Password"
                  fontSize="16px"
                  type="password"
                  fontWeight="500"
                  color="#333333"
                  placeholder="New Password"
                  className="w-[100%]"
                  FormHelperTextProps={{
                    className: 'animateErrorValue ',
                  }}
                  {...register('new_password')}
                  onInput={() => handleFieldFocus('new_password')}
                />
                {errors.new_password && (
                  <span style={errorMsg}>{errors.new_password.message}</span>
                )}
              </Grid>
            </Grid>

            <Grid
              item
              container
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className=" flex"
              justifyContent="center"
              alignItems="center"
              paddingBottom={3}
            >
              <Grid item lg={9} md={6}>
                <CustomTextField
                  error={Boolean(errors.current_password)}
                  style={{ isborder: false, boxShadow: 'rgba(0, 0, 0, 0.05)' }}
                  label="Confirm Password"
                  fontSize="16px"
                  type="password"
                  fontWeight="500"
                  color="#333333"
                  placeholder="Confirm Password"
                  className="w-[100%]"
                  FormHelperTextProps={{
                    className: 'animateErrorValue ',
                  }}
                  {...register('confirm_password')}
                  onInput={() => trigger('confirm_password')}
                />
                {errors.confirm_password && (
                  <span style={errorMsg}>
                    {errors.confirm_password.message}
                  </span>
                )}
              </Grid>
            </Grid>
            <Grid
              item
              container
              lg={11}
              md={12}
              sm={12}
              xs={12}
              className=" "
              justifyContent="center"
            >
              <Grid item lg={10} md={6} className="" justifyContent="center">
                {isUpdatingPassword ? (
                  <CustomButton>
                    <CircularProgress size={30} className="!text-white" />{' '}
                  </CustomButton>
                ) : (
                  <Button
                    type="submit"
                    style={{
                      backgroundColor: '#FFC200',
                      fontSize: '16px',
                      fontWeight: '500',
                      color: '#000000',
                      fontFamily: 'Poppins',
                      height: '44px',
                      width: '100%',
                      borderRadius: '34px',
                    }}
                  >
                    Change Password
                  </Button>
                )}
              </Grid>
            </Grid>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={2000}
              onClose={() => setSnackbarOpen(false)}
              message={errmsg ? '' : 'An error ocurred!'}
            />
          </Grid>
        </Grid>
      </form>
      <ResponseStatusPage
        isOpen={isOpen}
        first_message="Your password has been changed"
        isSettingPage={true}
        animation={done}
      />
    </Box>
  );
};

export default ChangePassword;
