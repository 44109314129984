import React from "react";
import { Grid, useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';

const Shipment = ({icon,text}) => {
  const theme1 = useTheme();
  const isSmall = useMediaQuery(theme1.breakpoints.down("sm"));
  return (
    <Grid item container className={`${isSmall ? "w-[100%]" : "max-w-[250px]"} max-h-[150px]`} direction="column" justifyContent="center" alignItems="center">
      <Grid item>
        <img  src={icon} alt={text} />
      </Grid >
      <Grid item className="pt-5">
        <p className="shipmentText">{text}</p>
      </Grid>
    </Grid>
  );
};

export default Shipment;
