import { useQuery } from "@tanstack/react-query";
import { axios } from "utils";

const GetDocumentsList=(data)=>{
  const method='GET'
  const url=`/users/${data.user_pk}/documents/`
  return axios({method,url,data})
}

export const useAllDocumentsList=(data)=>{

  return useQuery(
    ['GET_ALL_DOCUMENTS'],
    () => GetDocumentsList(data),
    {
      enabled:true
    }
  );
}