import { Grid } from '@mui/material'
import { HeroTags } from 'components/HeroTags/HeroTags'
import React from 'react'
 

export const HeroTagsContainer = () => {
  return (
    <Grid
    item
    container
    className="gap-5 py-8 justify-center 2xl:justify-center max-sm:!inline max-sm:py-0"
  >
    <Grid item className="max-sm:pl-5">
      <HeroTags value={"Hassle Free."} color={"border-primary-50"}/>
    </Grid>
    <Grid item className="max-sm:pl-5">
      <HeroTags value={"Quick Turnover."}color={"border-secondary-50"}/>
    </Grid>
    <Grid item className="max-sm:pl-5">
      <HeroTags value={"Financing available on all units."} color={"border-gray-50"}/>
    </Grid>
    <Grid item className="max-sm:pl-5">
      <HeroTags value={"Equipment Inspections."} color={"border-red-500"}/>
    </Grid>
  </Grid>
  )
}
