import React from 'react';
import {
  Dialog,
  Box,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Button,
} from '@mui/material';
import { useNavigate } from 'react-router';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { routes } from 'routes/RouteConstants';

export const Finance = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
  };
  const DocumentIcon = () => {
    return (
      <svg
        width="45"
        height="52"
        viewBox="0 0 45 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M35.4921 46.8045H10.1921C9.42994 46.8045 8.69899 46.5032 8.16006 45.9668C7.62113 45.4305 7.31836 44.703 7.31836 43.9445V8.84449C7.32199 8.08834 7.62637 7.3644 8.16491 6.831C8.70345 6.2976 9.43232 5.99816 10.1921 5.99817H32.2746L38.3659 12.0603V43.9445C38.3659 44.703 38.0631 45.4305 37.5242 45.9668C36.9852 46.5032 36.2543 46.8045 35.4921 46.8045Z"
          fill="#FFC200"
        />
        <path
          d="M7.31836 13.935V8.84449C7.32199 8.08834 7.62637 7.3644 8.16491 6.831C8.70345 6.2976 9.43232 5.99816 10.1921 5.99817H32.2746L38.3659 12.0603V26.4013"
          fill="#FFC200"
        />
        <path
          d="M38.3656 27.209C38.1505 27.209 37.9441 27.1239 37.792 26.9725C37.6398 26.8211 37.5544 26.6157 37.5544 26.4016V12.389L31.9444 6.79213H10.1919C9.64487 6.79213 9.12026 7.00839 8.73347 7.39333C8.34667 7.77827 8.12937 8.30037 8.12937 8.84476V13.9353C8.14437 14.0492 8.13477 14.165 8.10121 14.2749C8.06765 14.3848 8.0109 14.4863 7.93476 14.5727C7.85863 14.659 7.76485 14.7282 7.65969 14.7756C7.55454 14.823 7.44044 14.8475 7.325 14.8475C7.20957 14.8475 7.09546 14.823 6.99031 14.7756C6.88515 14.7282 6.79137 14.659 6.71524 14.5727C6.6391 14.4863 6.58235 14.3848 6.54879 14.2749C6.51523 14.165 6.50563 14.0492 6.52063 13.9353V8.84476C6.52425 7.87685 6.9122 6.94962 7.59991 6.2652C8.28762 5.58078 9.21931 5.19468 10.1919 5.19108H32.2744C32.3813 5.19007 32.4874 5.2101 32.5865 5.25002C32.6856 5.28994 32.7758 5.34897 32.8519 5.42371L38.9431 11.4858C39.0182 11.5616 39.0775 11.6513 39.1176 11.75C39.1578 11.8486 39.1779 11.9541 39.1769 12.0606V26.4016C39.1769 26.5076 39.1559 26.6126 39.1151 26.7106C39.0744 26.8085 39.0146 26.8975 38.9393 26.9725C38.8639 27.0475 38.7745 27.1069 38.6761 27.1475C38.5777 27.1881 38.4722 27.209 38.3656 27.209ZM7.31813 20.6953C7.10297 20.6953 6.89662 20.6102 6.74448 20.4588C6.59235 20.3074 6.50688 20.102 6.50688 19.8879V18.1911C6.49188 18.0772 6.50148 17.9614 6.53504 17.8515C6.5686 17.7415 6.62535 17.64 6.70149 17.5537C6.77762 17.4673 6.8714 17.3981 6.97656 17.3507C7.08171 17.3033 7.19581 17.2788 7.31125 17.2788C7.42669 17.2788 7.54079 17.3033 7.64594 17.3507C7.7511 17.3981 7.84488 17.4673 7.92101 17.5537C7.99715 17.64 8.0539 17.7415 8.08746 17.8515C8.12102 17.9614 8.13062 18.0772 8.11563 18.1911V19.929C8.10518 20.1336 8.01699 20.3266 7.86891 20.4688C7.72083 20.6111 7.52394 20.6921 7.31813 20.6953Z"
          fill="#997400"
        />
        <path
          d="M22.1134 46.8051H10.1921C9.42994 46.8051 8.69899 46.5037 8.16006 45.9674C7.62113 45.431 7.31836 44.7036 7.31836 43.9451V22.6251"
          fill="#FFC200"
        />
        <path
          d="M22.1131 47.6117H10.1918C9.21451 47.6117 8.27722 47.2253 7.58615 46.5375C6.89508 45.8497 6.50684 44.9169 6.50684 43.9443V22.638C6.53242 22.4437 6.62813 22.2653 6.77613 22.136C6.92413 22.0068 7.11431 21.9355 7.31121 21.9355C7.50811 21.9355 7.69829 22.0068 7.84629 22.136C7.99429 22.2653 8.09001 22.4437 8.11559 22.638V43.9443C8.11559 44.4887 8.33289 45.0108 8.71968 45.3957C9.10647 45.7807 9.63108 45.9969 10.1781 45.9969H22.0856C22.2808 46.0224 22.4601 46.1176 22.59 46.2649C22.7198 46.4122 22.7914 46.6015 22.7914 46.7974C22.7914 46.9934 22.7198 47.1827 22.59 47.33C22.4601 47.4773 22.2808 47.5725 22.0856 47.598L22.1131 47.6117Z"
          fill="#997400"
        />
        <path
          d="M38.3655 33.6673V43.9578C38.3655 44.7163 38.0627 45.4438 37.5238 45.9802C36.9848 46.5165 36.2539 46.8178 35.4917 46.8178H25.2617"
          fill="#FFC200"
        />
        <path
          d="M35.492 47.6119H25.262C25.0668 47.5864 24.8875 47.4911 24.7576 47.3438C24.6278 47.1966 24.5562 47.0073 24.5562 46.8113C24.5562 46.6154 24.6278 46.4261 24.7576 46.2788C24.8875 46.1315 25.0668 46.0363 25.262 46.0108H35.492C36.039 46.0108 36.5636 45.7945 36.9504 45.4096C37.3372 45.0247 37.5545 44.5026 37.5545 43.9582V33.6676C37.5801 33.4733 37.6758 33.2949 37.8238 33.1657C37.9718 33.0364 38.162 32.9652 38.3589 32.9652C38.5558 32.9652 38.746 33.0364 38.894 33.1657C39.042 33.2949 39.1377 33.4733 39.1633 33.6676V43.9582C39.1596 44.9261 38.7717 45.8533 38.084 46.5377C37.3963 47.2222 36.4646 47.6082 35.492 47.6119ZM38.3658 12.8676H32.2745C32.0594 12.8676 31.853 12.7826 31.7009 12.6312C31.5487 12.4798 31.4633 12.2744 31.4633 12.0603V5.99817H33.072V11.2529H38.3658V12.8676ZM38.3658 31.7245C38.1506 31.7245 37.9443 31.6394 37.7921 31.488C37.64 31.3366 37.5545 31.1312 37.5545 30.9171V28.7276C37.5801 28.5333 37.6758 28.3549 37.8238 28.2257C37.9718 28.0964 38.162 28.0252 38.3589 28.0252C38.5558 28.0252 38.746 28.0964 38.894 28.2257C39.042 28.3549 39.1377 28.5333 39.1633 28.7276V30.9171C39.1633 31.1289 39.0797 31.3322 38.9305 31.4832C38.7814 31.6343 38.5785 31.7209 38.3658 31.7245Z"
          fill="#997400"
        />
        <path
          d="M27.8334 26.4017H17.8509C17.6556 26.3762 17.4764 26.281 17.3465 26.1337C17.2166 25.9864 17.145 25.7971 17.145 25.6012C17.145 25.4052 17.2166 25.2159 17.3465 25.0687C17.4764 24.9214 17.6556 24.8261 17.8509 24.8007H27.8334C27.9478 24.7857 28.0642 24.7953 28.1746 24.8287C28.2851 24.8621 28.3871 24.9186 28.4739 24.9943C28.5606 25.0701 28.6301 25.1634 28.6778 25.2681C28.7254 25.3727 28.75 25.4863 28.75 25.6012C28.75 25.7161 28.7254 25.8296 28.6778 25.9343C28.6301 26.0389 28.5606 26.1323 28.4739 26.208C28.3871 26.2838 28.2851 26.3403 28.1746 26.3737C28.0642 26.4071 27.9478 26.4166 27.8334 26.4017ZM27.8334 18.4238H17.8509C17.6556 18.3984 17.4764 18.3031 17.3465 18.1558C17.2166 18.0085 17.145 17.8192 17.145 17.6233C17.145 17.4273 17.2166 17.2381 17.3465 17.0908C17.4764 16.9435 17.6556 16.8482 17.8509 16.8228H27.8334C27.9478 16.8078 28.0642 16.8174 28.1746 16.8508C28.2851 16.8842 28.3871 16.9407 28.4739 17.0164C28.5606 17.0922 28.6301 17.1855 28.6778 17.2902C28.7254 17.3948 28.75 17.5084 28.75 17.6233C28.75 17.7382 28.7254 17.8517 28.6778 17.9564C28.6301 18.061 28.5606 18.1544 28.4739 18.2301C28.3871 18.3059 28.2851 18.3624 28.1746 18.3958C28.0642 18.4292 27.9478 18.4387 27.8334 18.4238ZM27.8334 34.4754H17.8509C17.6556 34.4499 17.4764 34.3547 17.3465 34.2074C17.2166 34.0601 17.145 33.8708 17.145 33.6749C17.145 33.4789 17.2166 33.2896 17.3465 33.1423C17.4764 32.9951 17.6556 32.8998 17.8509 32.8743H27.8334C27.9478 32.8594 28.0642 32.869 28.1746 32.9024C28.2851 32.9358 28.3871 32.9922 28.4739 33.068C28.5606 33.1438 28.6301 33.2371 28.6778 33.3418C28.7254 33.4464 28.75 33.56 28.75 33.6749C28.75 33.7897 28.7254 33.9033 28.6778 34.008C28.6301 34.1126 28.5606 34.2059 28.4739 34.2817C28.3871 34.3575 28.2851 34.414 28.1746 34.4474C28.0642 34.4808 27.9478 34.4903 27.8334 34.4754Z"
          fill="#997400"
        />
      </svg>
    );
  };
  const handleOpenFinance = (name) => () => {
    if (name == 'SMR') {
      navigate(routes.FINANCING.pathname);
    } else {
      navigate(routes.OTHER_EQUIPMENT_FINANCING.pathname);
    }
  };
  return (
    <Dialog maxWidth="xs" fullWidth open={open} onClose={handleClose}>
      <DialogTitle onClose={handleClose}>
        <Typography
          variant="h4"
          className="!text-center !font-[Poppins] !font-semibold "
        >
          Apply for Finance
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid className="!flex -pb-2 items-center" lg={12} md={12} sm={12} item>
          {DocumentIcon()}
          <Typography className="!font-bold !font-[Space Grotesk] pl-2 !text-[24px] ">
            Document Required
          </Typography>
        </Grid>
        <Grid
          justifyContent={'center'}
          className="px-4 justify-center w-full "
          lg={12}
          md={12}
          sm={12}
          item
        >
          <Box className="pl-6  !max-w-[350px] ">
            
            <Typography className="!flex !items-baseline py-1">
              <FiberManualRecordIcon className="!text-gray-60 !text-[8px] pr-2 " />
              <Typography className="!text-sm">Stock number</Typography>
            </Typography>
            <Typography className="!flex !items-baseline py-1">
              <FiberManualRecordIcon className="!text-gray-60 !text-[8px] pr-2 " />
              <Typography className="!text-sm">Credit Application</Typography>
            </Typography>
            <Typography className=" !flex !items-baseline py-1">
              <FiberManualRecordIcon className="!text-gray-60 !text-[8px] pr-2" />
              <Typography className="!text-sm">
                Commercial Drivers License CDL front and back
              </Typography>
            </Typography>
            <Typography className=" !flex !items-baseline py-1">
              <FiberManualRecordIcon className="!text-gray-60 !text-[8px] pr-2" />
              <Typography className="!text-sm">
                Social security card front and back - Work Authorization Card
                front and back (if applicable)
              </Typography>
            </Typography>
            <Typography className=" !flex !items-baseline py-1">
              <FiberManualRecordIcon className="!text-gray-60 !text-[8px] pr-2 " />
              <Typography className="!text-sm">
                3 Months of latest bank statements
              </Typography>
            </Typography>
            <Typography className="  !flex !items-baseline py-1">
              <FiberManualRecordIcon className="!text-gray-60 !text-[8px] pr-2" />
              <Typography className="!text-sm">
                2 years most recent tax returns
              </Typography>
            </Typography>
            <Typography className=" !flex !items-baseline py-1">
              <FiberManualRecordIcon className="!text-gray-60 !text-[8px] pr-2" />
              <Typography className="!text-sm">
                Voided check for address and bank verification
              </Typography>
            </Typography>
            <Typography className=" !flex !items-baseline py-1">
              <FiberManualRecordIcon className="!text-gray-60 !text-[8px] pr-2" />
              <Typography className="!text-sm">
                If applying for financing through your business the Articles of
                Incorporation, and Employer Identification Number letters are
                required
              </Typography>
            </Typography>

            <Typography className=" !flex !items-baseline py-1">
              <FiberManualRecordIcon className="!text-gray-60 !text-[8px] pr-2" />
              <Typography className="!text-sm">Green Card(Optional)</Typography>
            </Typography>
            <Typography className=" !flex !items-baseline py-1">
              <FiberManualRecordIcon className="!text-gray-60 !text-[8px] pr-2" />
              <Typography className="!text-sm">Passports(Optional)</Typography>
            </Typography>
          </Box>
        </Grid>
        <Grid className="pt-4" spacing={2} container>
          <Grid lg={12} md={12} xs={12} item>
            <Button
              onClick={handleOpenFinance('SMR')}
              className="!w-full"
              variant="contained"
            >
              Sell My Rig Equipment
            </Button>
          </Grid>
          <Grid lg={12} md={12} xs={12} item>
            <Button
              onClick={handleOpenFinance('N-SMR')}
              className="h-14 !w-full"
              variant="outlined"
            >
              Non Sell My Rig Equipment
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
