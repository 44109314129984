import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import '../SMRR.css';
import { Box ,Grid,Divider} from '@mui/material';
import { useState } from 'react';



const accordianTitleStyle = {
    fontSize:"16px",
    fontWeight:'500',
    color:'#333333'
}
const accordianContentStyle = {
    fontSize:"16px",
    fontWeight:'400',
    color:'#757575'
}
const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    TransitionProps: { unmountOnExit: true },
    // border: `1px solid ${theme.palette.divider}`,
    minHeight: '90px',
    justifyContent: 'center',
    alignContent: 'center',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
}));

// const AccordionSummary = styled((props) => (
//     // console.log('myaccrdian Summary');
//     // return (
        

//     <MuiAccordionSummary
//         expandIcon={props.expanded === 'panel1' || props.expanded === 'panel2' || props.expanded === 'panel3' ?<img src={'/assets/icons/minus_expand.svg'} sx={{ fontSize: '0.9rem' }} />:
//         <img src={'/assets/icons/plus_expand.svg'} sx={{ fontSize: '0.9rem' }} />}
//         {...props}
//     />
// ))(({ theme }) => ({
//     paddingTop: '20px',
//     marginLeft: '-20px',

//     '& .MuiAccordionSummary-content': {
//         marginLeft: theme.spacing(4),
//     },
// }));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    paddingLeft: theme.spacing(4),

}));


const HelpCenterContent = () => {

    const [expanded, setExpanded] = useState(null);

    const handleChange = (panel) => (event, newExpand) => {
        // console.log({ expanded: event, panel: panel });
        setExpanded(newExpand ? panel : false);
    }

    return (
        <Box>
            <Grid container lg={11} md={12} xs={12} rowGap={4}>
                
                    <Grid item lg={12} md={12} xs={12}>
                        <Typography style={{marginLeft:'19px',color:'#06263A',fontWeight:'700',fontSize:'18px'}}>Hello,How can we help?</Typography>
                    </Grid>
                    <Grid item lg={12} md={12} xs={12}>
                       <Typography style={{marginLeft:'20px',color:'#545454',fontWeight:'700',fontSize:'16px'}}> FAQs</Typography>
                    </Grid>
                     
                <Grid item  lg={12} md={12} xs={12} style={{marginTop:'-5px'}}>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={expanded === 'panel1' ?<img src={'/assets/icons/minus_expand.svg'} sx={{ fontSize: '0.9rem' }} />:
                            <img src={'/assets/icons/plus_expand.svg'} sx={{ fontSize: '0.9rem' }} />}
                            // {...props}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{paddingTop:'20px'}}
                >

                    <Typography style={accordianTitleStyle}>What is Sell My Rig?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography style={accordianContentStyle}>
                -    Sell My Rig is a Used Commercial Truck Dealership. We are located in Fresno, 
                     California and expanding east. We sell Trucks and Trailers for local and long haul.
                     Our customer base began from larger fleets who wanted to recycle equipment quickly without the hassle of going through multiple channels, 
                     and expanded to the single owner operators and smaller fleet owners, after developing our application and web presence. 
                     Sell My Rig is the easiest way to buy or sell a rig! Download the app, browse our website, send us an email or text and give us a call!

                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Divider style={{ width: '97%',marginLeft:'20px'}} />
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                    expandIcon={expanded === 'panel2' ?<img src={'/assets/icons/minus_expand.svg'} sx={{ fontSize: '0.9rem' }} />:
                    <img src={'/assets/icons/plus_expand.svg'} sx={{ fontSize: '0.9rem' }} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    style={{paddingTop:'20px'}}
                >
                    <Typography style={accordianTitleStyle}>Can I call instead of using the app?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography style={accordianContentStyle}>
                    - Yes of course, our team members are always ready to help. You may choose any and all methods of communication. 
                      Call, Text, WhatsApp, and Email.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Divider style={{ width: '97%',marginLeft:'20px'}} />
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                    expandIcon={expanded === 'panel3' ?<img src={'/assets/icons/minus_expand.svg'} sx={{ fontSize: '0.9rem' }} />:
                    <img src={'/assets/icons/plus_expand.svg'} sx={{ fontSize: '0.9rem' }} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    style={{paddingTop:'20px'}}
                >
                    <Typography style={accordianTitleStyle}>Is there a free trial available?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography style={accordianContentStyle}>
                    - Sell My Rig is free to download and use.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Divider style={{ width: '97%',marginLeft:'20px'}} />
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary
                    expandIcon={expanded === 'panel4' ?<img src={'/assets/icons/minus_expand.svg'} sx={{ fontSize: '0.9rem' }} />:
                    <img src={'/assets/icons/plus_expand.svg'} sx={{ fontSize: '0.9rem' }} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    style={{paddingTop:'20px'}}
                >
                    <Typography style={accordianTitleStyle}>Do I have to make an account?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography style={accordianContentStyle}>
                    - You do not have to make an account. You will be able to search equipment without an account, but nothing further.
                      We do encourage you to make an account in order to take advantage of all our features. The app is free to download and use. 
                      Sell My Rig LLC does not sell your information in any way shape or form.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Divider style={{ width: '97%',marginLeft:'20px'}} />
            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary
                    expandIcon={expanded === 'panel5' ?<img src={'/assets/icons/minus_expand.svg'} sx={{ fontSize: '0.9rem' }} />:
                    <img src={'/assets/icons/plus_expand.svg'} sx={{ fontSize: '0.9rem' }} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    style={{paddingTop:'20px'}}
                >
                    <Typography style={accordianTitleStyle}>What is Your Cancellation Policy?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography style={accordianContentStyle} variantMapping={{ br: 'Typography' }}>
                   - If you don’t like what you see, you may deactivate your account in the settings tab under the accounts page.<br />
                   - We would love to hear feed back, give us a call or send an email to tell us how we can improve our services.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Divider style={{ width: '97%',marginLeft:'20px'}} />
            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <AccordionSummary
                    expandIcon={expanded === 'panel6' ?<img src={'/assets/icons/minus_expand.svg'} sx={{ fontSize: '0.9rem' }} />:
                    <img src={'/assets/icons/plus_expand.svg'} sx={{ fontSize: '0.9rem' }} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    style={{paddingTop:'20px'}}
                >
                    <Typography style={accordianTitleStyle}>Is Sell My Rig LLC affiliated with the manufacturers shown?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography style={accordianContentStyle} variantMapping={{ br: 'Typography' }}>
                    - No. Sell My Rig is not affiliated or associated with any of the Truck and or Trailer manufactures shown on this application or the Sell My Rig website.
                      Sell My Rig does not imply or express any association, affiliation or relationship with any commercial equipment manufacturer. Sell My Rig is not trying to give any false impression of connection, approval or sponsorship by any manufacturers see on this application.<br />
                    - The Logos and Artwork you see before you in this application are the copyrighted and registered intellectual property of its respective owners. Sell My Rig is displaying these logos only for comparative advertising and descriptive purposes. 
                     We do not use logos, names and artwork of other companies and businesses as our trademark or our property.<br />
                    - The equipment which is sold by Sell My Rig to the public or to other businesses, is not sourced directly from any manufacturer seen in this application. 
                    Sell My Rig is a Used Commercial Truck Dealership, we do not and can not purchase directly from manufacturers.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Divider style={{ width: '97%',marginLeft:'20px'}} />
            
            <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                <AccordionSummary
                    expandIcon={expanded === 'panel7' ?<img src={'/assets/icons/minus_expand.svg'} sx={{ fontSize: '0.9rem' }} />:
                    <img src={'/assets/icons/plus_expand.svg'} sx={{ fontSize: '0.9rem' }} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    style={{paddingTop:'20px'}}
                >
                    <Typography style={accordianTitleStyle}>Is Sell My Rig LLC affiliated with Slate Financial LLC?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography style={accordianContentStyle} variantMapping={{ br: 'Typography' }}>
                    - Sell My Rig LLC does not sell or create any financial product(s), we simple sell equipment. 
                      You may choose to use our premier financial partner Slate Financial LLC to apply for financing all in one place or you may procure your own financing and simply buy a rig from us!.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Divider style={{ width: '97%',marginLeft:'20px'}} />

            <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                <AccordionSummary
                    expandIcon={expanded === 'panel8' ?<img src={'/assets/icons/minus_expand.svg'} sx={{ fontSize: '0.9rem' }} />:
                    <img src={'/assets/icons/plus_expand.svg'} sx={{ fontSize: '0.9rem' }} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    style={{paddingTop:'20px'}}
                >
                    <Typography style={accordianTitleStyle}>Is my information safe on this application or website?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography style={accordianContentStyle} variantMapping={{ br: 'Typography' }}>
                    - Our application and website is fully encrypted and secure with the latest technology.
                      Your uploaded documents are safe and will not be shared with anyone other than the concerned party. 
                      The concerned parties are Sell My Rig (the used equipment dealership you are purchasing equipment from), 
                      and Slate Financial LLC (only if you choose to use their services for financing the equipment in question, 
                      if you do not choose Slate Financial LLC for your financing needs, no information will be sent to Slate Financial LLC).
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Divider style={{ width: '97%',marginLeft:'20px'}} />
             
            <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                <AccordionSummary
                    expandIcon={expanded === 'panel9' ?<img src={'/assets/icons/minus_expand.svg'} sx={{ fontSize: '0.9rem' }} />:
                    <img src={'/assets/icons/plus_expand.svg'} sx={{ fontSize: '0.9rem' }} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    style={{paddingTop:'20px'}}
                >
                    <Typography style={accordianTitleStyle}>How do I start looking for equipment?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography style={accordianContentStyle} variantMapping={{ br: 'Typography' }}>
                    - After downloading the application, open it on your phone or tablet. 
                      After a moment a page asking you to get started or log in will appear.
                      Choose get started to open the app right away and start looking for your next rig!<br />
                    - On our website www.SellMyRig.
                      com you may browse inventory as well.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Divider style={{ width: '97%',marginLeft:'20px'}} />
             
            <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                <AccordionSummary
                    expandIcon={expanded === 'panel10' ?<img src={'/assets/icons/minus_expand.svg'} sx={{ fontSize: '0.9rem' }} />:
                    <img src={'/assets/icons/plus_expand.svg'} sx={{ fontSize: '0.9rem' }} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    style={{paddingTop:'20px'}}
                >
                    <Typography style={accordianTitleStyle}>Looking for equipment in your area?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography style={accordianContentStyle} variantMapping={{ br: 'Typography' }}>
                    - On the top left corner you will see a zip code. 
                    -You may choose to search by zip code, and if equipment is available in that area it will be shown to you.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Divider style={{ width: '97%',marginLeft:'20px'}} />
             
             <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
                 <AccordionSummary
                     expandIcon={expanded === 'panel11' ?<img src={'/assets/icons/minus_expand.svg'} sx={{ fontSize: '0.9rem' }} />:
                     <img src={'/assets/icons/plus_expand.svg'} sx={{ fontSize: '0.9rem' }} />}
                     aria-controls="panel2a-content"
                     id="panel2a-header"
                     style={{paddingTop:'20px'}}
                 >
                     <Typography style={accordianTitleStyle}>What happens after I ask for a quote on a listing?</Typography>
                 </AccordionSummary>
                 <AccordionDetails>
                     <Typography style={accordianContentStyle} variantMapping={{ br: 'Typography' }}>
                     -  After a quote is requested on a listing, a member from our team will see your notification and basic information.<br />
                     -  You will be sent a notification stating we are working on your request and that you will be contacted shortly.<br />
                     - After your discussion with the team, or before that,
                        you may choose to hold the equipment you like.<br />
                     - To hold the equipment you must make a deposit, which is refundable. 
                        You may put that deposit towards other equipment as well should you choose to change the asset.<br />
                     - After the Rig is held, we will require you to provide payment,
                        you must pay cash, procure finance from your own source or request financing from Slate Financial LLC.<br />
                     - Until Sell My Rig LLC is paid in full for the equipment in question,
                        delivery of the asset will not be permitted under any circumstances.
                     </Typography>
                 </AccordionDetails>
             </Accordion>
 
             {/* <Divider style={{ width: '97%',marginLeft:'20px'}} />

             <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
                <AccordionSummary
                    expandIcon={expanded === 'panel12' ?<img src={'/assets/icons/minus_expand.svg'} sx={{ fontSize: '0.9rem' }} />:
                    <img src={'/assets/icons/plus_expand.svg'} sx={{ fontSize: '0.9rem' }} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    style={{paddingTop:'20px'}}
                >
                    <Typography style={accordianTitleStyle}>Looking for equipment in your area?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography style={accordianContentStyle} variantMapping={{ br: 'Typography' }}>
                    - On the top left corner you will see a zip code. 
                      You may choose to search by zip code, and if equipment is available in that area it will be shown to you.
                    </Typography>
                </AccordionDetails>
            </Accordion> */}

            <Divider style={{ width: '97%',marginLeft:'20px'}} />
             
             <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
                 <AccordionSummary
                     expandIcon={expanded === 'panel12' ?<img src={'/assets/icons/minus_expand.svg'} sx={{ fontSize: '0.9rem' }} />:
                     <img src={'/assets/icons/plus_expand.svg'} sx={{ fontSize: '0.9rem' }} />}
                     aria-controls="panel2a-content"
                     id="panel2a-header"
                     style={{paddingTop:'20px'}}
                 >
                 <Typography style={accordianTitleStyle}>How do I SELL MY RIG!?</Typography>
                 </AccordionSummary>
                 <AccordionDetails>
                     <Typography style={accordianContentStyle} variantMapping={{ br: 'Typography' }}>
                     -  Click on the sell tab on the bottom of the application home screen. <br />
                     - A pop up screen will give you a basic run down of the sell and listing process.Click continue   <br />
                    - You will be asked to upload photos of your equipment. Please make sure your photos are clear and not unnecessarily angled. 
                        Keep it simple.<br /> 
                     - Please make sure to add photos of your odometer, hour meter, VIN sticker, Engine Family Number Sticker or Serial Number Plate along with exterior and interior photos.<br />
                     - Along with photos of your truck, some basic information will be requested to make sure you are the registered owner.<br />
                     - Once this is complete we will receive a notification that you want to sell your rig,
                       we then will review your application and photos for completeness and contact you on how to proceed with the listing.
                     </Typography>
                 </AccordionDetails>
             </Accordion>
 
             <Divider style={{ width: '97%',marginLeft:'20px'}} />

             <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
                 <AccordionSummary
                     expandIcon={expanded === 'panel13' ?<img src={'/assets/icons/minus_expand.svg'} sx={{ fontSize: '0.9rem' }} />:
                     <img src={'/assets/icons/plus_expand.svg'} sx={{ fontSize: '0.9rem' }} />}
                     aria-controls="panel2a-content"
                     id="panel2a-header"
                     style={{paddingTop:'20px'}}
                 >
                 <Typography style={accordianTitleStyle}>Do I receive any warranty on the equipment?</Typography>
                 </AccordionSummary>
                 <AccordionDetails>
                     <Typography style={accordianContentStyle} variantMapping={{ br: 'Typography' }}>
                     - Unless Sell My Rig LLC specifically provides in writing and that specified warranty document is stamped and signed off by the respective administrative personnel,
                      no warranty is implied or expressed to be provided on any unit sold by or on the behalf of Sell My Rig LLC by its salespersons and or employees. 
                     </Typography>
                 </AccordionDetails>
             </Accordion>
 
             <Divider style={{ width: '97%',marginLeft:'20px'}} />

             <Accordion expanded={expanded === 'panel14'} onChange={handleChange('panel14')}>
                 <AccordionSummary
                     expandIcon={expanded === 'panel14' ?<img src={'/assets/icons/minus_expand.svg'} sx={{ fontSize: '0.9rem' }} />:
                     <img src={'/assets/icons/plus_expand.svg'} sx={{ fontSize: '0.9rem' }} />}
                     aria-controls="panel2a-content"
                     id="panel2a-header"
                     style={{paddingTop:'20px'}}
                 >
                     <Typography style={accordianTitleStyle}>What happens after I ask for financing from Slate Financial LLC?</Typography>
                 </AccordionSummary>
                 <AccordionDetails>
                     <Typography style={accordianContentStyle} variantMapping={{ br: 'Typography' }}>
                     -  Make sure you copy the stock number from the listing, you will need it when applying for financing. <br />
                     - After you click request financing you must add the stock number<br />
                       - After the stock number the next document you see will be the credit application. If you are applying under your business you fill in everything from the top. 
                       If you are not applying under the business then start from the second portion. Fill in everything you can and all sections marked with a red (*) must be filled. 
                       The haul sources section must be filled with 3 business haul sources and phone numbers not personal names.<br />
                      - Next you must submit the remaining financial documents to Slate Financial LLC so they may complete a soft underwrite on your application. 
                       It would be best practice to have the documents saved and ready on your devices files (not in the cloud) before you begin submissions.<br />
                      - You can find the list of documents you will need after you click the request finance button.<br />
                      - Once you have uploaded all the request documents you will receive a call from a member of Slate Financial LLC to discuss your application.
                      - Sell My Rig LLC does not create, participate in or sell any financial product(s),
                      you must procure your own financing or use Slate Financial LLC their phone number is 559-708-4476 .
                     </Typography>
                 </AccordionDetails>
             </Accordion>
 
             <Divider style={{ width: '97%',marginLeft:'20px'}} />

             <Accordion expanded={expanded === 'panel15'} onChange={handleChange('panel15')}>
                 <AccordionSummary
                     expandIcon={expanded === 'panel15' ?<img src={'/assets/icons/minus_expand.svg'} sx={{ fontSize: '0.9rem' }} />:
                     <img src={'/assets/icons/plus_expand.svg'} sx={{ fontSize: '0.9rem' }} />}
                     aria-controls="panel2a-content"
                     id="panel2a-header"
                     style={{paddingTop:'20px'}}
                 >
                     <Typography style={accordianTitleStyle}>If I like something I see, how can I see it in person?</Typography>
                 </AccordionSummary>
                 <AccordionDetails>
                     <Typography style={accordianContentStyle} variantMapping={{ br: 'Typography' }}>
                     - If you would like to see a specific truck or trailer in person, give us a call or send us an email.<br />
                     - Let a team member know you want to see the equipment, specify the stock number and we will send you a lot location of where the unit is at.
                       You can reach out to a team member at that location and see your desired rig.
                     </Typography>
                 </AccordionDetails>
             </Accordion>
             <Divider style={{ width: '97%',marginLeft:'20px'}} />
                </Grid>
            </Grid>
           
        </Box>
    )
}

export default HelpCenterContent;