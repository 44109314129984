import React from 'react';
import { useNavigate } from 'react-router';
import { routes } from 'routes/RouteConstants';
import Modal from '@mui/material/Modal';
import { Button, Grid, Typography } from "@mui/material";
import done from 'utils/gif/done.json'; 
import Lottie from 'react-lottie-player';
import '../SMRR.css';


const btnStyle = {
  fontFamily: 'Inter',
  color: '#B0B0B0',
  fontWeight: '500',
  fontSize: '16px',
  disableRipple: true,
  backgroundColor: '#fff',
  borderRadius: '8px',
  border: '1px solid #D0D5DD',
  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
  width:'100%',
  height:"54px",
};
const btnCancelStyle = {
  fontFamily: 'Inter',
  color: '#0F0F0F',
  fontWeight: '500',
  fontSize: '16px',
  disableRipple: true,
  borderRadius: '8px',
  backgroundColor: '#FFC200',
  border: '1px solid #D0D5DD',
  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
  width:'100%',
  height:"54px",
};
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  maxWidth: '420px',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow:
    '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
  borderRadius: '12px',
  padding: '20px 20px',
};
const fontStyle = {
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '20px',
  textAlign: 'center',
  color: '#757575',
  marginTop: '10px',
  marginBottom: '50px',
};

//Modal

const PaymentMsgModal = (props) => {
  const navigate = useNavigate();
  const { open, setClose, modelData } = props;
  const handleClose = () => props.setClose(setClose(false));
 
  
  const handleBacktoHome = () =>  {
    props.setClose(setClose(false));
    navigate(routes.REDIRECTOR);
  };
  
  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={style}
          justifyContent="center"
          style={{ maxWidth: '470px',padding: '20px 40px' }}
        >
           {modelData?.type==='success' ? (
              <>
              <Grid item justifyContent="center">
                <Typography
                  style={{                    
                    textAlign: 'center',
                    padding: '0 20px',                    
                  }}
                >
                   <Lottie            
                      animationData={done}
                      play
                      style={{ width: 200, height: 200, margin:'auto'}}
                    />
                </Typography>          
                <Typography
                  style={{
                    fontSize: '18px',
                    fontWeight: '500',
                    color: '#05945B',
                    textAlign: 'center',
                    padding: '0 20px',
                    marginTop: '10px',
                  }}
                >              
                {modelData?.first_message}
                </Typography>          
                <Typography style={fontStyle}>
                  {modelData?.second_message}
                </Typography>                
              </Grid>

              <Grid
                item
                className="flex"
                sx={{ width: '100%' }}
                justifyContent="space-between"
                alignItems="center"
              >               
                <Button                    
                  style={btnStyle}
                  onClick={() => handleBacktoHome()}
                >
                  Back to Home
                </Button>
              </Grid>
              </>
              ) : (
              <>
              <Grid item justifyContent="center">
              <Typography
                  style={{                    
                    textAlign: 'center',
                    padding: '0 20px',                    
                  }}
                >
                  <img src='assets/icons/failed-card.svg' alt='failed' style={{width: '200px'}} />
                </Typography>
                <Typography
                  style={{
                    fontSize: '20px',
                    fontWeight: '600',
                    color: '#DC2804',
                    textAlign: 'center',
                    padding: '0 20px',
                    marginTop: '40px',
                  }}
                >              
                {modelData?.first_message}
                </Typography>          
                <Typography style={fontStyle}>
                  {modelData?.second_message}
                </Typography>                
              </Grid>

              <Grid
                item
                className="flex"
                sx={{ width: '100%' }}
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                  className=""
                  style={btnCancelStyle}
                  onClick={() => handleClose()}
                >
                 Go Back
                </Button>          
              </Grid>
              </>
              )
            }         
        </Grid>
      </Modal>
    </div>
  );
};
export default PaymentMsgModal;
