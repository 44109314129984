import React, { useState } from 'react';
import { Grid } from "@mui/material";


const HeaderTab = (props) => {

    const [currentType, setCurrentType] = useState('Quote');
    const handleType = (name) => {
      setCurrentType(name);
     props.ontabChange(name);
    };

    return(
        <Grid container lg={12} md={12} sm={12} xs={12}>
          <Grid item>
            <button
              className={`${
                currentType === 'Quote'
                  ? 'active-btn-Home-Hero  '
                  : 'inactive-btn-Home-Hero '
              } rounded-l-md rounded-l-2 cursor-pointer`}
              onClick={() => handleType('Quote')}
            >
              Deposits
            </button>
            <button
              className={`${
                currentType === 'Inspection'
                  ? 'active-btn-Home-Hero  '
                  : 'inactive-btn-Home-Hero '
              } rounded-r-md rounded-l-2 cursor-pointer`}
              onClick={() => handleType('Inspection')}
            >
              Inspection Payments
            </button>
          </Grid>
        </Grid>
    )
}
export default HeaderTab;