import { CircularProgress, Grid, Typography } from '@mui/material';
import CustomButton from 'components/CustomButton/CustomButton';
import React, { useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OtpInput from 'react-otp-input'
import { useNavigate } from 'react-router';
import { formatPhoneNumber, formatePhoneNumber } from 'utils/phone';
import { ToastContainer } from 'react-toastify';
import useToast from 'utils/responseToast';
import { useSendOtpQuery, useVerifyOtp } from 'query/Auth';
import { useRefContext } from 'components/Layout';
import { PhoneIcon, emailIcon } from '../Signup/Signup';
 

export const OTPForm = ({
  prefilled,
  otpError,
  setOtpError,
  setforgotPassword,
  data,
  currentType,
  setOpenNewPasswordForm,
  setOpenOtp,
}) => {
  //console.log(data);

  const { handleOpenSomethingWentWrongDialog } = useRefContext();

  const navigate = useNavigate();
  const [otp, setotp] = useState('');
  const [resendOtpCount, setResendOtpCount] = useState(60);

  const [storeInterval, setStoreInterval] = useState();
  const toast = useToast();

  const [loading, setLoading] = useState(false);
  const sendOtpOnSuccess = (message) => {
    setLoading(false);
    setOpenOtp(true);
  };

  const sendOtpOnError = (err) => {
    setOtpError(err.errors[0].message);
    setLoading(false);
  };
  const onSuccessOtpVerification = (res) => {
    setOpenOtp(false);
    setOpenNewPasswordForm(true);
  };

  const onErrorOtpVerification = (err) => {
    if (err.status_code === 500) {
      handleOpenSomethingWentWrongDialog();
    }
    countDown();
    setOtpError(err.errors[0].message);
  };
  const { mutate: verifyOtp, isLoading: otpButtonLoading } = useVerifyOtp(
    onSuccessOtpVerification,
    onErrorOtpVerification
  );
  const { mutate: sendOtp, isLoading: sendingOtp } = useSendOtpQuery(
    sendOtpOnSuccess,
    sendOtpOnError
  );

  const secondConversion = (duration) => {
    const mins = ~~((duration % 3600) / 60);
    const secs = ~~duration % 60;

    let ret = '';
    ret += '' + `0${mins}` + ':' + (secs < 10 ? '0' : '');
    ret += '' + secs;

    return ret;
  };

  useEffect(() => {
    countDown();
  }, []);

  const countDown = () => {
    let intervalId = setInterval(() => {
      if (resendOtpCount == 0) {
        clearInterval(intervalId);
        return;
      }
      setResendOtpCount((prev) => {
        if (prev > 0) {
          return prev - 1;
        } else {
          return 0;
        }
      });
    }, 1000);

    setStoreInterval(intervalId);
  };

  const handleResendOtp = () => {
    setResendOtpCount(60);
    sendOtp(prefilled);
    countDown();
  };
  const handleVerifyOtp = () => {
    setLoading(true);
    if (otp) {
      setLoading(false);
      clearInterval(storeInterval);
      verifyOtp({ ...prefilled, otp: otp });
    } else {
      setLoading(false);
      setOtpError('Please enter otp');
    }
  };
  const handleBacktoLogin = () => {
    setOtpError('');
    clearInterval(storeInterval);
    setforgotPassword(false);
  };

  return (
    <Grid container>
      <Grid
        justifyContent="center"
        container
        item
        className="max-w-[456px]  "
      >
        <Grid
          item
          container
          // className="bg-[#A8CDE5] !w-[60px] h-[60px] rounded-full m-auto"
          justifyContent="center"
          alignItems="center"
        >
          {currentType === 'email' ? emailIcon() : PhoneIcon()}
        </Grid>
        <Grid item className="text-center">
          <Typography variant="h5" className="!font-[inter] font-semibold py-2">
            Check your {currentType}
          </Typography>
          <Typography variant="subtitle2" className="!font-[inter] py-5">
            A verification code was sent to { currentType=='mobile'? formatPhoneNumber(data?.emailPh,true): data?.emailPh}
          </Typography>
        </Grid>
        <Grid className="w-full" item container justifyContent="center">
        <Grid className="w-full "  item justifyContent="center" container>
          <OtpInput
            value={otp}
            onChange={setotp}
            numInputs={4}
            // onPaste={(e)=>{
            //   console.log(e)
            // }}
            inputType='number'
            inputStyle={{ height: '70px', width: '70px', fontSize: '30px', borderRadius:'8px' , border:'1px solid rgba(209, 213, 219, 1)' ,margin:'12px'}}
            renderSeparator={<span className='!w-4 !h-8' >{" "}</span>}
            renderInput={(props) => <input   {...props}  />}
             
           />
          </Grid>
          {otpError && (
            <Typography className="animateErrorValue pt-2 text-red-500 text-center text-xs">
              {otpError}
            </Typography>
          )}
        </Grid>
        <Grid className="w-full" item>
          {otpButtonLoading ? (
            <CustomButton
              styles="max-h-[44px] max-w-[360px] !w-full !text-[15px] font-medium my-5"
              fullWidth
            >
              <CircularProgress size={30} className="!text-white" />
            </CustomButton>
          ) : (
            <CustomButton
              onClick={otp.length == 4 ? handleVerifyOtp : () => {}}
              disabled={otp.length == 4 ? false : true}
              styles="h-[40px] !text-[15px] font-medium my-5"
              fullWidth
            >
              Continue
            </CustomButton>
          )}
        </Grid>
        <Grid className="text-center" item>
          <Typography className="!font-[inter] text-[12px]">
            Please do not share this code with anyone. Sell My Rig and its
            associates will never ask for your verification code.
          </Typography>
        </Grid>
      </Grid>
      <Grid>
        <Grid
          gap={4}
          lg={12}
          md={12}
          xs={12}
          justifyContent="center"
          container
          item
        >
          <Grid justifyContent="center" container lg={12} md={12} xs={12} item>
            <Typography className="!font-[inter] text-sm pt-3">
              {' '}
              Didn't recieve the{' '}
              {currentType == 'email'
                ? 'email'
                : currentType == 'mobile'
                ? 'message'
                : ''}?
              <span className="text-sm font-medium text-secondary-70 cursor-pointer pl-1">
                {resendOtpCount == 0 ? (
                  sendingOtp ? (
                    <CircularProgress size={20} className="!text-white" />
                  ) : (
                    <span onClick={handleResendOtp}>Resend otp</span>
                  )
                ) : (
                  `${secondConversion(resendOtpCount)}`
                )}
              </span>
            </Typography>
          </Grid>
          <Grid
            className="pt-6 "
            onClick={handleBacktoLogin}
            item
            justifyContent="center"
            container
            lg={12}
            md={12}
            xs={12}
          >
            <ArrowBackIcon className="px-1 text-gray-90" />
            <Typography className="!font-[inter] text-sm pb-4 justify-center ">
              <span className="text-sm font-medium cursor-pointer text-gray-90 ">
                Back to Log in
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
