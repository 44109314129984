import React, { useState, useEffect } from "react";
import ReactPlayer from 'react-player'
import { Grid } from "@material-ui/core";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import { Image } from 'antd';
import "swiper/css/navigation";
import "swiper/css/thumbs";
import {FreeMode, Navigation, Thumbs } from "swiper";
import SwiperCore, { Pagination } from 'swiper/core';
import { MicNone } from "@mui/icons-material";

const swiperStyle = {
    height:'300px',
    width:'380px',
    className:"swiperImgWd"
    
}

SwiperCore.use([Pagination]);

const ImagSliderComponent = (props) => {
    const[imagArrData,setImageArrData] = useState([]);
    const [isPlaying, setIsPlaying] = useState(null);   

    useEffect(()=>{      
        setImageArrData(props?.vehicalInfo?.data?.attachments)        
     },[props?.vehicalInfo?.data?.attachments]);    
 
    const [thumbsSwiper, setThumbsSwiper] = useState('');
    let slides =[];
    let subSlides =[];
    let slidesImage;
    let slidesVideo;
  let slidesVideoSub;
  let imageItems = [];

if (imagArrData && imagArrData?.length > 0) {
  imageItems = imagArrData
    ?.filter((el) =>
      [
        'IMAGE',
        'ENGINE_STICKER',
        'ENGINE',
        'FRONT',
        'BACK',
        'LEFT_DRIVE_TYRE',
        'RIGHT_DRIVE_TYRE',
        'LEFT_REAR_TYRE',
        'RIGHT_REAR_TYRE',
        'LEFT',
        'RIGHT',
        'ODOMETER',
        'HOURS_METER',
        'FULL_DASH',
        'SLEEPER_AREA',
        'ENGINE_FAMILY_NUMBER',
        'REEFER_SERIAL_NUMBER',
        'INSIDE_TRAILER',
      ]?.includes(el.description)
    )
    ?.map(
      (srcArr) =>
        srcArr.attachment || '/assets/landingPage/Truck_placeholder.jpg'
    );
}
    if (imagArrData && imagArrData.length > 0) {
      slidesImage = imagArrData
        ?.filter(
          (el) =>
            el.description === 'IMAGE' ||
            el.description === 'ENGINE_STICKER' ||
            el.description === 'ENGINE' ||
            el.description === 'FRONT' ||
            el.description === 'BACK' ||
            el.description === 'LEFT_DRIVE_TYRE' ||
            el.description === 'RIGHT_DRIVE_TYRE' ||
            el.description === 'LEFT_REAR_TYRE' ||
            el.description === 'RIGHT_REAR_TYRE' ||
            el.description === 'LEFT' ||
            el.description === 'RIGHT' ||
            el.description === 'ODOMETER' ||
            el.description === 'HOURS_METER' ||
            el.description === 'FULL_DASH' ||
            el.description === 'SLEEPER_AREA' ||
            el.description === 'ENGINE_FAMILY_NUMBER' ||
            el.description === 'REEFER_SERIAL_NUMBER' ||
            el.description === 'INSIDE_TRAILER'
        )
        .map((srcArr, index) => (
          <SwiperSlide key={srcArr.id}>
            {/* <img
              src={srcArr.attachment}
              alt={`Slide ${index + 1}`}
              className="SwiperImgTb"
            /> */}
            <Image.PreviewGroup items={imageItems}>
              <Image
                alt={`Slide ${index + 1}`}
                className="SwiperImgTb"
                src={srcArr.attachment}
              />
            </Image.PreviewGroup>
          </SwiperSlide>
        ));
      slidesVideo = imagArrData?.filter((el) => (el.description==='VIDEO')).map((srcArr, index) => (
        <SwiperSlide key={srcArr.id}>
          <div style={{position: 'relative',width:'100%'}}>
              <div style={{position: 'absolute',top: "0px", zIndex: '10', width:'100%',height:'70%'}}></div>
              <ReactPlayer
                key={srcArr.id}
                width={'100%'}
                height={'90%'}          
                url={srcArr.attachment}
                controls={true}
                onPlay={() => {
                  setIsPlaying(srcArr.id);
                }}
                playing={isPlaying === srcArr.id}
              />
           </div>
         </SwiperSlide>     
      ));

      slidesVideoSub = imagArrData?.filter((el) => (el.description==='VIDEO')).map((srcArr, index) => (
        <SwiperSlide key={srcArr.id}> 
            <video src={srcArr.attachment} style={{width: '100%', height: '100%'}} preload="auto"></video>         
         </SwiperSlide>     
      ));
     

      slides.push(slidesImage);
      slides.push(slidesVideo);

      subSlides.push(slidesImage);
      subSlides.push(slidesVideoSub);
    } else {      
      for (let i = 0; i < 4; i++) {
        slides.push(
          <SwiperSlide>
            <div
              style={{
                backgroundColor: '#ccc',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            > </div>
          </SwiperSlide>
        );
        subSlides.push(
          <SwiperSlide>
            <div
              style={{
                backgroundColor: '#ccc',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            > </div>
          </SwiperSlide>
        );
      }
    }         

    return (
        <>
          <Grid container >
            <Grid item  lg={11} md={12} sm={12} style={{justifyContent:'center',alignItems:'center',padding:'5px',marginLeft:'5px'}} className="md:!ml-0 lg:!ml-[5px]"> 
              <Swiper
                  style={swiperStyle}
                  pagination={{
                      clickable: true,
                      // type: "fraction",
                    }}
                  spaceBetween={10}
                  navigation={false}
                  thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null}}
                  modules={[Pagination,Thumbs]}
                  className="mySwiper2 swaperMb"
              >
                {slides}                
              </Swiper>                    
              <Swiper
                style={{ height: '71px', width: '380px', paddingTop: '10px' }}
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={4}
                freeMode={true}                
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper miniImg"
              >
                {subSlides}
              </Swiper>                    
            </Grid>
          </Grid>
        </>
    )
}
export default ImagSliderComponent;