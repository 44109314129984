import React, { useEffect, useState, useRef } from 'react';
import {
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
  Skeleton,
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useGetTransactionInspectionData } from 'query/Transactions';
import { insertCommas } from '../../../utils/USCurrencyFormat';
import Status from './Status';
import NoDataFoundIcon from 'components/NoDataFoundIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#FCFCFC',
    /* Shadow 01 */

    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
    borderRadius: '10px',
    width: '95%',
    minHeight: '100px',
    paddingBottom: '10px',
  },
  image: {
    minWidth: '80px',
    height: '70px',
    borderRadius: theme.spacing(1),
  },
  title: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#333333',
    fontFamily: 'Inter',
  },
  description: {
    fontSize: '14px',
    fontWeight: '400',
    color: '#545454',
    fontFamily: 'Poppins',
  },
  transactionId: {
    fontSize: '14px',
    fontWeight: '400',
    color: '#0F5E91',
    fontFamily: 'Inter',
  },
  amount: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#DC2804',
    fontFamily: 'Inter',
  },
  text: {
    color: theme.palette.text.secondary,
  },
}));

const getTime = (date) => {
  const formattedDate = new Date(date);

  let hours = formattedDate.getHours();

  hours = hours % 12 || 12;

  const minutes = formattedDate.getMinutes();
  let mintuesString = minutes.toString();
  if (mintuesString?.length < 2) {
    mintuesString = '0' + mintuesString;
  }

  let amOrPm = hours >= 12 ? 'pm  ' : 'am';

  const timeString = hours + ':' + mintuesString + ' ' + amOrPm;
  return timeString;
};
const getDate = (date) => {
  const dateIn = new Date(date);
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  const formattedDate = dateIn.toLocaleString('en-US', options);
  return formattedDate;
};

const ContentInspection = () => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const img = process.env.PUBLIC_URL + '/Vehical.png';
  const classes = useStyles();

  //api call
  const [isPage, setIsPage] = useState(1);
  const {
    data: InventoryDataResponse,
    refetch,
    isLoading,
  } = useGetTransactionInspectionData(isPage);

  const swiperRef = useRef();
  const mydata = InventoryDataResponse?.results || [];

  useEffect(() => {
    refetch(isPage);
  }, [refetch]);

  return (
    <>
      {isLoading ? (
        <Grid container lg={12} md={12} xs={12}>
          <Grid item lg={12} md={12} xs={12} className="w-[100%]">
            <Skeleton height="130px" />
          </Grid>
        </Grid>
      ) : (
        <>
          {mydata?.length > 0 ? (
            <>
              {mydata?.map((qouteData, index) => {
                return (
                  <Box className={classes.root} marginTop={2}>
                    <Grid
                      container
                      lg={12}
                      sm={12}
                      md={12}
                      xs={12}
                      key={index}
                      paddingLeft={isMedium && isSmall ? '' : 2}
                      paddingTop={4}
                    >
                      <Grid
                        item
                        container
                        lg={12}
                        sm={12}
                        md={12}
                        xs={12}
                        className=""
                      >
                        <Grid item lg={2} md={6} xs={12} sm={10} className="">
                          <Grid item container rowGap={1}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <Typography className={classes.title}>
                                Product
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={11}>
                              <Typography className={classes.description}>
                                {qouteData?.listing?.title}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item lg={2} md={6} xs={12} sm={10}>
                          <Grid item container rowGap={1}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <Typography className={classes.title}>
                                Txn Date
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={11}>
                              <Typography className={classes.description}>
                                {getDate(qouteData?.created_at)}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <Typography
                                style={{
                                  fontFamily: 'Inter',
                                  fontSize: '12px',
                                  color: '#757575',
                                  weight: '400',
                                }}
                              >
                                {getTime(qouteData?.created_at)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item lg={2} md={6} xs={12} sm={10}>
                          <Grid item container rowGap={1}>
                            <Grid item xs={12} md={12} lg={12}>
                              <Typography className={classes.title}>
                                Transaction ID
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <Typography
                                className={classes.transactionId}
                                style={{ fontSize: '14px' }}
                              >
                                {qouteData?.transaction_number
                                  ? qouteData?.transaction_number
                                  : 'NA'}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item lg={2} md={6} sm={10} xs={12}>
                          <Grid container rowGap={1}>
                            <Grid item xs={12} md={12} lg={12}>
                              <Typography className={classes.title}>
                                Paid Using
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <Typography className={classes.description}>
                                {/* Bank of Ameriacn credit card */}
                                {qouteData?.charge?.payment_method_details?.card
                                  ?.brand
                                  ? `${qouteData?.charge?.payment_method_details?.card?.brand} card`
                                  : 'NA'}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <Typography className={classes.description}>
                                {qouteData?.charge?.payment_method_details?.card
                                  ?.last4
                                  ? `****${qouteData?.charge?.payment_method_details?.card?.last4}`
                                  : 'NA'}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item lg={2} md={6} sm={10} xs={12}>
                          <Grid container rowGap={1}>
                            <Grid item xs={4} md={12} lg={12}>
                              <Typography className={classes.title}>
                                Tax Amount
                              </Typography>
                            </Grid>
                            <Grid item xs={4} md={12} lg={12}>
                              <Typography
                                style={{
                                  fontSize: '14px',
                                  fontWeight: '400',
                                  color: '#545454',
                                  fontFamily: 'Inter',
                                }}
                              >
                                {qouteData?.debited_from
                                  ? `$${insertCommas(qouteData?.debited_from)}`
                                  : 'NA'}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          lg={2}
                          md={6}
                          sm={10}
                          xs={12}
                          justifyContent="center"
                        >
                          <Grid container rowGap={1}>
                            <Grid item xs={12} md={12} lg={12}>
                              <Typography className={classes.title}>
                                Total Amount
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                              <Typography
                                style={{
                                  overflow: 'scroll',
                                  maxWidth: '400px',
                                }}
                                className={classes.amount}
                              >
                                {qouteData?.amount
                                  ? `$${insertCommas(qouteData?.amount)}`
                                  : ' NA'}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} paddingTop={3}>
                        {qouteData?.charge &&
                        qouteData?.status === 'succeeded' ? (
                          <Status
                            dateString={qouteData?.created_at}
                            type="Debited"
                          />
                        ) : (
                          <Status
                            dateString={qouteData?.created_at}
                            type="Refunded"
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                );
              })}
            </>
          ) : (
            <Grid container lg={11} md={12} sm={12}>
              <Box
                sx={{
                  margin: '0 auto',
                  display: 'table',
                  width: '100%',
                  paddingTop: '10px',
                  justifyContent: 'center',
                }}
              >
                <Box className="text-center ">
                  <Typography
                    variant="h4"
                    className="text-secondary-50 py-4 !font-bold !font-[Inter]"
                  >
                    No result found
                  </Typography>
                  <NoDataFoundIcon />
                </Box>
              </Box>
            </Grid>
          )}
        </>
      )}
    </>
  );
};
export default ContentInspection;
