import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import { routes } from 'routes/RouteConstants';
export const ApplyForFinance = () => {
  const navigate=useNavigate()
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const location=useLocation()
  const handleStartFinancing=()=>{
    navigate(routes.FINANCING.pathname,{state:{stock_number:location.state.stock_number}})
  }
  return (
    <Grid spacing={2} container className='!m-auto max-w-[1240px] py-12' justifyContent='center'>
      <Grid lg={12} md={12} sm={12} item>
     <Typography className='!font-bold' variant="h4">Apply for finance</Typography>
      </Grid>
      <Grid className='flex items-center ' lg={12} md={12} sm={12} item>
        {' '}
        <img className='w-14 h-14' src='assets/icons/investment-paper.svg'/> <Typography className='!font-bold'  variant="h4">Document Required</Typography>
      </Grid>
      <Grid lg={12} md={12} sm={12} item>
        <List className="!list-disc">
        <ListItem>
            <FiberManualRecordIcon className="!text-gray-60 !text-[8px] pr-2" />
            <ListItemText className='!text-gray-60'>Stock number</ListItemText>
          </ListItem>
        <ListItem>
            <FiberManualRecordIcon className="!text-gray-60 !text-[8px] pr-2" />
            <ListItemText className='!text-gray-60'>Credit Application</ListItemText>
          </ListItem>
          <ListItem>
            <FiberManualRecordIcon className="!text-gray-60 !text-[8px] pr-2" />
            <ListItemText className='!text-gray-60'>
              Commercial Drivers License CDL front and back
            </ListItemText>
          </ListItem>
          <ListItem>
            <FiberManualRecordIcon className="!text-gray-60 !text-[8px] pr-2" />
            <ListItemText className='!text-gray-60'>
              Social security card front and back - Work Authorization Card
              front and back (if applicable)
            </ListItemText>
          </ListItem>
          <ListItem>
            <FiberManualRecordIcon className="!text-gray-60 !text-[8px] pr-2" />
            <ListItemText className='!text-gray-60'>3 Months of latest bank statements</ListItemText>
          </ListItem>
          
          <ListItem>
            <FiberManualRecordIcon className="!text-gray-60 !text-[8px] pr-2" />
            <ListItemText className='!text-gray-60'>2 years most recent tax returns</ListItemText>
          </ListItem>
          
          <ListItem>
            <FiberManualRecordIcon className="!text-gray-60 !text-[8px] pr-2" />
            <ListItemText className='!text-gray-60'>
              Voided check for address and bank verification
            </ListItemText>
          </ListItem>
          <ListItem>
            <FiberManualRecordIcon className="!text-gray-60 !text-[8px] pr-2" />
            <ListItemText className='!text-gray-60'>
              If applying for financing through your business the Articles of
              Incorporation, and Employer Identification Number letters are
              required
            </ListItemText>
          </ListItem>
          
          <ListItem>
            <FiberManualRecordIcon className="!text-gray-60 !text-[8px] pr-2" />
            <ListItemText className='!text-gray-60'>Green Card(Optional)</ListItemText>
          </ListItem>
          <ListItem>
            <FiberManualRecordIcon className="!text-gray-60 !text-[8px] pr-2" />
            <ListItemText className='!text-gray-60'>Passports(Optional)</ListItemText>
          </ListItem>
        
         
        </List>
      </Grid>
      <Grid  item lg={12} md={12} sm={12} className={`text-right `} > <Button onClick={handleStartFinancing} sx={{width:'265px'}} variant='contained'>Continue</Button> </Grid>
    </Grid>
  );
};
