import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { Grid, Box, Typography, Divider, ToggleButton } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import '../SMRR.css';
import { CommonAccountLayout } from 'pages/commonComponents/CommonAccountLayout';
import SettingsMenu from './SettingsMenu';
import ToggleButtonComponent from './ToggleButton';

const titleStyle = {
  fontSize: '16px',
  fontWeight: '700',
  color: '#545454',
};
const subTitleStyle = {
  fontSize: '14px',
  fontWeight: '400',
  color: '#757575',
  paddingTop: '5px',
};

const PushNotificationContent = (props) => {
  return (
    <>
      <Grid
        container
        lg={12}
        md={12}
        xs={11}
        className="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          container
          lg={10}
          md={10}
          xs={10}
          className="flex"
          padding={2}
          style={{
            height: '130px',
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.08)',
            borderRadius: '8px',
          }}
        >
          <Grid item lg={8} md={10} xs={10} className="" padding="20px">
            <Typography className="flex" style={titleStyle}>
              Notificatons
            </Typography>
            <Typography style={subTitleStyle}>
              Receive notification based on activity
            </Typography>
          </Grid>
          <Grid
            item
            lg={4}
            md={2}
            xs={2}
            className="flex"
            padding={2}
            justifyContent="right"
            alignItems="right"
            style={{
              paddingTop: '32px',
              paddingLeft: '15px',
              paddingRight: '15px',
            }}
          >
            <ToggleButtonComponent status={props?.status} />
          </Grid>
          <Grid item lg={12} md={10} xs={2} paddingBottom={2}>
            <Divider style={{ width: '97%', marginLeft: '20px' }} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PushNotificationContent;
