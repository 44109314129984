import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { Grid, Box, useMediaQuery, useTheme } from '@mui/material';
import { CommonAccountLayout } from 'pages/commonComponents/CommonAccountLayout';
import { makeStyles } from '@material-ui/styles';
import '../SMRR.css';
import HeaderComponent from './HeaderComponent';
import HelpCenterContent from './HelpCenterContent';

const HelpCenter = () => {

  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));
  const isBelowMedium = useMediaQuery(theme.breakpoints.down('md'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <>


      <CommonAccountLayout
        Content={
          <Box width={'100%'}>
            <Box className={` ${isMedium ? 'w-[100%]' : 'w-[90%]'}`}>
              <Box className="p-4">
                <Box

                  className="w-[100%] h-[70px]"

                >
                  {/* <Grid  item style={{paddingTop:'28px'}}> */}
                  <HeaderComponent />
                  {/* </Grid> */}
                </Box>
                <Box


                  style={{ marginTop: '20px', marginBottom: '40px', border: 'none' }}
                >

                  <HelpCenterContent />
                </Box>
              </Box>
            </Box>

          </Box>
        }

      />
      {/* <Grid container>
            <Grid item lg={12} md={12} xs={12}>
                <HeaderComponent/>
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
                Hello SMR
            </Grid>
         </Grid> */}
    </>

  )
}

export default HelpCenter;