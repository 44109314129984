import { useQuery } from "@tanstack/react-query";
import { axios } from "utils";

const GetTrucksList=(data)=>{
  const method='GET'
  const url=`category/`
  return axios({method,url,data})
}

export const useTrucksList=()=>{

  return useQuery(
    ['GET_TRUCKS'],
    () => GetTrucksList(),
    {
      enabled:true,
    }
  );
}

// Brands List
const GetBrandsList=(data) => {
  console.log({ 'asdf': data });
  const method='GET'
  const url=`vehicle-brands/`
  return axios({method,url,params: data})
}

export const useGetBrandsList=(data)=>{
  return useQuery(
    ['GET_BRANDS'],
    () => GetBrandsList(data),
    {
      enabled:true,
      refetchOnMount: true
    }
  );
}

// Year List
const GetYearsList=(data) => {
  const method='GET'
  const url=`year/`
  return axios({method,url,data})
}

export const useGetYearsList=()=>{
  return useQuery(
    ['GET_YEARS'],
    () => GetYearsList(),
    {
      enabled:true,
    }
  );
}

// Manufacturer List
const GetManufacturerList=(data) => {
  const method='GET'
  const url=`trailer-manufacturer/`
  return axios({method,url,data})
}

export const useGetManufacturerList=()=>{
  return useQuery(
    ['GET_MANUFACTURERS'],
    () => GetManufacturerList(),
    {
      enabled:true,
    }
  );
}

// Transmission Manufacturer List
const GetTransmissionManufacturerList=(data) => {
  const method='GET'
  const url=`transmission-manufacturers/`
  return axios({method,url,data})
}

export const useGetTransmissionManufacturerList=()=>{
  return useQuery(
    ['GET_TRANSMISSION_MANUFACTURERS'],
    () => GetTransmissionManufacturerList(),
    {
      enabled:true,
    }
  );
}

// Axle Ratio
const GetAxleRatioList=(data) => {
  const method='GET'
  const url=`axle-ratio/`
  return axios({method,url,data})
}

export const useGetAxleRatioList=()=>{
  return useQuery(
    ['GET_AXLERATIO'],
    () => GetAxleRatioList(),
    {
      enabled:true,
    }
  );
}

// Wheel Base
const GetWheelBaseList=(data) => {
  const method='GET'
  const url=`wheel-base/`
  return axios({method,url,data})
}

export const useGetWheelBaseList=()=>{
  return useQuery(
    ['GET_WHEELBASE'],
    () => GetWheelBaseList(),
    {
      enabled:true,
    }
  );
}

// Tire Treads
const GetTireTread=(data) => {
  const method='GET'
  const url=`tire-treads/`
  return axios({method,url,data})
}

export const useGetTireTread=()=>{
  return useQuery(
    ['GET_TireTread'],
    () => GetTireTread(),
    {
      enabled:true,
    }
  );
}

// Brake Type
const GetBrakeType=(data) => {
  const method='GET'
  const url=`brake-type/`
  return axios({method,url,data})
}

export const useGetBrakeType=()=>{
  return useQuery(
    ['GET_BRAKETYPE'],
    () => GetBrakeType(),
    {
      enabled:true,
    }
  );
}

// Collision List
const GetCollisionList=(data) => {
  const method='GET'
  const url=`collision/`
  return axios({method,url,data})
}

export const useGetCollisionList=()=>{

  return useQuery(
    ['GET_COLLISION'],
    () => GetCollisionList(),
    {
      enabled:true,
    }
  );
}

// Mitigation List
const GetMitigationList=(data) => {
  const method='GET'
  const url=`mitigation-system/`
  return axios({method,url,data})
}

export const useGetMitigationList=()=>{

  return useQuery(
    ['GET_COLLISION'],
    () => GetMitigationList(),
    {
      enabled:true,
    }
  );
}

// Brands List
const GetModelsList=(data) => {
  const method='GET'
  const url=`brand-models/?per_page=1000&search=${data ? data : ''}`
  return axios({method,url})
}

export const useGetModelsList=(data)=>{
  return useQuery(
    ['GET_MODELS'],
    () => GetModelsList(data),
    {
      enabled:true
    }
  );
}

const GetColorList=(data)=>{
  const method='GET'
  const url=`vehicle-color/`
  return axios({method,url,data})
}
export const useGetColorList=()=>{
  
  return useQuery(
    ['GET_COLORS'],
    () => GetColorList(),
    {
      enabled:true,
    }
  );

}
const GetEnginesList=(data)=>{
  const method='GET'
  const url=`engine-manufacturer/`
  return axios({method,url,data})
}
export const useGetEnginesList=()=>{

  return useQuery(
    ['GET_ENGINES'],
    () => GetEnginesList(),
    {
      enabled:true,
    }
  );

}


const GetBedSizeList=(data)=>{
  const method='GET'
  const url=`bed-size/`
  return axios({method,url,data})
}

export const useGetBedSizeList=()=>{
  
  return useQuery(
    ['GET_BED_SIZE_LIST'],
    () => GetBedSizeList(),
    {
      enabled:true,
    }
  );

}


const GetHorsePowerList=(data)=>{
  const method='GET'
  const url=`horsepower/`
  return axios({method,url,data})
}

export const useGetHorsePowerList=()=>{

  return useQuery(
    ['GET_HORSE_POWER_LIST'],
    () => GetHorsePowerList(),
    {
      enabled:true,
    }
  );

}

const GetMileageList=(data)=>{
  const method='GET'
  const url=`mileage/`
  return axios({method,url,data})
}

export const useGetMileageList=()=>{

  return useQuery(
    ['GET_MILEAGE_LIST'],
    () => GetMileageList(),
    {
      enabled:true,
    }
  );
}

const GetGVWRList=(data)=>{
  const method='GET'
  const url=`gross-vehicle-weight-rating/`
  return axios({method,url,data})
}

export const useGetGVWRList=()=>{

  return useQuery(
    ['GET_GVWR_LIST'],
    () => GetGVWRList(),
    {
      enabled:true,
    }
  );
}


const GetTrailerLengthList=(data)=>{
  const method='GET'
  const url=`trailer-length/`
  return axios({method,url,data})
}

export const useTrailerLengthList=()=>{

  return useQuery(
    ['GET_TRAILER_LENGTH_LIST'],
    () => GetTrailerLengthList(),
    {
      enabled:true,
    }
  );
}




