import { format } from 'date-fns';
export const setTomorrowMinDate = () =>
  new Date().setDate(new Date().getDate() + 1);

export const parseDate = (date, formatType = 'yyyy-MM-dd') =>
  format(new Date(date), formatType);
// expected outcome "2022-11-09"

export const createSlotLabel = (hour, min) => {
  return `${hour > 12 ? hour - 12 : hour}:${
    min.toString().length < 2 ? 0 : ''
    // eslint-disable-next-line no-nested-ternary
  }${min} ${hour === 0 ? 'AM' : hour > 11 ? 'PM' : 'AM'}`;
};

export const ifOnlyDatesAreSame = (date1, date2) => {
  return (
    date1?.getDate() === date2?.getDate() &&
    date1?.getMonth() === date2?.getMonth() &&
    date1?.getFullYear() === date2.getFullYear()
  );
};

export const extractTimeFromDateObj = (date) => {
  const hour = date?.getHours();
  const min = date?.getMinutes();
  return `${hour > 10 ? '' : 0}${hour}:${min > 10 ? '' : 0}${min}:00`;
};
