const { Button } = require("@mui/material")

const CustomButton = ({children,styles,...others}) => {
    return(
        <Button {...others}  className={`!bg-secondary-50 !text-white !py-[12px] !rounded-md !text-[17px] w-[100%] hover:!bg-secondary-60 ${styles}`}>
            {children}
          </Button>
    )
}   

export default CustomButton;
