import { useQuery } from '@tanstack/react-query';
import { axios } from 'utils';
import { QueryKey } from './queryKeys';
import {
  formatUrlParamsComma,
} from '../utils/formatUrlParams';
import { filterRefactor } from 'utils/filterRefactor';
const GetFiltersCredentials = (payload) => {
  const method = 'GET';
  const url = 'filters/';
  let data = {};
  payload?.forEach((el) => {
    if (data[el.filter]) {
      data[el.filter] = [...data[el.filter], el.label];
    } else {
      data[el.filter] = [el.label];
    }
  });
  let config = { method, url };
  if (payload) {
    config = {
      ...config,
      params: formatUrlParamsComma(data),
    };
  }

  return axios(config);
};

export const useGetFilters = (data) => {
  return useQuery(
    [QueryKey.GET_FILTERS, data],
    () => GetFiltersCredentials(data),
    {
      enabled: true,
    }
  );
};

const GetFilterDataCredentials = (payload, isPage) => {
  const method = 'GET';
  const url = 'listing/';

  let data = {};
  payload?.forEach((el) => {
    if (el?.type == 'Sort') {
      data['ordering'] = [el._ordering];
    } else {
      filterRefactor(el, data);
    }
  });
  let config = { method, url };
  if (payload) {
    config = {
      ...config,
      params: formatUrlParamsComma({ ...data, per_page: 12, page: isPage }),
    };
  }

  return axios(config);
};

export const useGetFilterData = (data, isPage) => {
  return useQuery(
    [QueryKey.GET_FILTERED_DATA, data, isPage],
    () => GetFilterDataCredentials(data, isPage),
    {
      enabled: true,
    }
  );
};
