export const insertCommas = (data) => {
  let money = '' + data;
  let digitAfterDecimal = '';

  //extracting part after decimal
  if (money.indexOf('.') != -1) {
    digitAfterDecimal = money.slice(money.indexOf('.'));
    money = money.slice(0, money.indexOf('.'));
    // digitAfterDecimal = money.slice(money.indexOf('.'));
  }

  //  let money = []
  // Step 2: Reverse the string
  money = money.split('').reverse().join('');

  // Step 3: Insert commas
  let result = '';
  for (let i = 0; i < money?.length; i++) {
    if (i !== 0 && i % 3 === 0) {
      result += ',';
    }
    result += money.charAt(i);
  }

  // Step 4: Reverse the string again
  result = result.split('').reverse().join('');
  // Step 5: Return the modified string
  return result + digitAfterDecimal;
};
