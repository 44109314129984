import {
  FormControl,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  TextField,
  Typography,
  Grid,
} from '@material-ui/core';
import { Autocomplete } from '@mui/material';
import { nanoid } from 'nanoid';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { FiSearch } from 'react-icons/fi';

export const CustomMenuWithSearch = ({
  options,
  type,
  isSearch,
  filterValues,
  setFilterValues,
  handleClosePopUp,
}) => {
  const [search, setSearch] = useState('');
  const [values, setValues] = useState('');
  const [renderOptions, setRenderOptions] = useState(options || []);
  useEffect(() => {
    if (type == 'Year') {
      let filtered = options?.filter((el) => {
        return el?.label?.toString()?.indexOf(search) !== -1 ? true : false;
      });
      setRenderOptions(filtered);
    }
    if (type == 'Make') {
      let filtered = options?.filter((el) => {
        return el?.value?.toLowerCase()?.indexOf(search.toLowerCase()) !== -1
          ? true
          : false;
      });
      setRenderOptions(filtered);
    }
  }, [search]);
  const handleSelect = (el) => {
    let removed = filterValues.filter((el) => el.filter !== type);

    setFilterValues([
      ...removed,
      { filter: type, label: el.value, category: el.category, key: nanoid() },
    ]);
    handleClosePopUp();
  };
  return (
    <Grid
      container
      spacing={4}
      className=" px-4 pb-4 max-h-[260px] max-w-[250px]"
    >
      <Grid className="sticky top-0  bg-white" item>
        {isSearch ? (
          <FormControl sx={{ padding: '10px' }} variant="outlined">
            <OutlinedInput
              color="primary"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              sx={{
                '&.Mui-focused  .MuiOutlinedInput-notchedOutline': {
                  border: '1px solid gray !important',
                },
              }}
              type={type == 'Years' ? 'number' : 'text'}
              id="outlined-adornment-Search"
              startAdornment={
                <InputAdornment position="start">
                  {' '}
                  <FiSearch className="text-primary-50" />{' '}
                </InputAdornment>
              }
              aria-describedby="outlined-search-helper-text"
              placeholder={`Search ${type}`}
              inputProps={{
                'aria-label': 'Search',
              }}
            />
          </FormControl>
        ) : (
          <></>
        )}
      </Grid>

      <Grid
        spacing={2}
        item
        container
        className="max-h-[200px] overflow-scroll"
      >
        {renderOptions?.map((el) => {
          return (
            <Grid
              onClick={() => handleSelect(el)}
              justifyContent="center"
              className="CustomDropDownListItem "
              item
              container
            >
              {' '}
              <Typography>{el?.label}</Typography>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};
