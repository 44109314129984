import { createTheme, responsiveFontSizes } from '@mui/material/styles';

/**
 * Theme
 */
const commonTheme = createTheme({
  breakpoints: {
    values: {
      xs: 450,
      sm: 600,
      md: 800,
      lg: 1200,
      xl: 1436,
      '2xl': 1700,
    },
  },
  screenSize: {
    max_width: 1700,
  },
  palette: {
    primary: {
      main: '#093857',
      contrastText: '#FFF',
      // 25: 'F5FAFF',
      // 50: '#E8F3F8',
      // 100: '#D1E9FF',
      // 200: '#A1CBE2',
      // 300: '#73B2D4',
      // 400: '#4498C5',
      // 500: '#157EB7',
      // 600: '#105F89',
      // 700: '#0B3F5C',
      // 800: '#05202E',
      // 900: '#194185',
      25: '#FFF9E6',
      50: '#FFF9E6',
      10: '#FFF3CC',
      15: '#FFEDB3',
      20: '#FFE799',
      30: '#FFDA66',
      40: '#FFCE33',
      50: '#FFC200',
      60: '#CC9B00',
      70: '#997400',
      80: '#664E00',
      85: '#4D3A00',
      90: '#332700',
      light_border: '#E4E4E7',
    },
    secondary: {
      main: '#0F5E91',
      contrastText: '#FFF',
      light: '#357AA6',
      dark: '#0E9384',
      50: '#BBDBF0',
      100: '#A8CDE5',
      150: '#95BFDB',
      200: '#82B1D0',
      300: '#5B96BB',
      400: '#357AA6',
      500: '#0F5E91',
      600: '#0C4B74',
      700: '#093857',
      800: '#06263A',
      850: '#051C2C',
      900: '#03131D',
    },
    gray: {
      25: '#F4F7F7',
      50: '#E6E9E9',
      100: '#CDD2D1',
      200: '#9BA5A4',
      300: '#D1D5DB',
      400: '#364C49',
      500: '#6B7280',
      600: '#031714',
      700: '#374151',
      800: '#010807',
      900: '#000303',
    },
    error: {
      main: '#FF0000',
    },
  },
  typography: {
    fontFamily: `Inter`,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'inherit',
          borderRadius: '8px',
          fontWeight: 600,
          padding: '6px 16px',
          background: '#0F5E91',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
          fontFamily: `"Poppins", sans-serif`,
        },

        contained: {
          justifyContent: 'center',
          textAlign: 'center',
          fontWeight: 500,
          minHeight: '56px',
          minWidth: '124px !important',

          // border: '2px solid #FFC200 !important',

          backgroundColor: '#FFC200 !important',
          borderRadius:'4px !important',
          color: 'black !important',
          '&:hover': {
            backgroundColor: 'black !important',
            color: '#FFC200 !important',
          },
        },
        outlined: {
          // border: '2px solid #FFC200 !important',
          background: 'none',
          color: 'black !important',
          borderRadius:'4px !important',
        },
        ghost: {
          justifyContent: 'center',
          textAlign: 'center',
          fontWeight: 500,
          minHeight: '56px',
          minWidth: '124px !important',
          color: 'black !important',
          backgroundColor: 'rgba(245, 245, 245, 1) !important',
          minWidth: '124px !important',
        },
        invisible: {
          justifyContent: 'center',
          textAlign: 'center',
          fontWeight: 500,
          minHeight: '56px',
          minWidth: '124px !important',
          color: 'black !important',
          backgroundColor: 'none !important',
          '&:hover': {
            backgroundColor: 'none !important',
             
          },
        },
      },
     
    },
  },
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      // most basic recommended timing
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },

  easing: {
    // This is the most common easing curve.
    easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
    // Objects enter the screen at full velocity from off-screen and
    // slowly decelerate to a resting point.
    easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
    // Objects leave the screen at full velocity. They do not decelerate when off-screen.
    easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
    // The sharp curve is used by objects that may return to the screen at any time.
    sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
  },
  //   },
  //   MuiChip: {
  //     styleOverrides: {
  //       root: {
  //         borderRadius: '1px',
  //       },
  //     },
  //   },
  //   MuiDialog: {
  //     styleOverrides: {
  //       paper: {
  //         borderRadius: '24px',
  //       },
  //     },
  //   },
  //   MuiFormLabel: {
  //     styleOverrides: {
  //       root: {
  //         '&.Mui-focused': {
  //           color: '#3699FF',
  //         },
  //       },
  //       asterisk: {
  //         color: 'red',
  //       },
  //     },
  //   },
  //   MuiInputBase: {
  //     defaultProps: {
  //       color: 'secondary',
  //     },
  //     styleOverrides: {
  //       root: {
  //         '&.MuiOutlinedInput-root': {
  //           backgroundColor: '#fff',
  //           borderRadius: '8px',
  //           minHeight: '44px',
  //         },
  //         input: {
  //           lineHeight: '24px',
  //         },
  //       },
  //     },
  //   },
  //   MuiInputLabel: {
  //     styleOverrides: {
  //       root: {
  //         color: '#1A1A1A',
  //         opacity: '0.7',
  //       },
  //       asterisk: {
  //         color: 'red',
  //       },
  //     },
  //   },

  //   MuiDialogContent: {
  //     styleOverrides: {
  //       root: {
  //         padding: '0px',
  //       },
  //     },
  //   },
  //   MuiTextField: {
  //     styleOverrides: {
  //       root: {
  //         matginTop: '0px',
  //         borderRadisu: '6px',
  //         '& .MuiInputBase-root': {
  //           borderRadius: '6px',
  //         },
  //       },
  //     },
  //   },

  //   MuiFormHelperText: {
  //     styleOverrides: {
  //       root: {
  //         matginTop: '0px',
  //       },
  //     },
  //   },
  //   MuiFab: {
  //     styleOverrides: {
  //       root: {
  //         backgroundColor: 'white',
  //         boxShadow: 'none',
  //       },
  //     },
  //   },
  MuiInput: {
    input: {
      '&::placeholder': {
        color: '#757575',
      },
      // color: "white", // if you also want to change the color of the input, this is the prop you'd use
    },
  },
  // MuiSelect: {
  //   styleOverrides: {
  //     root: {
  //       backgroundColor: '#FFF!important',
  //     },
  //     select: {
  //       backgroundColor: '#FFF!important',
  //     },
  //   },
  // },

  // MuiMenuItem: {
  //   styleOverrides: {
  //     root: {
  //       backgroundColor: '#FFF',
  //     },
  //     select: {
  //       backgroundColor: '#FFF',

  //     },
  //   },
  // },

  //   MuiCard: {
  //     styleOverrides: {
  //       root: {
  //         boxShadow: 'none',
  //       },
  //     },
  //   },
  //   MuiAccordion: {
  //     styleOverrides: {
  //       root: {
  //         boxShadow: 'none',
  //       },
  //     },
  //   },
  // },
});

// Do your common overrides here
/**
 * Typography - body1
 */
// commonTheme.typography.body1.fontSize = "0.8rem";
// commonTheme.typography.body1.color = commonTheme.palette.text.primary;

/**
 * Typography - caption
 */
commonTheme.typography.caption.color = commonTheme.palette.text.hint;
/**
 *  ** THIS SHOULD BE LAST **
 * Root Theme
 */
const rootTheme = responsiveFontSizes(commonTheme);

export default rootTheme;
