import axios from 'axios';
import {
  getLocalStorageItem,
  localStorageKeys,
  getPathnameLocalStorageItem,
} from 'hooks';
import { RefreshAccessToken, handleRefreshAuthtokens } from 'query/Auth';
 
const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    
    'content-type': 'application/json',
    'X-CSRFTOKEN':
      'hIESQXzSEZxPitbMXJTmcpHf6k0Q4LUXHS8Ms9pxN3MBrquuwKbIgpGqvIgihFWy',
  },

});

const handleLogoutRedirectUser = () => {
  window.location = '/login';
  localStorage.clear()
};

AxiosInstance.interceptors.request.use(async (config) => {
  const authToken = await getLocalStorageItem(localStorageKeys.AUTH_TOKEN);
  if (authToken) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${authToken}`,
    };
  }
  // if (config && config.data && config.method === 'get') {
  //   config.url = config.url + getPathnameLocalStorageItem();
  //   config.data = { ...config.data };
  // }
  return config;
});

AxiosInstance.interceptors.response.use(
  (response) => response.data,
  async (error) => {
      const originalRequest = error.config;

      if (
        error?.response?.status === 401 &&
          (await getLocalStorageItem(localStorageKeys.AUTH_TOKEN)) &&
          !originalRequest._retry
      ) {
          originalRequest._retry = true;
          try {
              const access_token = await RefreshAccessToken();
              if (access_token) {
                handleRefreshAuthtokens(access_token);

                return await AxiosInstance(originalRequest);
              }
          } catch (error) {
              console.log(error);
              handleLogoutRedirectUser();
          }
      } else if (error?.response?.data?.message === "Unauthorized") {
          // alert('logoiy')
          handleLogoutRedirectUser();
      }

      return Promise.reject(error.response.data);
  }
);

export default AxiosInstance;
