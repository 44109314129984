import React, { useState } from "react";
import moment from 'moment';
import { Grid, Typography, Popover } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import {useDeleteListing} from '../../query/Equipmemnts';

const HeaderMenu = (props) => {
    console.log('propsHeader Menu', props);
    const { dateString,listingid, handleReloadData } = props    
    const formattedDate = moment(dateString).format('MMM DD, YYYY');
    const { mutate: DeleteListing, isLoading: deletingListing } = useDeleteListing();

    const style = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        padding: '5px',
        backgroundColor: '#F5F5F5',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px'
    }
    // Icon click event
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    //delete My Equipment(Listing)
    const handleDelete= () =>{
        DeleteListing(listingid, {
            onSuccess: (res) => {            
              handleReloadData();
              handleClose();
            },
            onError: (err) => {
              // setOpenDeletePromt(false);
            },
          })
    }

    return (

        <>
            <Grid container lg={12} md={12} sm={12} style={style}>
                <Grid item lg={3} md={3} sm={8}>
                    <Typography style={{ padding: '7px', fontSize: '14px', color: '#333333' }}>{`Posted on ${formattedDate}`}</Typography>
                </Grid>
                <Grid item lg={1} md={1} sm={2} className="cursor-pointer" style={{ paddingLeft: '55px', cursor: 'pointer' }}>
                    <Grid onClick={handleClick} style={{ float: "right", paddingRight: "15px" }}>
                        <img src="/assets/icons/three-dot-icon.svg" alt="dot" style={{ height: '20px' }} />                        
                    </Grid>
                </Grid>
            </Grid>            
            <Popover               
                id="simple-popover"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
            >
                <MenuItem onClick={handleDelete} style={{ fontSize: '12px'}} >Delete</MenuItem>
            </Popover>            
        </>
    )
}

export default HeaderMenu;