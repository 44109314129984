import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { axios } from 'utils';
import { QueryKey } from './queryKeys';

const GetReviewCredentials = (isPage) => {
  const method = 'GET';
  const url = 'review/';
  return axios({
    method,
    url,
    params: {
      per_page: 4,
      page: isPage,
    },
  });
};

export const useGetReview = (isPage) => {
  return useQuery(
    [QueryKey.GET_REVIEW_DATA],
    () => GetReviewCredentials(isPage),
    {
      enabled: true,
    }
  );
};

const CreateReviewCredentials = (payload) => {
  const method = 'POST';
  const url = 'review/';

  return axios({ method, url, data: payload });
};

export const useCreateReview = (SuccessCallback) => {
  const queryClient = useQueryClient();
  return useMutation(CreateReviewCredentials, {
    onSuccess: (res) => {
      queryClient.invalidateQueries([QueryKey.GET_REVIEW_DATA]);
      SuccessCallback();
    },
  });
};
