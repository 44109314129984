import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import FinanceCard from './FinanceCard';
import Button from 'components/LoadingButton';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { Finance } from './Finance';

export const HassleFreeFinancing = () => {
  const [openFinance, setOpenFinance] = useState(false);
  const handleFinanceOpen = () => {
    setOpenFinance(true);
  };
  const handleFinanceClose = () => {
    setOpenFinance(false);
  };
  return (
    <>
      <Finance open={openFinance} setOpen={handleFinanceClose} />
      <Box className="bg-secondary-90 py-[54px] hidden">
        <Grid item className={`!w-[100%] pb-24 px-5 `}>
          <Grid item container justifyContent="center" className="text-center">
            <Typography
              style={{ fontFamily: 'Space Grotesk'}}
              variant="h3"
              className="!font-semibold  text-white max-sm:!text-display-md"
            >
              Financing Available
            </Typography>
          </Grid>
          <Grid container item justifyContent="center" className="text-center !mt-[24px]">
            <Typography
              style={{ fontFamily: 'Space Grotesk' }}
              className="text-white pt- opacity-[.8] 5 !font-[24px] font-Inter"
            >
              Whether You Buy From Us Or Not!
            </Typography>
          </Grid>
          <Grid item container className="text-center !mt-[32px] max-sm:!mt-0" justifyContent="center" >
            <img
              className="max-w-[300px] p-6"
              src="assets/landingPage/SlateFinanceImg.svg"
              alt=""
            />
          </Grid>
          <Grid item container justifyContent="center" className="gap-5 pt-5">
            <Grid item>
              <Typography
                style={{ fontFamily: 'Space Grotesk' }}
                className="!text-[20px]  !font-medium text-white"
              >
                Established Fleets
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                style={{ fontFamily: 'Space Grotesk' }}
                className="!text-[20px]  !font-medium text-white"
              >
                • <span className="pl-5"> New Carriers</span>
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                style={{ fontFamily: 'Space Grotesk' }}
                className="!text-[20px]  !font-medium text-white"
              >
                • <span className="pl-5">First Time Buyers</span>
              </Typography>
            </Grid>
          </Grid>

          <Grid item container justifyContent="center" >
            <div className='!-mb-[50px]'>
            <Button
              onClick={handleFinanceOpen}
              variant="ghost"
              className=" !bg-white !text-secondary-90 font-semibold !py-[12px] !rounded-lg !text-[17px] w-[280px] !mt-[32px]"
            >
              Request Financing
            </Button>
            </div>
           
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
