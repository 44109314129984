import React, { useEffect, useState } from 'react';
import './PreviouslyUploadedFile.css';
import { Tooltip } from '@mui/material';
export const PreviouslyUploadedFile = (props) => {
  const {
    allPreviouslyUploadedDocuments,
    setAllPreviouslyUploadedDocuments,
    fileData,
    fileName,
    handleSuccess,
    handleDeSelect,
    document_type,
    document_side
  } = props;

  // const [fileData, setFileData] = useState({});

  // Components - Icons
  const fileIcon = () => {
    return (
      <svg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect x='2' y='2' width='32' height='32' rx='16' fill='#EDEDED' />
        <path
          d='M19.3334 11.5126V14.2663C19.3334 14.6397 19.3334 14.8264 19.406 14.969C19.4699 15.0944 19.5719 15.1964 19.6974 15.2603C19.84 15.333 20.0267 15.333 20.4 15.333H23.1537M23.3334 16.6585V21.4663C23.3334 22.5864 23.3334 23.1465 23.1154 23.5743C22.9236 23.9506 22.6177 24.2566 22.2413 24.4484C21.8135 24.6663 21.2535 24.6663 20.1334 24.6663H15.8667C14.7466 24.6663 14.1865 24.6663 13.7587 24.4484C13.3824 24.2566 13.0764 23.9506 12.8847 23.5743C12.6667 23.1465 12.6667 22.5864 12.6667 21.4663V14.533C12.6667 13.4129 12.6667 12.8529 12.8847 12.425C13.0764 12.0487 13.3824 11.7427 13.7587 11.551C14.1865 11.333 14.7466 11.333 15.8667 11.333H18.0079C18.4971 11.333 18.7416 11.333 18.9718 11.3883C19.1759 11.4373 19.371 11.5181 19.5499 11.6277C19.7518 11.7514 19.9247 11.9244 20.2706 12.2703L22.3961 14.3957C22.742 14.7417 22.9149 14.9146 23.0386 15.1164C23.1483 15.2954 23.2291 15.4905 23.2781 15.6945C23.3334 15.9247 23.3334 16.1693 23.3334 16.6585Z'
          stroke='#757575' stroke-width='1.33333' stroke-linecap='round' stroke-linejoin='round' />
        <rect x='2' y='2' width='32' height='32' rx='16' stroke='#F5F5F5' stroke-width='4' />
      </svg>
    );
  };

  const viewIcon = () => {
    return (
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M2.42012 12.7132C2.28394 12.4975 2.21584 12.3897 2.17772 12.2234C2.14909 12.0985 2.14909 11.9015 2.17772 11.7766C2.21584 11.6103 2.28394 11.5025 2.42012 11.2868C3.54553 9.50484 6.8954 5 12.0004 5C17.1054 5 20.4553 9.50484 21.5807 11.2868C21.7169 11.5025 21.785 11.6103 21.8231 11.7766C21.8517 11.9015 21.8517 12.0985 21.8231 12.2234C21.785 12.3897 21.7169 12.4975 21.5807 12.7132C20.4553 14.4952 17.1054 19 12.0004 19C6.8954 19 3.54553 14.4952 2.42012 12.7132Z'
          stroke='#cccccc' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'></path>
        <path
          d='M12.0004 15C13.6573 15 15.0004 13.6569 15.0004 12C15.0004 10.3431 13.6573 9 12.0004 9C10.3435 9 9.0004 10.3431 9.0004 12C9.0004 13.6569 10.3435 15 12.0004 15Z'
          stroke='#cccccc' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'></path>
      </svg>
    );
  };


  // Methods
  const handleFilePreview = (ev) => {
    ev.stopPropagation();
    window.open(fileData.document, '_blank');
  }
  const handleSelectedState = () => {
    let modifiedArr = [...allPreviouslyUploadedDocuments];
    modifiedArr.forEach(item => {
      if (item.id === fileData.id) {
        item._isSelected = !item._isSelected;
        // handleSuccess({resData: {data: item}, fileData: null});
        if(item._isSelected){
          handleSuccess(item);
        } else {
          handleDeSelect(item);
        }
      }
    });
    setAllPreviouslyUploadedDocuments(modifiedArr);
  };


  return (
    <div onClick={handleSelectedState} className={fileData?._isSelected ?  'pu-file-wrapper active' : 'pu-file-wrapper'}>
      <div className='icon'>
        {fileIcon()}
      </div>
      <div className='file-name'>
        {fileName || fileData?.document_key?.split("/")[1] || 'Unnamed'}
      </div>
      <div className='view-icon' onClick={(ev) => {handleFilePreview(ev)}}>
        <Tooltip title="Open file in new tab." placement="top">
          {viewIcon()}
        </Tooltip>
      </div>
    </div>
  )

}