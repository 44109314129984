import { Grid, Typography } from '@mui/material';
import React from 'react';

const BrandLogo = ({ image, name }) => {
  return (
    <Grid
      item
      container
      className=" hover:Scale_Child text-white hover:text-secondary-90 hover: shadow-md px-5 py-1 border-solid border-2 border-white hover:bg-white !max-w-[280px] !min-w-[250px] h-[80px] !rounded-md  t-margen"
      justifyContent="center"
      alignItems="center"
    >
      <img
        src={image}
        alt="brand-logo"
        className=" !max-w-[60px] !max-h[50px] !hover:scale-110 !pr-2"
      />
      <Typography className="!font-inter !text-[18px] !font-bold !leading-4 !tracking-normal !text-left !px-2">
        {name}
      </Typography>
    </Grid>
  );
};

export default BrandLogo;
