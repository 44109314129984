import React, { useState } from 'react';
import {
  Divider,
  Grid,
  Popover,
  Typography,
  useMediaQuery,
  MenuItem,
  MenuList,
  Box,
} from '@mui/material';
import { Image } from 'antd';
import { useTheme } from '@mui/material/styles';
import { SwiperSlide, Swiper } from 'swiper/react';
import { Pagination } from 'swiper';
import { numberWithCommas } from 'utils/helpers/numberWithCommas';
import { useCreateFavourites, useDeleteFavourites } from 'query/favourites';
import { getLocalStorageItem } from 'hooks';
import { useNavigate } from 'react-router';
import { routes } from 'routes/RouteConstants';
import Loader from 'react-js-loader';

const Likeheart = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_3071_118429)">
        <circle cx="24" cy="20" r="12" fill="white" />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.9954 16.1917C22.6458 14.6139 20.3953 14.1895 18.7044 15.6343C17.0135 17.079 16.7755 19.4946 18.1033 21.2033C19.2074 22.6239 22.5486 25.6203 23.6437 26.5901C23.7662 26.6986 23.8274 26.7528 23.8989 26.7741C23.9613 26.7927 24.0295 26.7927 24.0919 26.7741C24.1633 26.7528 24.2246 26.6986 24.3471 26.5901C25.4422 25.6203 28.7834 22.6239 29.8874 21.2033C31.2153 19.4946 31.0063 17.0638 29.2863 15.6343C27.5664 14.2047 25.3449 14.6139 23.9954 16.1917Z"
        fill="white"
        fill-opacity="0.6"
        stroke="#B0B0B0"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <filter
          id="filter0_d_3071_118429"
          x="0"
          y="0"
          width="48"
          height="48"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3071_118429"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3071_118429"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
const likedHeart = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_3071_118433)">
        <circle cx="24" cy="20" r="12" fill="white" />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.9954 16.1917C22.6458 14.6139 20.3953 14.1895 18.7044 15.6343C17.0135 17.079 16.7755 19.4946 18.1033 21.2033C19.2074 22.6239 22.5486 25.6203 23.6437 26.5901C23.7662 26.6986 23.8274 26.7528 23.8989 26.7741C23.9613 26.7927 24.0295 26.7927 24.0919 26.7741C24.1633 26.7528 24.2246 26.6986 24.3471 26.5901C25.4422 25.6203 28.7834 22.6239 29.8874 21.2033C31.2153 19.4946 31.0063 17.0638 29.2863 15.6343C27.5664 14.2047 25.3449 14.6139 23.9954 16.1917Z"
        fill="#0F5E91"
        stroke="#0F5E91"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <filter
          id="filter0_d_3071_118433"
          x="0"
          y="0"
          width="48"
          height="48"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3071_118433"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3071_118433"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
const share = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7914 10.6074C19.0355 10.3981 19.1575 10.2935 19.2023 10.169C19.2415 10.0598 19.2415 9.94022 19.2023 9.83095C19.1575 9.70645 19.0355 9.60183 18.7914 9.39258L10.3206 2.13196C9.9004 1.77176 9.69029 1.59166 9.5124 1.58725C9.3578 1.58342 9.21012 1.65134 9.11242 1.77122C9 1.90915 9 2.18589 9 2.73936V7.03462C6.86532 7.40807 4.91159 8.48974 3.45971 10.1139C1.87682 11.8845 1.00123 14.1759 1 16.551V17.1629C2.04934 15.8989 3.35951 14.8765 4.84076 14.1659C6.1467 13.5394 7.55842 13.1683 9 13.0705V17.2606C9 17.8141 9 18.0908 9.11242 18.2288C9.21012 18.3486 9.3578 18.4166 9.5124 18.4127C9.69029 18.4083 9.9004 18.2282 10.3206 17.868L18.7914 10.6074Z"
        stroke="white"
        stroke-width="1.67"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
const TruckCard = ({ refetch, ...props }) => {
  const shareOptions = [
    { label: 'Email', icon: 'assets/icons/share_mail.svg', value: 'mail' },
    { label: 'Twitter', icon: 'assets/icons/twitter.svg', value: 'twitter' },
    {
      label: 'Instagram',
      icon: 'assets/icons/instagram.svg',
      value: 'instagram',
    },
    { label: 'LinkedIn', icon: 'assets/icons/linkedin.svg', value: 'linkedin' },
    { label: 'Facebook', icon: 'assets/icons/facebook.svg', value: 'facebook' },
    { label: 'Copy Link', icon: 'assets/icons/link.svg', value: 'copy_link' },
  ];

  const data = { ...props };

  const [copiedSuccess, setCopySuccess] = useState(false);
  const [OpenShare, setOpenShare] = React.useState(null);
  const [currentShareLinkData, setCurrentShareLinkData] = useState(null);
  const { mutate: AddToFavourites } = useCreateFavourites();
  const { mutate: RemoveFromFavourites } = useDeleteFavourites();
  const handleOpenTruckDetail = (el) => (event) => {
    event.stopPropagation();
    navigate(`${routes.PRODUCT_DETAILS.pathname}?id=${data.id}`);
  };
  const isAuth = getLocalStorageItem('AUTH_TOKEN');
  const navigate = useNavigate();
  const [likeStatus, setLikedStatus] = useState(data.is_favourite);
  const handleShare = (event, data) => {
    event.stopPropagation();
    setOpenShare(event.currentTarget);
    setCurrentShareLinkData(data);
  };

  const handleCloseShare = () => {
    setOpenShare(null);
  };

  const OpenShareList = Boolean(OpenShare);

  const handleHeart = (action, id) => (e) => {
    e.stopPropagation();
    if (!isAuth || isAuth == 'undefined') {
      navigate('/login');
      return;
    }
    // console.log({action,id})
    if (action == 'like') {
      setLikedStatus(true);
      AddToFavourites(
        {
          listing: id,
        },
        {
          onSuccess: (res) => {
            refetch();
          },

          onError: (err) => {
            setLikedStatus(false);
          },
        }
      );
    } else if (action == 'unlike') {
      setLikedStatus(false);
      RemoveFromFavourites(
        {
          id: id,
        },
        {
          onSuccess: (res) => {
            refetch();
          },
          onError: (err) => {
            setLikedStatus(true);
          },
        }
      );
    }
  };

  function getMailtoUrl(to, subject, body) {
    const args = [];
    if (typeof subject !== 'undefined') {
      args.push('subject=' + encodeURIComponent(subject));
    }
    if (typeof body !== 'undefined') {
      args.push('body=' + encodeURIComponent(body));
    }
    let url = 'mailto:' + encodeURIComponent(to);
    if (args.length > 0) {
      url += '?' + args.join('&');
    }
    return url;
  }

  const handleSharing = (el) => (e) => {
    e.stopPropagation();
    let link =
      process.env.REACT_APP_URL +
      'inventory-details?id=' +
      currentShareLinkData.id;
    const imageUrl = currentShareLinkData.attachments?.[0]?.attachment;
    const copyLinkAndImage = async () => {
      try {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.src = imageUrl + '?not-from-cache-please';

        img.onload = async () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);

          // Convert canvas image to blob (PNG format)
          canvas.toBlob(async (pngBlob) => {
            const data = [
              new ClipboardItem({
                'text/plain': new Blob([link], { type: 'text/plain' }),
                'image/png': pngBlob,
              }),
            ];

            await navigator.clipboard.write(data);
            console.log('Link and image copied successfully!');
          }, 'image/png');
        };

        img.onerror = (errorEvent) => {
          console.error('Error loading the image.', errorEvent);
        };
      } catch (error) {
        console.error('Error copying to clipboard', error);
      }
    };

    switch (el.value) {
      case 'mail':
        {
          window.location.href = getMailtoUrl(
            'no-reply@example.com',
            'Check this Rig out',
            link
          );
        }
        break;
      case 'twitter':
        {
          var url =
            'https://twitter.com/share?url=' +
            encodeURIComponent(link) +
            '&t=Share on Twitter';
          window.open(url, '_blank');
        }
        break;
      case 'instagram':
        {
          var url =
            'https://www.instagram.com/?url=' + encodeURIComponent(link);
          window.open(url, '_blank');
        }
        break;
      case 'linkedin':
        {
          var url =
            'https://www.linkedin.com/share?url=' +
            encodeURIComponent(link) +
            '&t=Share on Linked In';
          window.open(url, '_blank');
        }
        break;
      case 'facebook':
        {
          var url =
            'https://www.facebook.com/sharer/sharer.php?u=' +
            encodeURIComponent(link) +
            '&t=Share on Facebook';
          window.open(url, '_blank');
        }
        break;
      case 'copy_link': {
        copyLinkAndImage();
        setCopySuccess(true);

        setTimeout(() => {
          setCopySuccess(false);
        }, 2000);
      }
    }
  };

  // console.log(copiedSuccess)
  const getImgSortFunction = (attachments) => {
    // Fix for a cases where after payment redirection entire applications execution was stopping
    // Something went wrong screen
    if (Array.isArray(attachments) && attachments.length) {
      const imgArray = attachments?.filter((el) => el.description === 'IMAGE');
      const imgArrayAscending = [...imgArray].sort((a, b) =>
        a.is_primary > b.is_primary ? -1 : 1
      );
      return imgArrayAscending;
    } else {
      return [];
    }
  };

  return (
    <>
      <Popover
        open={OpenShareList}
        anchorEl={OpenShare}
        onClose={handleCloseShare}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuList>
          {shareOptions?.map((el, index) => {
            return (
              <>
                <MenuItem
                  onClick={handleSharing(el)}
                  justifyContent="left"
                  className=" h-10 !w-[189px] flex !items-center !text-left "
                >
                  <img className="w-6 h-5 pr-2" src={el.icon} alt="" />
                  <Typography className="!items-center flex align-center !m-0 text-left">
                    {el.value === 'copy_link' && copiedSuccess ? (
                      <span className="text-green-500">Link Copied!</span>
                    ) : (
                      el.label
                    )}
                  </Typography>
                </MenuItem>
                {index !== shareOptions.length - 1 && (
                  <Divider className="!p-0 !m-0 !bg-gray-10" />
                )}
              </>
            );
          })}
        </MenuList>
      </Popover>
      <Grid
        spacing={4}
        item
        sx={{ boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.08)' }}
        className={`rounded-md p-2 h-[377px] w-[290px] min-w-[240px] cursor-pointer relative bg-white `}
      >
        <Grid item className="h-[195px] overflow-hidden relative w-[274px]">
          {' '}
          <Grid
            item
            className="absolute !flex top-0 right-0  z_index_adjustment pr-2 !items-center "
          >
            {likeStatus ? (
              <span
                onClick={handleHeart('unlike', data.id)}
                className="  hover:scale-105 cursor-pointer "
              >
                {likedHeart()}
              </span>
            ) : (
              <span
                onClick={handleHeart('like', data.id)}
                className="  hover:scale-105 cursor-pointer "
              >
                {Likeheart()}
              </span>
            )}
            <span
              onClick={(e) => handleShare(e, data)}
              className="hover:scale-105  cursor-pointer mb-3"
            >
              {share()}
            </span>
          </Grid>
          <Swiper
            pagination={{
              clickable: false,
            }}
            modules={[Pagination]}
            allowTouchMove={true}
            preventClicksPropagation={true}
            preventClicks={true}
          >
            {getImgSortFunction(data?.attachments)?.map((el) => {
              // console.log(el.mime_type);
              if (el.mime_type !== 'docs/pdf') {
                return (
                  <>
                    {/* <SwiperSlide className="justify-center text-center bg-secondary-90">
                      <Image.PreviewGroup
                        // items={{
                        //   el?.attachment
                        //   ? el.attachment
                        //   : '/assets/landingPage/Truck_placeholder.jpg',
                        // }}
                        width={200}
                      >
                        <img
                          src={
                            el?.attachment
                              ? el.attachment
                              : '/assets/landingPage/Truck_placeholder.jpg '
                          }
                          alt={el?.name || 'truck product'}
                          className="rounded-md h-[195px] overflow-hidden w-auto text-center max-w-[274px] "
                        />
                      </Image.PreviewGroup>
                      
                    </SwiperSlide> */}
                    <SwiperSlide className="justify-center text-center bg-secondary-90">
                      <Image.PreviewGroup
                        items={data?.attachments
                          ?.filter((el) => el.mime_type !== 'docs/pdf') // Filter out 'docs/pdf' mime types
                          .map((el) =>
                            el?.attachment
                              ? el.attachment
                              : '/assets/landingPage/Truck_placeholder.jpg'
                          )}
                      >
                        <Image
                          style={{
                            borderRadius:
                              '0.375rem' /* Equivalent to rounded-md in Tailwind */,
                            height: '195px',
                            overflow: 'hidden',
                            width: 'auto',
                            textAlign: 'center',
                            maxWidth: '274px',
                          }}
                          src={
                            el?.attachment
                              ? el.attachment
                              : '/assets/landingPage/Truck_placeholder.jpg'
                          }
                        />
                      </Image.PreviewGroup>
                    </SwiperSlide>
                  </>
                );
              }
            })}
          </Swiper>
        </Grid>

        <Grid item onClick={handleOpenTruckDetail(data)}>
          <Grid item className="p-2 h-[70px] ">
            <Typography
              variant="h6"
              sx={{ textTransform: 'capitalize' }}
              className=" !font-semibold !font-[Inter] !text-gray-80"
            >
              {data.title}
            </Typography>
          </Grid>

          <Grid
            lg={12}
            md={12}
            xs={12}
            item
            container
            justifyContent="space-around"
            className="py-2 !min-h-[50px] items-center trcklctaln "
          >
            <Grid lg={6} md={6} xs={6} item className="trcklctWd">
              <Typography
                variant="h4"
                sx={{ fontWeight: 600 }}
                className=" !font-semibold !text-[24px] !font-[Inter] !text-gray-90"
              >
                $
                {numberWithCommas(data?.selling_price?.toString() || 'NA') ||
                  '0'}
              </Typography>
            </Grid>
            <Grid
              spacing={1}
              lg={6}
              md={6}
              xs={6}
              justifyContent="right"
              alignItems="middle"
              item
              container
              className="trcklctWd"
            >
              <Box className="flex items-center">
                <img
                  className="w-[12px] h-[14px] pr-1"
                  src={'/assets/location.svg'}
                  alt="location"
                />

                <Typography className="!text-[12px] !font-semibold !text-gray-70 !font-[Inter]">
                  {data?.city},{data?.state}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="baseline"
            className="!mt-[6px] bg-[#EDEDED] p-2 rounded-md max-w-[274px] "
          >
            <Grid item container alignItems="center" className="!w-auto">
              <Grid item>
                <img src={'/assets/meter.svg'} alt="meter" />
              </Grid>
              <Grid item>
                <Typography className="!text-[10px] px-[5px] !text-[#262626] !font-[Inter]">
                  {data?.description === 'Trailer'
                    ? `${data?.vehicle?.hour || 'NA'} Hours`
                    : `${numberWithCommas(
                        data?.vehicle?.mileage?.toString() || 'NA'
                      )} miles`}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container alignItems="center" className="!w-auto">
              <Grid item>
                <img src={'/assets/piston.svg'} alt="piston" />
              </Grid>
              <Grid item>
                <Typography className="!text-[10px] !text-[#262626] px-[5px] !font-[Inter]">
                  {data?.vehicle?.make}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container alignItems="center" className="!w-auto">
              <Grid item>
                <img src={'assets/gear.svg'} alt="gear" />
              </Grid>
              <Grid item>
                <Typography className="!text-[10px]  !text-[#262626] px-[5px] !font-[Inter]">
                  {data?.vehicle?.gear_type}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default TruckCard;
