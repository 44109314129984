import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { Grid, Box, Typography, useMediaQuery, useTheme, Modal, Divider } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useGetPrivacyData } from '../../query/contactUs';
// import HeaderImg from './HeaderImg';
// import Content from './Content';
import Content from './ContentFirst';



const ImagSec = () => {
   const img = process.env.PUBLIC_URL + '/contactusImg.png';

 
   return (<>
    
        <Grid container>
         <Grid item justifyContent='center' alignItems='center'>
              <img
                     src={img}
                     alt="Example Image"
                     style={{ width: '620px', height: '717px', backgroundRepeat:'no-repeat',
                     backgroundSize:'cover',
                     backgroundPosition:'center' }}
                     className='formimg' />
         </Grid>
        </Grid>
   
   </>)

}

const ContactUs = () => {
    const theme = useTheme();
    const isBelowMedium = useMediaQuery(theme.breakpoints.down('md'))
    const { data: privacyPolicy, refetch: refetchPrivacy, isLoading: isLoadingPrivacy } = useGetPrivacyData();

    useEffect(()=>{
      refetchPrivacy();
    },[refetchPrivacy]);
   
    return(

        <Box>
           <Grid container lg={12} md={12} sm={12} xs={12} className='' justifyContent='center' padding={2}>
              
           <Grid item lg={5} md={6} sm={6} xs={12} className='formpd' justifyContent='center' padding={2}>
      <Content privacydata={privacyPolicy} />
    </Grid>

              <Grid item  lg={5} md={5} sm={5} xs={12} className='' style={{borderRadius:'8px'}} paddingTop={4.5} justifyContent='center'>
                 {/* <Grid item lg={12} md={12} sm={12} xs={12} className=' !h-[100%]' style={{borderRadius:'8px'}}> */}
                    <ImagSec />
                 </Grid>
              {/* </Grid> */}
             </Grid> 
        </Box>
    )
} 

export default ContactUs;