import {
  Grid,
  Typography,
  
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { CustomTextField } from 'components';
import CustomButton from 'components/CustomButton/CustomButton';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { gapi } from 'gapi-script';
import {
  CircularProgress,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
 
import { useForm } from 'react-hook-form';
 import {RxCrossCircled} from 'react-icons/rx'
import useToast from 'utils/responseToast';
 
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useRegisterQuery } from 'query/Auth';
import { routes } from 'routes/RouteConstants';
import { useRefContext } from 'components/Layout';
import { formatPhoneNumber,formatMobileNumber, normalizePhone } from 'utils/phone';
 


  const phoneValidation = (number) => {
        var regexp = /^\d{10}$/
      const valid = regexp.test(normalizePhone(number));
      return valid ? {
        isValid: true,
      } : {
        isValid: false,
        errorMessage: 'Mobile no. should be of 10 characters',
      }   
  }
 
const CreateAccountFormSchema = yup.object().shape({
  name: yup.string().required('Name is required')
        .min(2, 'Must be atleast 2 characters')
        .max(25, 'Must be maximum 25 characters'),
  phone: yup.string().required('Phone No. is required')
        .test('validator-phone', function (value) {
          const validation = phoneValidation(value);
          if (!validation.isValid) {
            return this.createError({
              path: this.path,
              message: validation.errorMessage,
            });
          }
          else {
            return true;
          }
  }),
  email: yup.string().required('Email is required').matches(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,'Enter a valid email'
  ),
  password: yup
    .string()
    .nullable()
    .min(8, 'Password must be at least 8 characters')
    .max(25, 'Password should not exceed 25 characters')
    .matches(/([a-z])/, {
      message: 'Atleast one small letter',
      name: 'small letter',
      excludeEmptyString: true,
    })
    .matches(/[A-Z]/, 'Atleast one capital letter')
    .matches(/(\d)/, 'Atleast one number')
    .matches(/(\W)/, 'Atleast one special character')
    .required('Password is required'),
});
 

const CreateaccountForm = () => {
  const { 
    handleOpenSomethingWentWrongDialog
  }=useRefContext()
  const navigate = useNavigate();
  const location = useLocation();
  const preFilled = location.state;
  const currentType = preFilled?.currentType
  const user = {
    name: '',
    [currentType]: preFilled?.emailPh || '',
    password: '',
  };

 
  const {
    register,
    
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(
      CreateAccountFormSchema
    ),
    defaultValues: user,
  });
 
  const {name,email,phone,password}=watch()

  const handleSetValue = (key, value) => {
      if(key==='phone'){
        const pvalue = ('' + value).replace(/\D/g, '').slice(0,10);      
        setValue(key, pvalue, {shouldValidate: true,});
      } else {
        setValue(key, value, {shouldValidate: true,});
      } 
    
  };
  const { mutate: createAccount } = useRegisterQuery(
    createAccountOnSuccessCallBack
  );

  const [loadingSignUpButton, setLoadingSignUpButton] = useState(false);

  const theme = useTheme();
   

  function createAccountOnSuccessCallBack() {
    setLoadingSignUpButton(false);
    navigate('/');
  }

  const formSubmit = (formValues) => {
    setLoadingSignUpButton(true);
    let data={...formValues,
        name:formValues?.name,
        country_code:'+1',
        password:formValues?.password,
        channel:preFilled?.currentType=="phone"?'PHONE_PASSWORD':'EMAIL_PASSWORD'
    }
    // console.log('register data',data)
    
    createAccount(data,{
        onError:(err)=>{
          if(err.status_code==500){
            handleOpenSomethingWentWrongDialog()
          }
            setError(err.errors[0].field,{type:'custom',message: err.errors[0].message+" "+"("+err.errors[0].field+")"})
            setLoadingSignUpButton(false)
        }
    })
  };
  const handleCancleSignUp=()=>{
    navigate(routes.SIGNUP.pathname)
  }
if(!currentType){
  return <Navigate to={routes.SIGNUP.pathname}/>
}
  return (
    <Grid
      container
      className="h-[100%] "
      alignItems="center"
      justifyContent="center"
    >
      <Grid
        item
        container
        className="flex  max-w-[360px] h-auto !w-full"
        direction="column"
      >
        <form onSubmit={handleSubmit(formSubmit)}>
          <Grid item className=" text-center items-start m-auto  ">
            <Typography variant="h4" className="!font-[inter]  font-semibold ">
              Create an account
            </Typography>
            <Typography className="text-gray-60 !font-[inter] pt-2 text-center">
              Welcome back! Please enter your details.
            </Typography>
          </Grid>

          <Grid className="m-auto my-4" item container justifyContent="center">
            <Typography className="!font-[inter] flex justify-between items-center bg-gray-20  rounded-xl my-4 px-4 text-center ">
              <span>
                {currentType == 'email'
                  ? preFilled?.emailPh
                  : formatPhoneNumber(preFilled?.emailPh, true)}
              </span>
              <RxCrossCircled
                className="pl-4 cursor-pointer"
                onClick={handleCancleSignUp}
              />
            </Typography>
          </Grid>

          <Grid
            container
            justifyContent="center"
            className="!m-auto !justify-center flex"
            item
          >
            <Grid
              item
              className="flex-col justify-center max-w-[360px] !w-full"
            >
              <Grid justifyContent="center " className="m-auto" item>
                <CustomTextField
                  error={Boolean(errors.name)}
                  helperText={errors.name && errors.name.message}
                  FormHelperTextProps={{
                    className: ' animateErrorValue ',
                  }}
                  label="Name"
                  placeholder="Enter your name"
                  className="!max-w-[360px] !w-full"
                  onChange={(e) => handleSetValue('name', e.target.value)}
                />
              </Grid>
              <Grid item className="py-5">
                {currentType == 'email' ? (
                  <CustomTextField
                    error={Boolean(errors.phone)}
                    helperText={errors.phone && errors.phone.message}
                    FormHelperTextProps={{
                      className: 'animateErrorValue ',
                    }}
                    label="Enter Phone Number"
                    placeholder="(xxx) - xxx -xxxx"
                    //onChange={(e) => handleSetValue('phone', e.target.value)}
                    onChange={(e) =>
                      handleSetValue(
                        'phone',
                        e?.target?.value?.trimStart().replace(/\D/, '')
                      )
                    }
                    //maxLength={10}
                    className="max-w-[360px]"
                    value={formatMobileNumber(phone, true)}
                  />
                ) : (
                  <CustomTextField
                    error={Boolean(errors.email)}
                    helperText={errors.email && errors.email.message}
                    FormHelperTextProps={{
                      className: 'animateErrorValue ',
                    }}
                    label="Email address"
                    placeholder="Email address"
                    onChange={(e) => handleSetValue('email', e.target.value)}
                    className="max-w-[360px]"
                  />
                )}
              </Grid>
              <Grid item className="pb-5">
                <CustomTextField
                  error={Boolean(errors.password)}
                  helperText={errors.password && errors.password.message}
                  FormHelperTextProps={{
                    className: ' animateErrorValue ',
                  }}
                  label="Create Password"
                  type="password"
                  placeholder="Create Password"
                  onChange={(e) => handleSetValue('password', e.target.value)}
                  className="max-w-[360px]"
                />
                <Typography className="text-secondary-50 text-sm pt-2">
                  Must be at least 8 characters.
                </Typography>
              </Grid>

              <Grid item>
                {loadingSignUpButton ? (
                  <CustomButton styles="max-w-[360px] max-h-[44px]">
                    <CircularProgress size={30} className="!text-white" />
                  </CustomButton>
                ) : (
                  <CustomButton type="submit" styles="max-w-[360px]">
                    Sign up
                  </CustomButton>
                )}
              </Grid>
            </Grid>
          </Grid>
        </form>

        <Grid
          item
          container
          justifyContent="center"
          className="text-gray-60 pt-5 "
        >
          <Typography className="!font-[inter] py-2 text-sm">
            Already have an account?{' '}
            <span
              onClick={() => {
                navigate(routes.LOGIN);
              }}
              className="!text-secondary-70 no-underline  font-medium cursor-pointer"
            >
              Log In
            </span>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CreateaccountForm;
