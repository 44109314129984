import { Button, Typography, Modal, Box, Grid } from '@mui/material';
import React from 'react';
import Lottie from 'react-lottie-player';
import { useLocation, useNavigate } from 'react-router';
import { routes } from 'routes/RouteConstants';
// import done from '../../../utils/gif/done.json';
import done from '../../../utils/gif/done.json';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '500px',
  height: '350px',
  transform: 'translate(-50%, -50%)',
  opacity: '2.8',
  backgroundColor: '#FFFFFF',
  boxShadow:
    '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
  borderRadius: '12px',
  p: 4,
};

const btnStyle = {
  backgroundColor: '#FFFFFF',
  width: '150px',
  height: '34px',
  boxShadow: 'rgba(0, 0, 0, 0.1)',
  borderRadius: '40px',
  fontSize: '12px',
  color: '#FFC200',
  border:'1px solid #757575',
  fontWeight: '400'
}

export const ResponseStatusPage = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;

  console.log('Props', props);
  const { isOpen, onClose } = props;

  const handleBackToHome = () => {
    navigate(routes.REDIRECTOR);
  };
  const handleSignin = () => {
    navigate(routes.LOGIN);
  }

  const handleClose = () => {
    props.onClose(onClose(false));
  }
  return (
    <div>
      <Modal open={isOpen}

        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">

        <Grid container justifyContent='center' className='' style={style}>

          <Grid item className='!h-[110px]' lg={12} md={12} sm={12} xs={12} justifyContent='center'>

            {props?.animation ? <Lottie
              animationData={props?.animation}
              play
              style={{ width: '200px', height: '200px', margin: 'auto' }}
            /> : <img src={data?.img} />

            }
          </Grid>

          <Grid item container lg={12} md={12} sm={12} xs={12} className="flex" justifyContent='center' marginTop='40px'>
            <Grid item lg={12} md={12} sm={12} className='flex ' justifyContent='center'>
              <Typography className="" style={{ fontSize: '21px', fontWeight: '600', fontFamily: 'Poppins', color: '#05945B', display: 'flex', justifyContent: 'center' }}>
                {props?.first_message}
              </Typography>
              {/* </Grid> */}

              {props?.second_message ?
                // (<Grid item lg={12} md={12} sm={12} xs={12} className="flex" justifyContent='center' marginTop='-20px'>
                (<Typography className="" style={{ fontSize: '15px', fontWeight: '400', fontFamily: 'Poppins', color: '#757575' }}>
                  {props?.second_message}
                </Typography>
                )
                : ''}
            </Grid>
          </Grid>

          {props?.isSettingPage ? (
  <Grid item className='flex' lg={12} md={12} sm={12} xs={12} justifyContent='center'>
    <Button
      onClick={handleSignin}
      // variant='outlined'
      className=""
      style={btnStyle}
    >
      Log In
    </Button>
  </Grid>
) : (props?.isContactUsPage ? (
  <Grid item lg={12} md={12} sm={12} xs={12} className='flex' justifyContent='center'>
    <Button
      onClick={handleClose}
      className=" "
      // variant="outlined"
      style={btnStyle}
    >
      Close
    </Button>
  </Grid>
) : (
  <Grid item lg={12} md={12} sm={12} xs={12} className='flex' justifyContent='center'>
    <Button
      onClick={handleBackToHome}
      className=" "
      // variant="outlined"
      style={btnStyle}
    >
      Back to Home
    </Button>
  </Grid>
))}

        </Grid>
      </Modal>
    </div>
  );
};

// export default ResponseStatusPage;
