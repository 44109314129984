import { Typography } from "@mui/material";
import React, { Component } from "react";

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Typography
          variant="h6"
          className="text-base font-semibold"
          color="error.main"
          align="center"
        >
          Something went wrong.
        </Typography>
      );
    }

    return this.props.children;
  }
}
