export const QueryKey = {
  GET_POPULAR_BRANDS: 'GET_POPULAR_BRANDS',
  GET_FEATURED_INVENTORY_DATA: 'GET_FEATURED_INVENTORY_DATA',
  GET_SEARCH_INVENTORY_DATA: 'GET_SEARCH_INVENTORY_DATA',
  GET_TOP_SEARCH_DATA: 'GET_TOP_SEARCH_DATA',
  GET_LOCATION_ADDRESS: 'GET_LOCATION_ADDRESS',
  GET_FILTERS: 'GET_FILTERS',
  GET_FILTERED_DATA: 'GET_FILTERED_DATA',
  GET_FAVOURITE_DATA: 'GET_FAVOURITE_DATA',
  GET_PRODUCT_LIST_DATA: 'GET_PRODUCT_LIST_DATA',
  GET_PRODUCT_DETALS_DATA: 'GET_PRODUCT_DETALS_DATA',
  GET_PAYMENT_CARD_DATA: 'GET_PAYMENT_CARD_DATA',
  GET_NEWLY_ADDED: 'GET_NEWLY_ADDED',
  GET_FINANCE_DETALS_DATA: 'GET_FINANCE_DETALS_DATA',

  GET_USER_PROFILE: 'GET_USER_PROFILE',
  GET_REVIEW_DATA: 'GET_REVIEW_DATA',

  GET_EQUIPMENTS_LIST_DATA: 'GET_EQUIPMENTS_LIST_DATA',
  GET_EQUIPMENTS_DETAILS_DATA: 'GET_EQUIPMENTS_DETAILS_DATA',

  GET_Transaction_DATA: 'GET_Transaction_DATA ',
  GET_Transaction_DATA_BY_ID: 'GET_Transaction_DATA_BY_ID ',

  GET_Transaction_QUOTE_DATA: 'GET_Transaction_QUOTE_DATA ',
  GET_Transaction_QUOTE_DATA_BY_ID: 'GET_Transaction_QUOTE_DATA_BY_ID',

  GET_Transaction_INSPECTION_DATA: 'GET_Transaction_INSPECTION_DATA',
  GET_Transaction_INSPECTION_DATA_BY_ID: 'GET_Transaction_INSPECTION_DATA_BY_ID',
  GET_PRIVACYPOLICY_DATA : ' GET_PRIVACYPOLICY_DATA ',
  GET_NOTIFICATION : 'GET_NOTIFICATION',

};
