import React, { useEffect, useState } from 'react';

import { DataListing } from './DataListing';
import { Grid,  useTheme, useMediaQuery } from '@material-ui/core';
import { Filters } from './Filter';
import { useGetFilterData, useGetFilters } from 'query/HomeFilterInventory';
import { PaginationCustom } from './Pagination';
import { AppliedFiltersComponent } from './Filter/AppliedFiltersComponent';
import { Box } from '@mui/material';
import { useRefContext } from 'components/Layout';
import {  useLocation } from 'react-router-dom';

export const InventoryListing = () => {
  const location = useLocation();
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const [isPage, setIsPage] = useState(1);
  const [filterFilter, setFilterFilter] = useState([]);

  const { filterValues, setFilterValues } = useRefContext();

  const {
    data: filteredData,
    isLoading,
    refetch,
  } = useGetFilterData(filterValues, isPage); //listing data API
  const {
    data: filterData,
    refetch: filterRefetch,
    dataUpdatedAt,
  } = useGetFilters(filterFilter);

  useEffect(() => {
    filterRefetch(filterFilter);
  }, [filterFilter]);

  useEffect(() => {
    refetch(filterValues, isPage);
  }, [filterValues, isPage]);

  useEffect(() => {
    if (location?.state?.forFilter?.length > 0) {
      setFilterFilter([
        // ...filterFilter,
        location?.state?.forFilter?.[0],
        location?.state?.forFilter?.[1],
      ]);
      setFilterValues([
        // ...filterValues,
        location?.state?.forFilter?.[0],
        location?.state?.forFilter?.[1],
      ]);
      location.state = null;
    }
  }, [location?.state?.forFilter]);

  useEffect(() => {
    if (location?.state?.forBrand?.length > 0) {
      setFilterFilter([
        // ...filterFilter,
        location?.state?.forBrand?.[0],
      ]);
      setFilterValues([
        // ...filterValues,
        location?.state?.forBrand?.[0],
      ]);
      location.state = null;
    }
  }, [location?.state?.forBrand]);

  useEffect(() => {
    const resetFilter = filterFilter?.filter((data) =>
      ['Commercial Truck', 'Dry Van', 'Trailer']?.includes(data?.label)
    );
    if (resetFilter?.length > 1) {
      setFilterFilter([resetFilter.slice(-1)?.[0]]);
      setFilterValues([resetFilter.slice(-1)?.[0]]);
    }
  }, [filterFilter]);

  return (
    <Box
      className=" flex p-5 max-w-[1700px] !justify-between m-auto truckCrdMb lg:mt-5"
      sx={{ marginTop: '141px important' }}
      container
    >
      {!isMedium ? (
        <Grid sx={{ overflow: 'scroll' }} lg={3} item container>
          <Filters
            data={filterData}
            dataUpdatedAt={dataUpdatedAt}
            refetch={filterRefetch}
            filterFilter={filterFilter}
            setFilterFilter={setFilterFilter}
            isappliedFilterVisible={true}
            appliedFilters={filterValues}
            setAppliedFilters={setFilterValues}
          />
        </Grid>
      ) : (
        <></>
      )}
      {isMedium && (
        <Box
          justifyContent="center"
          alignItems="center"
          sx={{ width: '100%' }}
          item
        >
          <AppliedFiltersComponent
            refetchFilters={filterRefetch}
            filterFilter={filterFilter}
            setFilterValues={setFilterFilter}
            filterBorder={true}
            appliedFilters={filterValues}
            setAppliedFilters={setFilterValues}
          />
        </Box>
      )}
      <Box
        justifyContent="center"
        className="w-full"
        lg={9}
        md={12}
        item
        container
      >
        <Box
          sx={{
            overflowY: 'scroll',
            overflowX: 'hidden',
            maxHeight: '1500px',
            paddingY: '10px',
          }}
        >
          <DataListing
            data={filteredData?.results}
            isloading={isLoading}
            refetch={refetch}
          />
        </Box>
        <Box className="!flex !justify-center !text-center ">
          {filteredData?.results?.length > 0 ? (
            <PaginationCustom
              pagination={filteredData?.pagination_option}
              isPage={isPage}
              setIsPage={setIsPage}
            />
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default InventoryListing;
