import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { currencyFormat } from 'utils';
import './SMRR.css';
const useStyles = makeStyles((theme) => ({
  myComponent: (props) => ({
    width: props.width || '250px',
    height: '31px',
    borderRadius: '100px',
    color: props.color,
    backgroundColor: props.background,
    fontSize: '14px',
  }),
}));
// to get 2.5% of original price to be paid
const getTokenMoney = (price) => {
  const tokenMoney = price * 0.025;
  return tokenMoney;
};
const ButtonComponent = (props) => {
  //set value of button label
  //console.log('B PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPprops',props);
  const [firstBtnVal, setFirstBtnVal] = useState(null);
  useEffect(() => {
    if (props?.value && !props?.isProductInfoPage) {
      console.log('first', props?.value);
      if (props?.value === 'DEPOSIT') {
        setFirstBtnVal(
          `Deposit ${currencyFormat(getTokenMoney(props?.price))} (2.5%)`
        );
      } else if (props?.value === 'DEPOSIT_SUCCESSFUL') {
        setFirstBtnVal('Deposit Successful');
      } else if (props?.value === 'DENIED') {
        setFirstBtnVal('Quote Denied');
      } else if (props?.value === 'AMOUNT_REFUNDED') {
        setFirstBtnVal('Quote Denied');
      } else if (props?.value === 'QUOTE_SENT') {
        setFirstBtnVal('Quote Requested');
      } else if (props?.value === 'QUOTE_REMOVED') {
        setFirstBtnVal('Quote Removed');
      } else if (props?.value === 'COMPLETE') {
        setFirstBtnVal('Financing Completed');
      } else if (props?.value === 'PRODUCT_ON_HOLD') {
        setFirstBtnVal('Product on Hold');
      } else if (props?.value === 'PRODUCT_SOLD') {
        setFirstBtnVal('Product Sold');
      } else if (props?.value === 'PRODUCT_REJECTED') {
        setFirstBtnVal('Product Rejected');
      } else if (props?.value === 'REVIEW_PENDING') {
        setFirstBtnVal('Review pending by SMR');
      } else if (props?.value === 'INSPECTION_FEE_PENDING') {
        setFirstBtnVal(`Pay ${currencyFormat(props.price)} Inspection Fee`);
      } else if (props?.value === 'INSPECTION_PENDING') {
        setFirstBtnVal(`Inspection Pending`);
      } else if (props?.value === 'PRODUCT_REMOVED') {
        setFirstBtnVal('Product Removed');
      } else if (props?.value === 'REMOVAL_REQUESTED') {
        setFirstBtnVal('Requested for Removal');
      } else if (props?.value === 'PRODUCT_LISTED') {
        setFirstBtnVal('Product Listed');
      }
    }
    //when we redirect to Product details page
    else if (props?.value && props?.isProductInfoPage) {
      if (props?.value === 'DEPOSIT') {
        setFirstBtnVal(
          `Deposit ${currencyFormat(getTokenMoney(props?.price))} (2.5%)`
        );
      } else if (props?.value === 'DEPOSIT_SUCCESSFUL') {
        setFirstBtnVal('Deposit Successful');
      } else if (props?.value === 'DENIED') {
        setFirstBtnVal('Quote Denied');
      } else if (props?.value === 'AMOUNT_REFUNDED') {
        setFirstBtnVal('Quote Denied Amount Refunded');
      } else if (props?.value === 'QUOTE_SENT') {
        setFirstBtnVal('Quote Requested');
      } else if (props?.value === 'COMPLETE') {
        setFirstBtnVal('Financing Completed');
      } else if (props?.value === 'PRODUCT_ON_HOLD') {
        setFirstBtnVal('Product on Hold');
      } else if (props?.value === 'PRODUCT_SOLD') {
        setFirstBtnVal('Product Sold');
      } else if (props?.value === 'PRODUCT_REJECTED') {
        setFirstBtnVal('Product Rejected');
      } else if (props?.value === 'REVIEW_PENDING') {
        setFirstBtnVal('Review pending by SMR');
      } else if (props?.value === 'INSPECTION_FEE_PENDING') {
        setFirstBtnVal(`Pay ${currencyFormat(props.price)} Inspection Fee`);
      } else if (props?.value === 'INSPECTION_PENDING') {
        setFirstBtnVal('Inspection Pending');
      } else if (props?.value === 'PRODUCT_REMOVED') {
        setFirstBtnVal('Product Removed');
      } else if (props?.value === 'REMOVAL_REQUESTED') {
        setFirstBtnVal('Requested for Removal');
      } else if (props?.value === 'PRODUCT_LISTED') {
        setFirstBtnVal('Product Listed');
      }
    }
  }, [firstBtnVal, props?.value]);
  //console.log('props', props);
  const classMap = {
    inspection_fee_pending: 'inspectionFeeBtnStyle',
    pending: 'extendBtnStyle',
    completed: 'waitingBtnStyle',
    denied: 'deniedBtnStyle',
    sent: 'quoteSentBtnStyle',
    financing: 'completeBtnStyle',
    remove: 'normalextbtnStyle',
  };
  const classes = useStyles(props);
  return (
    <>
      <Button
        className={props.type ? classMap[props.type] : ''}
        style={{
          width: props.width || '270px',
          textTransform: 'none',
          height: '32px',
        }}
      >       
        {firstBtnVal ? firstBtnVal : 'NA'}
      </Button>
    </>
  );
};
export default ButtonComponent;
