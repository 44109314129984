import { CircularProgress, Grid, Typography } from '@mui/material'
import { CustomTextField } from 'components'
import CustomButton from 'components/CustomButton/CustomButton'
import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import * as yup from 'yup';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useToast from 'utils/responseToast'
 
import 'react-toastify/dist/ReactToastify.css';
import { commonToastConfig } from 'utils/CommonToastConfig'
import { useForgotPasswordQuery, useOnboardingQuery, useSendOtpQuery } from 'query/Auth'
import { formatPhoneNumber, normalizePhone } from 'utils/phone'
import { useRefContext } from 'components/Layout'


const signupOnboardigSchema = yup.object().shape({
    emailPh: yup
      .string()
      .nullable()
      .required('Please enter email or phone number').min(2,'Atleast 2 characters')
  });

export const EmailForm = ({ currentType, setOtpError,setPrefilled, setforgotPassword, data,setData,setOpenOtp,setCurrentType,setOTPerror}) => {
    
    
   
    const {
         
        control,
        setValue,
        handleSubmit,
        formState: { errors },setError:setOnboardingError,
      } = useForm({resolver: yupResolver(signupOnboardigSchema)});
      const [blur, setBlur] = useState(false)
      const {handleOpenSomethingWentWrongDialog}=useRefContext()
    const sendOtpOnSuccess=(message)=>{
      
        // setLoading(false)
        setOpenOtp(true)
        
    }
    const emailPh=useWatch({control,name:'emailPh'})
    const sendOtpOnError=(message)=>{
       setOtpError(message)
  
    
    }
    
    const OnboardingpOnError=(message)=>{
      setOnboardingError('emailPh',{type:'custom', message:message})
  
    
    }
    const {mutate: sendOtp,isLoading}= useSendOtpQuery(sendOtpOnSuccess, sendOtpOnError)
    const {mutate:onBoarding,isLoading:loadingOnboardingStatus}= useOnboardingQuery()

    const handleBacktoLogin=()=>{
        setforgotPassword(false)
    }
     function validatePhoneNumber(input_str) {
        var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    
        return re.test(input_str);
      }
      function validateEmail(email) {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      }
    
   
 
    const handleResetPassword=(data)=>{
        setData(data)
        if(validateEmail(data.emailPh)){
            setCurrentType('email')
            
            onBoarding({email:  data.emailPh},{

                onSuccess:(res)=>{
                    if(res.data.intend_to=='SIGNUP'){
                        setOnboardingError('emailPh',{type:'custom',message:`Email Does not exist in our system`})
                        return
                      }
                     
                        setPrefilled({
                            "email":data.emailPh,
                            "channel": "EMAIL_OTP",
                            "otp_for": "Forgot Password"
                          })
                        sendOtp({
                            "email":data.emailPh,
                            "channel": "EMAIL_OTP",
                            "otp_for": "Forgot Password"
                          })
                    
                    
                },onError:(err)=>{
                    OnboardingpOnError(err.message)
                    if(err.status_code==500){
                      handleOpenSomethingWentWrongDialog()
                    }
                }
                
            })

          
        }else if(validatePhoneNumber(data.emailPh)){
            setCurrentType('mobile')
            onBoarding({phone:normalizePhone( data.emailPh)},{ onSuccess:(res)=>{
                if(res.data.intend_to=='SIGNUP'){
                    setOnboardingError('emailPh',{type:'custom',message:`Number does not exist in our system`})
                    return
                  }
                setPrefilled({
                    "country_code": "+1",
                    "phone": normalizePhone( data.emailPh),
                    "channel": "PHONE_OTP",
                    "otp_for": "Forgot Password"
                  })
                sendOtp({
                    "country_code": "+1",
                    "phone": normalizePhone( data.emailPh),
                    "channel": "PHONE_OTP",
                    "otp_for": "Forgot Password"
                  })
            },onError:(err)=>{
                OnboardingpOnError(err.message)
                if(err.status_code==500){
                  handleOpenSomethingWentWrongDialog()
                }
            }
        })
            
            
        }
      else{
       
        setOnboardingError('emailPh',{"type":'custom', message:'Please Enter a valid email or phone number'})
      }
         
    }

    const handleSetValue = (key, value) => {
      setValue(key, value, {
        shouldValidate: true,
      });
    };
  return (
    <>
     {/* <Grid container> */}
    
           <Grid className='text-center py-4 m-auto' md={12} xs={12} lg={12} item>
                <img className='w-[56px] h-[56px] text-center' src='/assets/Auth/ForgotPasswordKey.svg' alt="icon" />
            </Grid>
            <Grid  item> 
             <Typography variant="h4" className="!font-[inter] !font-semibold py-2">Forgot Password?</Typography>
            </Grid>
            <Grid item> 
             <Typography  className=" text-gray-60 !font-[inter] py-4 ">No worries, we'll send you reset instructions</Typography>
            </Grid>
            <form onSubmit={handleSubmit(handleResetPassword ,(e)=>{
                console.log(e)
            })}>
            <Grid justifyContent='center' alignItems='center' spacing={2} className='py-4' container item>
                <Grid className='w-[100%] py-2' lg={12} item>

                    <CustomTextField
                label="Enter Email or Phone Number"
                placeholder="Email or Phone Number"
                className="max-w-[400px]"
                error={Boolean(errors?.emailPh)}
                
                    FormHelperTextProps={{
                    className: 'animateErrorValue '
                    }} 
                
                value={formatPhoneNumber(emailPh, blur)}
                onBlur={()=>setBlur(true)}
                
                onChange={(e) => handleSetValue('emailPh', e.target.value)}
                helperText={errors?.emailPh?.message}
                />
                   
                </Grid>
                <Grid className='w-[100%] py-2'   lg={12} item>
                   {loadingOnboardingStatus ? <CustomButton styles='max-w-[400px]' ><CircularProgress size={30} className='!text-white' sx={{color:'white'}} /></CustomButton>: <CustomButton styles='max-w-[400px]' type='submit' >Reset Password</CustomButton>}
                </Grid>
               
                <Grid className='!mt-4' onClick={handleBacktoLogin} item justifyContent="center"  container lg={12} md={12} xs={12} >
                    <ArrowBackIcon className='px-1 text-gray-90'/>
                        <Typography  className='!font-[inter] text-sm pb-4 justify-center '> 
                         
                            <span className='text-sm font-medium cursor-pointer text-gray-90 '>Back to Log in</span> 
                        </Typography>
                    </Grid>
            </Grid>
            </form>
            {/* </Grid> */}
            </>
  )
}
