import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  Popover,
  Typography,
  CircularProgress,
} from '@mui/material';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useEffect, useMemo, useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { AiTwotoneLock } from 'react-icons/ai';
import { CustomTextField } from 'components';
import { CalendarTodayOutlined, LockOpenOutlined } from '@mui/icons-material';
import { useAddCardQuery, useSetUpIntentQuery } from 'query/payment';
import { Navigate, useNavigate } from 'react-router';
import { getLocalStorageItem } from 'hooks';
import { routes } from 'routes/RouteConstants';

const btnCancelStyle = {
  backgroundColor: '#F5F5F5',
  color: '#333333',
  fontWeight: '600',
  fontSize: '16px',
  disableRipple: true,
  borderRadius: '5px',
  height: '54px',
  width: '265px',
  marginRight: "30px",
};

const CardElementWrapper = ({ children, title, width, endAdorment }) => {
  return (
    <div
      style={{ marginLeft: '8px', marginRight: '8px' }}
      className={`${width || 'w-full'}`}
    >
      <FormHelperText
        variant="subtitle2"
        className=" mb-1 text-xs font-semibold text-gray-800"
      >
        {title}
      </FormHelperText>
      <div
        style={{
          border: '1px solid #F5F5F5',
          padding: '5px',
          borderRadius: '4px',
          position: 'relative',
        }}
        className="!box"
      >
        {children}
        <span className="absolute right-[2px] top-[2px]">
          {' '}
          {endAdorment && endAdorment}{' '}
        </span>
      </div>
    </div>
  );
};

const PaymentForm = ({ data, refetch, onSuccess, onError, handleCancel }) => {
  
  const [saveAPIError, setSaveAPIError] = useState(null);

  const cardNumberOption = useMemo(
    () => ({
      showIcon: true,
      style: {
        base: {
          fontSize: '16px',
          color: '#000',
          fontWeight: '300',
          letterSpacing: '0.025em',
          border: '1px solid black',
          fontFamily: 'Inter',
          '::placeholder': {
            color: '#a2a2a2',
          },
        },
        invalid: {
          color: '#9e2146',
          fontWeight: '300',
        },
      },
      placeholder: 'XXXX XXXX XXXX XXXX',
    }),
    []
  );

  const cardExpiryOption = useMemo(
    () => ({
      style: {
        base: {
          fontSize: '16px',
          fontWeight: '300',
          color: '#000',
          letterSpacing: '0.025em',
          fontFamily: 'Inter',
          '::placeholder': {
            color: '#a2a2a2',
            fontWeight: '300',
          },
          '::active': {
            border: '1px solid black',
            fontWeight: '300',
          },
        },
        invalid: {
          color: '#9e2146',
        },
      },
      placeholder: 'MM/YY',
    }),
    []
  );
  const cardCvvOption = useMemo(
    () => ({
      style: {
        base: {
          fontSize: '16px',
          fontWeight: '300',
          color: '#000',
          letterSpacing: '0.025em',
          fontFamily: 'Inter',
          '::placeholder': {
            color: '#a2a2a2',
            fontWeight: '300',
          },
          '::active': {
            border: '1px solid black',
            fontWeight: '300',
          },
        },
        invalid: {
          color: '#9e2146',
        },
      },
      placeholder: 'CVV',
    }),
    []
  );
  const { mutate: AddCard, isLoading: AddingCard } = useAddCardQuery();
  const {
    formState: { errors: paymenterror },
    setValue,
    setError,
  } = useForm();
  const [newCardId, setNewCardId] = useState('');
  const handleChangeValue = (field, value) => {
    setValue(field, value, {
      shouldValidate: field in paymenterror,
    });
  };

  const stripe = useStripe();
  const element = useElements();
  const navigate = useNavigate();
  const { mutate: setupIntent, isLoading } = useSetUpIntentQuery();
  const onSubmitPayment = async (event) => {
    // console.log({event})
    if (stripe && element) {
      const payload = await stripe.createPaymentMethod({
        type: 'card',
        card: element.getElement(CardNumberElement) || '',
      });
      setNewCardId(payload?.paymentMethod?.id || '');
    }
  };
  const onErrorCard = (err) => {
    setSaveAPIError(err?.data);
  };
  const handleAddCard = () => {
    // console.log(newCardId)
    setSaveAPIError(null);
    AddCard(
      {
        source: newCardId,
        is_primary: data?.length === 0 ? true : false,
      },
      {
        onSuccess: onSuccess,
        onError: onErrorCard,
      }
    );
  };
  const customer_id = getLocalStorageItem('CUSTOMER_ID');
  useEffect(() => {
    if (customer_id) {
      setupIntent(
        { customer_id: customer_id },
        {
          onSuccess: (res) => {
            refetch();
            // navigate(routes.PAYMENT_BILLLING.pathname)
          },
          onError: (err) => {
            console.log(err);
          },
        }
      );
      return;
    } else {
      navigate(routes.PAYMENT_METHODS.pathname);
    }
  }, []);
  return (
    <Box width={'100%'}>
      <Box sx={{ boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)', width: '90%' }}>
        <Box sx={{ maxWidth: '416px' }} className="p-4">
          <Grid spacing={4} container item>
            <Grid width="100%" item lg={12} md={12} sm={12}>
              <CardElementWrapper title={'Card Number'}>
                <CardNumberElement
                  onChange={onSubmitPayment}
                  options={cardNumberOption}
                />
                <FormHelperText
                  error={Boolean(
                    paymenterror.cardNumber || paymenterror.source
                  )}
                >
                  {paymenterror.cardNumber?.message ||
                    paymenterror?.source?.message}
                </FormHelperText>
              </CardElementWrapper>
            </Grid>
            <Grid className="flex  justify-evenly" item width="100%">
              <CardElementWrapper
                endAdorment={<CalendarTodayOutlined />}
                title={'Valid till'}
              >
                <CardExpiryElement
                  onChange={onSubmitPayment}
                  options={cardExpiryOption}
                />
                <FormHelperText error>
                  {paymenterror.expiray_card?.message}
                </FormHelperText>
              </CardElementWrapper>
              <CardElementWrapper
                endAdorment={<LockOpenOutlined />}
                title={'CVV'}
              >
                <CardCvcElement
                  onChange={onSubmitPayment}
                  options={cardCvvOption}
                />
                <FormHelperText error>
                  {paymenterror.cvc?.message}
                </FormHelperText>
              </CardElementWrapper>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        className="text-right py-5 "
        sx={{ width: '90%' }}
        lg={12}
        md={12}
        sm={12}
        item
      >
        
        <Typography className="animateErrorValue pb-2 pl-5 text-red-500 text-left text-xs">
          {saveAPIError} &nbsp;
        </Typography>
        
        <Button
          className=""
          style={btnCancelStyle}
          onClick={handleCancel}
        >
          Cancel
        </Button>
        {AddingCard ? (
          <Button disabled={newCardId ? false : true} className='saveCCard' variant="contained">
            <CircularProgress className="text-primary-50" />{' '}
          </Button>
        ) : (
          <Button
            onClick={handleAddCard}
            disabled={newCardId ? false : true}
            variant="contained"
            className='saveCCard'
          >
            Save Card
          </Button>
        )}
        
      </Box>
    </Box>
  );
};

export default PaymentForm;
