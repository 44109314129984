import React, { useEffect, useState } from 'react';
import { Grid, Typography, Snackbar } from '@material-ui/core';
import { CalculateEMI } from 'pages/productDetailFlow/CalculateEMI';
import { copyToClipboard } from 'utils/copyfunction';
import { currencyFormat } from 'utils';
import 'pages/SMRRInfo/SMRR.css';
import { numberWithCommas } from 'utils/helpers/numberWithCommas';

const fontStyle = {
  fontSize: '13px',
  fontWeight: '400',
  color: '#333333',
};
const viewOptionStyle = {
  width: '64px',
  height: '24px',
  marginLeft: '20px',
  fontSize: '14px',
  color: '#0F5E91',
  border: '1px solid',
  borderBottomColor: '#0F5E91',
  borderTopColor: '#FFFFFF',
  borderLeftColor: '#FFFFFF',
  borderRightColor: '#FFFFFF',
};
const SliderContentFinancingDetail = (props) => {
  const [description, setDescription] = useState(null);
  const [viewOption, setViewOption] = useState(null);
 

  //description
  useEffect(() => {
    if (props?.vehicalInfo?.data?.listing?.description) {
      if (props?.vehicalInfo?.data?.listing?.description?.length > 150) {
        setDescription(
          props?.vehicalInfo?.data?.listing?.description.slice(0, 3)
        );
        setViewOption('See More');
      }
      if (props?.vehicalInfo?.data?.listing?.description?.length < 150) {
        setDescription(props?.vehicalInfo?.data?.listing?.description);
        setViewOption(null);
      }
    }
  }, [props?.vehicalInfo?.data?.listing?.description]);

  //snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleSnackbar = () => {
    setSnackbarOpen(true);
  };
  //copy functionality
  const handleCopyClick = (id) => {
    copyToClipboard(id);
    handleSnackbar();
  };

  //handle viewoption click
  const handleVeiwOption = (text) => {
    if (text === 'See More') {
      setViewOption('See Less');
      setDescription(props?.vehicalInfo?.data?.listing?.description);
    }
    if (text === 'See Less') {
      setViewOption('See More');
      setDescription(
        props?.vehicalInfo?.data?.listing?.description.slice(0, 200)
      );
    }
  };

  return (
    <>
      {props?.EquipmentType === 'SMR' ? (
        <Grid container>
          <Grid
            item
            container
            lg={12}
            md={12}
            sm={12}
            spacing={3}
            className="md-shadow"
          >
            <Grid item lg={8} md={10} sm={12}>
              <Typography
                className="font-Inter"
                style={{
                  fontSize: '17px',
                  color: '#333333',
                  fontWeight: '500',
                }}
              >
                {props?.vehicalInfo?.data?.listing?.title
                  ? props?.vehicalInfo?.data?.listing?.title
                  : 'NA'}
              </Typography>
            </Grid>
            <Grid item container lg={6} sm={5} md={10}>
              <Grid item lg={6} sm={4} md={4}>
                <Typography
                  style={{
                    fontSize: '14px',
                    color: '#357AA6',
                    fontWeight: '500',
                    fontFamily: 'Poppins',
                  }}
                >
                  Stock Number :
                </Typography>
              </Grid>
              <Grid item lg={3} sm={4} md={4}>
                <Typography
                  style={{
                    fontSize: '14px',
                    color: '#357AA6',
                    fontWeight: '500',
                    fontFamily: 'Poppins',
                  }}
                >
                  {props?.vehicalInfo?.data?.listing?.unique_id
                    ? props?.vehicalInfo?.data?.listing?.unique_id
                    : 'NA'}
                </Typography>
              </Grid>
              <Grid
                item
                lg={2}
                sm={2}
                md={2}
                style={{ cursor: 'pointer', paddingRight: '10px' }}
              >
                <img
                  src={'/assets/copyicon.svg'}
                  alt="copy"
                  style={{ height: '19px' }}
                  onClick={() =>
                    handleCopyClick(
                      props?.vehicalInfo?.data?.listing?.unique_id
                    )
                  }
                />
              </Grid>
              <Snackbar
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={() => setSnackbarOpen(false)}
                message="Vehicle Id is copied to clipboard"
              />
            </Grid>
            <Grid item container lg={8} md={10} sm={12}>
              {props?.tabType !== 'productDetails' ? (
                <>
                  {props?.vehicalInfo?.data?.listing?.quotes?.quote_price ? (
                    <>
                      <Grid item>
                        <Typography
                          className="font-Inter"
                          style={{
                            color: '#333333',
                            fontSize: '23px',
                            fontWeight: '700',
                          }}
                        >
                          {currencyFormat(
                            props?.vehicalInfo?.data?.listing?.quotes
                              ?.quote_price
                          )}
                        </Typography>
                      </Grid>
                      <Grid item style={{ paddingLeft: '40px' }}>
                        <Typography
                          className="font-Inter"
                          style={{
                            color: '#757575',
                            fontSize: '21px',
                            fontWeight: '500',
                            textDecoration: 'line-through',
                          }}
                        >
                          {currencyFormat(
                            props?.vehicalInfo?.data?.listing?.selling_price
                          )}
                        </Typography>
                      </Grid>
                    </>
                  ) : (
                    <Grid item>
                      <Typography
                        className="font-Inter"
                        style={{
                          color: '#333333',
                          fontSize: '23px',
                          fontWeight: '700',
                        }}
                      >
                        {currencyFormat(
                          props?.vehicalInfo?.data?.listing?.selling_price
                        )}
                      </Typography>
                    </Grid>
                  )}
                </>
              ) : (
                <Grid item>
                  <Typography
                    className="font-Inter"
                    style={{
                      color: '#333333',
                      fontSize: '23px',
                      fontWeight: '700',
                    }}
                  >
                    {currencyFormat(
                      props?.vehicalInfo?.data?.listing?.selling_price
                    )}
                  </Typography>
                </Grid>
              )}
            </Grid>
            {/* <Grid item container lg={8} sm={12} md={10}>
            {props.tabType === 'productDetails' && (
              <>
                <Grid item>
                  <Typography
                    className="font-Inter"
                    style={{
                      color: '#757575',
                      fontSize: '14px',
                      fontWeight: '700',
                    }}
                  >
                    $1000/month
                  </Typography>
                </Grid>
                <Grid
                  item
                  className="border-style"
                  style={{ marginLeft: '60px', marginTop: '-5px' }}
                  spacing={2}
                >
                  <CalculateEMI
                    data={
                      props?.EMI === 'Listing'
                        ? props?.vehicalInfo?.data?.listing
                        : props?.vehicalInfo?.data
                    }
                    open={openEMI}
                    setOpen={setOpenEMI}
                  />
                </Grid>
              </>
            )}
          </Grid> */}
            <Grid
              item
              container
              lg={10}
              md={12}
              sm={12}
              justifyContent="space-between"
              className="!mt-1 bg-[#EDEDED] p-2 rounded-md max-w-[437px]"
              style={{ marginLeft: '7px' }}
            >
              <Grid item container alignItems="center" className="!w-auto">
                <Grid
                  item
                  style={{
                    border: '2px solid #0F5E91',
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '12.2px',
                  }}
                >
                  <img src={'/assets/Vectormiles.svg'} alt="meter" />
                </Grid>
                <Grid item>
                  <Typography style={fontStyle} className="px-[5px] font-Inter">
                    {props?.vehicalInfo?.data?.listing?.vehicle?.mileage
                      ? `${numberWithCommas(
                          props?.vehicalInfo?.data?.listing?.vehicle?.mileage?.toString()
                        )} miles`
                      : 'NA'}{' '}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container alignItems="center" className="!w-auto">
                <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
                  <img src={'/assets/brandLogo.svg'} alt="piston" />
                </Grid>
                <Grid item>
                  <Typography style={fontStyle} className="px-[5px] font-Inter">
                    {props?.vehicalInfo?.data?.listing?.vehicle?.make
                      ? props?.vehicalInfo?.data?.listing?.vehicle?.make
                      : 'NA'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container alignItems="center" className="!w-auto">
                <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
                  <img src={'/assets/gearshift.svg'} alt="gear" />
                </Grid>
                <Grid item>
                  <Typography
                    style={fontStyle}
                    className="px-[10px] font-Inter"
                  >
                    {props?.vehicalInfo?.data?.listing?.vehicle?.gear_type
                      ? props?.vehicalInfo?.data?.listing?.vehicle?.gear_type
                      : 'NA'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* Vehical detials along with div */}
            <Grid
              item
              container
              lg={8}
              md={10}
              sm={12}
              style={{ marginTop: '5px' }}
            >
              <Grid item>
                <img
                  className="w-[18px] h-[21px]"
                  src={'/assets/location.svg'}
                  alt="location"
                />
              </Grid>
              <Grid item>
                {props?.vehicalInfo?.data?.listing?.city && (
                  <>
                    <Typography
                      className="font-Inter"
                      style={{
                        fontSize: '14px',
                        color: '#757575',
                        fontWeight: '500',
                        margin: '1px 5px 1px 5px',
                        fontFamily: 'Inter',
                      }}
                    >
                      {props?.vehicalInfo?.data?.listing?.city
                        ? props?.vehicalInfo?.data?.listing?.city
                        : 'NA'}
                      ,&nbsp;
                      {props?.vehicalInfo?.data?.listing?.state
                        ? props?.vehicalInfo?.data?.listing?.state
                        : 'NA'}
                      ,&nbsp;
                      {props?.vehicalInfo?.data?.listing?.zip_code
                        ? props?.vehicalInfo?.data?.listing?.zip_code
                        : 'NA'}
                    </Typography>
                  </>
                )}
              </Grid>
            </Grid>

            <Grid item container lg={10} md={12} sm={12}>
              <Grid item lg={12} md={12} sm={12}>
                <Typography
                  className="font-Inter"
                  style={{
                    color: '#333333',
                    fontSize: '16px',
                    fontWeight: '500',
                  }}
                >
                  Description
                </Typography>
              </Grid>
              <Grid
                item
                container
                style={{ marginTop: '18px' }}
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                <Grid item lg={8} md={10} sm={12} xs={12}>
                  <Typography
                    className="font-Inter"
                    style={{ color: '#545454', fontSize: '14px' }}
                  >
                    {description}
                    {props?.vehicalInfo?.data?.listing?.description?.length <
                    150 ? (
                      ''
                    ) : (
                      <span
                        className="box"
                        style={{ ...viewOptionStyle, cursor: 'pointer' }}
                        onClick={() => handleVeiwOption(viewOption)}
                      >
                        {viewOption}
                      </span>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};
export default SliderContentFinancingDetail;
