import {
  Grid,
  List,
  ListItem,
  Typography,
  useMediaQuery,
  useTheme,
  Link,
} from '@mui/material';
import { Finance } from 'pages/LandingPage/Finance';
import React, { useState } from 'react';
import { usePaymentTanCPPQuery } from 'query/payment';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useNavigate } from 'react-router';
import { nanoid } from 'nanoid';

const TiktokIcon = ({ color = '#ffffff' }) => {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="28px"
      height="28px"
    >
      <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
    </svg>
  );
};
export const Footer = () => {
  const navigate = useNavigate();

  const theme = useTheme();
  const [openFinance, setOpenFinance] = useState(false);
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const handleFinanceOpen = () => {
    setOpenFinance(true);
  };
  const handleFinanceClose = () => {
    setOpenFinance(false);
  };

  const { data: tanCPPData } = usePaymentTanCPPQuery();
  const tanC = tanCPPData?.data
    ? tanCPPData?.data[0]?.terms_and_conditions
    : '';
  const pPolicy = tanCPPData?.data ? tanCPPData?.data[0]?.privacy_policy : '';

  return (
    <>
      <Finance open={openFinance} setOpen={handleFinanceClose} />
      <Grid
        className={`bg-secondary-90 !text-white py-[50px] !w-[100%] footerC`}
      >
        <Grid className="footerCant">
          <Grid
            item
            className={`${isSmall ? 'footerLogoCantSmall' : 'footerLogoCant'}`}
          >
            <Grid item className={`${isSmall ? 'w-[120px]' : 'w-[150px]'}`}>
              <img src={'/assets/second-logo.png'} alt="logo" />
            </Grid>
            <Grid item>
              <Typography className="pt-5 pb-10 max-w-[250px] max-sm:max-w-fit">
                Explore Transportation Solutions For Every Challenge And Budget
              </Typography>
            </Grid>
          </Grid>
          <Grid
            className={`${isSmall ? 'footerMenuCantSmall' : 'footerMenuCant'}`}
            item
          >
            <List>
              <ListItem className="footMenuH">Finance</ListItem>
              <ListItem
                className="pb-0 cursor-pointer"
                onClick={handleFinanceOpen}
              >
                <p className="footMenu">Truck Financing</p>
              </ListItem>
              <ListItem
                className="pb-0 cursor-pointer"
                onClick={handleFinanceOpen}
              >
                <p className="footMenu">Trailer Financing</p>
              </ListItem>
              <ListItem
                className="pb-0 cursor-pointer"
                onClick={handleFinanceOpen}
              >
                <p className="footMenu">Dry Vans Financing</p>
              </ListItem>
            </List>
          </Grid>
          <Grid
            item
            className={`${isSmall ? 'footerMfCantSmall' : 'footerMfCant'}`}
          >
            <List>
              <ListItem className="footMenuH footMenuC">Manufacturers</ListItem>
              <ListItem className="pb-0">
                <Link
                  onClick={() => {
                    navigate('/inventory', {
                      state: {
                        forBrand: [
                          {
                            key: nanoid(),
                            label: 'Kenworth',
                            filter: 'brand',
                          },
                        ],
                      },
                    });
                  }}
                  className="footMenu cursor-pointer"
                >
                  Kenworth
                </Link>
              </ListItem>
              <ListItem className="pb-0">
                <Link
                  className="footMenu cursor-pointer"
                  onClick={() => {
                    navigate('/inventory', {
                      state: {
                        forBrand: [
                          {
                            key: nanoid(),
                            label: 'Freightliner',
                            filter: 'brand',
                          },
                        ],
                      },
                    });
                  }}
                >
                  Freightliner
                </Link>
              </ListItem>
              <ListItem className="pb-0">
                <Link
                  className="footMenu cursor-pointer"
                  onClick={() => {
                    navigate('/inventory', {
                      state: {
                        forBrand: [
                          {
                            key: nanoid(),
                            label: 'Peterbilt',
                            filter: 'brand',
                          },
                        ],
                      },
                    });
                  }}
                >
                  Peterbilt
                </Link>
              </ListItem>
              <ListItem className="pb-0">
                <Link
                  className="footMenu cursor-pointer"
                  onClick={() => {
                    navigate('/inventory', {
                      state: {
                        forBrand: [
                          {
                            key: nanoid(),
                            label: 'Volvo',
                            filter: 'brand',
                          },
                        ],
                      },
                    });
                  }}
                >
                  Volvo
                </Link>
              </ListItem>
              <ListItem className="pb-0">
                <Link
                  className="footMenu cursor-pointer"
                  onClick={() => {
                    navigate('/inventory', {
                      state: {
                        forBrand: [
                          {
                            key: nanoid(),
                            label: 'International',
                            filter: 'brand',
                          },
                        ],
                      },
                    });
                  }}
                >
                  International
                </Link>
              </ListItem>
              <ListItem className="pb-0">
                <Link
                  className="footMenu cursor-pointer"
                  onClick={() => {
                    navigate('/inventory', {
                      state: {
                        forBrand: [
                          {
                            key: nanoid(),
                            label: 'Western Star',
                            filter: 'brand',
                          },
                        ],
                      },
                    });
                  }}
                >
                  Western Star
                </Link>
              </ListItem>
            </List>
          </Grid>
          <Grid
            item
            className={`${isSmall ? 'footerMenuCantSmall' : 'footerMenuCant'}`}
          >
            <List>
              <ListItem className="footMenuH">&nbsp;</ListItem>
              <ListItem className="pb-0">
                <Link
                  className="footMenu cursor-pointer"
                  onClick={() => {
                    navigate('/inventory', {
                      state: {
                        forBrand: [
                          {
                            key: nanoid(),
                            label: 'Wabash',
                            filter: 'brand',
                          },
                        ],
                      },
                    });
                  }}
                >
                  Wabash
                </Link>
              </ListItem>
              <ListItem className="pb-0">
                <Link
                  className="footMenu cursor-pointer"
                  onClick={() => {
                    navigate('/inventory', {
                      state: {
                        forBrand: [
                          {
                            key: nanoid(),
                            label: 'Great Dane',
                            filter: 'brand',
                          },
                        ],
                      },
                    });
                  }}
                >
                  Great Dane
                </Link>
              </ListItem>
              <ListItem className="pb-0">
                <Link
                  className="footMenu cursor-pointer"
                  onClick={() => {
                    navigate('/inventory', {
                      state: {
                        forBrand: [
                          {
                            key: nanoid(),
                            label: 'Utility',
                            filter: 'brand',
                          },
                        ],
                      },
                    });
                  }}
                >
                  Utility
                </Link>
              </ListItem>
              <ListItem className="pb-0">
                <Link
                  className="footMenu cursor-pointer"
                  onClick={() => {
                    navigate('/inventory', {
                      state: {
                        forBrand: [
                          {
                            key: nanoid(),
                            label: 'Hyundai',
                            filter: 'brand',
                          },
                        ],
                      },
                    });
                  }}
                >
                  Hyundai
                </Link>
              </ListItem>
              <ListItem className="pb-0">
                <Link
                  className="footMenu cursor-pointer"
                  onClick={() => {
                    navigate('/inventory', {
                      state: {
                        forBrand: [
                          {
                            key: nanoid(),
                            label: 'Stoughton',
                            filter: 'brand',
                          },
                        ],
                      },
                    });
                  }}
                >
                  Stoughton
                </Link>
              </ListItem>
              <ListItem className="pb-0">
                <Link
                  className="footMenu cursor-pointer"
                  onClick={() => {
                    navigate('/inventory', {
                      state: {
                        forBrand: [
                          {
                            key: nanoid(),
                            label: 'Vanguard',
                            filter: 'brand',
                          },
                        ],
                      },
                    });
                  }}
                >
                  Vanguard
                </Link>
              </ListItem>
            </List>
          </Grid>

          <Grid
            item
            className={`${isSmall ? 'footerMenuCantSmall' : 'footerMenuCant'}`}
          >
            <List>
              <ListItem className="footMenuH">Legal</ListItem>
              <ListItem className="pb-0">
                <Link
                  href={tanC ? tanC : '#'}
                  target="_blank"
                  rel="noreferrer"
                  className="footMenu"
                >
                  Terms
                </Link>
              </ListItem>
              <ListItem className="pb-0">
                <Link
                  onClick={() => navigate('/Privacy')}
                  className="footMenu cursor-pointer"
                >
                  Privacy
                </Link>
              </ListItem>

              <ListItem className="pb-0">
                <Link href="/disclaimer" className="footMenu">
                  Disclaimer
                </Link>
              </ListItem>
              <ListItem className="pb-0">
                <Link href="/accounts/settings" className="footMenu">
                  Settings
                </Link>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        container
        className="bg-secondary-50 px-10 min-h-[96px] m-auto "
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          container
          style={{ maxWidth: '1600px', padding: '0 20px', margin: 'auto' }}
          justifyContent={isSmall || isXSmall ? 'center' : 'space-between'}
          className="max-sm:!px-0 t-align"
        >
          <Grid
            lg={4}
            sx={12}
            md={4}
            className="max-md:!text-center max-md:py-4"
            item
          >
            <Typography className="footCopy">
              © {new Date().getFullYear()} Sell My Rig LLC. All rights reserved.
            </Typography>
          </Grid>
          <Grid
            lg={4}
            sx={12}
            md={4}
            className="max-md:!text-center max-md:py-4"
            item
          >
            <Typography className="footCopy text-center">
              Dealership Number: 49887
            </Typography>
          </Grid>
          <Grid
            lg={4}
            sx={12}
            md={4}
            item
            container
            className={` gap-5 !w-auto  ${
              isSmall || isXSmall ? '!text-center' : 'justify-end'
            } ${isMedium || isLarge ? '!m-auto' : 'm-0'} text-s`}
          >
            <Link
              href="https://www.tiktok.com/@selllmyrig"
              target="_blank"
            >
              <TiktokIcon />
            </Link>
            {/* <Link
              href="https://www.facebook.com/profile.php?id=100092651114283&mibextid=LQQJ4d"
              target="_blank"
            >
              <FacebookIcon
                className={`text-[28px] text-white cursor-pointer`}
              />
            </Link> */}
            {/* <Link href="#">
              <TwitterIcon
                className={`text-[28px] text-white cursor-pointer`}
              />
            </Link> */}
            {/* <Link
              href="https://instagram.com/sellmyrigllc?igshid=MzMyNGUyNmU2YQ=="
              target="_blank"
            >
              <InstagramIcon
                className={`text-[28px] text-white cursor-pointer`}
              />
            </Link> */}
            {/* <Link
              href="https://www.linkedin.com/company/sell-my-rig/"
              target="_blank"
            >
              <LinkedInIcon
                className={`text-[28px] text-white cursor-pointer`}
              />
            </Link> */}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
