import React, { useRef, useState } from 'react';
import { Button, CircularProgress, Grid, Link, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CustomTextField } from '../../../../components';
import { useRefContext } from '../../../../components/Layout';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import { FileSelectorPSRH } from './fileSelector';
import './RepairHistory.css';
import { FileUploader } from '../../../commonComponents/FileUploader/FIleUploader';


const RepairHistoryPS = (props) => {
  const {
    isLoading,
    activeStep,
    setActiveStep,
    repairHistoryMap,
    setRepairHistoryMap,
    repairHistoryInputFileDataMap,
    setRepairHistoryInputFileDataMap,
    handleFinalSubmit
  } = props;
  const [repairHistoryValidation, setRepairHistoryValidation] = useState(false);
  const [inputFileElData, setInputFileElData] = useState();
  const [filesList, setFilesList] = useState([1]);
  const [renderFileUploader, setRenderFileUploader] = useState(true);
  // JS - Definitions outside the return statement

  const theme = useTheme();
  const isXLarge = useMediaQuery(theme.breakpoints.down('xl'));
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const {headerRef, navRef} = useRefContext();
  const fileInputRef = useRef("fileInputRef");
  
  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const LinearProgressWithLabel = (props) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress sx={{
                          '&.MuiLinearProgress-root': {
                            'height': '8px',
                            'border-radius': '4px',
                            'background-color': '#FFF3CC'
                          },
                        '&.MuiLinearProgress-root .MuiLinearProgress-bar': {
                          'background-color': '#FFC200 !important'
                        }
                          
                        }} variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  const deleteIcon = () => {
    return <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.5 1.5H11.5M1.5 4H16.5M14.8333 4L14.2489 12.7661C14.1612 14.0813 14.1174 14.7389 13.8333 15.2375C13.5833 15.6765 13.206 16.0294 12.7514 16.2497C12.235 16.5 11.5759 16.5 10.2578 16.5H7.74221C6.42409 16.5 5.76503 16.5 5.24861 16.2497C4.79396 16.0294 4.41674 15.6765 4.16665 15.2375C3.88259 14.7389 3.83875 14.0813 3.75107 12.7661L3.16667 4M7.33333 7.75V11.9167M10.6667 7.75V11.9167" stroke="#B0B0B0" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  }

  const fileIcon = () => {
    return <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2" y="2.5" width="32" height="32" rx="16" fill="#FFF3CC" />
      <path d="M19.3334 12.0131V14.7668C19.3334 15.1402 19.3334 15.3269 19.406 15.4695C19.4699 15.5949 19.5719 15.6969 19.6974 15.7608C19.84 15.8335 20.0267 15.8335 20.4 15.8335H23.1537M23.3334 17.159V21.9668C23.3334 23.0869 23.3334 23.647 23.1154 24.0748C22.9236 24.4511 22.6177 24.7571 22.2413 24.9488C21.8135 25.1668 21.2535 25.1668 20.1334 25.1668H15.8667C14.7466 25.1668 14.1865 25.1668 13.7587 24.9488C13.3824 24.7571 13.0764 24.4511 12.8847 24.0748C12.6667 23.647 12.6667 23.0869 12.6667 21.9668V15.0335C12.6667 13.9134 12.6667 13.3533 12.8847 12.9255C13.0764 12.5492 13.3824 12.2432 13.7587 12.0515C14.1865 11.8335 14.7466 11.8335 15.8667 11.8335H18.0079C18.4971 11.8335 18.7416 11.8335 18.9718 11.8888C19.1759 11.9377 19.371 12.0186 19.5499 12.1282C19.7518 12.2519 19.9247 12.4249 20.2706 12.7708L22.3961 14.8962C22.742 15.2421 22.9149 15.4151 23.0386 15.6169C23.1483 15.7959 23.2291 15.991 23.2781 16.195C23.3334 16.4252 23.3334 16.6698 23.3334 17.159Z" stroke="#997400" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
      <rect x="2" y="2.5" width="32" height="32" rx="16" stroke="#FFF9E6" stroke-width="4" />
    </svg>
  }

  const uploadIcon = () => {
    return <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4" y="4" width="42" height="42" rx="21" fill="#F5F5F5" />
      <g clip-path="url(#clip0_2_17)">
        <path d="M28.5002 28.5L25.0002 25M25.0002 25L21.5002 28.5M25.0002 25V32.875M32.3414 30.5912C33.1949 30.1259 33.869 29.3897 34.2576 28.4987C34.6461 27.6078 34.7269 26.6128 34.4871 25.6708C34.2474 24.7288 33.7007 23.8935 32.9335 23.2967C32.1663 22.6999 31.2222 22.3756 30.2502 22.375H29.1477C28.8828 21.3505 28.3892 20.3995 27.7039 19.5933C27.0186 18.7871 26.1594 18.1468 25.191 17.7205C24.2226 17.2941 23.1702 17.0929 22.1128 17.1318C21.0554 17.1708 20.0206 17.449 19.0862 17.9454C18.1518 18.4419 17.3421 19.1437 16.718 19.9981C16.0939 20.8525 15.6715 21.8373 15.4828 22.8784C15.2941 23.9195 15.3438 24.9899 15.6283 26.0091C15.9127 27.0282 16.4245 27.9696 17.1252 28.7625" stroke="#0F5E91" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
      </g>
      <rect x="4" y="4" width="42" height="42" rx="21" stroke="#EDEDED" stroke-width="6.3" />
      <defs>
        <clipPath id="clip0_2_17">
          <rect width="21" height="21" fill="white" transform="translate(14.5002 14.5)" />
        </clipPath>
      </defs>
    </svg>

  }

  // Methods

  const handleSkipAndContinue = () => {
    // Reset the videosMap
    setRepairHistoryMap([]);
    setRepairHistoryInputFileDataMap([]);
    // and submit the form
    handleSubmitLocal();
  }

  const handleSubmitLocal = () => {
    handleFinalSubmit()
    /*if(repairHistoryMap.length) {
      handleFinalSubmit()
    } else {
      setRepairHistoryValidation(true);
    }*/
  }

  const handleReRender = () => {
    // re render upload component
    setRenderFileUploader(false);
    setTimeout(() => {
      console.log({repairHistoryInputFileDataMap, repairHistoryMap});
      setRenderFileUploader(true);
    },351);
  }

  const handleDelete = (index) => {
    console.log({index}, 'Delete');
    console.log({ repairHistoryInputFileDataMap });
    // Remove entry from the view

    let currentRepairHistoryList = [...repairHistoryMap];
    currentRepairHistoryList.splice(index, 1);
    setRepairHistoryMap(currentRepairHistoryList);

    let currentRepairHistoryInputDataList = [...repairHistoryInputFileDataMap];
    currentRepairHistoryInputDataList.splice(index, 1);
    setRepairHistoryInputFileDataMap(currentRepairHistoryInputDataList);

    console.log({currentRepairHistoryList, currentRepairHistoryInputDataList});

    handleReRender();
  }
  const handleSuccess = (callback) => {
    console.log({ callback });
    // update the imagesMap
    let currentRepairHistoryList = [...repairHistoryMap];
    currentRepairHistoryList.push(callback?.resData.data[0]);
    setRepairHistoryMap(currentRepairHistoryList);

    let currentRepairHistoryInputDataList = [...repairHistoryInputFileDataMap];
    currentRepairHistoryInputDataList.push(callback.fileData);
    setRepairHistoryInputFileDataMap(currentRepairHistoryInputDataList);
    setRepairHistoryValidation(false);
      handleReRender();
  }

  const handleUploadMore = () => {
    let uploadedFiles = [...filesList, 1];
    setFilesList(uploadedFiles);
    // setRepairHistoryInputFileDataMap([...repairHistoryInputFileDataMap, null]);
  }

  const handleClick = () => {
    fileInputRef.current.click();
  }

  const handleFileElChange = (event) => {
    console.log(event.target.value, event.target.files );
    let file = event.target.files[0];
    console.log(file);
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function(e) {
      setInputFileElData(this.result);
      console.log(this.result);
    }
  }

  // Template
  return (
    /*<div>*/
    <div className='RepairHistoryPage ag-grid' style={{ height : 'calc(100vh - ' + headerRef?.current?.clientHeight + 'px)'}}>
      <header className='content-header'>
        <div className='action-bar flex justify-between p-2'>
          <div></div>
          <div className='actions'>
          </div>
        </div>
      </header>
      <div className='main'>
        <div className='stepper-content-header flex justify-between items-center py-2'>
          <h3>Repair History</h3>
          <div>
          {/*<Button
              onClick={handleUploadMore}
              style={{ width: '275px', height: '44px', minHeight: '44px' }}
              className='!py-[12px] !px-[30px] hover:!text-[#0F5E91] !bg-[transparent] hover:!bg-[transparent] !border-[#0F5E91] !text-[#0F5E91]'
              variant='contained'>
              Click to upload more
            </Button>*/}
          </div>
        </div>
        <div className='stepper-content'>
          <h3 className='ask mt-6 mb-2'><h3 className='font-Inter ask mt-6 mb-2'>Do you have Repair History ?</h3></h3>
          <div className="files-grid" style={{
              width: 'calc(100% - 64px)',
          }}>
            {
              renderFileUploader && repairHistoryInputFileDataMap.map((item, index) => {
                return <FileUploader
                  key={index}
                  myIndex={index}
                  fileDataDefault={item}
                  type='attachment'
                  is_primary={true}
                  attachmentDescription='REPAIR_HISTORY'
                  handleSuccess={handleSuccess}
                  handleError
                  handleDelete={handleDelete}
                ></FileUploader>;
              })
            }
            {
              renderFileUploader && repairHistoryMap.length < 5?
                <FileUploader
                  type='attachment'
                  is_primary={true}
                  attachmentDescription='REPAIR_HISTORY'
                  handleSuccess={handleSuccess}
                  handleError
                  handleDelete={handleDelete}
                ></FileUploader> : null
            }
          </div>
          {repairHistoryValidation ? <p className="error pt-4 pl-1" style={{right: '0px', left: 'inherit', bottom: '-28px'}}> Please add at least one repair history record.</p> : null}
        </div>
      </div>
      <footer className='content-footer mb-[200px]'>
        <div className='stepper-content-actions-footer flex justify-between'>
          <div></div>
          <div className='flex flex-col gap-6'>
            {isLoading ? (
              <Button
                style={{
                  width: '275px',
                  minHeight: '44px',
                  height: '44px',
                }}
                disabled={isLoading}
                variant="contained"
              >
                <CircularProgress size={30} className="text-white" />
              </Button>
            ) : (
            <Button
              style={{ width: '275px', height: '44px', minHeight: '44px' }}
              onClick={() =>{
                // show the completion animation
                handleSubmitLocal();
              }}
              disabled={isLoading || repairHistoryMap.length <= 0}
              variant='contained'>
              Send Request
            </Button>
            )}
              <> {isLoading ? (
                <Button
                  style={{
                    width: '275px',
                    minHeight: '44px',
                    height: '44px'
                  }}
                  className='!py-[12px] !px-[30px] hover:!text-[#000000] !bg-[transparent] hover:!bg-[transparent] !border-[#f1c40f]'
                  disabled={isLoading}
                  variant='contained'
                >
                  <CircularProgress size={30} className='text-white' />
                </Button>
              ) : (
                <Button
                  style={{ width: '275px', height: '44px', minHeight: '44px' }}
                  className='!py-[12px] !px-[30px] hover:!text-[#000000] !bg-[transparent] hover:!bg-[transparent] !border-[#f1c40f]'
                  onClick={() => {
                    // show the completion animation
                    handleSkipAndContinue();
                  }}
                  variant='contained'>
                  Skip and Continue
                </Button>
              )} </>
          </div>
        </div>
      </footer>
    </div>
  );
};


export {
  RepairHistoryPS
};