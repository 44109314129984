import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Routes, { NavigationLinks } from 'routes/RouteConstants';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router';
import { MdOutlineArrowForwardIos } from 'react-icons/md';
import { border } from '@mui/system';

const selectedItemStyle = {
  backgroundColor: '#0F5E91',
  color: '#FFFFFF',
  borderRadius: '10px',
  boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.08)',
};
export default function Sidebar() {
  
  const navigate = useNavigate();
  const location = useLocation();

  const [openSecondLevel, setOpenSecondLevel] = useState(false);
  const [navItemSelected, setNavItemSelected] = useState('');

  const handleNavigate = (NavigationItem) => {
    navigate(NavigationItem.redirectLink);
    setNavItemSelected(NavigationItem.name);
  };

  useEffect(() => {
    setNavItemSelected(location.pathname);
  }, [location]);

  const displayDrawerLinks = (NavigationItem, NavigationIndex) => {
    const isItemSelected = navItemSelected === NavigationItem.name;
    const selcetdStyle = isItemSelected ? selectedItemStyle : '';
    // console.log(NavigationItem,isItemSelected,NavigationItem.name);
    return (
      <>
        <div
          className="no-scrollbar"
          style={{ color: '#0F5E91', width: '300px' }}
        >
          <List
            component="nav"
            sx={{ padding: '8px 16px', boxSizing: 'border-box' }}
            aria-labelledby="nested-list-subheader"
            //   sx={classes.root}
          >
            {NavigationItem && (
              <>
                <ListItem
                  className="cursor-pointer "
                  sx={
                    NavigationItem.redirectLink == location.pathname
                      ? selectedItemStyle
                      : null
                  }
                  onClick={() => {
                    handleNavigate(NavigationItem);
                  }}
                >
                  { NavigationItem.redirectLink == location.pathname ? NavigationItem.iconActive : NavigationItem.icon}
                  <ListItemText>
                    <Typography className="!font-medium">
                      {NavigationItem.name}
                    </Typography>
                  </ListItemText>

                  <MdOutlineArrowForwardIos
                    className={`text-secondaty-50 ${
                      NavigationItem.redirectLink == location.pathname
                        ? 'text-white'
                        : null
                    }`}
                  />
                </ListItem>
              </>
            )}
          </List>
        </div>
      </>
    );
  };
  return (
    <>
      {/* <div className="py-4 ! h-[90vh]"> */}
      <div className="py-4 ! h-[auto]">
        <List>{NavigationLinks?.map(displayDrawerLinks)}</List>
      </div>
    </>
  );
}
