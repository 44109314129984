import { Box, Checkbox, Typography } from '@mui/material';
import { nanoid } from 'nanoid';

export const SortComponent = ({ data, appliedFilters, setAppliedFilters }) => {
  const handleChangeCheckBox = (data) =>(e)=> { 
   
    if(e.target.checked){
        if(appliedFilters.some(item => item.type === 'Sort')){
            const removed = appliedFilters.filter(el => el.type!=='Sort')
             switch (data) {
                case 'Relevance':
                    setAppliedFilters([...removed,{key:nanoid(), label:'Relevance',_ordering:'Relevance',type:'Sort'}])
                case "A-Z": 
                    setAppliedFilters([...removed,{key:nanoid(), label:data,_ordering:'title',type:'Sort'}])
                  break;
                case "Z-A":
                    setAppliedFilters([...removed,{key:nanoid(), label:data,_ordering:'-title',type:'Sort'}])
                  break;
                case "Price high to low":
                    setAppliedFilters([...removed,{key:nanoid(), label:data,_ordering:'-price',type:'Sort'}])
                  break;
                case "Price low to high":
                    setAppliedFilters([...removed,{key:nanoid(), label:data,_ordering:'price',type:'Sort'}])
                  break;
                default:
                  break;
               }
        }
        else{
          switch (data) {
            case 'Relevance':
                setAppliedFilters([...appliedFilters,{key:nanoid(), label:'Relevance',_ordering:'Relevance',type:'Sort'}])
            case "A-Z":
                setAppliedFilters([...appliedFilters,{key:nanoid(), label:data,_ordering:'title',type:'Sort'}])
              break;
            case "Z-A":
                setAppliedFilters([...appliedFilters,{key:nanoid(), label:data,_ordering:'-title',type:'Sort'}])
              break;
            case "Price high to low":
                setAppliedFilters([...appliedFilters,{key:nanoid(), label:data,_ordering:'-price',type:'Sort'}])
              break;
            case "Price low to high":
                setAppliedFilters([...appliedFilters,{key:nanoid(), label:data,_ordering:'price',type:'Sort'}])
              break;
            default:
              break;
           }

        }
    }else{
        const removed = appliedFilters.filter(el => el.type!=='Sort'  )
        setAppliedFilters([...removed])
    }
    // if(e.target.checked){
    //    appliedFilters.forEach((el)=>{
    //     if(Object.keys(el).includes('_ordering')){
            
    //     }else{
    //         setAppliedFilters([...appliedFilters,{key:nanoid(), label:data,_ordering:data}])
    //     }
    // })
       
  
  }
  return (
    <Box>
      {data?.map((el) => {
        return (
           <Typography sx={{ alignItems: 'middle', justifyContent: 'left' }}>
            <Typography
              id="text"
              sx={{
                textAlign: 'left',
                fontSize: '14px',
                color: 'text.secondary',
              }}
            >
              <Checkbox
                checked={appliedFilters.some((item) => item.label === el)}
                onChange={handleChangeCheckBox(el)}
              /> {el}
            </Typography>
          </Typography>
        );
      })}
    </Box>
  );
};
