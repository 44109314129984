import React, { useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useGetAllFinancialListQuery } from 'query/Financing/financing';
import { useNavigate } from 'react-router';
import { routes } from 'routes/RouteConstants';
import BasicModal from 'pages/SMRRInfo/EquipmentInfo/Modal';
import FinanceStatusButton from './FinanceStatusButton';
import { Finance } from 'pages/LandingPage/Finance';
import { Box } from '@material-ui/core';
import NoDataFoundIcon from 'components/NoDataFoundIcon';
import { useEffect } from 'react';

const FinancingListing = () => {
  const [currentType, setCurrentType] = useState('SMR');
  const [openFinance, setOpenFinance] = useState(false);
  const handleFinanceOpen = () => {
    setOpenFinance(true);
  };
  const handleFinanceClose = () => {
    setOpenFinance(false);
  };
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const [type, setType] = useState(null);
  const [quoteId, setQuoteId] = useState(null);

  const handleType = (name) => {
    setCurrentType(name);
  };
  const handleModal = (id) => {
    setOpen(true);
    setQuoteId(id);
  };

  const handleFinancialDetail = (id) => {
    navigate(`${routes.FINANCING_LISTING_DETAIL.pathname}?id=${id}`);
  };

  const getImgUrlFunction = (attachments) => {
    const imgArray = attachments?.filter((el) => el.description === 'IMAGE');
    const imgArrayAscending = [...imgArray].sort((a, b) =>
      a.is_primary > b.is_primary ? -1 : 1
    );
    return imgArrayAscending[0]?.attachment;
  };
  const getImgUrlFunction1 = (attachments) => {
    const imgArray = attachments?.filter(
      (el) => el.description === 'ENGINE_STICKER'
    );
    const imgArrayAscending = [...imgArray].sort((a, b) =>
      a.is_primary > b.is_primary ? -1 : 1
    );
    return imgArrayAscending[0]?.attachment;
  };

  const { data: financeListData, refetch: refetchListing } =
    useGetAllFinancialListQuery(currentType);
  
   
    useEffect(() => {
      refetchListing();
    }, [])

  const handlePayment = (data) => {
    
    const listing_id = data?.id;
    const unique_id = data?.listing?.unique_id;
    const value = data?.listing?.quotes?.quote_price;

    const payment_Type = 'Quotes';
    const tabtype = true;
    navigate(routes.FINANCE_PAYMENT_BILLLING.pathname, {
      state: { listing_id, unique_id, value, tabtype, payment_Type },
    });
  };

  return (
    <>
      <Finance open={openFinance} setOpen={handleFinanceClose} />
      <BasicModal
        open={open}
        setClose={setOpen}
        id={quoteId}
        refetchListing={refetchListing}
        type="FinanceList"
      />
      <div className="ml-[100px]">
        <div className="">
          <Grid
            container
            lg={12}
            md={12}
            sm={12}
            className="mt-[80px] mb-[24px]"
          >
            <Grid item>
              <button
                className={`${
                  currentType === 'SMR'
                    ? 'active-btn-Home-Hero'
                    : 'inactive-btn-Home-Hero'
                } rounded-l-md rounded-l-2 cursor-pointer`}
                onClick={() => handleType('SMR')}
              >
                SMR Equipment
              </button>
              <button
                className={`${
                  currentType === 'NON_SMR'
                    ? 'active-btn-Home-Hero'
                    : 'inactive-btn-Home-Hero'
                } rounded-r-md rounded-l-2 cursor-pointer`}
                onClick={() => handleType('NON_SMR')}
              >
                Other Equipment
              </button>
            </Grid>
          </Grid>
          <Divider />
        </div>
        <div className="h-[400px] scrollable-div" style={{ overflow: 'auto' }}>
          {financeListData?.results?.length > 0 ? (
            financeListData?.results?.map((financeData, index) => {
            
              return currentType === 'SMR' ? (
                <Grid
                  container
                  className="max-md:flex-col mt-[24px]"
                  columnGap={2}
                  key={index}
                >
                  <Grid
                    item
                    lg={2}
                    md={6}
                    sm={3}
                    className=""
                    onClick={() => handleFinancialDetail(financeData?.id)}
                  >
                    <div className="imgListinDiv">
                      {getImgUrlFunction(financeData?.attachments) && (
                        <img
                          src={
                            getImgUrlFunction(financeData?.attachments)
                              ? getImgUrlFunction(financeData?.attachments)
                              : ''
                          }
                          alt=""
                          className="listImg"
                        />
                      )}
                    </div>
                  </Grid>
                  <Grid item container lg={9.5} md={6} sm={12} className="">
                    <Grid item container lg={12} md={12} sm={4} className="">
                      <Grid item lg={12} md={12} sm={12}>
                        <Typography
                          item
                          style={{
                            fontSize: '18px',
                            color: '#333333',
                            fontWeight: '500',
                            paddingTop: '8px',
                          }}
                        >
                          {financeData?.listing?.title || 'NA'}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        container
                        lg={12}
                        md={12}
                        sm={3}
                        style={{
                          marginLeft: '.1px',
                        }}
                      >
                        {financeData?.listing?.state && (
                          <>
                            <img
                              className="w-[20px] h-[23px]"
                              src={'/assets/location.svg'}
                              alt="location"
                            />

                            <Typography
                              style={{
                                fontSize: '14px',
                                color: '#757575',
                                fontWeight: '400',
                                paddingLeft: '7px',
                              }}
                            >
                              {financeData?.listing?.city},
                              {financeData?.listing?.state},
                              {financeData?.listing?.zip_code}
                            </Typography>
                          </>
                        )}
                      </Grid>

                      <div className="flex flex-col relative">
                        <div className="">
                        
                          <Grid item lg={8} md={8} sm={12} xs={12} className='relative top-[4px] '>
                            {financeData?.listing?.quotes?.status !==
                              'QUOTE_SENT' &&
                              financeData?.status !== 'DENIED' &&
                              financeData?.status !== 'COMPLETE' && (
                                <FinanceStatusButton
                                  value={financeData?.listing?.quotes?.status}
                                  type={type}
                                  isProducInfoPage={false}
                                  price={financeData?.listing?.inspection_fee}
                                />
                              )}
                          </Grid>
                          {financeData?.status === 'UNDER_PROCESS' && (
                            <Grid
                              item
                              className="rounded-tl-none rounded-tr-none rounded-bl-lg rounded-br-lg p-1 absolute top-[36px]"
                              style={{
                                marginLeft: '.1px',
                                backgroundColor: '#FFF3CC',
                                width: '370px',
                              }}
                            >
                              <div className="flex justify-between items-center text-center">
                                <div >
                                <Typography
                                  style={{
                                    fontSize: '12px',
                                    fontWeight: '400',
                                    color: '#997400',
                                    textAlign: 'center',
                                  }}
                                  className="py-1.5 font-Inter"
                                >
                                  Application Under Process
                                </Typography>
                                </div>
                               
                                <div>
                                {(financeData?.listing?.quotes?.status ===
                                  'DEPOSIT' ||
                                  financeData?.listing?.quotes?.status ===
                                    'DEPOSIT_SUCCESSFUL') && (
                                  <Typography
                                    className="font-Inter text-[#333333] text-[12px] font-semibold cursor-pointer underline"
                                    onClick={() => handleModal(financeData?.id)}
                                  >
                                    Remove Application
                                  </Typography>
                                )}
                                </div>
                           
                              </div>
                            </Grid>
                          )}
                          {financeData?.status === 'DENIED' && (
                            <Grid
                              item
                              className="rounded-tl-none rounded-tr-none rounded-bl-lg rounded-br-lg p-1 absolute top-[21px]"
                              style={{
                                marginLeft: '.1px',
                                backgroundColor: '#FDF3F2',
                                width: '370px',
                                textAlign: 'center',
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize: '14px',
                                  fontWeight: '500',
                                  color: '#DC2804',
                                  textAlign: 'center',
                                }}
                                className="py-1 font-Inter"
                              >
                                Application Denied
                              </Typography>
                            </Grid>
                          )}
                          {financeData?.status === 'CANCELED' && (
                            <Grid
                              item
                              className="rounded-tl-none rounded-tr-none rounded-bl-lg rounded-br-lg p-1 absolute top-[36px]"
                              style={{
                                marginLeft: '.1px',
                                backgroundColor: '#FDF3F2',
                                width: '370px',
                                textAlign: 'center',
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize: '14px',
                                  fontWeight: '500',
                                  color: '#DC2804',
                                  textAlign: 'center',
                                }}
                                className="py-1 font-Inter"
                              >
                               Quote Denied
                              </Typography>
                            </Grid>
                          )}
                          {financeData?.status === 'COMPLETE' && (
                            <Grid
                              item
                              className="rounded-tl-none rounded-tr-none rounded-bl-lg rounded-br-lg p-1 absolute top-[14px]"
                              style={{
                                marginLeft: '.1px',
                                backgroundColor: '#EDF7F2',
                                width: '370px',
                                textAlign: 'center',
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize: '14px',
                                  fontWeight: '500',
                                  color: '#05945B',
                                  textAlign: 'center',
                                }}
                                className="py-1 font-Inter"
                              >
                                Financing Completed
                              </Typography>
                            </Grid>
                          )}
                        </div>
                      </div>
                    </Grid>

                    <Grid item container lg={12} md={12} sm={12}>
                      <Grid
                        item
                        container
                        lg={6}
                        md={6}
                        sm={12}
                        className=" flex"
                        justifyContent="left"
                        alignItems="center"
                      >
                      
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        className=" flex items-end justify-end"
                      >
                        {financeData?.listing?.quotes?.status === 'DEPOSIT' && (
                          <Button
                            className="normalButton "
                            style={{
                              fontSize: '14px',
                              textTransform: 'none',
                              width: '121px',
                            }}
                            onClick={() => handlePayment(financeData)}
                          >
                            Pay Now
                          </Button>
                        )}
                        {(financeData?.listing?.quotes?.status === 'SENT' ||
                          financeData?.listing?.quotes?.status ===
                            'QUOTE_SENT') && (
                          <Typography
                            className="font-Inter text-[#333333] text-[14px] font-semibold cursor-pointer underline"
                            onClick={() => handleModal(financeData?.id)}
                          >
                            Remove Application
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  container
                  className="max-md:flex-col mt-[24px]"
                  columnGap={2}
                  key={index}
                >
                  <Grid
                    item
                    lg={2}
                    md={6}
                    sm={3}
                    className=""
                    onClick={() => handleFinancialDetail(financeData?.id)}
                  >
                    <div className="imgListinDiv">
                      {getImgUrlFunction1(financeData?.attachments) && (
                        <img
                          src={
                            getImgUrlFunction1(financeData?.attachments)
                              ? getImgUrlFunction1(financeData?.attachments)
                              : ''
                          }
                          alt=""
                          className="listImg"
                        />
                      )}
                    </div>
                  </Grid>
                  <Grid item container lg={9.5} md={6} sm={12} className="">
                    <Grid item container lg={12} md={12} sm={4} className="">
                      <Grid item lg={12} md={12} sm={12}>
                        <Typography
                          item
                          style={{
                            fontSize: '18px',
                            color: '#333333',
                            fontWeight: '500',
                            paddingTop: '8px',
                          }}
                        >
                          Equipment Id
                        </Typography>
                        <Typography
                          item
                          style={{
                            fontSize: '14px',
                            color: '#333333',
                            fontWeight: '500',
                            paddingTop: '8px',
                          }}
                        >
                          {financeData?.id || 'NA'}
                        </Typography>
                      </Grid>
                    </Grid>
                    <div className="flex flex-col">
                      {financeData?.status === 'UNDER_PROCESS' && (
                        <Grid
                          item
                          className="rounded-tl-none rounded-tr-none rounded-bl-lg rounded-br-lg p-1 relative top-[38px]"
                          style={{
                            marginLeft: '.1px',
                            backgroundColor: '#FFF3CC',
                            width: '370px',
                          }}
                        >
                        
                            <Typography
                              style={{
                                fontSize: '12px',
                                fontWeight: '400',
                                color: '#997400',
                              }}
                              className="py-1.5 font-Inter"
                            >
                              Application Under Process
                            </Typography>
                      
                        </Grid>
                      )}
                      {financeData?.status === 'DENIED' && (
                        <Grid
                          item
                          className="rounded-tl-none rounded-tr-none rounded-bl-lg rounded-br-lg p-1"
                          style={{
                            marginLeft: '.1px',
                            backgroundColor: '#FDF3F2',
                            width: '370px',
                            textAlign: 'center',
                          }}
                        >
                          
                            <Typography
                              style={{
                                fontSize: '14px',
                                fontWeight: '500',
                                color: '#DC2804',
                                textAlign: 'center',
                              }}
                              className="py-1 font-Inter"
                            >
                            Application Denied
                            </Typography>
                      
                        </Grid>
                      )}
                      {financeData?.status === 'COMPLETE' && (
                        <Grid
                          item
                          className="rounded-tl-none rounded-tr-none rounded-bl-lg rounded-br-lg p-1"
                          style={{
                            marginLeft: '.1px',
                            backgroundColor: '#EDF7F2',
                            width: '370px',
                            textAlign: 'center',
                          }}
                        >
                     
                            <Typography
                              style={{
                                fontSize: '14px',
                                fontWeight: '500',
                                color: '#05945B',
                                textAlign: 'center',
                              }}
                              className="py-1 font-Inter"
                            >
                               Financing Completed
                            </Typography>
                        
                        </Grid>
                      )}
                    </div>

                    <Grid item container lg={12} md={12} sm={12}>
                      <Grid
                        item
                        container
                        lg={6}
                        md={6}
                        sm={12}
                        className=" flex"
                        justifyContent="left"
                        alignItems="center"
                      ></Grid>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        className="text-end flex item-center justify-end"
                      >
                        {financeData?.status === 'UNDER_PROCESS' && (
                          <Typography
                            className="font-Inter text-[#333333] text-[14px] font-semibold cursor-pointer underline"
                            onClick={() => handleModal(financeData?.id)}
                          >
                            Remove Application
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })
          ) : (
            <Grid container lg={11} md={12} sm={12}>
              <Box
                sx={{
                  margin: '0 auto',
                  display: 'table',
                  width: '100%',
                  paddingTop: '10px',
                  justifyContent: 'center',
                }}
              >
                <Box className="text-center">
                  <Typography
                    variant="h4"
                    className="text-secondary-50 py-4 !font-bold !font-[Inter]"
                  >
                    No results found
                  </Typography>
                  <NoDataFoundIcon />
                </Box>
              </Box>
            </Grid>
          )}
        </div>
      </div>
      <div className="flex justify-end items-end absolute bottom-0 right-0 m-4">
        <Button
          style={{
            width: '275px',
            minHeight: '44px',
            height: '44px',
          }}
          // disabled={enableButton}
          onClick={handleFinanceOpen}
          variant="contained"
        >
          Apply Financing
        </Button>
      </div>
    </>
  );
};

export default FinancingListing;
