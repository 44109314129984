import { useMutation, useQuery } from '@tanstack/react-query';
import { axios } from "utils";

const GetNotifications=(data)=>{
  const method='GET'
  const url=`notification/`
  return axios({method,url,data})
}
export const useGetNotifications=()=>{

  return useQuery(
    ['GET_NOTIFICATIONS'],
    () => GetNotifications(),
    {
      enabled:true,
    }
  );

}

const SaveFCMToken=(data)=> {
  const payload = {
    device_id: null,
    registration_id: data.fcmToken,
    type: 'web'
  }
  const method='POST'
  const url=`devices/`
  return axios({method,url,data: payload})
}

// { mutate: saveFCMTokenMutation, isLoading }
export const SaveFCMTokenMutation = (data) => {
  return useMutation(SaveFCMToken, {
    onSuccess: (res) => {
    },
    onError: (err) => {
      console.log(err, 'saveFCMTokenMutation Error');
    }
  });
};
const SetReceiptReadStatus=(data)=>{
  const payload = {
    "read_receipt": true
  }
  const method='PATCH'
  const url=`notification/${data.id}/`
  return axios({method,url,data: payload})
}

// { mutate: saveFCMTokenMutation, isLoading }
export const SetReceiptReadStatusMutation = (data) => {
  return useMutation(SetReceiptReadStatus, {
    onSuccess: (res) => {
    },
    onError: (err) => {
      console.log(err, 'SetReceiptReadStatusMutation Error');
    }
  });
};

