import { useQuery,useMutation } from "@tanstack/react-query"; 
import { axios } from "utils";
import { QueryKey } from "./queryKeys";


//api headers 
const GetListedEquipmentData = (id,isPage) => {
    const method = 'GET';
    const url = `user/${id}/listing/`;
    return axios({method,url,params:{
        per_page:50,
        page:isPage
    }})
}

export const useGetAllEquipmentData = (id,isPage) => {
    return useQuery([QueryKey.GET_EQUIPMENTS_LIST_DATA,id],
     ()=> GetListedEquipmentData(id,isPage),{
          enabled:Boolean(id),
      } )
}

//delete Equipments/listing
const validDeletentListing = (id) => {
    const method = 'PATCH';
    const url = `listing/${id}/status/`;
    return axios({ method, url, data:{
        id: id,
        status:'REMOVAL_REQUESTED',
        reason: '',
        description: ''
    }});
  };
  export const useDeleteListing = () => {
    return useMutation(validDeletentListing);
  };