import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { Grid, Box, Typography, useMediaQuery, useTheme, Modal, Divider } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';


const Content = () => {


    return(
        <>
          <Grid container lg={12} md={12} sm={12} xs={12}>
              <Grid item lg={12} md={12} sm={12} xs={12} spacing={4}>
               < ContentItem />
              </Grid>
          </Grid>
        </>
    )
}

export default Content;

const ContentItem = () => {

    const overLayContainer ={

        backgroundColor:'rgba(255, 255, 255, 0.3)',
        backdropFilter:'blur(12px)',
        width:'100%',
        height:'92px',
        bottom:'0px',
        marginTop:'-92px'
    }
  const img=process.env.PUBLIC_URL + '/auth-bg.png';

    return(

        <>
           <Grid container lg={12} md={12} sm={12} xs={12} spacing={3} padding={3} className='!w-[510px]'>
               <Grid item container lg={12} md={12} sm={12} xs={12} className=' '>
                  <Grid item lg={12} md={12} sm={12} xs={12} className='h-[345px]'>
                    <img src={img} alt="InventryImg" style={{height:'100%',width:'100%'}}/>
                  </Grid>
                  <Grid item container lg={12} md={12} sm={12} xs={12} className='flex ' justifyContent='space-between' style={overLayContainer}>
                     <Grid item conainer lg={5} md={5} sm={5} xs={5} justifyContent='center' alignItems='center'>
                        {/* <Grid item lg={12} md={12} sm={12} xs={12} className='box'> */}
                        <Typography  style={{fontSize:'14px',fontWeight:'600',color:'#FFFFFF',display:'flex',justifyContent:'center',alignItems:'center',marginTop:'30px'}}>Oliva Rhye</Typography>
                        {/* </Grid> */}
                        {/* <Grid item lg={12} md={12} sm={12} xs={12} className='box' > */}
                        <Typography  style={{fontSize:'14px',fontWeight:'400',color:'#FFFFFF',display:'flex',justifyContent:'center',alignItems:'center'}}>20 Jan 2023</Typography>
                        {/* </Grid> */}
                         
                     </Grid>
                     <Grid item lg={3} md={3} sm={3} xs={3}>
                       <Typography style={{fontSize:'14px',fontWeight:'600',color:'#FFFFFF',marginTop:'30px'}}>Desine</Typography>
                     </Grid>
                  </Grid>
               </Grid>
               <Grid item lg={12} md={12} sm={12} xs={12}>
                 <Typography style={{fontSize:'24px',fontWeight:'600',color:'#03131D'}}>
                    10 Tips for Selling Your Old Truck Quickly and Profitably 
                 </Typography>
               </Grid>
               <Grid item lg={12} md={12} sm={12} xs={12}>
                 <Typography style={{fontSize:'18px',fontWeight:'400',color:'#757575'}}>
                    Clean it up: The first step to selling your old truck is to make it look as good as possible.
                    Give it a thorough cleaning both inside and out.
                 </Typography>
               </Grid>
               <Grid item container lg={12} md={12} sm={12} xs={12}>
                 <Grid item lg={3} md={3} sm={6} xs={5} className='flex' justifyContent='left' alignItems='left' columnGap='10px'>
                    <Typography style={{fontSize:'16px',fontWeight:'500',color:'#0F5E91'}}>Read post</Typography>
                    
                 </Grid>
                 <Grid item lg={1} md={1} sm={1} xs={1}>
                   <img src={'/assets/icons/upArrow.svg'} style={{height:'9px',width:'9px'}} />
                 </Grid>
               </Grid>
           </Grid>
        </>
    )
} 