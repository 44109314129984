import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Button } from '@mui/material';
import { Grid } from '@material-ui/core';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import '../SMRR.css';
import BasicModal from './Modal';
import { routes } from 'routes/RouteConstants';
import { useGetProductDetails } from 'query/productDetails';

const ProductDetailsButton = (props) => {
    const navigate= useNavigate();
    const [inspReportURL, setInspReportURL] = useState(null);
    const [specSheetArrayURL, setSpecSheetArrayURL] = useState(null);
    const {data: listingDetails}= useGetProductDetails(props?.listing_id, true);
    
    useEffect(() => {
        const inspReportArray = listingDetails?.data?.attachments?.filter((el) => el.description==='INSPECTION_REPORT');
        setInspReportURL(inspReportArray ? inspReportArray[0]?.attachment : null);

        const specSheetArray = listingDetails?.data?.attachments?.filter((el) => el.description==='SPECIFICATION_SHEET');
        setSpecSheetArrayURL(specSheetArray ? specSheetArray[0]?.attachment : null);

    }, [listingDetails]);

    const containerStyle = {
        justifyContent: 'flex-end',
        paddingTop: '55px',
    }
    const [open, setOpen] = useState(false);
    const [quoteId, setQuoteId] = useState();
    const handleModalOpen = (id) => {
        setOpen(true);
        setQuoteId(id);
    }
    const handlePayment=(id,listing_id,unique_id,value,tabtype)=>{
        const payment_Type = tabtype ? 'Quotes': 'Inspection';       
        navigate(routes.PAYMENT_BILLLING,{state:{id,listing_id,unique_id, value,tabtype,payment_Type}})
    }
    const handleViewPDF=(docurl)=>{
        window.open(docurl, '_blank');
    }
    const redirectEquipmentInfo=()=>{        
        navigate(routes.Equipment_Info)
    }
    return (
            <Grid container lg={12} md={12} sm={12} justifyContent='center'>
                <Grid item container lg={10} sm={12} md={12}>
                    <Grid item container spacing={2} style={containerStyle}>
                        <Grid item>
                            <Button className='product-detail-pageBtn'
                                startIcon={<InsertDriveFileOutlinedIcon style={{ height: '23px', marginTop: '-3px' }} />}
                                style={{ backgroundColor: '#F5F5F5', color: '#545454', border: '1px solid #B0B0B0' }}
                                disabled={!specSheetArrayURL}
                                onClick={()=>handleViewPDF(specSheetArrayURL)} 
                            >                                    
                                View Specification Sheet
                            </Button>
                        </Grid>
                        <Grid item >
                            <Button className='product-detail-pageBtn'
                                startIcon={<InsertDriveFileOutlinedIcon style={{ height: '23px', marginTop: '-3px' }} />}
                                style={{ backgroundColor: '#F5F5F5', color: '#545454', border: '1px solid #B0B0B0' }}
                                disabled={!inspReportURL}
                                onClick={()=>handleViewPDF(inspReportURL)} 
                            >
                                View Inspection Report
                            </Button>
                        </Grid>
                        {(props?.quoteType === 'DEPOSIT' || props?.quoteType === 'INSPECTION_FEE_PENDING') &&
                            <Grid item><Button onClick={()=>handlePayment(props?.id,props?.listing_id, props.unique_id,props?.validValue,props.isQuoteTab)} className='product-detail-pageBtn' style={{ backgroundColor: '#FFC200', color: ' #0F0F0F', border: '1px solid #FFC200' }}> Pay now</Button></Grid>
                        }

                        {props?.quoteType === 'QUOTE_SENT' &&                            
                            <>
                                <Grid item>
                                    <Button className='product-detail-pageBtn'onClick={() => handleModalOpen(props?.id)}
                                        style={{ backgroundColor: '#F5F5F5', color: '#545454', border: '1px solid #B0B0B0' }}>
                                        Remove Quote
                                    </Button>
                                </Grid>
                                <BasicModal open={open} setClose={setOpen} id={quoteId} refetchQuote={redirectEquipmentInfo}/>                                
                            </>
                        }
                    </Grid>
                </Grid>
            </Grid>
    )
}
export default ProductDetailsButton;