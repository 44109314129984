import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { FileUploader } from 'pages/commonComponents/FileUploader/FIleUploader';
import { useRefContext } from 'components/Layout';
import { PreviouslyUploadedFile } from '../../../../components/PreviouslyUploadedFile';
import { FileUploaderReadOnly } from '../../../commonComponents/FileUploaderReadOnly/FileUploaderReadOnly';
export const SocialSecurity = (props) => {
  const {
    activeStep,
    setActiveStep,
    data,
    setData,
    deleteDocument,
    inputData,
    setInputData,
    allPreviouslyUploadedDocuments,
    setAllPreviouslyUploadedDocuments,
  } = props;
  const { headerRef } = useRefContext();
 

  // State
  const [selectedFrontFile, setSelectedFrontFile] = useState(null);
  const [selectedBackFile, setSelectedBackFile] = useState(null);

  // Methods

  const handleIsSelectedState = (fileData, state = true) => {
    let modifiedArr = [...allPreviouslyUploadedDocuments];
    modifiedArr.forEach((item) => {
      if (item.id === fileData.id) {
        item._isSelected = state;
      }
    });
  };

  const handlePreviouslyUploadedPreviewDelete = (item, type) => {
    // set _isSelected to false
    handleIsSelectedState(item, false);
    // remove from the selected state used (to show read only preview)
    if (type === 'FRONT') {
      const newData = { ...data };
      newData.documents.SocialSecurity[0] = null;
      setData(newData);
      // remove inputFile cache as well
      const newInputData = { ...inputData };
      newInputData.documents.SocialSecurity[0] = null;
      setInputData(newInputData);
      setSelectedFrontFile(null);
    }
    if (type === 'BACK') {
      const newData = { ...data };
      newData.documents.SocialSecurity[1] = null;
      setData(newData);
      const newInputData = { ...inputData };
      newInputData.documents.SocialSecurity[1] = null;
      setInputData(newInputData);
      setSelectedBackFile(null);
    }

    handleSetEnabled();
  };
  const handlePreviouslyUploadedDocumentClick = (item, type) => {
    if (type === 'FRONT') {
      if (selectedFrontFile) {
        // deselect the last selected file
        handleIsSelectedState(selectedFrontFile, false);
      }
      // set the newly selected file
      setSelectedFrontFile(item);
      // Set/OverWrite data on documents[key]
      const newData = { ...data };
      newData.documents.SocialSecurity[0] = item;
    }
    if (type === 'BACK') {
      if (selectedBackFile) {
        // deselect the last selected file
        handleIsSelectedState(selectedBackFile, false);
      }
      // set the newly selected file
      setSelectedBackFile(item);
      // Set/OverWrite data on documents[key]
      const newData = { ...data };
      newData.documents.SocialSecurity[1] = item;
    }

    handleSetEnabled();
  };

  const handleSetEnabled = () => {
  };

  const handleSuccess = (res, index) => {
    const newData = { ...data };
    newData.documents.SocialSecurity[index] = res.resData?.data;

    const newInputData = { ...inputData };
    newInputData.documents.SocialSecurity[index] = {
      file: res.fileData,
      description: res.attachmentDescription,
    };

    setInputData(newInputData);
    setData(newData);
  };

  const [previouslyUploadedDocsAvailable, setPreviouslyUploadedDocsAvailable] =
    useState(false);
  useEffect(() => {
    if (
      allPreviouslyUploadedDocuments.filter((item) => {
        return item.document_type === 'SOCIAL_SECURITY';
      }).length
    ) {
      setPreviouslyUploadedDocsAvailable(true);
    } else {
      console.log('Else Condition');
    }
  }, [allPreviouslyUploadedDocuments]);

  useEffect(() => {
    handleSetEnabled();
  }, [data?.documents?.SocialSecurity]);

  useEffect(() => {
    if (
      data?.documents?.SocialSecurity[0] &&
      !inputData?.documents?.SocialSecurity[0]
    ) {
      setSelectedFrontFile(data?.documents?.SocialSecurity[0]);
    }
    if (
      data?.documents?.SocialSecurity[1] &&
      !inputData?.documents?.SocialSecurity[1]
    ) {
      setSelectedBackFile(data?.documents?.SocialSecurity[1]);
    }
  }, []);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };
  return (
    <div
      className="UploadVideoPS ag-grid"
      style={{
        height: 'calc(100vh - ' + headerRef?.current?.clientHeight + 'px)',
      }}
    >
      <header className="content-header">
        <div className="action-bar flex justify-between p-2">
          <div></div>
          <div className="actions"></div>
        </div>
      </header>
      <div className="main">
        <div className="stepper-content-header py-2 pb-10">
          <p className="text-[#051C2C] text-[16px]  font-medium	 !font-[Inter]">
            {' '}
            Social Security
          </p>
        </div>

        <Grid container className="stepper-content">
          <Grid item lg={6} md={12} sx={12}>
            <p className=" pb-8 text-[#333333] text-[14px]  font-medium	 !font-[Inter]">
              Front
            </p>
            {selectedFrontFile ? (
              <FileUploaderReadOnly
                fileDataDefault={selectedFrontFile}
                handleDelete={(item) => {
                  handlePreviouslyUploadedPreviewDelete(item, 'FRONT');
                }}
              />
            ) : (
              <FileUploader
                document_type="SOCIAL_SECURITY"
                document_side="FRONT"
                attachmentDescription="Front"
                fileDataDefault={
                  inputData?.documents?.SocialSecurity?.length > 0
                    ? inputData?.documents?.SocialSecurity[0]?.file
                    : null
                }
                is_primary={false}
                type={'document'}
                handleSuccess={(res) => {
                  handleSetEnabled();
                  handleSuccess(res, 0);
                }}
                handleError
                handleDelete={() => {
                  handleSetEnabled();
                  deleteDocument('SocialSecurity', 'SOCIAL_SECURITY', 'FRONT');
                }}
              />
            )}
          </Grid>
          <Grid item lg={6} md={12} sx={12}>
            <p className=" pb-8 text-[#333333] text-[14px]  font-medium	 !font-[Inter]">
              Back
            </p>{' '}
            {selectedBackFile ? (
              <FileUploaderReadOnly
                fileDataDefault={selectedBackFile}
                handleDelete={(item) => {
                  handlePreviouslyUploadedPreviewDelete(item, 'BACK');
                }}
              />
            ) : (
              <FileUploader
                document_side="BACK"
                document_type="SOCIAL_SECURITY"
                fileDataDefault={
                  inputData?.documents?.SocialSecurity?.length > 1
                    ? inputData?.documents?.SocialSecurity[1]?.file
                    : null
                }
                attachmentDescription="Back"
                is_primary={false}
                type={'document'}
                handleSuccess={(res) => {
                  handleSetEnabled();
                  handleSuccess(res, 1);
                }}
                handleError
                handleDelete={() => {
                  handleSetEnabled();
                  deleteDocument('SocialSecurity', 'SOCIAL_SECURITY', 'BACK');
                }}
              />
            )}
          </Grid>
        </Grid>
        {previouslyUploadedDocsAvailable ? (
          <div>
            <div className="or-section max-w-[1010px] !my-12"></div>
            <div className="stepper-content-header py-2 pb-10">
              <p className="text-[#051C2C] text-[16px]  font-medium	 !font-[Inter]">
                {' '}
                Choose a document
              </p>
            </div>
            <Grid container className="stepper-content">
              <Grid item lg={6} md={12} sx={12}>
                <Typography className="text-sm py-2">Front</Typography>
                {allPreviouslyUploadedDocuments?.map((item) => {
                  if (
                    item.document_type === 'SOCIAL_SECURITY' &&
                    item.document_side === 'FRONT'
                  ) {
                    return (
                      <div className="mb-4">
                        <PreviouslyUploadedFile
                          handleSuccess={(item) => {
                            // handleSuccess(res, 0)
                            handlePreviouslyUploadedDocumentClick(
                              item,
                              'FRONT'
                            );
                          }}
                          handleDeSelect={(item) => {
                            handlePreviouslyUploadedPreviewDelete(
                              item,
                              'FRONT'
                            );
                          }}
                          document_type="SOCIAL_SECURITY"
                          document_side="FRONT"
                          fileData={item}
                          allPreviouslyUploadedDocuments={
                            allPreviouslyUploadedDocuments
                          }
                          setAllPreviouslyUploadedDocuments={
                            setAllPreviouslyUploadedDocuments
                          }
                        />
                      </div>
                    );
                  }
                })}
              </Grid>
              <Grid item lg={6} md={12} sx={12}>
                <Typography className="text-sm py-2">Back</Typography>
                {allPreviouslyUploadedDocuments?.map((item) => {
                  if (
                    item.document_type === 'SOCIAL_SECURITY' &&
                    item.document_side === 'BACK'
                  ) {
                    return (
                      <div className="mb-4">
                        <PreviouslyUploadedFile
                          handleSuccess={(item) => {
                            // handleSuccess(res, 0)
                            handlePreviouslyUploadedDocumentClick(item, 'BACK');
                          }}
                          handleDeSelect={(item) => {
                            handlePreviouslyUploadedPreviewDelete(item, 'BACK');
                          }}
                          document_type="SOCIAL_SECURITY"
                          document_side="BACK"
                          fileData={item}
                          allPreviouslyUploadedDocuments={
                            allPreviouslyUploadedDocuments
                          }
                          setAllPreviouslyUploadedDocuments={
                            setAllPreviouslyUploadedDocuments
                          }
                        />
                      </div>
                    );
                  }
                })}
              </Grid>
            </Grid>
          </div>
        ) : null}
      </div>
      <footer className="content-footer mb-[200px]">
        <div className="stepper-content-actions-footer flex justify-between">
          <div></div>
          <div className="flex flex-col gap-6">
            <Button
              style={{
                width: '275px',
                minHeight: '44px',
                height: '44px',
              }}
              onClick={handleNext}
              variant="contained"
            >
              Continue
            </Button>
          </div>
        </div>
      </footer>
    </div>
  );
};
