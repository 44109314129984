import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { FileUploader } from 'pages/commonComponents/FileUploader/FIleUploader';
import { useRefContext } from 'components/Layout';
import { PreviouslyUploadedFile } from '../../../../components/PreviouslyUploadedFile';
export const BankStatement = (props) => {
  const {
    activeStep,
    setActiveStep,
    data,
    setData,
    deleteAttachment,
    deleteDocumentAntiDode,
    inputData,setInputData,
    deleteDocument,
    allPreviouslyUploadedDocuments,
    setAllPreviouslyUploadedDocuments
  } = props;
  const { headerRef, navRef } = useRefContext();
  const [enableButton, setEnableButton] = useState(data?.documents?.BankStatement?false:true);

  const handleSuccess = (res, index) => {
  
    const newData = { ...data };
    newData.documents.BankStatement[index] = res.resData?.data;

    const newInputData = { ...inputData };

    newInputData.documents.BankStatement[index] = { file: res.fileData, description: res.attachmentDescription }

    // setData - using resData and setInputData is using input elements selected files data object
    setInputData(newInputData);
    setData(newData);
  };
  /*const handleSuccess = (res,event,name) => {
    const newData = { ...data };
    const newinputData = { ...inputData };

    if (newData?.documents.BankStatement) {
      const datafiltered = {};

      for (let key in newData.documents) {
        if (key != 'BankStatement') {
          datafiltered[key] = newData.documents[key];
        }
      }

      newData.documents = { ...datafiltered, BankStatement: res.resData?.data };

      const inputDatafiltered = {};
      for (let key in newinputData.documents) {
        if (key != 'BankStatement') {
          inputDatafiltered[key] = newinputData.documents[key];
        }
      }
      newinputData.documents = {
        ...inputDatafiltered,
        BankStatement: res.fileData,
      };
    } else {
      newinputData.documents = {
        ...newinputData.documents,
        BankStatement: res.fileData,
      };
      newData.documents = {
        ...newData.documents,
        BankStatement: res.resData?.data,
      };
    }
    setData(newData);
    setInputData(newinputData);
   setEnableButton(false)
  };*/

  useEffect(()=>{
    handleSetEnabled();
  },[data?.documents?.BankStatement])

  const handleSetEnabled = () => {
    // setTimeout(() => {
    //   if (data.documents.BankStatement?.filter(item => {
    //     return item != null;
    //   }).length === 3) {
    //     setEnableButton(false);
    //   } else {
    //     setEnableButton(true);
    //   }
    // }, 50);
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };
  return (
    <div
      className="UploadVideoPS ag-grid"
      style={{
        height: 'calc(100vh - ' + headerRef?.current?.clientHeight + 'px)',
      }}
    >
      <header className="content-header">
        <div className="action-bar flex justify-between p-2">
          <div></div>
          <div className="actions"></div>
        </div>
      </header>
      <div className="main">
        <div className="'BankStatement'per-content-header py-2 pb-8">
          <p className="text-[#051C2C] text-[16px]  font-medium	 !font-[Inter]">
            Upload Bank Statement
          </p>
        </div>

        <Grid container className="'BankStatement'per-content">
          <Grid container item lg={12} md={12} xs={12}>
            <Grid item lg={12} md={12} xs={12}>
              <p className=" py-2 text-[#757575] text-[14px] !font-[Inter]">
                3 most recent monthly bank statements
              </p>
            </Grid>
            <Grid lg={6} md={12} xs={12} item className="py-2">
              {' '}
              {console.log(inputData.documents.BankStatement[0]?.file)}
              {
                Array(3).fill().map((item, index) => {
                  return (<div className='mt-6' key={index}>
                    <FileUploader
                      fileDataDefault={inputData.documents.BankStatement[index]?.file || null}
                      is_primary={false}
                      type={'document'}
                      document_type='BANK_STATEMENT'
                      document_side='NONE'
                      attachmentDescription='document'
                      handleSuccess={(res) => {
                        handleSetEnabled();
                        handleSuccess(res, index)
                      }}
                      handleError
                      handleDelete={() => {
                        handleSetEnabled();
                        deleteDocumentAntiDode('BankStatement', index);
                      }}
                    />
                  </div>)
                })
              }
            </Grid>
          </Grid>
        </Grid>
 </div>
      <footer className="content-footer mb-[200px]">
        <div className="'BankStatement'per-content-actions-footer flex justify-between">
          <div></div>
          <div className="flex flex-col gap-6">
            <Button
              style={{
                width: '275px',
                minHeight: '44px',
                height: '44px',
              }}
              // disabled={enableButton}
              onClick={handleNext}
              variant="contained"
            >
              Continue
            </Button>
          </div>
        </div>
      </footer>
    </div>
  );
};
