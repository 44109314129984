import React, { useEffect, useState } from 'react';

import { CustomTextField } from 'components';

import { maskAmount } from 'utils';

let postRegexValue = null;
const FloatField = (props) => {
  const {
    pattern,
    onChange,
    value,
    maskAmount: shouldMaskAmount = false,
    ...rest
  } = props;
  /**
   * If you are going to use a pattern,
   * Please take care that we are replacing any non-digit characters except decimal '.'
   */

  const [localState, setLocalState] = useState({
    isFocused: false,
    currentValue: value,
  });

  useEffect(() => {
    if (!localState.isFocused) {
      setLocalState({
        ...localState,
        currentValue: shouldMaskAmount ? maskAmount(value) : value,
      });
    }
  }, [value]);

  const handleChange = (event) => {
    postRegexValue = null;
    const eventValue = event.target.value;
    if ([null, ''].includes(eventValue)) {
      postRegexValue = null;
    }

    postRegexValue = eventValue;
    postRegexValue =
      postRegexValue?.replaceAll(/[^0-9.]/g, '').match(pattern) || [];

    const firstValue = postRegexValue[0] || '';

    if (postRegexValue !== localState.currentValue) {
      let currentValue = null;

      if (firstValue.includes('.')) {
        currentValue = firstValue;
      } else if (parseFloat(firstValue) === 0) {
        currentValue = 0;
      } else {
        currentValue = firstValue;
      }

      setLocalState({
        ...localState,
        currentValue: shouldMaskAmount
          ? maskAmount(currentValue)
          : currentValue,
      });
    }
    if (onChange) {
      onChange(firstValue);
    }
  };

  return (
    <CustomTextField
      value={localState.currentValue || ''}
      onChange={handleChange}
      {...rest}
    />
  );
};

FloatField.defaultProps = {
  pattern: /^\d*\.?\d{0,2}/g,
};

export default FloatField;
