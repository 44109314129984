import { useMutation, useQuery } from "@tanstack/react-query";
import { axios } from "utils";
import { QueryKey } from "./queryKeys";

const validUpdateUserCredential=(data)=>{
    const method="PATCH";
    const url=`profile/`;

    return axios({method,url,data})
}
export const useUpdateUser=()=>{
    
    return useMutation(validUpdateUserCredential)
}

const validUploadAttachmentCredentials=(data)=>{
    const method='POST'
    const url='upload-attachment/'
    const headers ={'content-type':'multipart/form-data'}
    return axios({method,url,data,headers})
}
export const useUploadAttachment=()=>{
    
    return useMutation(validUploadAttachmentCredentials)
}



const ValidGetProfileCredentials = () => {
    const method='GET'
    const url='auth/me/'
    return axios({method,url})
  };
  
  export const useGetUserProfile = () => {
    return useQuery(
        [QueryKey.GET_USER_PROFILE],
      () => ValidGetProfileCredentials(),
      {
        enabled: true,
      }
    );
  };


const validDeleteAttachmentCredentials=(data)=>{
    const method='DELETE'
    const url=`upload-attachment/${data}/`
 
    return axios({method,url})
}
export const useDeleteAttachment=()=>{
    
    return useMutation(validDeleteAttachmentCredentials)
}
