import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { getLocalStorageItem, localStorageKeys, setLocalStorageItem } from 'hooks';
import { useNavigate } from 'react-router';
import { axios } from 'utils';
import { saveData } from 'utils/localStorage';

// headers for api requests are here



const validLoginCredentials = (data) => {
  const method = 'POST';
  const url = `auth/login/`;

  return axios({ method, url, data });
};
const validOnboardingCredentials = (data) => {
  // Todo: Remove this data mutation if required
  let modifiedPayload = { ...data };
  if (modifiedPayload.phone) {
    modifiedPayload.phone = '+1' + modifiedPayload.phone;
  }
  const method = 'POST';
  const url = `on-boarding/`;
  return axios({ method, url, data: modifiedPayload });
};
const validOTPCredentials = (data) => {
  const method = 'POST';
  const url = `auth/send-otp/`;

  return axios({ method, url, data });
};
const validVerifyOtpCredentials = (data) => {
  const method = 'POST';
  const url = `auth/verify-otp/`;

  return axios({ method, url, data });
};
const validCreateAccountCredentials = (data) => {
  const method = 'POST';
  const url = `auth/register/`;

  return axios({ method, url, data });
};

export const RefreshAccessToken = () => {
  const method = "POST";
  const url = `auth/refresh-token/`;
  const data = {
    refresh_token: localStorage.getItem('REFRESH_TOKEN'),
  };
  return axios({ method, url, data });
};

export const handleRefreshAuthtokens = (response) => {
  const { access_token, refresh_token } = response?.data;
  setLocalStorageItem(localStorageKeys.AUTH_TOKEN, access_token);
  setLocalStorageItem(localStorageKeys.REFRESH_TOKEN, refresh_token);
};
const validGoogleLoginCredentials = (data) => {
  const method = 'POST';
  const url = `auth/oauth/google/`;
  return axios({ method, url, data });
};
const validFacebookLoginCredentials = (data) => {
  const method = 'POST';
  const url = `auth/oauth/facebook/`;
  return axios({ method, url, data });
};

const validResetPasswordCredentials = (data) => {
  const method = 'POST';
  const url = `auth/reset-password/`;
  return axios({ method, url, data });
};
const logOutCredentials = () => {
  const method = 'POST';
  const url = `auth/revoke/`;
  const data = {
    refresh: getLocalStorageItem('REFRESH_TOKEN'),
  };
  return axios({ method, url, data });
};
//useMutation starts here

//login starts here
export const useLoginQuery = (loginSuccess, loginFailed) => {
  return useMutation(validLoginCredentials, {
    onSuccess: (res) => {
      loginSuccess(res);
      saveData(localStorageKeys.AUTH_TOKEN, res.data.access_token);
      saveData(localStorageKeys.REFRESH_TOKEN, res.data.refresh_token);
      saveData(localStorageKeys.USER_DETAILS, res.data.user);
    },
    onError: (err) => {
      loginFailed(err);
    },
  });
};

export const useGoogleLoginQuery = (loginSuccess, loginFailed) => {
  return useMutation(validGoogleLoginCredentials, {
    onSuccess: (res) => {
      loginSuccess(res);
      saveData(localStorageKeys.AUTH_TOKEN, res.data.access);
      saveData(localStorageKeys.REFRESH_TOKEN, res.data.refresh);
      saveData(localStorageKeys.USER_DETAILS, res.data.user_data);
    },
    onError: (err) => {
      loginFailed(err);
    },
  });
};
export const useFacebookLoginQuery = (loginSuccess, loginFailed) => {
  return useMutation(validFacebookLoginCredentials, {
    onSuccess: (res) => {
      loginSuccess(res);

      saveData(localStorageKeys.AUTH_TOKEN, res.data.access);
      saveData(localStorageKeys.REFRESH_TOKEN, res.data.refresh);
      saveData(localStorageKeys.USER_DETAILS, res.data.user_data);
    },
    onError: (err) => {
      loginFailed(err);
    },
  });
};

// onBoarding Starts here

export const useOnboardingQuery = () => {
  return useMutation(validOnboardingCredentials);
};

export const useSendOtpQuery = (sendOtpOnSuccess, sendOtpOnError) => {
  return useMutation(validOTPCredentials, {
    onSuccess: (res) => {
      sendOtpOnSuccess(res.message);
    },
    onError: (err) => {
      sendOtpOnError(err.errors[0].message);
    },
  });
};

export const useVerifyOtp = (
  onSuccessOtpVerification,
  onErrorOtpVerification
) => {
  return useMutation(validVerifyOtpCredentials, {
    onSuccess: (res) => {
      onSuccessOtpVerification(res);
    },
    onError: (err) => {
      onErrorOtpVerification(err);
    },
  });
};

export const useRegisterQuery = (callback) => {
  return useMutation(validCreateAccountCredentials, {
    onSuccess: (res) => {
      callback();
      saveData(localStorageKeys.AUTH_TOKEN, res.data.access_token);
      saveData(localStorageKeys.REFRESH_TOKEN, res.data.refresh_token);
      saveData(localStorageKeys.USER_DETAILS, res.data.user);
    },
  });
};

//forgot password starts here

export const useResetPasswordQuery = (
  resetPasswordOnSuccess,
  resetPasswordOnError
) => {
  return useMutation(validResetPasswordCredentials, {
    onSuccess: (res) => {
      resetPasswordOnSuccess(res);
    },
    onError: (err) => {
      resetPasswordOnError(err);
    },
  });
};

export const useLogoutQuery = () => {
  return useMutation(logOutCredentials);
};
