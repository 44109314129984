import React, { useEffect, useState } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FilterChildComponent } from './FilterChildComponent';
import { Box, FormControl, InputAdornment, OutlinedInput } from '@mui/material';
import { FiSearch } from 'react-icons/fi';
import { nanoid } from 'nanoid';

import { SortComponent } from './SortComponent';
import { CustomSlider } from 'components/Sliders/CustomSlider';
import { debounce } from 'utils/debounce';
import { numberWithCommas } from 'utils/helpers/numberWithCommas';
import { CustomizedRadios } from './AnumComponents';

export const FilterParentComponent = ({
  title,
  data,
  appliedFilters,
  setAppliedFilters,
  ShowSearch,
  isSlider,
  filterFilter,
  setFilterFilter,
  isAnum,
}) => {
  const [expanded, setExpanded] = useState(false);
  // console.log('i am data',data)
  const [search, setSearch] = useState('');
  const [renderOptions, setRenderOptions] = useState(data);
  const [sliderValue, setSliderValue] = useState(
    title == 'Price' ? [0, 1000000] : [0, 200000]
  );
  const [renderSliderValue, setRenderSliderValue] = useState([0, 1000000]);
  useEffect(() => {
    let filtered = data?.filter((el) => {
      return el.toString()?.toLowerCase()?.indexOf(search.toLowerCase()) !== -1
        ? true
        : false;
    });
    setRenderOptions(filtered);
  }, [search, data]);

  const handleChangeExpansion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChangeSlider = (event, newValue) => {
    setSliderValue(newValue);
    if (title == 'Price') {
      let removed = appliedFilters.filter(
        (el) =>
          el.filter !== 'price_max' &&
          el.filter !== 'price_min' &&
          el.filter !== 'price'
      );

      setAppliedFilters([
        ...removed,
        {
          label: `$${numberWithCommas(newValue[0])} - $${numberWithCommas(
            newValue[1]
          )}`,
          filter: 'price',
          key: nanoid(),
        },
        { label: newValue[0], filter: 'price_min', key: nanoid() },
        { label: newValue[1], filter: 'price_max', key: nanoid() },
      ]);
    } else if (title == 'mileage') {
      let removed = appliedFilters.filter((el) => {
        if (
          el.filter !== 'mileage_max' &&
          el.filter !== 'mileage_min' &&
          el.filter !== 'mileage'
        ) {
          return el;
        }
      });
           setAppliedFilters([
             ...removed,
             {
               label: `${newValue[0]} miles - ${newValue[1]} miles`,
               filter: 'mileage',
               key: nanoid(),
             },
             { label: newValue[0], filter: 'mileage_min', key: nanoid() },
             { label: newValue[1], filter: 'mileage_max', key: nanoid() },
           ]);
    } else if (title == 'Hours') {
      let removed = appliedFilters.filter((el) => {
        if (
          el.filter !== 'hour_max' &&
          el.filter !== 'hour_min' &&
          el.filter !== 'hours'
        ) {
          return el;
        }
      });

      setAppliedFilters([
        ...removed,
        {
          label: `${newValue[0]} hours - ${newValue[1]} hours`,
          filter: 'hours',
          key: nanoid(),
        },
        { label: newValue[0], filter: 'hour_min', key: nanoid() },
        { label: newValue[1], filter: 'hour_max', key: nanoid() },
      ]);
    }
  };

  useEffect(() => {
    if (appliedFilters.length == 0) {
      setSliderValue([0, 1000000]);
      setRenderSliderValue([0, 1000000]);
    }
  }, [appliedFilters]);
  return (
    <Accordion
      disableGutters
      sx={{
        minHeight: '65px',
        maxHeight: '320px',
        boxShadow: 'none',
        borderBottom: '1px solid #E3E3E3',
        '&:before': {
          display: 'none',
        },
        '&.MuiPaper-elevation1': {
          boxShadow: 'none !important',
        },
      }}
      TransitionProps={{ unmountOnExit: false }}
      expanded={expanded === 'parent'}
      onChange={handleChangeExpansion('parent')}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className="!text-gray-30" />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{ boxShadow: 'none !important' }}
      >
        <Typography
          sx={{
            flexShrink: 0,
            minHeight: '60px',
            position: 'absolute',

            display: 'flex',
            textTransform: 'capitalize',
            fontWeight: '500 !important',
          }}
          className="capitalize px-2 text-[#262626] font-[Poppins] text-[14px] "
        >
          {title
            .split('_')
            .map((el) => {
              return el;
            })
            .join(' ')}
        </Typography>
      </AccordionSummary>

      <AccordionDetails sx={{ justifyContent: 'left', alignItems: 'center' }}>
        {ShowSearch == true && (
          <FormControl sx={{ padding: '10px' }} variant="outlined">
            <OutlinedInput
              color="primary"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              sx={{
                '&.Mui-focused  .MuiOutlinedInput-notchedOutline': {
                  border: '1px solid gray',
                },
                height: '36px',
              }}
              type={'text'}
              id="outlined-adornment-Search"
              startAdornment={
                <InputAdornment position="start">
                  {' '}
                  <FiSearch className="text-secondary-50" />{' '}
                </InputAdornment>
              }
              aria-describedby="outlined-search-helper-text"
              placeholder={`Search ${title
                .split('_')
                .map((el) => {
                  return el;
                })
                .join(' ')}`}
              inputProps={{
                'aria-label': 'Search',
              }}
            />
          </FormControl>
        )}
        <Box
          sx={{
            overflow: 'scroll',
            maxHeight: '250px',
            minHeight: '100px',

            paddingX: '5px',
          }}
        >
          {title == 'Sort' ? (
            <SortComponent
              data={data}
              appliedFilters={appliedFilters}
              setAppliedFilters={setAppliedFilters}
            />
          ) : isSlider ? (
            <Box className="p-5">
              <div style={{ marginTop: '-0.5rem', marginBottom: '0.5rem' }}>
                <CustomSlider
                  appliedFilters={appliedFilters}
                  handleChange={handleChangeSlider}
                  // min={0}
                  // max={title == 'Price' ? 1000000 : 200000}

                  setRenderSliderValue={setRenderSliderValue}
                  type={title}
                  value={renderSliderValue}
                  className="py-20"
                />
              </div>
              <Typography className="pt-8">
                {title == 'Price' ? (
                  <span>
                    <Typography className="flex justify-between">
                      <span className="box border-gray-50 px-2 py-1 text-sm ">
                        ${numberWithCommas(renderSliderValue[0])}
                      </span>
                      <span className="box border-gray-50 px-2 py-1 text-sm">
                        ${numberWithCommas(renderSliderValue[1])}
                      </span>
                    </Typography>
                  </span>
                ) : title == 'mileage' ? (
                  <span>
                    <Typography className="flex justify-between">
                      <span className="box border-gray-50 px-2 py-1 text-sm">
                        {numberWithCommas(renderSliderValue[0])} miles
                      </span>
                      <span className="box border-gray-50 px-2 py-1 text-sm">
                        {numberWithCommas(renderSliderValue[1])} miles
                      </span>
                    </Typography>
                  </span>
                ) : (
                  <span>
                    <Typography className="flex justify-between">
                      <span className="box border-gray-50 px-2 py-1 text-sm">
                        {numberWithCommas(renderSliderValue[0])} hours
                      </span>
                      <span className="box border-gray-50 px-2 py-1 text-sm">
                        {numberWithCommas(renderSliderValue[1])} hours
                      </span>
                    </Typography>
                  </span>
                )}
              </Typography>
            </Box>
          ) : isAnum ? (
            <Box className="py-2">
              <CustomizedRadios
                title={title}
                setAppliedFilters={setAppliedFilters}
                appliedFilters={appliedFilters}
              />
            </Box>
          ) : (
            renderOptions?.map((el, index) => {
              return (
                <FilterChildComponent
                  filterFilter={filterFilter}
                  setFilterFilter={setFilterFilter}
                  parent={title}
                  key={index}
                  setAppliedFilters={setAppliedFilters}
                  appliedFilters={appliedFilters}
                  data={el}
                />
              );
            })
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
