import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  ListItem,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { FilterParentComponent } from './FilterParentComponent';
import { useGetFilters } from 'query/HomeFilterInventory';
import Skeleton from '@mui/material/Skeleton';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Drawer, useMediaQuery, useTheme } from '@material-ui/core';
import { FilterOutlined, TuneOutlined } from '@mui/icons-material';
import { Filters } from '.';
import { useLocation, useNavigate } from 'react-router';
export const AppliedFiltersComponent = ({
  appliedFilters,
  setAppliedFilters,
  filterBorder,
  setSeggrigatedData,
  refetchFilters,
  setFilterFilter,
}) => {
  const theme = useTheme();
  const isXLarge = useMediaQuery(theme.breakpoints.down('xl'));
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const latest = useRef();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    latest?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });
    setAppliedFilters(appliedFilters);
  }, [appliedFilters]);

  const handleClearFilters = () => {
    setOpen(false);
    setAppliedFilters([]);
    setFilterFilter([]);

    navigate(location.pathname, {});
  };
  const handleApplyFilters = () => {
    setOpen(false);
    setAppliedFilters(appliedFilters);
  };

  return (
    <Box
      sx={{
        padding: `${
          isMedium ? '5px 5px 5px 5px' : '1.5rem 1.5rem 0rem 1.5rem'
        }`,
        border: `${
          isMedium && filterBorder == true ? '1px solid gray' : 'none'
        }`,
        borderRadius: `${isMedium ? '5px' : '0px'}`,
      }}
      className={` ${
        isMedium ? 'min-h-50px' : 'min-h-[20px]'
      } max-w-[330px]  relative mb-5`}
    >
      {!isMedium && (
        <Box
          sx={{
            display: 'flex',

            justifyContent: 'space-between',
          }}
        >
          <p className="mb-1 !font-semibold !font-[Inter] !text-[18px] !text-[#000000]">
            Filters
          </p>

          {appliedFilters?.length > 0 && (
            <p
              className="mb-1 !font-semibold !font-[Inter] !text-[18px] cursor-pointer !text-secondary-50"
              onClick={handleClearFilters}
            >
              Clear all
            </p>
          )}
        </Box>
      )}
      <Grid
        spacing={1}
        item
        container
        sx={{ justifyContent: 'left', paddingY: `${!isMedium ? '1rem' : '0'}` }}
      >
        {appliedFilters?.length === 0 ? (
          <Box
            sx={{ justifyContent: 'space-between' }}
            className="!justify-between !w-full flex px-2 items-center text-center  "
          >
            <Typography className="!text-gray-50">No Filter Applied</Typography>
            <span>
              {' '}
              {isMedium && (
                <IconButton>
                  <TuneOutlined
                    onClick={() => {
                      setOpen(true);
                    }}
                    variant="secondary"
                  />
                </IconButton>
              )}
            </span>
          </Box>
        ) : (
          appliedFilters?.map((data, index) => {
            if (
              data.filter != 'price_min' &&
              data.filter != 'price_max' &&
              data.filter != 'mileage_max' &&
              data.filter != 'mileage_min' &&
              data.filter != 'hour_max' &&
              data.filter != 'hour_min'
            ) {
              const handleDelete = (chipToDelete) => () => {
                if (chipToDelete.filter == 'price') {
                  setAppliedFilters(
                    appliedFilters.filter(
                      (chip) =>
                        chip.filter !== 'price' &&
                        chip.filter !== 'price_min' &&
                        chip.filter !== 'price_max'
                    )
                  );
                } else if (chipToDelete.filter == 'mileage') {
                  setAppliedFilters(
                    appliedFilters.filter(
                      (chip) =>
                        chip.filter !== 'mileage' &&
                        chip.filter !== 'mileage_min' &&
                        chip.filter !== 'mileage_max'
                    )
                  );
                } else {
                  setAppliedFilters(
                    appliedFilters.filter(
                      (chip) => chip.key !== chipToDelete.key
                    )
                  );
                }
              };

              return (
                <Grid item key={data?.key}>
                  <Chip
                    sx={{
                      borderRadius: '2px !important',
                      boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.08)',
                      background: 'white',
                      color: '#262626',
                      '& .MuiChip-root': {
                        backgroundColor: 'red',
                        padding: '5px',
                        fontSize: '12px', // Set the font size
                      },// Set the font color
                    }}
                    // {data[index]filter != data.filter}
                    label={
                      data.filter == 'mileage_max'
                        ? data.label + ' ' + 'mils'
                        : data.label
                    }
                    ref={index == appliedFilters.length - 1 ? latest : null}
                    onDelete={handleDelete(data)}
                    deleteIcon={
                      <CloseIcon
                        sx={{ color: 'black !important', fontWeight: 500 }}
                      />
                    }
                  />
                </Grid>
              );
            }
          })
        )}
      </Grid>
      {/* <span>
              {' '}
              {isMedium && (
                <IconButton>
                  <TuneOutlined
                    onClick={() => {
                      setOpen(true);
                    }}
                    variant="secondary"
                  />
                </IconButton>
              )}
        </span> */}
      <Drawer
        PaperProps={{
          sx: { width: '100% !important' },
        }}
        className="!w-full"
        anchor="right"
        open={open}
      >
        <Filters
          isappliedFilterVisible={false}
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
        />
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Button
            sx={{ margin: '5px', width: '100%' }}
            onClick={handleClearFilters}
            variant="outlined"
          >
            Clear Filter
          </Button>
          <Button
            sx={{ margin: '5px', width: '100%' }}
            onClick={handleApplyFilters}
            variant="contained"
          >
            Apply
          </Button>
        </Box>
      </Drawer>
    </Box>
  );
};
