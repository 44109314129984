// import useToast from "./useToast";
import currencyFormat from "./currencyFormat";
import axios from './axiosInstance';
import { formatPortDisplayText } from './helpers/formatPortText';
import { onlyCharacters } from './onlyCharacters';
import { formatResponseError, setFormErrors } from './helpers/error';
import {
  setTomorrowMinDate,
  ifOnlyDatesAreSame,
  createSlotLabel,
} from './date';
import { isPhoneNumberValid } from './phone';
import { maskAmount } from './numberMask';
import { convertDaysIntoSlots } from './slots';

function ValidateEmail(email) {
  if (/^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/.test(email)) {
    return true;
  }
  // alert('You have entered an invalid email address!');
  return false;
}

export {
  currencyFormat,
  axios,
  formatPortDisplayText,
  formatResponseError,
  setFormErrors,
  ValidateEmail,
  onlyCharacters,
  setTomorrowMinDate,
  maskAmount,
  isPhoneNumberValid,
  ifOnlyDatesAreSame,
  createSlotLabel,
  convertDaysIntoSlots,
};
