import { CommonAccountLayout } from 'pages/commonComponents/CommonAccountLayout';
import React, { useEffect, useState } from 'react';
import { PaymentDefaultPage } from './PaymentDefaultPage';
import { CardFormMain } from './CardFormMain';
import { useCreateCustomerAccountQuery,useGetCardQuery} from 'query/payment';
import { getLocalStorageItem, setLocalStorageItem } from 'hooks';
import { ListCards } from './ListCards';
import { Grid,Box,Typography, Skeleton } from '@mui/material';

export const PaymentMethod = () => {
  const [showCardForm, setShowCardForm] = useState(false);
  const [customer_id, setCustomerId] = useState(getLocalStorageItem('CUSTOMER_ID'));


  const { data: paymentCards,  refetch,  dataUpdatedAt, isLoading } = useGetCardQuery();
  const data = paymentCards?.results;

  const { mutate: createCustomerAccount } = useCreateCustomerAccountQuery();
  

  useEffect(() => {     
    if (!customer_id) {
      createCustomerAccount(
        {},
        {
          onSuccess: (res) => {
            // console.log('i am here res',res)
            setLocalStorageItem('CUSTOMER_ID', res?.data?.id);
          },
          onError: (err) => {
            // console.log('i am here err',err)
          },
        }
      );

      return;
    } else {
    }
  }, []);

  
  const onSuccess = (res) => {
    refetch();
    setShowCardForm(false);
  };
  const onError = (err) => {
    //setShowCardForm(false);
  };
  const handleCancel = (res) => {    
    setShowCardForm(false);
  };
  
  return (
    <CommonAccountLayout
      Content={
        showCardForm ? (
          <>
          <Box className="p-8">          
            <Grid container item lg={12} md={12} xs={12} spacing={2}>
              <Typography className="p-4 font-bold">Add Payment Method</Typography>
            </Grid>
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
                className="pl-4"
              >
              <CardFormMain
                data={data}
                refetch={refetch}
                onSuccess={onSuccess}
                onError={onError}
                handleCancel={handleCancel}
              />
              </Grid>
              
          </Box>
          </>
        ) : isLoading ? (
         <Grid spacing={2} container>
          <Grid  item>
          <Skeleton
            className=" m-auto"
            variant="rectangular"
            width={'90%'}
            height={'240px'}
          />
          </Grid>
          <Grid  item>
          <Skeleton
            className=" m-auto"
            variant="rectangular"
            width={'90%'}
            height={'240px'}
          />
          </Grid>
          <Grid  item>
          <Skeleton
            className=" m-auto"
            variant="rectangular"
            width={'90%'}
            height={'240px'}
          />
          </Grid>
         </Grid>
        ) : data?.length == 0 ? (
          <PaymentDefaultPage
            showCardForm={showCardForm}
            setShowCardForm={setShowCardForm}
          />
        ) : (
          <ListCards
            refetch={refetch}
            setShowCardForm={setShowCardForm}
            data={paymentCards}
          />
        )
      }
    />
  );
};
