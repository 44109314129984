import React, { useEffect, useState } from 'react';
import { Button, Grid, Link, Select, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CustomTextField } from '../../../../components';
import { useRefContext } from '../../../../components/Layout';
import './BasicInformationPS.css';
import { MenuItem } from '@material-ui/core';
import { DevTool } from "@hookform/devtools";
import {
  useGetColorList,
  useGetBedSizeList,
  useGetBrandsList,
  useGetModelsList,
  useGetYearsList,
  useGetManufacturerList,
  useGetAxleRatioList,
  useGetWheelBaseList,
  useGetTireTread,
  useGetBrakeType,
  useGetCollisionList,
  useGetMitigationList,
  useGetEnginesList,
  useGetTransmissionManufacturerList,
  useGetHorsePowerList,
  useGetMileageList,
  useGetGVWRList,
  useTrailerLengthList,
  useGetBrandsListt
} from 'pages/ProductSell/ProductSellQueries/ProductSellQueries';
import { useForm } from 'react-hook-form';
import { numberWithCommas } from '../../../../utils/helpers/numberWithCommas';
import login from '../../../Auth/Login/Login';
import AmountTextField from 'components/AmountNumberTextField';


const BasicInformationPS = (props) => {
  const {
    category,
    sub_category,
    activeStep,
    setActiveStep,
    basicInfoFormElements,
    setBasicInfoFormElements,
    vehicle,
    setVehicle,
    handleFinalSubmit
  } = props;
  // JS - Definitions outside the return statement

  const theme = useTheme();
  const isXLarge = useMediaQuery(theme.breakpoints.down('xl'));
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));


  const { headerRef, navRef } = useRefContext();

  const {
    control,
    register,
    unregister,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    defaultValues: vehicle
  });
  const onSubmit = (data) => {
    console.log({data});
    let veh = {...vehicle, ...data};
    console.log({ veh });
    setVehicle(veh);
    // Set the data to the state in the parent
    // move to the next step in stepper
    setActiveStep(activeStep + 1);
  }

  const onError = (err) => {
    // Handle Errors
    console.log({ err });
  }


  const setSelectData = function(label, dataToSet) {
    let basicInfoFormElementsModified = [...basicInfoFormElements];
    basicInfoFormElementsModified.forEach(itemsGroup => {
      itemsGroup?.forEach(item => {
        if (item.label === label) {
          item.data = [...dataToSet];
        }
      });
    });
    return basicInfoFormElementsModified;
  }

  const handleCurrencyUpdate = () => {
    // set the data here
    console.log('handleCurrencyUpdate()');
  }

  const getLabel = (item) => {
    console.log('getLabel()', item?.validation);
    console.log(item?.validation?.required ? item.label : item.label + ' (Optional)');
    return item?.validation?.required ? item.label : item.label + ' (Optional)';
  }


  // Queries
  const { data: colorsListData, isFetching: isFetchingColorsListData } = useGetColorList();
  const { data: enginesListData, isFetching: isFetchingEnginesListData } = useGetEnginesList();
  const { data: bedSizeListData, isFetching: isFetchingBedSizeListData } = useGetBedSizeList();
  const { data: brandsListData, isFetching: isFetchingBrandsListData, refetch: refetchGetBrandsList } = useGetBrandsList({sub_category__id__in: sub_category});
  const { data: modelsListData, isFetching: isFetchingModelsListData, refetch: refetchGetModelsList } = useGetModelsList(getValues('make'));
  const { data: yearsListData, isFetching: isFetchingYearsListData } = useGetYearsList();
  const { data: transmissionManufacturerListData, isFetching: isFetchingTransmissionManufacturerListData } = useGetTransmissionManufacturerList();
  const { data: manufacturerListData, isFetching: isFetchingManufacturerListData } = useGetManufacturerList();
  const { data: axleRatioListData, isFetching: isFetchingAxleRatioListData } = useGetAxleRatioList();
  const { data: wheelBaseListData, isFetching: isFetchingWheelBaseListData } = useGetWheelBaseList();
  const { data: tireTreadListData, isFetching: isFetchingTireTreadListData } = useGetTireTread();
  const { data: brakeTypeListData, isFetching: isFetchingBrakeTypeListData } = useGetBrakeType();
  const { data: collisionListData, isFetching: isFetchingCollisionListData } = useGetCollisionList();
  const { data: mitigationListData, isFetching: isFetchingMitigationListData } = useGetMitigationList();
  const { data: horsePowerListData, isFetching: isFetchingHorsePowerListData } = useGetHorsePowerList();
  const { data: mileageListData, isFetching: isFetchingMileageListData } = useGetMileageList();
  const { data: gvwrListData, isFetching: isFetchingGVWRListData } = useGetGVWRList();
  const { data: trailerLengthListData, isFetching: isFetchingTrailerLengthListData } = useTrailerLengthList();

  // Modify the basicInfoFormData - For Select Drop Downs
  useEffect(() => {
    if (colorsListData) {
      let basicInfoFormElementsModified = setSelectData('Color', colorsListData?.results);
      setBasicInfoFormElements(basicInfoFormElementsModified);
    }
    if (enginesListData) {
      let basicInfoFormElementsModified = setSelectData('Engine Manufacturer', enginesListData?.results);
      setBasicInfoFormElements(basicInfoFormElementsModified);
    }
    if (brandsListData) {
      let basicInfoFormElementsModified = setSelectData('Brand', brandsListData?.results);
      setBasicInfoFormElements(basicInfoFormElementsModified);
    }
    if (modelsListData) {
      let basicInfoFormElementsModified = setSelectData('Model', modelsListData?.results);
      setBasicInfoFormElements(basicInfoFormElementsModified);
    }
    if(bedSizeListData) {
      let basicInfoFormElementsModified = setSelectData('Bed Size', bedSizeListData?.results);
      setBasicInfoFormElements(basicInfoFormElementsModified);
    }
    if(yearsListData) {
      let modifiedData = [...yearsListData.data];
      modifiedData.forEach(item => {
        item.name = item.year;
        item.id = item.year;
      })
      let basicInfoFormElementsModified = setSelectData('Year', modifiedData);
      setBasicInfoFormElements(basicInfoFormElementsModified);
    }
    if(manufacturerListData) {
      let basicInfoFormElementsModified = setSelectData('Trailer Manufacturer', manufacturerListData?.results);
      setBasicInfoFormElements(basicInfoFormElementsModified);
    }
    if(transmissionManufacturerListData) {
      let basicInfoFormElementsModified = setSelectData('Transmission Manufacturer', transmissionManufacturerListData?.results);
      setBasicInfoFormElements(basicInfoFormElementsModified);
    }
    if(axleRatioListData) {
      let modifiedData = [...axleRatioListData.results];
      modifiedData.forEach(item => {
        item.name = item.ratio;
      })
      let basicInfoFormElementsModified = setSelectData('Axle', modifiedData);
      setBasicInfoFormElements(basicInfoFormElementsModified);
    }
    if(wheelBaseListData) {
      let basicInfoFormElementsModified = setSelectData('Wheel Base', wheelBaseListData?.results);
      setBasicInfoFormElements(basicInfoFormElementsModified);
    }
    if(tireTreadListData) {
      let basicInfoFormElementsModified = setSelectData('Tire Tread Left', tireTreadListData?.results);
      setBasicInfoFormElements(basicInfoFormElementsModified);
    }
    if(brakeTypeListData) {
      let basicInfoFormElementsModified = setSelectData('Brake Type', brakeTypeListData?.results);
      setBasicInfoFormElements(basicInfoFormElementsModified);
    }
    if(collisionListData) {
      let basicInfoFormElementsModified = setSelectData('Collision Mitigation', collisionListData?.results);
      setBasicInfoFormElements(basicInfoFormElementsModified);
    }
    if(horsePowerListData) {
      let modifiedData = [...horsePowerListData.results];
      modifiedData.forEach(item => {
        item.name = item.value;
      })
      let basicInfoFormElementsModified = setSelectData('Horse Power', horsePowerListData?.results);
      setBasicInfoFormElements(basicInfoFormElementsModified);
      if(mitigationListData) {
        let basicInfoFormElementsModified = setSelectData('Mitigation System', mitigationListData?.results);
        setBasicInfoFormElements(basicInfoFormElementsModified);
      }
      if(mileageListData) {
        let modifiedData = [...mileageListData.results];
        modifiedData.forEach(item => {
          item.name = item.value;
        });
        let basicInfoFormElementsModified = setSelectData('Mileage', mileageListData?.results);
        setBasicInfoFormElements(basicInfoFormElementsModified);
      }
      if(gvwrListData) {
        let basicInfoFormElementsModified = setSelectData('GVWR', gvwrListData?.results);
        setBasicInfoFormElements(basicInfoFormElementsModified);
      }
      if(trailerLengthListData) {
        let basicInfoFormElementsModified = setSelectData('Trailer Length', trailerLengthListData?.results);
        setBasicInfoFormElements(basicInfoFormElementsModified);
      }
    }
  }, [
    colorsListData,
    enginesListData,
    brandsListData,
    bedSizeListData,
    modelsListData,
    yearsListData,
    manufacturerListData,
    transmissionManufacturerListData,
    axleRatioListData,
    wheelBaseListData,
    tireTreadListData,
    brakeTypeListData,
    collisionListData,
    mitigationListData,
    horsePowerListData,
    mileageListData,
    gvwrListData
  ]
  );

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      console.log({ value, name, type })
      if(name && name.includes('_text') && type === 'change') {
        // set the select drop-downs value to '' empty string or null
        console.log(name, " Found the optional type", name.replace("_text", ""));
        // unregister([name]);
        // register(name+'_text', { required: `${name} is mandatory` });
        setValue(name.replace('_text', ''), '');
      }
      if(name && !name.includes('_text') && type === 'change') {
        // unregister([name+'_text']);
        // register(name, { required: `${name} is mandatory` });
        setValue(name + '_text', '');
      }
      // Handle the optional DD rendering based on the DD selected value
      if(name === 'make'){
        console.log({ value });
        // call the API again - model list
        setTimeout(() => {
          refetchGetModelsList({brand_id__in: value.make});
        },500);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);


  useEffect(() => {
    refetchGetBrandsList({sub_category__id__in: sub_category});
    refetchGetModelsList();
  }, [sub_category])

  // Template
  return (
    /*<div>*/
    <>
    <form onSubmit={handleSubmit(onSubmit, onError)}>
    <div className='BasicInformationPS ag-grid'
         style={{ height: 'calc(100vh - ' + headerRef?.current?.clientHeight + 'px)' }}>
      <header className='content-header'>
        <div className='action-bar flex justify-between p-2'>
          <div></div>
          <div className='actions'>
          </div>
        </div>
      </header>
      <div className='main'>
        <div className='stepper-content-header py-2 pb-8'>
          <h3 className='font-Inter'>Add Basic Information</h3>
        </div>
        <div className='stepper-content'>
          <div className='flex flex-col'>
            {
                  basicInfoFormElements.map((formGroup) => {
                console.log("formGroup",formGroup)
                return (<div className='flex flex-wrap gap-[24px]'>
                  {formGroup.map(item => {
                    if(item.renderIfCategoryIs === '' || (item.renderIfCategoryIs.indexOf(category) != -1)){

                      if (item.type === 'whitespace') {
                        return <div className='form-item whitespace-container'></div>;
                      }
                      if (item.type === 'currency') {
                        return <div className='form-item currency-container'>
                          <div className='currency-label'>
                            $
                          </div>
                          <CustomTextField
                            onKeyDown={(e) => {
                              if (e.which != 8 && e.which != 0 && e.which < 48 || e.which > 57) {
                                e.preventDefault();
                              }
                            }}
                            
                            inputProps={{ maxLength: 15, type: 'tel' }}
                            FormHelperTextProps={{
                              className: 'animateErrorValue'
                            }}
                            {...register(item.apiKey, item?.validation)}
                           

                            type='number'
                            label={item.label}
                            placeholder={'Enter ' + item.label} />
                          {errors[item.apiKey] ?
                            <p className='error pt-2 pl-1'>{errors[item.apiKey].message}</p> : null}
                        </div>;
                      }
                      if (item.type === 'text' && item.type !== 'optional') {
                        return <div className='form-item'>
                          <label
                            className='MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-colorPrimary MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink mb-1 !font-[500] !font-[Inter] text-lg text-black css-jicodr-MuiFormLabel-root-MuiInputLabel-root'>
                            {getLabel(item)}</label>
                          <CustomTextField
                            {...register(item.apiKey, item?.validation)}
                            FormHelperTextProps={{
                              className: 'animateErrorValue '
                            }}
                            type={item?.validationType || 'text'}
                            // label={getLabel(item)}
                            placeholder={'Enter ' + item.label} />
                          {errors[item.apiKey] ?
                            <p className='error pt-2 pl-1'>{errors[item.apiKey].message}</p> : null}
                        </div>;
                      }
                      if (item.type === 'select' && item.type !== 'optional') {
                        return <div className='form-item'>
                          <label
                            className='MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-colorPrimary MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink mb-1 !font-[500] !font-[Inter] text-lg text-black css-jicodr-MuiFormLabel-root-MuiInputLabel-root'>
                            {getLabel(item)}</label>
                          <select

                            {...register(item.apiKey, item?.validation)}
                            label={item.label}
                            variant='outlined'
                            disableUnderline={true}
                            sx={{
                              height: '44px',
                              borderRadius: '8px',
                              paddingLeft: '10px',
                              paddingTop: '8px',
                              paddingBottom: '2px'
                            }}
                            displayEmpty
                          >
                            <option value=''>
                              Select {item.label}
                            </option>
                            {item.data?.map((ddItem) => {
                              return (<option key={ddItem.id} value={ddItem.name}>
                                {ddItem.name || ddItem.displayName || ddItem.display_name}
                              </option>);
                            })}
                          </select>
                          {/*<Select
                          {...register(item.apiKey, item?.validation)}
                          label='{item.label}'
                          variant='outlined'
                          disableUnderline={true}
                          sx={{
                            height: '44px',
                            borderRadius: '8px',
                            paddingLeft: '10px',
                            paddingTop: '8px',
                            paddingBottom: '2px'
                          }}
                          displayEmpty
                          defaultValue=''
                        >
                          <MenuItem value=''>
                            Select {item.label}
                          </MenuItem>
                          {item.data?.map((ddItem) => {
                            return (<MenuItem value={ddItem.id}>
                              {ddItem.name || ddItem.displayName || ddItem.display_name}
                            </MenuItem>);
                          })}
                        </Select>*/}
                          {errors[item.apiKey] ?
                            <p className='error pt-2 pl-1'>{errors[item.apiKey].message}</p> : null}
                        </div>;
                      }
                      if (item.type === 'optional') {
                        return (
                          <div className='optional-form-item-group'>
                            <div className='form-item'>
                              <label
                                className='MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-colorPrimary MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink mb-1 !font-[500] !font-[Inter] text-lg text-black css-jicodr-MuiFormLabel-root-MuiInputLabel-root'>
                                {getLabel(item)}</label>
                              <select
                                {...register(item.apiKey, {required: item.label === 'Wheel Base' || watch(`${item.apiKey}_text`) ? false : `${item.label} is Mandatory`})}
                                // onChange={(e) => {
                                //   console.log("Here unregister the item.apiKey");
                                //   setValue(item.apiKey+'_text', '');
                                //   // unregister(item.apiKey + '_text', { keepError: false });
                                //   // register(item.apiKey, {required: 'Mandatory Error'})
                                //   // unregister(item.apiKey);
                                //   // register(item.apiKey, {required: false});
                                //   // unregister(item.apiKey+'_text');
                                //   // register(item.apiKey+'_text', {required: 'mandatory message'});
                                //   // setValue(item.apiKey, e.target.value);
                                // }}
                                /*name={item.apiKey}
                                onChange={(e) => {
                                  console.log("Here unregister the _text");
                                  setValue(item.apiKey, e.target.value)
                                }}
                                ref={register( item.apiKey,{required: true})}*/
                                label={item.label}
                                variant='outlined'
                                disableUnderline={true}
                                sx={{
                                  height: '44px',
                                  borderRadius: '8px',
                                  paddingLeft: '10px',
                                  paddingTop: '8px',
                                  paddingBottom: '2px',
                                }}
                                displayEmpty
                              >
                                <option value=''>
                                  Select {item.label}
                                </option>
                                {item.data?.map((ddItem) => {
                                  if(ddItem.label === 'Trailer Length') {
                                    return (<option key={ddItem.id} value={ddItem.display_name}>
                                    {ddItem.name || ddItem.displayName || ddItem.display_name}
                                  </option>)
                                  } else {
                                  return (<option key={ddItem.id} value={ddItem.name}>
                                    {ddItem.name || ddItem.displayName || ddItem.display_name}
                                  </option>);
                                  }
                                })}
                              </select>
                              {errors[item.apiKey] ?
                                <p className='error pt-2 pl-1'>{errors[item.apiKey].message}</p> : null}
                            </div>
                            <div className='form-item'>
                              <CustomTextField
                                {...register(item.apiKey + '_text', {required:  item.label === 'Wheel Base' || watch(item.apiKey) ? false : `${item.label} is Mandatory`, minLength: 2, maxLength: 25})}
                                // onChange={(e) => {
                                //   console.log("Here unregister the item.apiKey");
                                //   setValue(item.apiKey, '');
                                //   // unregister(item.apiKey, { keepError: false });
                                //   // register(item.apiKey+'_text', {required: 'Mandatory Error'})
                                //   // unregister(item.apiKey+'_text');
                                //   // register(item.apiKey+'_text', {required: false});
                                //   // unregister(item.apiKey);
                                //   // register(item.apiKey, {required: 'mandatory message'});
                                //   // setValue(item.apiKey+'_text', e.target.value);
                                // }}
                                /*name={item.apiKey + '_text'}
                                onChange={(e) => {
                                  console.log("Here unregister the item.apiKey");
                                  setValue(item.apiKey + '_text', e.target.value);
                                }}
                                ref={register(item.apiKey + '_text',{required: true})}*/
                                FormHelperTextProps={{
                                  className: 'animateErrorValue '
                                }}
                                type='text'
                                label={getLabel(item)}
                                placeholder={'Enter ' + item.label} />
                              {errors[item.apiKey+'_text'] ? <p className="error pt-2 pl-1">{errors[item.apiKey+'_text'].message}</p> : null}

                              {errors[item.apiKey+'_text']?.type === 'minLength' ?
                                <p className='error pt-2 pl-1'>{item.label} should be of minimum 2 characters</p> : null}
                              {errors[item.apiKey+'_text']?.type === 'maxLength' ?
                                <p className='error pt-2 pl-1'>{item.label} should be of maximum 25 characters</p> : null}
                            </div>
                          </div>);
                      }
                    }
                  }                  )}
                  {/*<div className='section-breaker w-full h-[2px] mb-[24px] bg-[#F5F5F5]'></div>*/}
                  <div className='section-breaker w-full'></div>
                </div>);
              })
            }
          </div>
        </div>
      </div>
      <footer className='content-footer mb-[200px]'>
        <div className='stepper-content-actions-footer flex justify-between'>
          <div></div>
          <div className='flex flex-col gap-6 mb-6'>
            <Button
              type='submit'
              style={{ width: '275px', height: '44px', minHeight: '44px' }}
              variant='contained'>
              Continue
            </Button>
          </div>
        </div>
      </footer>
    </div>
    </form>
    <DevTool control={control} /> {/* set up the dev tool */}
    </>
  );
};


export {
  BasicInformationPS
};