import React, { useState } from 'react';
import { Grid, Box , CircularProgress, IconButton, Typography,} from '@mui/material'; 
import { MdOutlinePhotoCamera } from 'react-icons/md';
import Button from '@mui/material/Button';
import { useDeleteAttachment, useUploadAttachment } from 'query/Accounts';
import { useRefContext } from 'components/Layout';

//Styles
const imgContStyle = {
  width: '155px',
  height: '155px',
  borderRadius: '75px',
};
const btnStyle = {
  fontSize: '13px', 
  fontWeight: '400',
  textAlign: 'center',  
  borderRadius: '14px',
};

const PorifileImgGrid = ({ image,data, file, setFile, src,uploadProfileImage,deleteProfileImage,handleOpenSomethingWentWrongDialog,refetch }) => {
  //handle file change
  const handleFileChange = (event) => {
    //console.log(event.target.files)
    setFile(event.target.files[0]);
    if(data?.profile_image?.attachment){
      deleteProfileImage(data?.profile_image?.id);
    }

    const formData= new FormData()
    formData.append('attachment',event.target.files[0] )
    formData.append('description','PROFILE_IMAGE')
    formData.append('is_primary',true )
   
    uploadProfileImage(formData,
      {
        onSuccess: (res) => {
            refetch();
            setTimeout(() => {
              window.location.reload();
            }, 1000)
        },
        onError: (err) => {
            if(err.status_code===500){
                handleOpenSomethingWentWrongDialog()
            }
        },
      }
    );
  };

  return (
    <>
      <Grid item container style={{ position: 'relative' }}>
        <Grid item>
          <div className="profile-img-container" style={imgContStyle}>
            <img src={src} alt="Upload Profile pic" style={imgContStyle} />
          </div>
          <Grid
            className="cameraIcon-container"
            style={{ right: '4px', bottom: '10px' }}
          >
            <div className="camera-icon-div">
              <input
                type="file"
                accept="image/*"
                id="upload-avatar-pic"
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
              <label htmlFor="upload-avatar-pic">
                <IconButton component="span">
                  <MdOutlinePhotoCamera
                    color="#0F5E91"
                    style={{ paddingLeft: '1px',paddingTop:'2px' }}
                  />
                </IconButton>
              </label>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const PorfileImgContainer = ({ refetch,data, image }) => {
  const { mutate: uploadProfileImage , isLoading:uploadingProfilePic} = useUploadAttachment();
  const {mutate:deleteProfileImage,isLoading:isDeletingProfilePic}= useDeleteAttachment()
  const [file, setFile] = useState(null);
  const {handleOpenSomethingWentWrongDialog} = useRefContext() 

  const src = file
    ? URL.createObjectURL(file)
    : process.env.PUBLIC_URL +
      `${
        data?.profile_image?.attachment ||
        'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png'
      }`;

  const handleProfileImageDelete=()=>{
      deleteProfileImage(data?.profile_image?.id,{
          onSuccess:(res)=>{
              refetch()
              setTimeout(() => {
                window.location.reload();
              }, 1000)
          },onError:(res)=>{
              handleOpenSomethingWentWrongDialog()
          }
      })
  }
  return (
    <Box
      className="shadow-md text-center m-auto "
      justifyContent={'center'}
      style={{ width: '225px', borderRadius: '12px' }}
    >
      <Box className="div-border-Style">
        <Grid
          justifyContent="center"
          container
          className="m-auto profile-first-container"
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <Grid
            item
            sx={{
              display: 'flex',
              paddingTop: '-20px',
            }}
          >
           {(uploadingProfilePic ||isDeletingProfilePic)? <CircularProgress  className='!text-secondary-50'/>: <PorifileImgGrid
              src={src}
              file={file}
              setFile={setFile}
              uploadProfileImage={uploadProfileImage}
              deleteProfileImage={deleteProfileImage}
              refetch={refetch}
              handleOpenSomethingWentWrongDialog={handleOpenSomethingWentWrongDialog}
              image={image}
              data={data}
            />}
          </Grid>
          <Grid item>            
            <Typography className="!font-semibold text-center">
              {data?.name}
            </Typography>
          </Grid>
          {data?.profile_image?.attachment &&
          <Grid item>                
            <Button onClick={handleProfileImageDelete}  className="button-style  hover:!text-black  !text-white !bg-none" style={btnStyle}>
              Delete Photo 
            </Button>            
          </Grid>
           }
        </Grid>
      </Box>
    </Box>
  );
};

export default PorfileImgContainer;
