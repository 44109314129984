import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export const PaginationCustom = ({ pagination, isPage, setIsPage }) => {
  const handleChange = (event, value) => {
    setIsPage(value);
  };
  // console.log({isPage})
  return (
    <Stack spacing={2} className="m-auto py-5">
      <Pagination
        page={isPage}
        onChange={handleChange}
        count={pagination.pages}
        renderItem={(item) => (
          <PaginationItem
            slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
            {...item}
          />
        )}
      />
    </Stack>
  );
};
