import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';

import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import Skeleton from '@mui/material/Skeleton';
import { useRef } from 'react';
import { useGetFavourites } from 'query/favourites';
import TruckCard from 'pages/LandingPage/TruckCard';
import { useEffect } from 'react';
import { PaginationCustom } from 'pages/InventoryListing/Pagination';

export const Favourites = () => {
  const theme = useTheme();
  const isXLarge = useMediaQuery(theme.breakpoints.up('xl'));
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const isLargeUp = useMediaQuery(theme.breakpoints.up('lg'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const isMediumUp = useMediaQuery(theme.breakpoints.up('md'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const [isPage, setIsPage] = useState(1);
  const {
    data: InventoryDataResponse,
    refetch,
    isLoading,
  } = useGetFavourites(isPage);
  const mydata = InventoryDataResponse?.results.map((el) => {
    return el.listing;
  });

  useEffect(() => {
    refetch(isPage);
  }, [isPage]);

  if (mydata?.length == 0) {
    return (
      <Box justifyContent="center" className="m-auto p-20 flex items-center ">
        <SentimentVeryDissatisfiedIcon className="text-primary-50 text-[32px] px-2" />{' '}
        <Typography
          fontFamily="space Grotesk"
          className="text-center text-gray-50"
          variant={isSmall ? 'h6' : 'h4'}
        >
          {' '}
          No Items in your wishlist, try some from our inventory
        </Typography>
      </Box>
    );
  }
  return (
    <Grid style={{ background: '#FBFBFB' }} container className="">
      <Grid
        style={{ margin: 'auto' }}
        container
        className={`  ${
          isMedium ? 'px-[100px]' : 'px-10 !justify-center text-center '
        } py-16  !w-[100%]  max-w-[1700px]  overflow-scroll`}
      >
        <Grid item container>
          <Grid
            justifyContent={`${isSmall || isXSmall ? 'center' : isMedium?'center':''}`}
            container
            item
            className="relative"
            style={{paddingLeft:isSmall || isXSmall || isMedium ? '':isMediumUp && isLarge?'20px':isLargeUp && isXLarge ?'80px':isXLarge?'110px':''}}
          >
            
            <Typography
              variant="h4"
              className={`!font-bold text-left pb-14
              text-secondary-90`}
              style={{ fontFamily: 'Space Grotesk' }}
            >
              Favourite Inventory
            </Typography>
          </Grid>
        </Grid>

        <Grid
          spacing={2}
          className="py-2"
          justifyContent={`${isSmall || isXSmall ? 'center' : 'center'}`}
          item
          container
        >
          {isLoading ? (
            <Grid spacing={2} className="py-2" item container>
              {Array(4)
                .fill(1)
                ?.map((el) => {
                  return (
                    <Grid item>
                      {' '}
                      <Skeleton
                        variant="rectangular"
                        width={'290px'}
                        height={'377px'}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          ) : (
            mydata?.map((el) => {
              return (
                <Grid  item>
                  <TruckCard refetch={refetch} {...el} />
                </Grid>
              );
            })
          )}
        </Grid>

        {mydata && (
          <PaginationCustom
            pagination={InventoryDataResponse?.pagination_option}
            isPage={isPage}
            setIsPage={setIsPage}
          />
        )}
      </Grid>
    </Grid>
  );
};
