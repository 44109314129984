import {
  Grid,
  Typography,
  TextField,
  CircularProgress,
  InputAdornment,
  styled,
  Dialog,
  useMediaQuery,
  useTheme,
  IconButton,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { CustomTextField } from 'components';
import CustomButton from 'components/CustomButton/CustomButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { Country_Code } from 'utils/CountryCode';
import OtpInput from 'react-otp-input';
import { formatPhoneNumber, normalizePhone } from 'utils/phone';
import CloseIcon from '@mui/icons-material/Close';
import { emailIcon, PhoneIcon } from '../Signup/Signup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import {
//   CircularProgress,
//   InputAdornment,
//   styled,
//   useMediaQuery,
//   useTheme,
// } from '@material-ui/core';
import jwt_decode from 'jwt-decode';
import {
  useFacebookLoginQuery,
  useGoogleLoginQuery,
  useLoginQuery,
  useSendOtpQuery,
  useVerifyOtp,
} from 'query/Auth';
// import {useSendOtpQuery} from 'query/au'
import { useForm, useWatch } from 'react-hook-form';
import useToast from 'utils/responseToast';
import { ToastContainer } from 'react-toastify';
import { routes } from 'routes/RouteConstants';
import 'react-phone-input-2/lib/style.css';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { saveData } from 'utils/localStorage';
import GoogleLogin from 'react-google-login';
import { gapi } from 'gapi-script';
import { localStorageKeys } from 'hooks';
import { formatMobileNumber } from 'utils/phone';
import { useRefContext } from 'components/Layout';

const SCOPE =
  'https://www.googleapis.com/auth/userinfo.email  https://www.googleapis.com/auth/plus.me';

//login

const Login = ({ setforgotPassword }) => {
  const LoginFormSchemaEmail = yup.object().shape({
    email: yup
      .string()
      .nullable()
      .required('Email is required')
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Enter a valid email'
      ),

    password: yup.string().nullable().required('Password is required'),
  });
  const LoginFormSchemaMobile = yup.object().shape({
    phone: yup
      .string()
      .required('Mobile Number is required')
      .min(10, 'Must be atleast 10 characters')
      .matches(/^\d{10}$/, 'Please enter a valid Mobile No'),
    password: yup.string().nullable().required('Password is required'),
  });
  const location = useLocation();
  const [currentType, setCurrentType] = useState('email');
  const [resendOtpCount, setResendOtpCount] = useState(60);
  const prefilled = location?.state?.data;

  const user = {
    email: prefilled?.email || '',
    phone: prefilled?.mobile || '',
    password: '',
    country_code: '+1',
  };

  const handleBacktoLogin = () => {
    setOtpError('');
    clearInterval(storeInterval);
    navigate(routes.LOGIN.pathname);
  };

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: SCOPE,
      });
    };
    gapi.load('client:auth2', initClient);
  });
  const { handleOpenSomethingWentWrongDialog } = useRefContext();
  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
    setError: setLoginError,
    clearError,
  } = useForm({
    resolver: yupResolver(
      currentType == 'email' ? LoginFormSchemaEmail : LoginFormSchemaMobile
    ),
    defaultValues: user,
  });

  const handleSetValue = (key, value) => {
    if (key === 'phone') {
      const pvalue = ('' + value).replace(/\D/g, '').slice(0, 10);
      setValue(key, pvalue, { shouldValidate: true });
    } else {
      setValue(key, value, { shouldValidate: true });
    }
  };
  // const {phone, password} = watch()

  const phone = useWatch({ control, name: 'phone' });
  // const password = useWatch({ control, name: 'phone' });

  const navigate = useNavigate();
  const toast = useToast();
  const [dataBackup, setDataBackup] = useState();
  const [loadingButton, setLoadingButton] = useState(false);
  const [storeInterval, setStoreInterval] = useState();
  const [open, setOpen] = useState(false);
  const [otpButtonLoading, setOtpButtonLoading] = useState(false);

  //send otp

  function sendOtpOnSuccess(res) {
    // setOnboardLoading(false);
    setOpen(true);
    countDown();
  }

  function sendOtpOnError(err) {
    if (err.status_code == 500) {
      handleOpenSomethingWentWrongDialog();
    }
    // setOnboardLoading(false);
    setOtpError(err.erros[0].message);
  }

  const secondConversion = (duration) => {
    const mins = ~~((duration % 3600) / 60);
    const secs = ~~duration % 60;

    let ret = '';
    ret += '' + `0${mins}` + ':' + (secs < 10 ? '0' : '');
    ret += '' + secs;

    return ret;
  };

  const countDown = () => {
    setResendOtpCount(60);
    let intervalId = setInterval(() => {
      if (resendOtpCount == 0) {
        clearInterval(intervalId);
        return;
      }
      setResendOtpCount((prev) => {
        if (prev > 0) {
          return prev - 1;
        } else {
          return 0;
        }
      });
    }, 1000);

    setStoreInterval(intervalId);
  };

  const { mutate: sendOtp, isLoading: sendingOtp } = useSendOtpQuery(
    sendOtpOnSuccess,
    sendOtpOnError
  );

  const theme = useTheme();
  const XSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const isLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));

  function loginSuccess(res) {
    setLoadingButton(false);
    setOpen(false);
    navigate(routes.REDIRECTOR);
  }

  function loginFailed(err) {
    setLoadingButton(false);

    if (err?.status_code == 422) {
      if (
        err.errors[0].message ==
        'Your account has been deactivated.Please contact your admin.'
      ) {
        setOpen(true);

        if (err.errors[0].field == 'phone') {
          setDataBackup();
          sendOtp({
            country_code: '+1',
            phone: dataBackup.phone,
            channel: 'PHONE_OTP',
            otp_for: 'Reactivate',
          });
        } else if (err.errors[0].field == 'email') {
          sendOtp({
            email: dataBackup.email,
            channel: 'EMAIL_OTP',
            otp_for: 'Reactivate',
          });
        }
      } else {
        setLoginError(err.errors[0].field, {
          type: 'custom',
          message: err.errors[0].message,
        });
      }

      // setLoginError(err.errors[0].field, {
      //   type: 'custom',
      //   message: err.errors[0].message,
      // });

      // setLoginError(err.errors[0].field, {
      //   type: 'custom',
      //   message: err.errors[0].message,
      // });
      //  if(err?.status_code == 422){

      // });
    }
    if (err?.status_code == 500) {
      handleOpenSomethingWentWrongDialog();
    }
  }

  const { mutate } = useLoginQuery(loginSuccess, loginFailed);
  const { mutate: GoogleLoginQuery } = useGoogleLoginQuery(
    loginSuccess,
    loginFailed
  );
  const { mutate: FacebookLoginQuery } = useFacebookLoginQuery(
    loginSuccess,
    loginFailed
  );

  const { mutate: verifyOtp } = useVerifyOtp(
    onSuccessOtpVerification,
    onErrorOtpVerification
  );

  const [OTP, setOTP] = useState('');
  const [resendOtpCredentials, setresendOtpCredentials] = useState();
  const [otpError, setOtpError] = useState('');

  const handleType = (name) => () => {
    setCurrentType(name);
  };

  const handleLogin = (name) => (data) => {
    setDataBackup(data);
    setLoadingButton(true);
    setTimeout(() => {
      if (name === 'regular') {
        if (currentType == 'phone') {
          const newData = {
            country_code: user.country_code,
            phone: data.phone,
            password: data.password,
            channel: 'PHONE_PASSWORD',
          };

          mutate({ ...newData });
        } else {
          const newData = {
            email: data.email,
            password: data.password,
            channel: 'EMAIL_PASSWORD',
          };
          // setDataBackup(data);
          mutate({ ...newData });
        }
      }
    }, 1500);
  };

  const handleVerifyOtp = () => {
    if (OTP.length < 4) {
      setOtpError('Please enter OTP');
      return;
    }
    setOtpButtonLoading(true);
    if (OTP) {
      setOtpError('');

      if (currentType == 'phone') {
        setresendOtpCredentials({
          country_code: '+1',
          phone: dataBackup.phone,
          channel: 'PHONE_OTP',
          otp: OTP,
          // otp_for: 'Reactivation',
        });
      } else if (currentType == 'email') {
        setresendOtpCredentials({
          country_code: '+1',
          phone: dataBackup.phone,
          channel: 'PHONE_OTP',
          otp: OTP,
          // otp_for: 'Reactivation',
        });
        verifyOtp({
          // ...resendOtpCredentials,
          email: dataBackup.email,
          channel: 'EMAIL_OTP',
          // otp_for: 'Ractivation',
          otp: OTP,
        });
      }
      setTimeout(() => {}, 2000);
    } else {
      setOtpError('Please enter a valid OTP');
    }
  };

  function onSuccessOtpVerification(res) {
    clearInterval(storeInterval);
    setOtpButtonLoading(false);
    setOpen(false);
    // setLoginError('', {
    //   type: 'custom',
    //   // message: err.errors[0].message,
    // });

    navigate(routes.LOGIN.pathname);
  }

  function onErrorOtpVerification(err) {
    setOtpButtonLoading(false);
    setOtpError(err.message);
    if (err.status_code == 500) {
      handleOpenSomethingWentWrongDialog();
    }
  }

  const onSuccessGoogleLogin = (tokenResponse) => {
    var decoded = jwt_decode(tokenResponse.tokenId);
    GoogleLoginQuery({
      id_token: tokenResponse.tokenId,
    });
    saveData(localStorageKeys.USER_DETAILS, decoded);
  };
  const onFailureGoogleLogin = (err) => {
    if (err.status_code == 500) {
      handleOpenSomethingWentWrongDialog();
    }
  };

  const FacebookLoginCallback = (res) => {
    saveData(localStorageKeys.USER_DETAILS, res);
    FacebookLoginQuery({ access_token: res.accessToken });
  };

  //send OTP Dialog box

  const handleCloseOtp = () => {
    setResendOtpCount(60);
    clearInterval(storeInterval);
    setOpen(false);
    setOTP('');
    setOtpError('');
  };

  const handleResendOtp = () => {
    setOTP('');
    if (currentType == 'email') {
      sendOtp(
        {
          email: dataBackup.email,
          channel: 'EMAIL_OTP',
          otp_for: 'Reactivate',
        },
        {
          onSuccess: (res) => {
            countDown();
          },
        }
      );
    }
  };
  //

  return (
    <>
      <Grid
        container
        className="!py-5 h-[100%] relative"
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          lg={10}
          item
          className={`absolute ${
            !isMedium ? 'top-0 right-0 ' : '!top-16 !right-16 '
          } text-right`}
        >
          <button
            className={`!font-[inter] ${
              currentType == 'email' ? 'active-btn  ' : 'inactive-btn '
            } rounded-l-[5px] hover:active-btn rounded-l-1 cursor-pointer`}
            onClick={handleType('email')}
          >
            Email
          </button>

          <button
            className={`!font-[inter] ${
              currentType == 'phone' ? 'active-btn' : 'inactive-btn'
            } rounded-r-[5px] hover:active-btn rounded-l-1 cursor-pointer`}
            onClick={handleType('phone')}
          >
            Mobile
          </button>
        </Grid>
        <Grid
          item
          container
          className={`h-auto !w-auto ${isLarge ? 'mt-10' : ''}`}
          direction="column"
        >
          <Grid item>
            <Typography variant="h4" className="!font-semibold !font-[inter] ">
              Log In
            </Typography>
            <Typography className="text-gray-60 font-[inter] pt-2">
              Welcome back! Please enter your details.
            </Typography>
          </Grid>
          <form onSubmit={handleSubmit(handleLogin('regular'))}>
            <Grid item className="py-7">
              {currentType == 'phone' && (
                <CustomTextField
                  error={Boolean(errors.phone)}
                  helperText={errors.phone && errors.phone.message}
                  FormHelperTextProps={{
                    className: 'animateErrorValue ',
                  }}
                  startAdornment={<Typography>{user.country_code}</Typography>}
                  type="text"
                  label="Mobile No."
                  placeholder="(xxx) - xxx -xxxx"
                  className="max-w-[360px]"
                  value={formatMobileNumber(phone, true)}
                  //maxLength={10}
                  onChange={(e) =>
                    handleSetValue(
                      'phone',
                      e?.target?.value?.trimStart().replace(/\D/, '')
                    )
                  }
                />
              )}
              {currentType == 'email' && (
                <CustomTextField
                  error={Boolean(errors.email)}
                  helperText={errors.email && errors.email.message}
                  FormHelperTextProps={{
                    className: ' animateErrorValue ',
                  }}
                  type="email"
                  label="Email"
                  placeholder="Enter your email"
                  className="max-w-[360px]"
                  onChange={(e) => handleSetValue('email', e.target.value)}
                />
              )}
            </Grid>
            <Grid item className="pb-4">
              <CustomTextField
                error={Boolean(errors.password)}
                helperText={errors.password && errors.password.message}
                FormHelperTextProps={{
                  className: 'animateErrorValue ',
                }}
                label="Password"
                {...register('password')}
                type="password"
                placeholder="Password"
                className="max-w-[360px]"
              />
            </Grid>

            <Grid
              style={{ alignItems: 'center' }}
              className={`${XSmall ? 'py-4' : 'pb-4'} `}
              lg={12}
              md={12}
              xs={12}
              item
              container
            >
              <Grid
                justifyContent={XSmall ? 'center' : 'right'}
                lg={12}
                md={12}
                xs={12}
                item
                container
              >
                <Typography>
                  {' '}
                  <span
                    onClick={() => {
                      setforgotPassword(true);
                    }}
                    className="text-secondary-50 text-right font-medium text-sm  font-[inter] cursor-pointer"
                  >
                    Forgot password?
                  </span>
                </Typography>
              </Grid>
            </Grid>

            <Grid className="justify-center text-center" item>
              {loadingButton ? (
                <CustomButton styles="max-h-[44px] max-w-[360px]">
                  <CircularProgress size={30} className="!text-white" />
                </CustomButton>
              ) : (
                <CustomButton type="submit" styles="max-w-[360px] max-h-[44px]">
                  Log In
                </CustomButton>
              )}
            </Grid>
          </form>
          <Grid
            item
            container
            className="gap-4 pt-5 justify-center"
            justifyContent={XSmall ? 'center' : 'space-between'}
          >
            <Grid item>
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                render={(renderProps) => (
                  <div
                    onClick={renderProps.onClick}
                    className="border-2 border-solid border-[#D0D5DD]  min-w-[172px] py-2 text-center rounded-lg cursor-pointer"
                  >
                    <img
                      className="w-7"
                      src="/assets/google.svg"
                      alt="google-logo"
                    />
                  </div>
                )}
                buttonText="Login"
                onSuccess={onSuccessGoogleLogin}
                onFailure={onFailureGoogleLogin}
                cookiePolicy={'single_host_origin'}
              />
            </Grid>
            <Grid
              item
              // className="border-2 border-solid border-gray-50 min-w-[172px] px-20 py-2 rounded cursor-pointer"
            >
              {/* <FacebookLogin
                appId={process.env.REACT_APP_FACEOOK_APP_ID}
                autoLoad={false}
                fields="name,email,picture"
                callback={FacebookLoginCallback}
                cssClass="my-facebook-button-class"
                render={(renderProps) => (
                  <div
                    onClick={renderProps.onClick}
                    className="border-2 border-solid border-[#D0D5DD] min-w-[172px] py-2 text-center rounded-lg cursor-pointer"
                  >
                    <img
                      className="w-7"
                      src="/assets/Auth/FacebookIcon.svg"
                      alt="google-logo"
                    />
                  </div>
                )}
                textButton=""
              /> */}
            </Grid>
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            className="text-gray-60 pt-5 "
          >
            <Typography className="text-sm font-[inter]">
              Don't have an account?{' '}
              <span
                onClick={() => {
                  navigate(routes.SIGNUP.pathname);
                }}
                className="text-secondary-50 no-underline  font-medium cursor-pointer"
              >
                Sign Up
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <ToastContainer />
      <Dialog justifyContent="center" alignItems="center" open={open}>
        <IconButton className="absolute right-4 top-4" onClick={handleCloseOtp}>
          <CloseIcon />
        </IconButton>
        <Grid item className="max-w-[456px] max-h-[618px] p-12">
          <Grid item container justifyContent="center" alignItems="center">
            {currentType === 'email' ? emailIcon() : PhoneIcon()}
          </Grid>
          <Grid item className="text-center ">
            <Typography
              variant="h5"
              className="!font-[inter] font-semibold py-2"
            >
              Check your {currentType}
            </Typography>
            <Typography variant="subtitle2" className="!font-[inter] py-5">
              A verification code was sent to{' '}
              {currentType == 'phone'
                ? formatPhoneNumber(dataBackup?.emailPh, true)
                : dataBackup?.emailPh}
            </Typography>
          </Grid>
          <Grid item container justifyContent="center">
            <Grid item justifyContent="center">
              <OtpInput
                value={OTP}
                onChange={setOTP}
                numInputs={4}
                inputType="number"
                inputStyle={{
                  height: '70px',
                  width: '70px',
                  fontSize: '30px',
                  borderRadius: '8px',
                  border: '1px solid rgba(209, 213, 219, 1)',
                  margin: '12px',
                }}
                renderSeparator={<span className="w-4 h-8"> </span>}
                renderInput={(props) => <input {...props} />}
              />
              {otpError && (
                <Typography className="animateErrorValue pt-2 text-red-500 text-center text-xs">
                  {otpError}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid item>
            {otpButtonLoading ? (
              <CustomButton
                styles="max-h-[44px] !text-[15px] font-medium my-5"
                fullWidth
              >
                <CircularProgress size={30} className="text-white" />
              </CustomButton>
            ) : (
              <CustomButton
                onClick={OTP.length == 4 ? handleVerifyOtp : () => {}}
                disabled={OTP.length == 4 ? false : true}
                styles="h-[40px] !text-[15px] font-medium my-5"
                fullWidth
              >
                Continue
              </CustomButton>
            )}
          </Grid>
          <Grid justifyContent="center" className="text-center" item>
            <Typography className="text-sm !font-[inter]">
              Please do not share this code with anyone. Sell My Rig and its
              associates will never ask for your verification code.
            </Typography>
          </Grid>
          <Typography className="!font-[inter] text-sm text-center pt-4">
            {' '}
            Didn't recieve the{' '}
            {currentType == 'email'
              ? 'mail'
              : currentType == 'phone'
              ? 'message'
              : ''}
            ?
            <span className="text-sm font-medium text-secondary-50 cursor-pointer pl-1">
              {resendOtpCount == 0 ? (
                sendingOtp ? (
                  <CircularProgress size={30} className="!text-white" />
                ) : (
                  <span onClick={handleResendOtp}>Resend OTP</span>
                )
              ) : (
                `${secondConversion(resendOtpCount)}`
              )}
            </span>
          </Typography>
        </Grid>

        <Grid>
          <Grid
            onClick={handleBacktoLogin}
            item
            justifyContent="center"
            className="pb-4 text-center"
            container
            lg={12}
            md={12}
            xs={12}
          >
            {/* <ArrowBackIcon className="px-1 text-gray-90" /> */}
            <Typography className="!font-[inter] text-sm pb-4 justify-center ">
              <span
                className="text-sm font-medium cursor-pointer text-gray-90 "
                style={{ color: '#B32306' }}
              >
                This account had been deactivated, kindly verify first!
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default Login;
