import { useQuery } from '@tanstack/react-query';
import { axios } from 'utils';
import { QueryKey } from './queryKeys';

/// api request body to get Product details for Sent_Quote Tab

const GetProductDetailsByListingId = (id, tabtype = true) => {
  if (tabtype === true) {
    const method = 'GET';
    const url = `listing/${id}/`;
    return axios({ method, url });
  } else if (tabtype === false) {
    const method = 'GET';
    const url = `listing/${id}`;
    return axios({ method, url });
  }
};

export const useGetProductDetails = (id, tabtype) => {
  return useQuery(
    [QueryKey.GET_PRODUCT_DETALS_DATA, id],
    () => {
      return GetProductDetailsByListingId(id, tabtype);
    },
    {
      enabled: true,
    }
  );
};
const GetFinanceDetailsByListingId = (id) => {
  const method = 'GET';
  const url = `finance/${id}/`;
  return axios({ method, url });
};

export const useGetFinanceDetails = (id) => {
  return useQuery(
    [QueryKey.GET_FINANCE_DETALS_DATA, id],
    () => GetFinanceDetailsByListingId(id),

    {
      enabled: Boolean(id),
    }
  );
};



///api request body to get Product details for My_Equipment Tab

const GetEquipmentDetailsByListingId = (unique_id) => {
  const method = 'GET';
  const url = `listing/${unique_id}`;

  return axios({ method, url });
};

export const useGetEquipentDetails = (unique_id) => {
  return useQuery(
    [QueryKey.GET_EQUIPMENTS_DETAILS_DATA, unique_id],
    () => {
      return GetEquipmentDetailsByListingId(unique_id);
    },
    {
      enabled: true,
    }
  );
};
