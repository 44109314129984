import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import {
  Grid,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Modal,
  CircularProgress,
  Snackbar,
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import '../SMRR.css';
import PushNotificationContent from './PushNotificatoinContent';
import { RiErrorWarningLine } from 'react-icons/ri';
import { useDeactivateAccount } from '../../../query/settings';
import { useNavigate } from 'react-router';
import { routes } from '../../../routes/RouteConstants';
import { ResponseStatusPage } from '../PaymentMethod/ResponseStatusPage';
import done from '../../../utils/gif/done.json';

const confirmBtn = {
  backgroundColor: '#FFFFFF',
  height: '44px',
  width: '100%',
  fontSize: '16px',
  fontWeight: '500',
  color: '#4B5563',
  fontFamily: 'Poppins',
  borderRadius: '34px',
  border: '1px solid #9CA3AF',
};
const cancelBtn = {
  backgroundColor: '#FFC200',
  height: '44px',
  width: '100%',
  fontSize: '16px',
  fontWeight: '500',
  color: '#332700',
  fontFamily: 'Poppins',
  borderRadius: '34px',
};

const CustomButton = ({ children }) => {
  // console.log(styles);
  return (
    <Button
      style={{
        backgroundColor: '#FFFFFF',
        border: '1px solid #9CA3AF',
        height: '44px',
        width: '100%',
        borderRadius: '34px',
        fontWeight: '500',
      }}
    >
      {children}
    </Button>
  );
};

const ConfimationPage = () => {
  const navigation = useNavigate();
  const { mutate: deactivateAccount, isLoading } = useDeactivateAccount();
  const [isOpen, setIsOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleAccountdisable = () => {
    // setIsOpen()
    deactivateAccount(null, {
      onSuccess: (res) => {
        localStorage.clear();

        setIsOpen(true);
      },
      onError: (err) => {
        setSnackbarOpen(true);
      },
    });
  };

  const handleCancel = () => {
    navigation(routes.Settings);
  };

  return (
    <>
      <Grid
        container
        lg={12}
        md={12}
        className=""
        justifyContent="center"
        alignItems="center"
        paddingTop={3}
      >
        <Grid
          item
          conatiner
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className=""
          justifyContent="center"
          paddingTop={5}
        >
          <Grid
            item
            className=""
            justifyContent="center"
            alignItems="center"
            paddingBottom={3}
          >
            <Typography
              style={{
                fontSize: '24px',
                fontWeight: '600',
                fontFamily: 'Poppins',
                color: '#101828',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {' '}
              Are you sure?
            </Typography>
            <Typography
              style={{
                fontSize: '12px',
                fontWeight: '400',
                fontFamily: 'Poppins',
                color: '#757575',
                display: 'flex',
                justifyContent: 'center',
                paddingTop: '10px',
              }}
            >
              {' '}
              What is going to happen:
            </Typography>
            <Typography
              style={{
                fontSize: '14px',
                fontWeight: '500',
                fontFamily: 'Poppins',
                color: '#333333',
                display: 'flex',
                justifyContent: 'center',
                paddingTop: '30px',
                textShadow: 'opx 4px rgba(0, 0, 0, 0.25)',
              }}
            >
              {' '}
              Your account will deactivated immediately
            </Typography>
          </Grid>

          <Grid
            item
            container
            justifyContent="center"
            className=""
            paddingTop={5}
          >
            <Grid
              item
              container
              lg={12}
              md={12}
              xs={12}
              sm={12}
              className=""
              justifyContent="center"
            >
              <Grid
                item
                lg={10}
                xs={10}
                sm={12}
                justifyContent="center"
                className="flex"
              >
                {isLoading ? (
                  <CustomButton>
                    <CircularProgress
                      size={30}
                      className=""
                      style={{ color: '#FFC200' }}
                    />{' '}
                  </CustomButton>
                ) : (
                  <Button onClick={handleAccountdisable} style={confirmBtn}>
                    Yes, I'm sure
                  </Button>
                )}
              </Grid>
              <Snackbar
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={() => setSnackbarOpen(false)}
                message="An error ocurred!"
              />
              <Grid
                item
                paddingTop={2}
                lg={10}
                md={12}
                xs={12}
                className=" flex"
                justifyContent="center"
              >
                <Button onClick={handleCancel} style={cancelBtn}>
                  I changed my mind
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ResponseStatusPage
          isOpen={isOpen}
          first_message="Account has been deactivated succesfully !"
          //  second_message = 'It will be reactivated if you relogging within 30 days. Afterwards, your data will be deleted permanently.'
          isSettingPage={true}
          animation={done}
        />
      </Grid>
    </>
  );
};

export default ConfimationPage;
