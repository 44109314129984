import {
  IconButton,
  Button,
  Typography,
  Grid,
  CircularProgress,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { CustomTextField } from 'components';
import { EmailOutlined } from '@mui/icons-material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useAddQuoteQuery } from 'query/quote';
import { Navigate, useNavigate, useParams } from 'react-router';
import { routes } from 'routes/RouteConstants';
import { useRefContext } from 'components/Layout';
import { getLocalStorageItem } from 'hooks';
import APIResponceMsgModal from 'components/APIResponceMsgModal';
import { useGetProductDetails } from 'query/productDetails';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';

const requestQuoteFormSchema = yup.object().shape({
  name: yup.string(),
  email: yup.string(),
  phone: yup.string(),
});

const btnStyle = {
  fontFamily: 'Inter',
  color: '#B0B0B0',
  fontWeight: '500',
  fontSize: '16px',
  disableRipple: true,
  backgroundColor: '#fff',
  borderRadius: '8px',
  border: '1px solid #D0D5DD',
  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
  width:'250px',
  height:"54px",
};

export const RequestQuoteForm = ({ open, setOpen }) => {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const productId = searchParams.get('id');
  const { data: ListingDetails, refetch } = useGetProductDetails(productId);
  const listingDetailsData = ListingDetails?.data || [];  
  const listingQuoteData = ListingDetails?.data?.quotes?.user?.id || null;  

  const [open2, setOpen2] = useState(false);
  const [openResponse, setOpenResponse] = useState(false);
  const [modelData, setModelData] = useState({});

  const [inspReportURL, setInspReportURL] = useState(null);
  const [specSheetArrayURL, setSpecSheetArrayURL] = useState(null);

  useEffect(() => {
      const inspReportArray = ListingDetails?.data?.attachments?.filter((el) => el.description==='INSPECTION_REPORT');
      setInspReportURL(inspReportArray ? inspReportArray[0]?.attachment : null);

      const specSheetArray = ListingDetails?.data?.attachments?.filter((el) => el.description==='SPECIFICATION_SHEET');
      setSpecSheetArrayURL(specSheetArray ? specSheetArray[0]?.attachment : null);

  }, [ListingDetails]);

  const { handleOpenSomethingWentWrongDialog } = useRefContext();
  //const { id } = useParams();
  const params = new URLSearchParams(window.location.search);
  const userData = getLocalStorageItem('USER_DETAILS');
  const autoFilledData = {
    name: userData?.name || '',
    email: userData?.email || '',
    phone: userData?.phone,
  };
  
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(requestQuoteFormSchema),
    defaultValues: autoFilledData,
  });
  const { mutate: AddQuote, isLoading } = useAddQuoteQuery();
  const handleClickOpen = () => {
    if(listingQuoteData) {      
      setOpen2(true);
    } else {
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleBacktoEquipmentInfo = () => {
    navigate(routes.Equipment_Info);
  }; 

  const handleRequestQuote = (formValues) => {
    let data = {
      description: formValues.description,
      listing: params.get('id'),
    };
  AddQuote(data, {
    onSuccess: (res) => {
      refetch();
      setOpen(false);
      setOpenResponse(true);
      setModelData({
        type: 'success',
        first_message: 'Request has been sent successfully',
        second_message: `Thank you, Support team will contact you`,
      });
    },
    onError: (err) => {
      if (err.status_code === 500) {
        handleOpenSomethingWentWrongDialog();
      }
      setError(err.errors[0].field, {
        type: 'custom',
        message: err.errors[0].message + ' ' + '(' + err.errors[0].field + ')',
      });
    },
  });
  };
  if (!userData && open) {
    return <Navigate to="/login" />;
  }
  const containerStyle = {
      justifyContent: 'flex-end',
      paddingTop: '20px',
  }  
  const handleViewPDF=(docurl)=>{
    window.open(docurl, '_blank');
  }
  return (
    <>
      {/* <Grid item lg={10} md={10} sm={12} className="text-right py-1 !m-auto"> */}
      <Grid item lg={10} md={12} sm={12} className="text-right py-1 !m-auto respLtp ">
        <Grid item container spacing={2} style={containerStyle} className='containerSt'>
          <Grid item>
            <Button
              className="product-detail-pageBtn"
              startIcon={
                <InsertDriveFileOutlinedIcon
                  style={{ height: '23px', marginTop: '-3px' }}
                />
              }
              style={{
                backgroundColor: '#F5F5F5',
                color: '#545454',
                border: '1px solid #B0B0B0',
              }}
              disabled={!specSheetArrayURL}
              onClick={() => handleViewPDF(specSheetArrayURL)}
            >
              View Specification Sheet
            </Button>
          </Grid>
          <Grid item>
            <Button
              className="product-detail-pageBtn"
              startIcon={
                <InsertDriveFileOutlinedIcon
                  style={{ height: '23px', marginTop: '-3px' }}
                />
              }
              style={{
                backgroundColor: '#F5F5F5',
                color: '#545454',
                border: '1px solid #B0B0B0',
              }}
              disabled={!inspReportURL}
              onClick={() => handleViewPDF(inspReportURL)}
            >
              View Inspection Report
            </Button>
          </Grid>
          {listingDetailsData?.status !== 'PRODUCT_SOLD' && (
            <Grid item>
              <Button
                className="!w-[265px] quote"
                onClick={handleClickOpen}
                variant="contained"
                style={{ minHeight: '40px' }}
              >
                Get a Quote
              </Button>
            </Grid>
          )}
        </Grid>

        {open && (
          <Dialog
            maxWidth="sm"
            fullWidth={true}
            onClose={handleClose}
            aria-labelledby="EMI Calculator"
            open={open}
          >
            <DialogTitle className="text-center relative !text-[32px] !font-bold">
              Get a Quote
              <IconButton className="absolute right-2 " onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent
              sx={{ justifyContent: 'center' }}
              className="!pb-[42px]"
            >
              <form onSubmit={handleSubmit(handleRequestQuote)} id="test">
                <Grid spacing={2} container>
                  <Grid item lg={12} md={12} sm={12}>
                    <CustomTextField
                      error={Boolean(errors.name)}
                      helperText={errors.name && errors.name.message}
                      FormHelperTextProps={{
                        className: 'animateErrorValue ',
                      }}
                      disabled
                      {...register('name')}
                      placeholder="Name"
                      label="Name"
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12}>
                    {' '}
                    <CustomTextField
                      {...register('email')}
                      error={Boolean(errors.email)}
                      helperText={errors.email && errors.email.message}
                      FormHelperTextProps={{
                        className: 'animateErrorValue ',
                      }}
                      startAdornment={
                        <EmailOutlined className="text-gray-70" />
                      }
                      disabled
                      placeholder="Email"
                      label="Email"
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12}>
                    {' '}
                    <CustomTextField
                      {...register('phone')}
                      error={Boolean(errors.phone)}
                      helperText={errors.phone && errors.phone.message}
                      FormHelperTextProps={{
                        className: 'animateErrorValue ',
                      }}
                      disabled
                      startAdornment={<Typography>+1</Typography>}
                      type="number"
                      placeholder="(3215)-564-845"
                      label="Phone Number"
                    />
                    {userData?.phone === '' ? (
                      <p className="my-2">
                        Please update your phone number from profile
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12}>
                    {' '}
                    <CustomTextField
                      multiline
                      rows={4}
                      {...register('description')}
                      placeholder="  Write"
                      label="Additional Information"
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12}>
                    {' '}
                    {isLoading ? (
                      <Button
                        className="!w-full"
                        width="100%"
                        variant="contained"
                      >
                        <CircularProgress className="!text-white" />
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        form="test"
                        className="!w-full"
                        width="100%"
                        variant="contained"
                      >
                        Continue
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </form>
            </DialogContent>
          </Dialog>
        )}

        {open2 && (
          <Dialog
            maxWidth="sm"
            fullWidth={true}
            onClose={handleClose2}
            aria-labelledby="EMI Calculator"
            open={open2}
          >
            <DialogTitle className="text-center relative !text-[32px] !font-bold">
              Get a Quote
              <IconButton className="absolute right-2 " onClick={handleClose2}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent
              sx={{ justifyContent: 'center' }}
              className="!pb-[42px]"
            >
              <div
                style={{
                  textAlign: 'center',
                  fontSize: '18px',
                  fontWeight: '600',
                  color: '#FF0000',
                  marginTop: '50px',
                }}
              >
                You have already requested quote on this product.
              </div>
              <div
                style={{
                  textAlign: 'center',
                  fontSize: '14px',
                  fontWeight: '400',
                  color: '#757575',
                  marginBottom: '30px',
                  marginTop: '10px',
                }}
              >
                Please check equipment info for more details.
              </div>
              <div style={{ textAlign: 'center' }}>
                <Button
                  style={btnStyle}
                  onClick={() => handleBacktoEquipmentInfo()}
                >
                  Go to Equipment Info
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        )}
        <APIResponceMsgModal
          open={openResponse}
          setClose={setOpenResponse}
          modelData={modelData}
          listingDetailsData={listingDetailsData}
          isSellerInfoShow
        />
      </Grid>
    </>
  );
};
