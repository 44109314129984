import { Visibility, VisibilityOff } from '@mui/icons-material';
import { CiMail } from 'react-icons/ci';
import {
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import React, { forwardRef, useEffect, useRef, useState } from 'react';
const StyledTextField = styled(TextField)({
  input: {
    height: '1.0375em',
    padding: '14.5px 14px',
    // paddingLeft:'20px',
    '&:-webkit-autofill  ': {
      WebkitBoxShadow: '0 0 0 0 100px ' + '0' + ' inset',
      backgroundColor: '#FFF !important;',
      backgroundClip: 'content-box !important',
      // marginLeft:'-5px'
    },
  },
  '& .MuiInputBase-root': {
    backgroundColor: '#fff !important',
    borderRadius: '8px',
  },
  '& label.Mui-focused': {
    // color: 'green',
  },
  '& .MuiInput-underline:after': {
    // borderBottomColor: 'green',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '8px',
      // backgroundColor: '#FFF !important;',
    },
    '&:hover fieldset': {
      // borderColor: 'yellow',
    },
    '&.Mui-focused fieldset': {
      // borderColor: 'green',
    },
  },
  '& .MuiFormLabel-root': {
    top: '-4px',
  },
});

// const style = {
//     textOverflow: 'ellipsis',
//     overflow:'hidden',
//     whiteSpace: 'nowrap',
//     width: '100%'

// } 

const CustomTextField = forwardRef((props, ref) => {
  const {
    maxLength,
    label,
    fontSize,
    required,
    error,
    helperText,
    variant,
    placeholder,
    disabled,
    fullWidth,
    type,
    startAdornment,
    labelOverFlow,
    endAdornment,
    isborder,
    isMultiline,
    minrows,
    fontWeight,
    color,
    ...rest
  } = props;

  //  states
  const [showPassword, setShowPassword] = useState(false);
  // Handlers
  const handleToggleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword((prevState) => !prevState);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const eyeOff = () => {
    return (
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.2429 5.09232C11.6494 5.03223 12.0686 5 12.5004 5C17.6054 5 20.9553 9.50484 22.0807 11.2868C22.2169 11.5025 22.285 11.6103 22.3231 11.7767C22.3518 11.9016 22.3517 12.0987 22.3231 12.2236C22.2849 12.3899 22.2164 12.4985 22.0792 12.7156C21.7793 13.1901 21.3222 13.8571 20.7165 14.5805M7.22432 6.71504C5.06225 8.1817 3.59445 10.2194 2.92111 11.2853C2.78428 11.5019 2.71587 11.6102 2.67774 11.7765C2.6491 11.9014 2.64909 12.0984 2.67771 12.2234C2.71583 12.3897 2.78393 12.4975 2.92013 12.7132C4.04554 14.4952 7.39541 19 12.5004 19C14.5588 19 16.3319 18.2676 17.7888 17.2766M3.50042 3L21.5004 21M10.3791 9.87868C9.8362 10.4216 9.50042 11.1716 9.50042 12C9.50042 13.6569 10.8436 15 12.5004 15C13.3288 15 14.0788 14.6642 14.6217 14.1213"
          stroke="#545454"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  };
  const eyeOpen = () => {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.42012 12.7132C2.28394 12.4975 2.21584 12.3897 2.17772 12.2234C2.14909 12.0985 2.14909 11.9015 2.17772 11.7766C2.21584 11.6103 2.28394 11.5025 2.42012 11.2868C3.54553 9.50484 6.8954 5 12.0004 5C17.1054 5 20.4553 9.50484 21.5807 11.2868C21.7169 11.5025 21.785 11.6103 21.8231 11.7766C21.8517 11.9015 21.8517 12.0985 21.8231 12.2234C21.785 12.3897 21.7169 12.4975 21.5807 12.7132C20.4553 14.4952 17.1054 19 12.0004 19C6.8954 19 3.54553 14.4952 2.42012 12.7132Z"
          stroke="#545454"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.0004 15C13.6573 15 15.0004 13.6569 15.0004 12C15.0004 10.3431 13.6573 9 12.0004 9C10.3435 9 9.0004 10.3431 9.0004 12C9.0004 13.6569 10.3435 15 12.0004 15Z"
          stroke="#545454"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  };

  return (
    <div style={{ width: '100%' }}>
      <InputLabel
        shrink
        htmlFor="bootstrap-input"
        required={required}
        className="mb-1 !font-[500] !font-[Inter] !text-[18px] !text-[#545454]"
        // sx={{ textOverflow: !labelOverFlow ? 'ellipsis' : 'unset' }}
        // className="mb-1 !font-[500] !font-[Inter]   text-black"

        style={{
          textOverflow: !labelOverFlow ? 'ellipsis' : 'unset',
          overflow: !labelOverFlow ? 'hidden' : 'visible',
          fontSize: fontSize ? fontSize : '',
          maxWidth: '100%',
          fontWeight: fontWeight ? fontWeight : '',
          color: color ? color : '',
        }}
        // style={style}
      >
        {label}
      </InputLabel>
      <StyledTextField
        //   label={label}

        border={isborder ? '' : 'none'}
        placeholder={placeholder}
        multiline={isMultiline ? true : false}
        minRows={minrows ? minrows : ''}
        error={error}
        helperText={helperText || null}
        inputRef={ref}
        variant={variant}
        disabled={disabled}
        fullWidth={fullWidth}
        autoComplete={false}
        size="medium"
        className="p-0"
        // maxLength={maxLength}/
        /* eslint-disable no-nested-ternary */
        type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
        InputProps={{
          startAdornment: startAdornment || null,
          endAdornment: (
            <>
              {type === 'password' ? (
                <InputAdornment position="end">
                  <IconButton
                    className="-mr-4"
                    aria-label="toggle password visibility"
                    onClick={handleToggleShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    size="large"
                  >
                    {showPassword ? eyeOpen() : eyeOff()}
                  </IconButton>
                </InputAdornment>
              ) : (
                endAdornment || null
              )}
            </>
          ),
        }}
        inputProps={{
          maxLength: +maxLength,
        }}
        {...rest}
      />
    </div>
  );
});

CustomTextField.displayName = 'CustomTextField';

CustomTextField.defaultProps = {
  label: '',
  required: false,
  error: false,
  helperText: '',
  variant: 'outlined',
  disabled: false,
  fullWidth: true,

  type: 'text',
};

export default CustomTextField;
