import { Grid,Box } from '@mui/material'
import { Typography, Button } from '@mui/material'

import React from 'react'

export const PaymentDefaultPage = ({showCardForm,setShowCardForm}) => {
  const handleOpenCardForm=()=>{
    setShowCardForm(true)
  }
  return (
    <Box className="p-8">          
    
      <Grid
        item
        lg={10}
        md={12}
        xs={12}
        className="pl-4 mt-20"
      >
       <Grid lg={12} md={12} sm={12} item className='text-center w-full '>
            <img className='w-12 h-12 py-2' src='/assets/icons/Featured Payment icon.svg' alt=""/>
            <Typography className='py-2 text-md !font-medium text-gray-80' >No Card Found</Typography>
            <Typography className='pt-2 pb-1 !text-gray-70'>You have no payment methods yet. Please add a new card.</Typography>
          
        </Grid>
        <Grid className='text-center mt-10' lg={12} md={12} sm={12}  item>
          <Button onClick={handleOpenCardForm} className='!w-[256px] !h-11 !font-medium' variant='contained'>Add Payment Method</Button>
        </Grid>
        </Grid>
       
        </Box>
  )
}
