import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useMediaQuery } from '@mui/material';

import { useTheme } from '@mui/material/styles';
const FinanceCard = ({ icon, heading, details, border = false,isApproval }) => {

  const theme = useTheme();
  const isXLarge = useMediaQuery(theme.breakpoints.down('xl'));
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <>
      <Grid item className="px-5 py-10 max-sm:pb-0 ">
        <Box
          item
          className=" flex h-[50px] rounded-full relative"
          container
          justifyContent="left"
          alignItems="center"
        >
          <img src={icon} alt="icon" className="w-[50px] relative" />

          <Typography
            style={{ fontFamily: 'Space Grotesk' }}
            variant="h5"
            className="pl-2 !font-bold  text-[#115D90]"
          >
            {heading}
          </Typography>
          {!isApproval && (
            <Typography
              style={{ fontFamily: 'Space Grotesk' }}
              variant="h5"
              className={`text-secondary-90 pl-2 !font-bold ${
                isSmall
                  ? 'rotate-90 left-0 bottom-0 translate-y-20 -translate-x-12 absolute'
                  : ''
              }`}
            >
              ------------
            </Typography>
          )}
        </Box>
        <Grid item className="pt-8 pb-5"></Grid>
        <Grid item>
          <Typography
            style={{ fontFamily: 'Space Grotesk' }}
            className={`inline-block text-gray-60 ${isSmall && 'text-right'} `}
          >
            <pre
              className={`max-sm:-mt-9 max-sm:ml-14 text-left whitespace-pre-line ${
                isSmall && 'ml-10 '
              } -mt-5 font-[poppins]`}
            >
              {details}
            </pre>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default FinanceCard;
