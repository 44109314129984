import React, { useState, useEffect } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { currencyFormat } from 'utils';
import 'pages/SMRRInfo/SMRR.css';
const useStyles = makeStyles((theme) => ({
  myComponent: (props) => ({
    width: props.width || '250px',
    height: '31px',
    borderRadius: '100px',
    color: props.color,
    backgroundColor: props.background,
    fontSize: '14px',
  }),
}));
// to get 2.5% of original price to be paid
const getTokenMoney = (price) => {
  const tokenMoney = price * 0.025;
  return tokenMoney;
};
const FinanceStatusButton = (props) => {
  //set value of button label
  //console.log('props',props);
  const [firstBtnVal, setFirstBtnVal] = useState(null);
  useEffect(() => {
    if (props?.value && !props?.isProductInfoPage) {
      if (props?.value === 'DEPOSIT') {
        setFirstBtnVal(
          `Deposit ${currencyFormat(getTokenMoney(props?.price))} (2.5%)`
        );
      } else if (props?.value === 'DEPOSIT_SUCCESSFUL') {
        setFirstBtnVal('Deposit Successful');
      } else if (props?.value === 'AMOUNT_REFUNDED') {
        setFirstBtnVal('Amount Refunded');
      }
    }
  
  }, [firstBtnVal]);

  let color;

if (props?.value === 'AMOUNT_REFUNDED') {
  color = '#DC2804'; // Red color for AMOUNT_REFUNDED
} else if (props?.value === 'DEPOSIT') {
  color = '#FFFFFF'; // Green color for DEPOSIT
} else if (props?.value === 'DEPOSIT_SUCCESSFUL') {
  color = '#0F0F0F'; // Blue color for DEPOSIT_SUCCESSFUL
} else {
  color = ''; // Default color if none of the conditions match
  }
  let backgroundColor;

if (props?.value === 'AMOUNT_REFUNDED') {
  backgroundColor = '#FDF3F2'; // Red background for AMOUNT_REFUNDED
} else if (props?.value === 'DEPOSIT') {
  backgroundColor = '#0F5E91'; // Green background for DEPOSIT
} else if (props?.value === 'DEPOSIT_SUCCESSFUL') {
  backgroundColor = '#FFC200'; // Blue background for DEPOSIT_SUCCESSFUL
} else {
  backgroundColor = ''; // Default background if none of the conditions match
}


  return (
    <>
      {/* <Grid item container> */}
      {/* <Button
        className={props.type ? classMap[props.type] : ''}
        style={{
          width: props.width || '270px',
          textTransform: 'none',
          height: '32px',
        }}
      >
        {' '}
        {firstBtnVal ? firstBtnVal : 'NA'}
      </Button> */}
      <Grid
        item
        className="rounded-tl-none rounded-tr-none rounded-bl-lg rounded-br-lg p-1"
        style={{
          marginLeft: '.1px',
          backgroundColor: backgroundColor,
          width: '370px',
          textAlign: 'center',
        }}
      >
        <Typography
          style={{
            fontSize: '14px',
            fontWeight: '500',
            color: color,

            textAlign: 'center',
          }}
          className="py-1 font-Inter"
        >
          {firstBtnVal ? firstBtnVal : 'NA'}
        </Typography>
      </Grid>

      {/* </Grid> */}
    </>
  );
};
export default FinanceStatusButton;