
import React from 'react'
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm, { CardForm } from './CardForm';

export const CardFormMain = ({data,refetch, onSuccess, onError, handleCancel}) => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TEST_PUBLIC_KEY)
  // console.log('process  dot env',process.env.REACT_APP_STRIPE_TEST_PUBLIC_KEY)
 
  return stripePromise && (
      <Elements stripe={stripePromise}>
       <PaymentForm refetch={refetch} data={data} onSuccess={onSuccess} onError ={onError} handleCancel={handleCancel}/>
      </Elements>
     
  )
}
