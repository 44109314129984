import { Grid, Box, CircularProgress, Snackbar } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import { useState } from 'react';
import '../Account.css';
import { CiMail } from 'react-icons/ci';

import { display, textAlign } from '@material-ui/system';

import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { BsCheck2Circle } from 'react-icons/bs';
import { CustomTextField } from 'components';
import { useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { getLocalStorageItem } from 'hooks';
import { useUpdateUser } from 'query/Accounts';
import { useEffect } from 'react';
import { formatMobileNumber } from '../../../utils/phone';
import { ResponseStatusPage } from '../../SMRRInfo/PaymentMethod/ResponseStatusPage';
import done from '../../../utils/gif/done.json';

//Styles
const fontStyle = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '150%',
  textAlign: 'center',
  color: '#545454',
};

const btnStyle = {
  fontWeight: '600',
  fontSize: '16px',
  borderRadius: '12px',
  width: '185px',
  height: '35px',
};

const btnAffreStyle = {
  backgroundColor: '#FFC200',
  color: '#333333',
  fontWeight: '600',
  fontSize: '16px',
  disableRipple: true,
  borderRadius: '12px',
};
const btnCancelStyle = {
  backgroundColor: '#F5F5F5',
  color: '#333333',
  fontWeight: '600',
  fontSize: '16px',
  disableRipple: true,
  borderRadius: '12px',
};
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '400px',
  height: '260px',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow:
    '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
  borderRadius: '12px',
  p: 4,
};

//Modal
const BasicModal = (props) => {
  const { isOpen, setClose } = props;
  // console.log('modal handler', props.handleUpdateUser)

  const handleClose = () => props.setClose(setClose(false));

  const handleCloseFromParent = () => {
    if (props?.modalClose && props?.modalClose == true) {
      // setClose(true)
    }
    if (props?.modalClose && props?.modalClose == false) {
      // setClose(false);
    }
  };

  return (
    <div>
      <Modal
        open={isOpen}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container>
            <Grid
              item
              sx={{ display: 'flex', flexDirection: 'column', height: '230px' }}
            >
              <Grid
                container
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                  justifyContent: 'center',
                  width: '100%',
                  marginTop: '-3px',
                }}
              >
                <Grid
                  item
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'relative',
                  }}
                >
                  <div
                    style={{
                      height: '72px',
                      width: '72px',
                      display: 'flex',
                      justifyContent: 'center',
                      borderRadius: '36px',
                      backgroundColor: '#ECFDF3',
                    }}
                  >
                    <div
                      style={{
                        height: '50px',
                        width: '50px',
                        display: 'flex',
                        justifyContent: 'center',
                        position: 'absolute',
                        top: '11px',
                        borderRadius: '25px',
                        backgroundColor: '#D1FADF',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          position: 'absolute',
                          top: '6px',
                        }}
                      >
                        <BsCheck2Circle
                          style={{
                            height: '38px',
                            width: '29px',
                            color: '#07794C',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    className="font-Inter"
                    style={{
                      fontSize: '18px',
                      fontWeight: '500',
                      color: '#262626',
                    }}
                  >
                    Profile Update
                  </Typography>
                </Grid>
                <Grid
                  item
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                  }}
                >
                  <Typography style={fontStyle}>
                    Your Profile updates will be saved. Are you sure you want to
                    proceed?
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ width: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                {/* <div className="detailsContainer-button-style"> */}
                <Button
                  className="detailsContainer-button-style"
                  style={btnCancelStyle}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                {/* </div> */}
                {/* <div className="detailsContainer-button-style" style={{ backgroundColor: '#FFC200' }}> */}
                {props?.isLoading ? (
                  <CustomButton>
                    {' '}
                    <CircularProgress size={8} className="!text-white" />
                  </CustomButton>
                ) : (
                  <Button
                    className="detailsContainer-button-style"
                    style={btnAffreStyle}
                    // onClick={handleClose}
                    form={props?.id}
                    type="submit"
                    // onClick={handleCloseFromParent}
                  >
                    Yes
                  </Button>
                )}
                {/* </div> */}
              </div>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

//Loader

const CustomButton = ({ children }) => {
  // console.log(styles);
  return (
    <Button
      style={{
        backgroundColor: '#FFC200',
        height: '44px',
        width: '100%',
        borderRadius: '34px',
        color: '#000000',
        fontWeight: '500',
      }}
    >
      {children}
    </Button>
  );
};

const userFormSchema = yup.object().shape({
  email: yup.string().nullable().required('Email is requird'),

  name: yup.string().nullable().required('Name is required'),
});

const ProfileDetailsGrid = () => {
  // console.log({ modalClose: modalClose });
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [apiresponse, setApiresponse] = useState(null);
  const [disablefield, setDisablefield] = useState();
  const [buttonAlignment, setButtonAlignment] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const getdata = getLocalStorageItem('USER_DETAILS');

  //disable primary field i.e. phone/email
  useEffect(() => {
    if (getdata?.is_email_primary) {
      setDisablefield(true);
    } else if (getdata?.is_phone_primary) {
      setDisablefield(false);
    }
  }, [getdata]);

  const { mutate: updateUser, refetch, isLoading } = useUpdateUser();
  const [data, setData] = React.useState({
    ...getdata,
    name: getdata?.name || 'Olive',
    phone: getdata?.phone || 'NA',
    image: getdata?.profile_image?.attachment,
  });

  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
    setError: setLoginError,
  } = useForm({ resolver: yupResolver(userFormSchema), defaultValues: data });

  const handleUpdateUser = (formValues) => {
    const data = {
      name: `${formValues?.name}`,
      email: formValues?.email,
      phone: formValues?.phone,
    };

    // const isUpdatingUser = null;
    updateUser(data, {
      onSuccess: (res) => {
        setOpen(false);
        // setApiresponse('Success');
        // setSnackbarOpen(true)
        setIsOpen(true);
        const storedObjectString = localStorage.getItem('USER_DETAILS');
        const storedObject = JSON.parse(storedObjectString);
        storedObject.name = data?.name;
        storedObject.phone = data?.phone;
        storedObject.email = data?.email;
        localStorage.setItem('USER_DETAILS', JSON.stringify(storedObject));
        // refetch()
      },
      onError: (err) => {
        setOpen(false);

        if (err?.status_code == 422) {
          if (
            err?.errors[0]?.message == 'User with this Phone already exists.'
          ) {
            setLoginError(err.errors[0].field, {
              type: 'custom',
              message: err.errors[0].message,
            });
            setButtonAlignment(true);
          } else if (
            err?.errors[0]?.message ==
            'User with this Email Address already exists.'
          ) {
            setLoginError(err.errors[0].field, {
              type: 'custom',
              message: err.errors[0].message,
            });
            setButtonAlignment(true);
          }
        } else {
          setApiresponse('Failed');
          setSnackbarOpen(true);
        }
      },
    });
  };

  const phone = useWatch({ control, name: 'phone' });

  const handleSetValue = (key, value) => {
    if (key === 'phone') {
      const pvalue = ('' + value).replace(/\D/g, '').slice(0, 10);
      setValue(key, pvalue, { shouldValidate: true });
    } else {
      setValue(key, value, { shouldValidate: true });
    }
  };

  return (
    <Box
      justifyContent={'center'}
      className="shadow-md p-2 h-[293px]"
      style={{ borderRadius: '12px' }}
    >
      <form onSubmit={handleSubmit(handleUpdateUser)} id="profile-form">
        <Grid container lg={12} md={12} sm={12} xs={12} spacing={2}>
          <Grid
            lg={12}
            md={12}
            sm={12}
            xs={12}
            spacing={2}
            item
            container
            className="container-grid"
          >
            <Grid justifyContent="left" item container lg={12} md={12} sm={12}>
              <Grid item lg={6} md={12} sm={12} className="w-[100%]">
                {/* <CustomTextField id="outlined-basic" placeholder="John"  variant="outlined" /> */}
                <CustomTextField
                  style={{ isborder: false, boxShadow: 'rgba(0, 0, 0, 0.05)' }}
                  label="Name"
                  type="text"
                  fontSize="17px"
                  placeholder="John"
                  className="w-[96%]"
                  {...register('name')}
                  error={Boolean(errors.name)}
                  // disabled={true}
                  helperText={errors.name && errors.name.message}
                  FormHelperTextProps={{
                    className: 'animateErrorValue ',
                  }}
                />
              </Grid>
              <Grid lg={6} md={12} sm={12} item className="w-[100%]">
                {/* <CustomTextField
                  style={{ border: 'none', boxShadow: 'rgba(0, 0, 0, 0.05)' }}
                  label="Phone number"
                  type="text"
                  fontSize="17px"
                  placeholder="Deo"
                  className="w-[96%]"
                  {...register('last_name')}
                /> */}

                <CustomTextField
                  error={Boolean(errors.phone)}
                  helperText={errors.phone && errors.phone.message}
                  FormHelperTextProps={{
                    className: 'animateErrorValue ',
                  }}
                  startAdornment={<Typography>+1</Typography>}
                  type="text"
                  disabled={!disablefield}
                  label={
                    !disablefield ? 'Phone number(Default)' : 'Phone number'
                  }
                  placeholder="(xxx) - xxx -xxxx"
                  className="w-[96%]"
                  value={formatMobileNumber(phone, true)}
                  //maxLength={10}
                  onChange={(e) =>
                    handleSetValue(
                      'phone',
                      e?.target?.value?.trimStart().replace(/\D/, '')
                    )
                  }
                />
              </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12}>
              <CustomTextField
                style={{ border: 'none', boxShadow: 'rgba(0, 0, 0, 0.05)' }}
                startAdornment={
                  <CiMail
                    style={{
                      width: '24px',
                      height: '34px',
                      marginTop: '-3px',
                      marginLeft: '-2px',
                    }}
                  />
                }
                label={disablefield ? 'Email(Default)' : 'Email'}
                type="text"
                fontSize="17px"
                placeholder="Johndeo@smr.com"
                className="w-[98.5%]"
                disabled={disablefield}
                {...register('email')}
                error={Boolean(errors.email)}
                helperText={errors.email && errors.email.message}
                FormHelperTextProps={{
                  className: 'animateErrorValue ',
                }}
              />
            </Grid>
            <Grid
              justifyContent="right"
              lg={12}
              md={12}
              xs={12}
              item
              container
              className="w-[100%] py-10"
              style={{ paddingRight: '43px', gap: '20px' }}
              spacing={3}
            >
              {/* <Grid justifyContent="center" item lg={3} md={6} sm={12} xs={12} marginTop='15px'>

                <Button
                  className='detailsContainer-button-style'

                  style={btnCancelStyle} >
                  Cancel
                </Button>
              </Grid> */}
              <Grid
                justifyContent="center"
                item
                lg={3}
                md={6}
                sm={12}
                xs={12}
                marginTop={buttonAlignment ? '' : '15px'}
              >
                {/* {isLoading ? <Button variant="contained" style={btnStyle}  >
                  <CircularProgress size={30} className='text-primary-50' />
                </Button> :*/}
                <Button
                  className="detailsContainer-button-style"
                  style={btnAffreStyle}
                  onClick={handleOpen}
                >
                  Save changes
                </Button>
                {/* <Button className="detailsContainer-button-style"
                  style={btnAffreStyle} onClick={handleOpen}>
                  Save changes
                </Button> */}
              </Grid>
              <Snackbar
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={() => setSnackbarOpen(false)}
                message={apiresponse === 'Failed' ? 'An error ocurred!' : ''}
              />
            </Grid>
          </Grid>
        </Grid>
        <BasicModal
          isOpen={open}
          handleOpen={handleOpen}
          setClose={setOpen}
          data={data}
          id="profile-form"
          handleUpdateUser={handleUpdateUser}
          isLoading={isLoading ? isLoading : null}
        />
      </form>
      {/* //modal */}
      <ResponseStatusPage
        isOpen={isOpen}
        first_message="Your profile has been updated successfully"
        isProfilePage={true}
        animation={done}
      />
    </Box>
  );
};

export default ProfileDetailsGrid;
