import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { StepConnector, StepIcon } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import './stepper.css'

const steps = [
  {
    label: "Stock number",
    description: "Please enter your stock number"
  },
  {
    label: "Credit Application",
    description: "Please submit your credit application"
  },
  {
    label: "Basic Information",
    description: "Please enter your basic information"
  },
  {
    label: "Commercial Drivers License",
    description: "Please upload your drivers license"
  },
  {
    label: "Social Security",
    description: "Please upload social security"
  },
  {
    label: "Work Permit/Green Card/Passport",
    description: "Please upload your work permit/green card/passport"
  },
  {
    label: "Bank Statement ",
    description: "Please upload bank statement"
  },
  {
    label: "Tax Return",
    description: "Please upload your tax return "
  },
  {
    label: "Voided Check",
    description: "Please upload your voided check"
  },
  {
    label: "Articles of Incorporation",
    description: "Please upload your articles of incorporation"
  },
  {
    label: "EIN Letter",
    description: "Please upload your EIN letter"
  }


];

export default function VerticalLinearStepper(props) {
  const {activeStep, setActiveStep} = props;
  // const [activeStep, setActiveStep] = React.useState(2);

  const processLabel = (obj) => {
    return <><div>label</div><div>description</div></>;
  }
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const defaultLabel = () => {
    return <div className='stepper-icon'> <div className='icon'> </div> </div>;
  }
  const activeLabel = () => {
    return <div className='stepper-icon active'> <div className='icon'> </div> </div>;
  }
  const completedLabel = () => {
    return <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_2706_33024)'>
        <rect width='24' height='24' rx='12' fill='white' />
        <path fill-rule='evenodd' clip-rule='evenodd'
              d='M17.096 7.39016L9.93602 14.3002L8.03602 12.2702C7.68602 11.9402 7.13602 11.9202 6.73602 12.2002C6.34602 12.4902 6.23602 13.0002 6.47602 13.4102L8.72602 17.0702C8.94602 17.4102 9.32601 17.6202 9.75601 17.6202C10.166 17.6202 10.556 17.4102 10.776 17.0702C11.136 16.6002 18.006 8.41016 18.006 8.41016C18.906 7.49016 17.816 6.68016 17.096 7.38016V7.39016Z'
              fill='#05945B' />
      </g>
      <defs>
        <clipPath id='clip0_2706_33024'>
          <rect width='24' height='24' rx='12' fill='white' />
        </clipPath>
      </defs>
    </svg>
      ;
  }

  const editIcon = () => {
    return <><span>Completed</span> <svg className='cursor-pointer' width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.91769 12.0771C1.94831 11.8015 1.96363 11.6636 2.00533 11.5348C2.04234 11.4205 2.09461 11.3117 2.16075 11.2114C2.2353 11.0984 2.33336 11.0003 2.52948 10.8042L11.3337 2.00004C12.0701 1.26366 13.264 1.26366 14.0003 2.00004C14.7367 2.73642 14.7367 3.93033 14.0003 4.66671L5.19614 13.4709C5.00002 13.667 4.90196 13.7651 4.78892 13.8396C4.68862 13.9058 4.57985 13.958 4.46556 13.995C4.33673 14.0367 4.1989 14.0521 3.92324 14.0827L1.66699 14.3334L1.91769 12.0771Z'
        stroke='#BBDBF0' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
    </svg></>
  }
  const labelIcon = (index) => {
    return index === activeStep ? activeLabel : defaultLabel;
  };

  const theme = createTheme({
  components: {
    // Name of the component
    MuiStepper: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          color: '#FFFFFF'
        },
      },
    },
  },
});

  const orientation = Boolean(window.innerWidth > 600) ? 'vertical' : 'horizontal';

  return (
    <Box className='custom-stepper-container' sx={{ maxWidth: 400, padding: '30px 30px 30px 0px' }}>
      <Stepper className="custom-stepper" sx={{ color: '#ffffff' }} activeStep={activeStep} orientation={orientation}>
        {steps.map((step, index) => (
          <Step
            key={step.label}
            sx={{
              '& .MuiStepLabel-label': {
                color: '#fff'
              },
              '& .MuiStepLabel-label.MuiStepLabel-Completed': {
                color: '#fff'
              },
              '& .MuiCollapse-wrapper': {
                visibility: 'visible'
              },
              '& .MuiCollapse-wrapperInner': {
                height: 'auto'
              },
              '& .MuiStepLabel-root': {
                alignItems: 'flex-start'
              },
            }}
          >
            <StepLabel
              sx={{ color: '#ffffff' }}
              StepIconComponent={ index >= activeStep ? labelIcon(index) : completedLabel }
            >
              <div className='stepper-label-wrapper'>
                <div className='stepper-label'>{step.label}</div>
                <div onClick={() => { if(index < activeStep) {setActiveStep(index)} }} className='stepper-description-completed'>{index >= activeStep ? step.description : editIcon()}</div>
              </div>

            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className="mobile-label hidden">
        {steps[activeStep].label}
      </div>
      {/*<div>
        <button onClick={() => {handleNext()}}>Next Test</button>
      </div>*/}
    </Box>
  );
}