import React, { useEffect, useRef, useState } from 'react';
import { Button, Grid, Link, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CustomTextField } from '../../../../components';
import { useRefContext } from '../../../../components/Layout';
import { useForm } from 'react-hook-form';


const DescriptionPS = (props) => {
  const {activeStep, setActiveStep, desc, setDesc} = props;
  // JS - Definitions outside the return statement

  const theme = useTheme();
  const isXLarge = useMediaQuery(theme.breakpoints.down('xl'));
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const {headerRef, navRef} = useRefContext();

  // const [description, setDesc] = useState('');
  // React Form Hook
  // const descriptionFormRef = useRef();


  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({defaultValues: { description: desc }});
  const onSubmit = (data) => {
    console.log({data});
    // Set the data to the state in the parent
    data.description ? setDesc(data.description) : setDesc('');
    // move to the next step in stepper
    setActiveStep(activeStep + 1);
  }

  const onError = (err) => {
    // Handle Errors
    console.log({ err });
  }


  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // console.log(value, name, type)
      if (value.description || value.description === '') {
        setDesc(value.description);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);



  // Template
  // console.log({headerRef, navRef});
  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
    <div className='ag-grid' style={{ height : 'calc(100vh - ' + headerRef?.current?.clientHeight + 'px)'}}>
      <header className='content-header'>
        <div className='action-bar flex justify-between p-2'>
          <div></div>
          <div className='actions'>
          </div>
        </div>
      </header>
      <div className='main'>
        <div className='stepper-content-header py-2 pb-3'>
          <h3>Add Description</h3>
        </div>

        <div className='stepper-content'>
          <div className='form-item' style={{width: 'fit-content'}}>
          <textarea
            {...register('description', {required: 'Description is required', minLength: 4})}
            maxLength={1000}
            placeholder='Write Description'
            cols='70'
            rows='10'
            style={{
              borderColor: '#D0D5DD',
              borderRadius: '8px',
              fontSize: '14px',
              fontFamily: 'Inter',
              color: '#545454',
              padding: '10px',
              resize: 'none'
            }}
            className='placeholder-[#757575]'
          >
          </textarea>
            {console.log(errors)}
            {errors['description'] ? <p className="error pt-4 pl-1" style={{right: 'inherit', left: '0px', bottom: '0px'}}>{errors['description'].message}</p> : null}
            {errors['description']?.type === 'minLength' ? <p className="error pt-4 pl-1" style={{right: 'inherit', left: '0px', bottom: '0px'}}>Please enter at least 4 characters</p> : null}
            <div className='note-container my-4 flex justify-end'>
            <h5 className='note-text text-[12px] text-[#545454] font-Inter opacity-60'>
              {1000 - desc.length} Characters Left
            </h5>
          </div>
          </div>
        </div>
      </div>
      <footer className='content-footer mb-[200px]'>
        <div className='stepper-content-actions-footer flex justify-between'>
          <div></div>
          <div className='flex flex-col gap-6'>
            <Button
              style={{ width: '275px', height: '44px', minHeight: '44px' }}
              type='submit'
              variant='contained'>
              Continue
            </Button>
          </div>
        </div>
      </footer>

    </div>
</form>
  );
};


export {
  DescriptionPS
};
