import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Skeleton,
} from '@mui/material';
import TruckCard from 'pages/LandingPage/TruckCard';
import React from 'react';

import { useNavigate } from 'react-router';
import { routes } from 'routes/RouteConstants';
const Nodata = () => {
  return (
    <svg
      width="225"
      height="197"
      viewBox="0 0 225 197"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M199.447 94.7729C199.447 115.947 192.534 135.422 181.068 151.156C174.684 159.599 167.053 167.079 158.307 172.897C144.122 182.436 127.197 188.065 109.002 188.065C58.9594 188.065 18.5619 146.283 18.5619 94.9617C18.5619 43.4519 59.1407 1.85889 109.002 1.85889C127.197 1.85889 144.122 7.46874 158.307 16.8376C167.053 22.6553 174.684 30.1353 181.068 38.5786C192.704 54.1241 199.447 73.5985 199.447 94.7729Z"
        fill="#F5F5F5"
      />
      <path
        opacity="0.3"
        d="M181.068 38.3896V150.967C174.684 159.41 167.052 166.89 158.307 172.708H66.4185V32.0241C66.6017 27.8874 68.2394 24.1473 70.7856 21.5218C73.6982 18.5374 77.5195 16.8374 81.8866 16.8374H158.307C167.052 22.6552 174.872 29.9652 181.068 38.3896Z"
        fill="#EDEDED"
      />
      <path
        d="M164.503 36.8973H112.823V16.8374H180.69C180.879 16.8374 181.068 16.8374 181.068 16.8374C191.608 17.0263 200.165 26.0175 200.165 37.0675H177.782"
        fill="#EDEDED"
      />
      <path
        d="M182.522 16.8374C178.518 17.0263 174.872 18.8962 172.152 21.5218C171.963 21.7107 171.605 22.0885 171.416 22.4662C169.055 25.2807 167.411 29.0207 167.411 32.9496V152.837C167.411 164.095 158.685 173.086 147.759 173.086H52.4086V32.0241C52.59 27.8874 54.2276 24.1473 56.7757 21.5218C59.6865 18.5374 63.5077 16.8374 67.8748 16.8374H163.596H181.786C182.145 16.8374 182.334 16.8374 182.522 16.8374Z"
        fill="white"
      />
      <path
        d="M148.305 44.1882H120.101C119.009 44.1882 118.281 43.2627 118.281 42.3182C118.281 41.3927 119.19 40.4482 120.101 40.4482H148.488C149.58 40.4482 150.308 41.3927 150.308 42.3182C150.308 43.2627 149.397 44.1882 148.305 44.1882Z"
        fill="#EDEDED"
      />
      <path
        d="M106.816 44.1882H69.8772C68.7854 44.1882 68.0583 43.2627 68.0583 42.3182C68.0583 41.3927 68.9667 40.4482 69.8772 40.4482H106.816C107.908 40.4482 108.637 41.3927 108.637 42.3182C108.637 43.2627 107.908 44.1882 106.816 44.1882Z"
        fill="#EDEDED"
      />
      <path
        d="M130.472 63.1145H93.7147C92.6229 63.1145 91.8958 62.17 91.8958 61.2445C91.8958 60.1112 92.8061 59.3745 93.7147 59.3745H130.472C131.564 59.3745 132.293 60.3 132.293 61.2445C132.293 62.3589 131.564 63.1145 130.472 63.1145Z"
        fill="#EDEDED"
      />
      <path
        d="M81.8868 63.1145H69.8772C68.7854 63.1145 68.0583 62.17 68.0583 61.2445C68.0583 60.1112 68.9667 59.3745 69.8772 59.3745H81.8868C82.9785 59.3745 83.7076 60.3 83.7076 61.2445C83.7076 62.3589 82.7972 63.1145 81.8868 63.1145Z"
        fill="#E3E3E3"
      />
      <path
        d="M148.305 82.2286H125.741C124.649 82.2286 123.922 81.2841 123.922 80.3397C123.922 79.2253 124.832 78.4697 125.741 78.4697H148.305C149.397 78.4697 150.126 79.4141 150.126 80.3397C150.308 81.2841 149.397 82.2286 148.305 82.2286Z"
        fill="#E3E3E3"
      />
      <path
        d="M115.006 82.2286H69.8772C68.7854 82.2286 68.0583 81.2841 68.0583 80.3397C68.0583 79.2253 68.9667 78.4697 69.8772 78.4697H115.006C116.098 78.4697 116.825 79.4141 116.825 80.3397C116.825 81.2841 116.098 82.2286 115.006 82.2286Z"
        fill="#EDEDED"
      />
      <path
        d="M130.472 101.139H105.543C104.451 101.139 103.724 100.214 103.724 99.2694C103.724 98.3249 104.632 97.3994 105.543 97.3994H130.472C131.564 97.3994 132.293 98.3249 132.293 99.2694C132.293 100.214 131.564 101.139 130.472 101.139Z"
        fill="#EDEDED"
      />
      <path
        d="M94.8066 101.139H69.8772C68.7854 101.139 68.0583 100.214 68.0583 99.2694C68.0583 98.3249 68.9667 97.3994 69.8772 97.3994H94.8066C95.8984 97.3994 96.6276 98.3249 96.6276 99.2694C96.6276 100.214 95.8984 101.139 94.8066 101.139Z"
        fill="#E3E3E3"
      />
      <path
        d="M148.305 120.253H131.018C129.927 120.253 129.199 119.309 129.199 118.384C129.199 117.25 130.11 116.495 131.018 116.495H148.305C149.397 116.495 150.126 117.439 150.126 118.384C150.308 119.309 149.397 120.253 148.305 120.253Z"
        fill="#EDEDED"
      />
      <path
        d="M119.738 120.253H90.6227C89.531 120.253 88.8019 119.309 88.8019 118.384C88.8019 117.25 89.7123 116.495 90.6227 116.495H119.738C120.83 116.495 121.557 117.439 121.557 118.384C121.738 119.309 120.83 120.253 119.738 120.253Z"
        fill="#E3E3E3"
      />
      <path
        d="M79.8862 120.253H69.6957C68.604 120.253 67.8748 119.309 67.8748 118.384C67.8748 117.25 68.7853 116.495 69.6957 116.495H79.8862C80.9779 116.495 81.7052 117.439 81.7052 118.384C81.7052 119.309 80.9779 120.253 79.8862 120.253Z"
        fill="#EDEDED"
      />
      <path
        d="M130.473 139.179H113.186C112.094 139.179 111.367 138.235 111.367 137.29C111.367 136.365 112.277 135.42 113.186 135.42H130.473C131.564 135.42 132.293 136.365 132.293 137.29C132.293 138.235 131.564 139.179 130.473 139.179Z"
        fill="#EDEDED"
      />
      <path
        d="M101.903 139.179H69.8772C68.7854 139.179 68.0583 138.235 68.0583 137.29C68.0583 136.365 68.9667 135.42 69.8772 135.42H101.903C102.995 135.42 103.724 136.365 103.724 137.29C103.724 138.235 102.814 139.179 101.903 139.179Z"
        fill="#E3E3E3"
      />
      <path
        d="M105.908 156.973H131.018C131.018 161.28 132.656 165.397 135.569 168.212C138.298 171.026 142.119 172.896 146.487 172.896H33.1197C28.9339 172.896 24.9313 171.196 22.2019 168.212C19.4724 165.397 17.6516 161.469 17.6516 156.973H92.0773C92.2606 156.973 106.089 157.162 105.908 156.973Z"
        fill="#EDEDED"
      />
      <path
        d="M34.7289 21.5025C37.1183 20.6903 38.4255 18.0648 37.6491 15.5903C36.8709 13.1348 34.3058 11.7937 31.9163 12.5871C29.5269 13.3804 28.2199 16.0248 28.9962 18.4993C29.7725 20.9548 32.3395 22.2959 34.7289 21.5025Z"
        fill="#CCCCCC"
      />
      <path
        d="M44.6588 6.22109C46.2833 5.67331 47.1729 3.87886 46.644 2.19776C46.117 0.535544 44.3717 -0.389925 42.7472 0.15785C41.1209 0.705626 40.2331 2.50007 40.7601 4.18118C41.289 5.84339 43.0344 6.76886 44.6588 6.22109Z"
        fill="#CCCCCC"
      />
      <path
        d="M3.09399 107.145C4.80343 107.145 6.18798 105.709 6.18798 103.952C6.18798 102.196 4.80343 100.76 3.09399 100.76C1.38644 100.76 0 102.196 0 103.952C0 105.709 1.38644 107.145 3.09399 107.145Z"
        fill="#05945B"
      />
      <path
        d="M218.109 67.6464C220.924 65.002 221.112 60.4876 218.543 57.5787C215.975 54.6888 211.592 54.4808 208.778 57.1441C205.963 59.7885 205.756 64.2842 208.343 67.1931C210.912 70.102 215.295 70.2908 218.109 67.6464Z"
        fill="#CCCCCC"
      />
      <path
        d="M216.73 36.5199C218.336 36.5199 219.639 35.1789 219.639 33.5167C219.639 31.8545 218.336 30.5132 216.73 30.5132C215.124 30.5132 213.821 31.8545 213.821 33.5167C213.821 35.1789 215.124 36.5199 216.73 36.5199Z"
        fill="#DC2804"
      />
      <g filter="url(#filter0_d_5670_33029)">
        <path
          d="M58.7377 63.0243C58.929 69.2038 53.7479 74.4572 47.4155 74.6462H44.3459L35.9022 74.8351C23.2374 75.2131 12.6821 65.2732 12.2976 52.9144C12.1064 40.5366 22.0861 30.2375 34.7508 29.8596C47.4156 29.4816 57.9709 39.4215 58.3533 51.7803L58.5465 56.4671L58.7377 63.0243Z"
          fill="#0F5E91"
        />
      </g>
      <path
        d="M31.8447 61.6227C31.1175 61.6227 30.3902 61.4338 29.8444 60.8671L24.5668 55.4271C23.475 54.3127 23.475 52.6314 24.5668 51.4981C25.6586 50.3837 27.2963 50.3837 28.388 51.4981L31.6634 54.8793L41.3079 44.9437C42.3997 43.8104 44.0374 43.8104 45.1292 44.9437C46.2209 46.0582 46.2209 47.7583 45.1292 48.8728L33.4824 60.8671C33.3011 61.2449 32.5738 61.6227 31.8447 61.6227Z"
        fill="white"
      />
      <g filter="url(#filter1_d_5670_33029)">
        <path
          d="M216.259 157.161C215.687 159.631 214.937 161.909 213.971 164.187C213.794 164.742 213.596 165.125 213.222 165.7C209.988 171.96 204.861 177.091 198.59 180.136C197.841 180.499 197.072 180.882 196.5 181.074C190.426 183.543 183.584 184.501 176.741 182.969C158.698 179.178 147.109 161.526 150.909 143.491C154.709 125.437 172.383 113.854 190.426 117.664C196.874 118.985 202.573 122.22 207.326 126.777C215.115 134.741 218.546 146.133 216.259 157.161Z"
          fill="#FFC200"
        />
      </g>
      <path
        d="M211.63 164.266C211.441 164.832 211.271 165.21 210.912 165.777C207.815 171.953 202.904 177.016 196.897 180.019C196.16 180.378 195.443 180.756 194.895 180.944L172.323 157.711L177.782 157.9L183.599 158.089L187.793 155.105C187.793 155.105 191.06 149.287 190.89 148.909C190.701 148.531 187.075 142.732 187.075 142.732L191.06 143.488L211.63 164.266Z"
        fill="url(#paint0_linear_5670_33029)"
      />
      <path
        d="M195.613 161.09L190.891 156.216C194.517 150.588 193.063 143.107 187.604 139.349C182.145 135.609 174.873 137.101 171.227 142.73C167.601 148.34 169.055 155.839 174.514 159.598C178.518 162.204 183.599 162.204 187.434 159.598L192.156 164.452C193.063 165.396 194.517 165.396 195.443 164.452C196.519 163.526 196.519 162.015 195.613 161.09ZM176.138 154.535C173.418 151.721 173.418 147.036 176.138 144.222C178.877 141.426 183.429 141.426 186.149 144.222C188.888 147.036 188.888 151.721 186.149 154.535C183.241 157.35 178.877 157.35 176.138 154.535Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_5670_33029"
          x="4.29492"
          y="26.8491"
          width="62.4479"
          height="60.9966"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.859467 0 0 0 0 0.832639 0 0 0 0 0.916667 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5670_33029"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5670_33029"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_5670_33029"
          x="142.187"
          y="113.938"
          width="82.7689"
          height="82.769"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.760784 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5670_33029"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5670_33029"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_5670_33029"
          x1="187.245"
          y1="156.125"
          x2="198.71"
          y2="167.25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EEB500" />
          <stop offset="1" stop-color="#FFC200" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export const DataListing = ({ data, isloading, refetch }) => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));


  return (
    <>
      <Grid
        justifyContent={isMedium ? 'center' : 'center'}
        spacing={5}
        className="px-5 max-md:px-1 relative "
        item
        container
      >
        {isloading ? (
          <Grid spacing={2} item container>
            <Grid item lg={4} md={6} xs={12}>
              {' '}
              <Skeleton
                variant="rectangular"
                width={'330px'}
                height={'350px'}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              {' '}
              <Skeleton
                variant="rectangular"
                width={'330px'}
                height={'350px'}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              {' '}
              <Skeleton
                variant="rectangular"
                width={'330px'}
                height={'350px'}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              {' '}
              <Skeleton
                variant="rectangular"
                width={'330px'}
                height={'350px'}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              {' '}
              <Skeleton
                variant="rectangular"
                width={'330px'}
                height={'350px'}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              {' '}
              <Skeleton
                variant="rectangular"
                width={'330px'}
                height={'350px'}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              {' '}
              <Skeleton
                variant="rectangular"
                width={'330px'}
                height={'350px'}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              {' '}
              <Skeleton
                variant="rectangular"
                width={'330px'}
                height={'350px'}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              {' '}
              <Skeleton
                variant="rectangular"
                width={'330px'}
                height={'350px'}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              {' '}
              <Skeleton
                variant="rectangular"
                width={'330px'}
                height={'350px'}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              {' '}
              <Skeleton
                variant="rectangular"
                width={'330px'}
                height={'350px'}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              {' '}
              <Skeleton
                variant="rectangular"
                width={'330px'}
                height={'350px'}
              />
            </Grid>
          </Grid>
        ) : data?.length == 0 || !data ? (
          <Box
            sx={{
              margin: '0 auto',
              display: 'table',
              width: '100%',
              paddingTop: '100px',
              justifyContent: 'center',
            }}
          >
            <Box className="text-center ">
              <Typography
                variant="h4"
                className="text-secondary-50 py-4 !font-bold !font-[Inter]"
              >
                No result found
              </Typography>
              {Nodata()}
            </Box>
          </Box>
        ) : (
          data?.map((el) => {
            return (
              <Grid item>
                <TruckCard refetch={refetch} {...el} />
              </Grid>
            );
          })
        )}
      </Grid>
    </>
  );
};
