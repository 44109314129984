import React, { useState } from 'react';
import {
  Grid,
  Typography,
  useMediaQuery,
  Popover,
  Slider,
} from '@mui/material';

import { useTheme } from '@mui/material/styles';

import { CustomMenuWithSearch } from 'components/CustomMenuWithSearch/CustomMenuWithSearch';

function valuetext(value) {
  return `${value}°C`;
}

const minDistance = 10;
export const Select_Gear_Type = ({ filterValues, setFilterValues }) => {
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMake = Boolean(anchorEl);
  const id = openMake ? 'simple-popover' : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid item container lg={2} md={3} sm={12} className='gearWid'>
      <Grid
        justifyContent="center"
        onClick={handleClick}
        className={`${
          isSmall || isXSmall ? 'flex justify-around !align-middle' : 'grid'
        } cursor-pointer relative  p-2 min-w-[165px] homeAln `}
        item
      >
        <Typography className="cursor-pointer">Transmission</Typography>
        {filterValues.some((el) => el.filter == 'gear_type') && (
          <Typography className="text-left animateFilterValue">
            {filterValues.find((el) => el.filter == 'gear_type')?.label}
          </Typography>
        )}
      </Grid>
      <Popover
        className="min-h[228px] !mt-4 "
        id={id}
        open={openMake}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <CustomMenuWithSearch
          handleClosePopUp={handleClose}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
          isSearch={false}
          type="gear_type"
          options={[
            { label: 'Manual', value: 'Manual' },
            { label: 'Automatic', value: 'Automatic' },
          ]}
        />
      </Popover>
    </Grid>
  );
};
