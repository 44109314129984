import { Button, Typography } from '@mui/material'
import React from 'react'
import done from '../../utils/gif/done.json'
 
import Lottie from 'react-lottie-player'
import { useLocation, useNavigate } from 'react-router'
import { routes } from 'routes/RouteConstants'

export const RequestQuoteSuccessPage = () => {
  const navigate=useNavigate()
  const location= useLocation()
  
  const handleBackToHome=()=>{
    navigate(routes.REDIRECTOR)
  }
  return (
    <div className='absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]'>
    
    <div className='h-full  justify-between  text-center px-10' >
        <div>
            <Lottie
            
            animationData={done}
            play
            style={{ width: 200, height: 200, margin:'auto'}}
            />
        </div>
        <div className='py-2'>
        <Typography className='text-[#05945B] py-2 font-semibold font-[poppins]' >Request has been sent <br/> successfully</Typography>
        <Typography className='text-gray-60 py-1 text-sm'>support team will contact you to schedule an <br/> inspection.</Typography>
        <Button onClick={handleBackToHome} className='max-w-[370px] w-full !text-primary-50 !border-0 shadow-md ' variant='outlined'>Back to Home</Button>
        </div>
    </div>

    </div>
  )
}
