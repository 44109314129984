import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { Grid, Box, Typography, useMediaQuery, useTheme, Modal, Divider } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import {Finance} from 'pages/LandingPage/Finance';
import { YourShipment } from 'pages/LandingPage/YourShipment';
import { HassleFreeFinancing } from 'pages/LandingPage/HassleFreeFinancing';
import WhyChooseSellMyRig from 'pages/LandingPage/WhyChooseSellMyRig';
import JoinOurCommunity from 'pages/LandingPage/JoinOurCommunity';


const Content = () => {


   return (

      <>
         <Grid container lg={12} md={12} sm={12} xs={12} className='' rowGap={6}>
            <Grid item lg={12} md={12} sm={12} xs={12} className='flex' paddingTop={6} justifyContent='center' alignItems='center' >
               <ContentFirst />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} className='flex' paddingTop={6} justifyContent='center' alignItems='center' >
               <WhyChooseSellMyRig />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} className='flex' paddingTop={1} justifyContent='center' alignItems='center' >
            <YourShipment />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} className='!h-[485px] hidden' paddingTop={6} style={{ backgroundColor: '#03131D' }}>
               <HassleFreeFinancing />
               
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} className='flex' paddingTop={6} paddingBottom={3} justifyContent='center' alignItems='center' 
            backgroundColor={'#F5F5F5'}>
               <JoinOurCommunity />
            </Grid>
         </Grid>
      </>
   )
}
export default Content;

const ContentFirst = () => {
   const theme = useTheme();
   const isBelowMedium = useMediaQuery(theme.breakpoints.down('md'));
   const style={
      height:isBelowMedium?'300px':'400px',
      backgroundRepeat:'no-repeat',
      backgroundSize:'cover',
      backgroundPosition:'center'
   }

   const img = process.env.PUBLIC_URL + '/ourMissionBackImg.png';

   return (

      <>
         <Grid container lg={12} md={12} sm={11} xs={12} className='' spacing={6}>
            {/* <Grid item container lg={6} md={8} sm={12} xs={12} className='aboutPd'>

               <Grid item lg={12} md={12} sm={12} xs={10} className='' style={{ height: isBelowMedium?'300px':'400px', width: '100%', backgroundImage: `url(${img})`, borderRadius: '183px 0px 0px 183px' }} >

               </Grid>
               <img src={img} alt='background-img' style={style}/>
               <Grid item lg={12} md={12} sm={10} xs={9} className=''>
                  <ContentSubFirst />
               </Grid>
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12} >
               <OurMissionContent />
            </Grid> */}
             <Grid container lg={12} md={12} sm={12} xs={12} className='justify-center'>
                     <Grid item lg={11} md={12} sm={10} xs={11} className='text-center'>
                           <OurMissionContent />
                     </Grid>
                     <Grid item lg={6} md={8} sm={12} xs={12} className='aboutPd flex justify-center'>
                              <ContentSubFirst />
                     </Grid>
                  
               
             </Grid>
         </Grid>
      </>
   )
}

const ContentSubFirst = () => {

   const theme = useTheme();
   const isBelowMedium = useMediaQuery(theme.breakpoints.down('md'));
   const isBelowSmall = useMediaQuery(theme.breakpoints.down('xs'));
   return (

      <>
         <Grid container lg={8} md={8} sm={8} xs={12} className=' flex' style={{ height:isBelowMedium?'100px':'91px',borderRadius: '202px', backgroundColor: '#FFFFFF', border: '5px solid #FFC200' }} justifyContent='center' alignItems='center'>

            <Grid item container lg={3} md={3} sm={3} xs={4} className=' flex' justifyContent='center' alignItems='center'>
               <Grid item lg={12} md={12} sm={12} xs={12} className=' flex' justifyContent='center' alignItems='center'>
                  <img src={'/assets/icons/support.svg'} style={{ height: '18px', width: '20px' }} />
               </Grid>

               <Typography style={{ fontSize: '18px', fontWeight: '500', color: '#03131D', paddingTop: '5px' }}> Support</Typography>
            </Grid>
            <Grid item container lg={3} md={3} sm={3} xs={4} className=' flex' justifyContent='center' alignItems='center'>
               <Grid item lg={12} md={12} sm={12} xs={12} className=' flex' justifyContent='center' alignItems='center'>
                  <img src={'/assets/icons/sales.svg'} style={{ height: '18px', width: '20px' }} />
               </Grid>

               <Typography style={{ fontSize: '18px', fontWeight: '500', color: '#03131D', paddingTop: '5px' }}> Sales</Typography>
            </Grid>

            <Grid item container lg={3} md={3} sm={3} xs={4} className=' flex' justifyContent='center' alignItems='center'>
               <Grid item lg={12} md={12} sm={12} xs={12} className=' flex' justifyContent='center' alignItems='center'>
                  <img src={'/assets/icons/phone.svg'} style={{ height: '18px', width: '20px' }} />
               </Grid>

               <Typography style={{ fontSize: '18px', fontWeight: '500', color: '#03131D', paddingTop: '5px' }}> Phone</Typography>
            </Grid>

         </Grid>
      </>
   )
}

const OurMissionContent = () => {

   return (
      <>
         <Grid container lg={12} md={12} sm={12} xs={12} rowGap={4}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
               <Typography style={{ fontSize: '31px', fontWeight: '700', color: '#0F0F0F' }}>
                  Our Mission
               </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
               <Typography style={{ fontSize: '18px', fontWeight: '600', color: '#757575' }}>
               At Sell My Rig, we're dedicated to creating a user-friendly space where businesses seamlessly trade transportation equipment.

               </Typography>
               {/* <Typography style={{ fontSize: '18px', fontWeight: '600', color: '#757575' }}>
               Sell My Rig was created to hinder dishonesty and make a positive impact for transacting with
titled equipment.

               </Typography> */}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
               {/* <Button style={{ height: '43px', width: '240px', backgroundColor: '#0F5E91', fontSize: '16px', fontWeight: '400', color: '#FFFFFF' }}>Learn more</Button> */}
            </Grid>
         </Grid>
      </>
   )
}

const ContentSecond = () => {
   const img = process.env.PUBLIC_URL + '/slate.png';

   const theme = useTheme();
     const isMedium = useMediaQuery(theme.breakpoints.up('md'));
     const isBelowMedium = useMediaQuery(theme.breakpoints.down('md'));
     const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
     const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));

     //Request Financing
     const [isFinanceOpen,setFinanceOpen] = useState(false);
     const requestFinancing = () => {
       
          setFinanceOpen(true);
     }

   return (

      <>
         <Grid container className='' lg={12} md={12} sm={12} xs={12} spacing={3}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
               <Typography className='flex' style={{ fontSize: isBelowMedium?'33px':'53px', fontWeight: '700', color: '#FFFFFF' }} justifyContent='center' >
                  Financing Availble
               </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
               <Typography className='flex' style={{ fontSize: isBelowMedium?'18px': '24px', fontWeight: '400', color: '#FFFFFF' }} justifyContent='center' >
                  Whether You Buy From Us Or Not!
               </Typography>
            </Grid>
            <Grid item lg={12} className='flex' md={12} sm={12} xs={12} justifyContent='center' alignItems='center'>
               <img src={img} style={{ height:isBelowMedium?'61px': '81px', width:isBelowMedium?'100%' :'359px' }} />
            </Grid>
            <Grid item container lg={12} md={12} sm={12} xs={12} className='flex' justifyContent={isBelowMedium?'flex start':'center'} alignItems='center' spacing={isBelowMedium?1:3}>
               <Grid item>
                  <Typography style={{ fontSize: isBelowMedium?'15px':'22px', fontWeight: '400', color: '#FFFFFF' }}>Established Meets</Typography>
               </Grid>
               <Grid item>
                  <div style={{ height: isBelowMedium?'3px':'7px', width:isBelowMedium?'3px': '7px', borderRadius: '20px', backgroundColor: '#BBDBEF' }}></div>
               </Grid>
               <Grid item><Typography style={{ fontSize:isBelowMedium?'15px' :'22px', fontWeight: '400', color: '#FFFFFF' }}>New Carriers</Typography></Grid>
               <Grid item><div style={{ height: isBelowMedium?'3px':'7px', width:isBelowMedium?'3px': '7px', borderRadius: '20px', backgroundColor: '#BBDBEF' }}></div></Grid>
               <Grid item><Typography style={{ fontSize: isBelowMedium?'15px':'22px', fontWeight: '400', color: '#FFFFFF' }}>Authorization Card</Typography></Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} className='flex' justifyContent='center' alignItems='center' padding='5px'>
               <Button style={{ backgroundColor: '#FFFFFF', fontSize: '22px', fontWeight: '500', maxWidth: '317px', maxHeight: '61px', boxShadow: 'rgba(0, 0, 0, 0.08)',display:'flex',justifyContent:'center' }}
                 onClick={()=>requestFinancing()}>
                  Request Financing
               </Button>
            </Grid>
            <Finance open={isFinanceOpen} setOpen={setFinanceOpen}/>
         </Grid>
      </>
   )
}

const ContentThird = () => {
   // const isBelowMedium = useMediaQuery(theme.breakpoints.down('md'));

   return (
      <>
         <Grid container lg={12} md={12} sm={11} xs={12} spacing={4} paddingBottom={4}>
            <Grid item lg={12} md={12} sm={11} xs={12} className='flex'  justifyContent='center' padding='10px'>
               <ThirdSubContent />
            </Grid>
            <Grid item lg={12} md={12} sm={11} xs={12} className=' flex' justifyContent='center'>
               <ThirdSubConsec />
            </Grid>
         </Grid>
      </>
   )
}

const ThirdSubContent = () => {
   const theme = useTheme();
   const isBelowMedium = useMediaQuery(theme.breakpoints.down('md'));

   const heading = {

      fontSize: '19px',
      fontWeight: '700',
      color: '#115D90',
      order: '1',
      display:'flex',
      justifyContent:'center'
      // paddingLeft:"20px"

   }
   const subHeding = {
      fonstSize: '12px',
      fontWeight: '400',
      color: '#262626',
      opacity: '0.7',
      // width: '283px'
   }
   const icon = {
      height: '28px',
      width: '32px'
   }

   return (
      <>
         <Grid container lg={12} md={12} sm={12} xs={12} className='flex' justifyContent='center' columnGap={2}>
            {/* <Grid item container> */}
            <Grid container item lg={2.5} md={4} sm={12} xs={12} className={isBelowMedium?'flex':''} justifyContent={isBelowMedium?'center':''}>
               <Grid item container lg={12} md={12} sm={12} xs={12} >
                  <Grid item lg={1} md={1} sm={1} xs={1} >
                     <img src={'/assets/icons/credit.svg'} style={icon} />
                  </Grid>
                  <Grid item lg={9} md={10} sm={11} xs={11} > 
                     <Typography style={heading} >Credit Application</Typography>
                  </Grid>

               </Grid>

               <Grid item lg={10} md={12} sm={12} xs={12} >
                  <Typography style={subHeding} >
                     Lorem ipsum is dummy text of the printing and typesetting.
                     Lorem Ipsum is simply dummy.
                  </Typography>
               </Grid>

            </Grid>

            {/* ------- */}
            <Grid item container className=' !h-[30px]  flex !w-[153px] ' justifyContent='center' alignItems='center'>
               <Grid item container
                  className=' '>
                  <Grid item className='!w-[170px] ' style={{ border: '2px dashed #051C2C' }}>

                  </Grid>
               </Grid>

            </Grid>

            <Grid container item lg={2.5} md={5} sm={12} xs={12} className={isBelowMedium?'flex':''} justifyContent={isBelowMedium?'center':''}>
               <Grid item container lg={12} md={12} sm={12} xs={12} columnGap='10px'>
                  <Grid item lg={1} md={1} sm={1} xs={1}>
                     <img src={'/assets/icons/document.svg'} style={icon} />
                  </Grid>
                  <Grid item lg={10} md={10} sm={11} xs={11} >
                     <Typography style={heading} >Document Verification</Typography>
                  </Grid>

               </Grid>

               <Grid item lg={10} md={12} sm={12} xs={12} >
                  <Typography style={subHeding} >
                     Lorem ipsum is dummy text of the printing and typesetting.
                     Lorem Ipsum is simply dummy.
                  </Typography>
               </Grid>

            </Grid>

            <Grid item container className=' !h-[30px]  flex !w-[153px] ' justifyContent='center' alignItems='center' >
               <Grid item container
                  className=' '>
                  <Grid item className='!w-[170px] ' style={{ border: '2px dashed #051C2C' }}>

                  </Grid>
               </Grid>

            </Grid>

            <Grid container item lg={2} md={4} sm={12} xs={12}>
               <Grid item container lg={12} md={12} sm={12} xs={12} >
                  <Grid item lg={1} md={1} sm={1} xs={1}>
                     <img src={'/assets/icons/approvalIcon.svg'} style={icon} />
                  </Grid>
                  <Grid item lg={8} md={10} sm={10} xs={10}>
                     <Typography style={heading} >Approval</Typography>
                  </Grid>

               </Grid>

               <Grid item lg={12} md={12} sm={12} xs={12} >
                  <Typography style={subHeding} >
                     Lorem ipsum is dummy text of the printing and typesetting.
                     Lorem Ipsum is simply dummy.
                  </Typography>
               </Grid>

            </Grid>
         </Grid>
         {/* </Grid>    */}
      </>
   )
}

const ThirdSubConsec = () => {
   const theme = useTheme();
   const isBelowMedium = useMediaQuery(theme.breakpoints.down('md'));

   const font={
      fontSize:'28px',
      fontWeight:'500',
      color:'#051C2C'
   }
   return (

      <>
         <Grid container lg={8} md={12} sm={12} xs={12} className=''>
            <Grid item container lg={3} md={2} sm={2} xs={2} gap={2} className='flex' justifyContent={isBelowMedium?'space-between':''} paddingLeft={isBelowMedium?'50px':''} paddingRight={isBelowMedium?'50px':''}>
                <Grid item lg={12} md={12} sm={12} xs={12}  className=' flex' justifyContent='center' alignItems='center'>
                <img src={'assets/icons/ShopIcon.svg'} style={{height:'70px',width:'62px'}}/>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}  className=' flex' justifyContent='center' alignItems='center'>
                   <Typography style={font}>Shop</Typography>
                </Grid>
            </Grid>
            <Grid item container lg={3} md={2} sm={2} xs={2} gap={2} className='flex' justifyContent={isBelowMedium?'space-between':''} paddingLeft={isBelowMedium?'50px':''} paddingRight={isBelowMedium?'50px':''}>
              <Grid item lg={12} md={12} sm={12} xs={12} gap={2} className='flex' justifyContent='center'>
                <img src={'assets/icons/research.svg'} style={{height:'70px',width:'62px'}}/>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} className='flex' justifyContent='center'>
              <Typography style={font}>Research</Typography>
              </Grid>
            </Grid>
            <Grid item container lg={3} md={2} sm={2} xs={2} gap={2} className='flex' justifyContent={isBelowMedium?'space-between':''} paddingLeft={isBelowMedium?'50px':''} paddingRight={isBelowMedium?'50px':''}>
              <Grid item lg={12} md={12} sm={12} xs={12} className='flex' justifyContent='center'>
              <img src={'assets/icons/newsIcon.svg'} style={{height:'70px',width:'62px'}}/>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} className='flex' justifyContent='center'>
              <Typography style={font}>News</Typography>
              </Grid>
           </Grid>
            <Grid item container lg={3} md={2} sm={2} xs={2} gap={2} className='flex' justifyContent={isBelowMedium?'space-between':''} paddingLeft={isBelowMedium?'50px':''} paddingRight={isBelowMedium?'50px':''} >
              <Grid item lg={12} md={12} sm={12} xs={12} className='flex' justifyContent='center'>
              <img src={'assets/icons/sell.svg'} style={{height:'70px',width:'62px'}}/>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} className='flex' justifyContent='center'>
              <Typography style={font} >Sell</Typography>
              </Grid>
            </Grid>
         </Grid>
      </>
   )
}