import React, { useEffect, useState } from 'react';
import { Button, Grid, Link, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import './TruckCategory.css';
import { useTrucksList } from '../../ProductSellQueries/ProductSellQueries';


const TruckCategory = (props) => {
  let {
    activeStep,
    setActiveStep,
    category,
    setCategory,
    sub_category,
    setSub_category,
    truckList,
    setTruckList
  } = props;
  // JS - Definitions outside the return statement

  const theme = useTheme();
  const isXLarge = useMediaQuery(theme.breakpoints.down('xl'));
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const { data: trucksListData, isFetching: isFetchingColorsData } = useTrucksList();
  const [ modifiedTrucksListData, setModifiedTrucksListData ] = useState([]);

  useEffect(() => {
    if (trucksListData) {
      let modifiedTrucksListData = [...trucksListData.results];
      modifiedTrucksListData.forEach((category) => {
        category.sub_category.forEach((truck) => {
          if(truck.id === sub_category) {
            truck._isActive = true;
          } else {
            truck._isActive = false;
          }
        });
      });
      setModifiedTrucksListData(modifiedTrucksListData);
    }
  }, [trucksListData]);

  const resetCategorySelection = () => {
    let tl = [...modifiedTrucksListData];
    console.log({tl});
    tl?.forEach((category) => {
      category?.sub_category?.forEach((truck) => {
        truck._isActive = false;
      });
    });
    return tl;
  }

  const handleTruckSelection = (category, truck) => {
    let resetedData = resetCategorySelection();
    // console.log({resetedData, category, truck});
    // truck._isActive = true;
    resetedData.forEach(cat => {
      if(cat.id === category.id) {
        cat.sub_category.forEach(subCat => {
          if(subCat.id === truck.id) {
            subCat._isActive = true;
            // set the category and sub_category in the parents state
            setCategory(cat.id);
            setSub_category(subCat.id);
          }
        })
      }
    });
    // Set category and sub_category in parent state
    setModifiedTrucksListData(resetedData)

    setTimeout(() => {
      setActiveStep(activeStep + 2);
    },310)
  }

  // Helper Components
  const ContactUsIcon = () => {
    return <svg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.88028 7.85335C7.57627 9.30297 8.52506 10.6616 9.72664 11.8632C10.9282 13.0648 12.2869 14.0136 13.7365 14.7096C13.8612 14.7694 13.9235 14.7994 14.0024 14.8224C14.2828 14.9041 14.627 14.8454 14.8644 14.6754C14.9313 14.6275 14.9884 14.5704 15.1027 14.4561C15.4523 14.1064 15.6271 13.9316 15.8029 13.8174C16.4658 13.3864 17.3204 13.3864 17.9833 13.8174C18.1591 13.9316 18.3339 14.1064 18.6835 14.4561L18.8783 14.6509C19.4098 15.1824 19.6755 15.4481 19.8198 15.7335C20.1069 16.301 20.1069 16.9713 19.8198 17.5389C19.6755 17.8242 19.4098 18.09 18.8783 18.6214L18.7207 18.779C18.1911 19.3087 17.9263 19.5735 17.5662 19.7757C17.1667 20.0001 16.5462 20.1615 16.088 20.1601C15.6751 20.1589 15.3928 20.0788 14.8284 19.9186C11.795 19.0576 8.93264 17.4332 6.54466 15.0452C4.15668 12.6572 2.53221 9.79483 1.67124 6.76144C1.51103 6.19699 1.43092 5.91477 1.4297 5.50182C1.42833 5.0436 1.58969 4.42311 1.81411 4.0236C2.01636 3.66357 2.28117 3.39876 2.8108 2.86913L2.96843 2.7115C3.49987 2.18006 3.7656 1.91433 4.05098 1.76999C4.61854 1.48292 5.2888 1.48292 5.85636 1.76999C6.14174 1.91433 6.40747 2.18006 6.93891 2.7115L7.13378 2.90637C7.48338 3.25597 7.65819 3.43078 7.77247 3.60655C8.20347 4.26945 8.20347 5.12403 7.77247 5.78692C7.65819 5.96269 7.48338 6.1375 7.13378 6.4871C7.01947 6.60142 6.96231 6.65857 6.91447 6.72538C6.74446 6.96281 6.68576 7.30707 6.76748 7.58743C6.79048 7.66632 6.82041 7.72866 6.88028 7.85335Z'
        stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
    </svg>;
  };

  // Template
  return (
    <>
      <div className='TruckCategoryPage action-bar flex justify-between py-8'>
        <div></div>
        <div className='actions'>
          <div className='flex flex-col gap-[14px] items-end w-full'>
            <Button
              href='/accounts/help-center'
              size='small'
              style={{width: '33%', minHeight: '48px', padding: '0px', borderRadius: `22px`}}
              className='full-rounded-btn !text-white hover:!text-white !bg-[#0F5E91] hover:!bg-[#0F5E91] !border-none'
              variant='contained'
              startIcon={<ContactUsIcon />}
            >
              Contact US
            </Button>
            <Button
              onClick={()=> {setActiveStep(activeStep + 1)}}
              style={{width: '33%', minHeight: '48px', padding: '0px'}}
              className='!text-[#0F0F0F] hover:!text-[#0F0F0F] !bg-[#FFC200] hover:!bg-[#FFC200] !border-none'
              variant='contained'
            >
              Continue by using VIN Number
            </Button>
          </div>
        </div>
      </div>
      <div className='or-section'>
      </div>
      <div className='stepper-content-header p-2 pb-8'>
        <h2
          className='font-Inter'
          style={{
          fontStyle: 'normal',
          fontWeight: '500',
          fontSize: '18px',
          color: '#051C2C',
          margin: '24px 0px'
        }}>Continue by using Category</h2>
        <h3
          className='font-Inter'
          style={{
          color: '#051C2C',
        }}>Select Category</h3>
      </div>

      <Grid container spacing={3}>
        {modifiedTrucksListData?.map((category) => {
          return <Grid key={category.id} item xs={12} sm={12} md={6} lg={4}>
            <div className='category'>
              <div className='category-heading pb-3'>
                {category.name}
              </div>
              {category.sub_category.map((truck, index) => {
                return <div key={index} onClick={() => {
                  handleTruckSelection(category, truck);
                }}
                            className={['truck', 'cursor-pointer', 'transition-shadow', 'hover:drop-shadow-md', truck._isActive ? 'active' : ''].join(' ')}>
                  <div className='img-wrapper'>
                    <img className='img' src={truck.image} alt='' />
                  </div>
                  <span className='name'>{truck.name}</span>
                </div>;
              })}
            </div>
          </Grid>;
        })
        }
      </Grid>
    </>
  );
};


export {
  TruckCategory
};