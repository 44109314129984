import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { Grid, Box, Typography, useMediaQuery, useTheme, Modal, Divider } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { Height } from '@mui/icons-material';

const NewsSecTab = () => {

    return (
        <>
            <Grid container lg={12} sm={12} xs={12} rowGap='20px'>
                <Grid item lg={12} sm={12} xs={12}>
                    <Popular />
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                   <LibraryResource />
                </Grid>
            </Grid>
        </>
    )
}

export default NewsSecTab;

const Popular = () => {

    const img = process.env.PUBLIC_URL + '/workshop.png';

    return (
        <>
            <Grid container lg={12} sm={12} xs={12} paddingTop='10px' rowGap='10px'>
                <Grid item lg={12} sm={12} xs={12}  paddingBottom='20px'>
                    <Typography style={{color:'#0F5E91',fontWeight:'700',fontSize:'18px'}}>MOST POPULAR</Typography>
                    <Divider style={{ width: '30%', backgroundColor:'#0F5E91',height:'3px' }} />
                </Grid>
                <Grid item container lg={12} sm={12} xs={12} columnGap='10px'>
                    <Grid item lg={4} sm={6} xs={12} >
                        <img src={img} alt='workshop' style={{ height: '90px', width: '100px' }} />
                    </Grid>
                    <Grid item lg={7} sm={6} xs={12}>
                        <Typography>EPA greenlights California truck emission rules, drawing industry outcry</Typography>
                    </Grid>
                </Grid>
                <Divider style={{ width: '100%', backgroundColor:'#CCCCCC' }} />
                <Grid item container lg={12} sm={12} xs={12} columnGap='10px'>
                    <Grid item lg={4} sm={6} xs={12} >
                        <img src={img} alt='workshop' style={{ height: '90px', width: '100px' }} />
                    </Grid>
                    <Grid item lg={7} sm={6} xs={12}>
                        <Typography>EPA greenlights California truck emission rules, drawing industry outcry</Typography>
                    </Grid>
                </Grid>
                <Divider style={{ width: '100%', backgroundColor:'#CCCCCC' }} />
                <Grid item container lg={12} sm={12} xs={12} columnGap='10px'>
                    <Grid item lg={4} sm={6} xs={12} >
                        <img src={img} alt='workshop' style={{ height: '90px', width: '100px' }} />
                    </Grid>
                    <Grid item lg={7} sm={6} xs={12}>
                        <Typography>EPA greenlights California truck emission rules, drawing industry outcry</Typography>
                    </Grid>
                </Grid>
                <Divider style={{ width: '100%', backgroundColor:'#CCCCCC' }} />
                <Grid item container lg={12} sm={12} xs={12} columnGap='10px'>
                    <Grid item lg={4} sm={6} xs={12} >
                        <img src={img} alt='workshop' style={{ height: '90px', width: '100px' }} />
                    </Grid>
                    <Grid item lg={7} sm={6} xs={12}>
                        <Typography>EPA greenlights California truck emission rules, drawing industry outcry</Typography>
                    </Grid>
                </Grid>
                <Divider style={{ width: '100%', backgroundColor:'#CCCCCC' }} />
                <Grid item container lg={12} sm={12} xs={12} columnGap='10px' >
                    <Grid item lg={4} md={4} sm={6} xs={4}  >
                        <img src={img} alt='workshop' style={{ height: '90px', width: '100px' }} />
                    </Grid>
                    <Grid item lg={7} md={8} sm={6} xs={8}>
                        <Typography>EPA greenlights California truck emission rules, drawing industry outcry</Typography>
                    </Grid>
                </Grid>

            </Grid>
        </>
    )
}

const LibraryResource = () => {

  return(
    <>
       <Grid container lg={12} sm={12} xs={12} paddingTop='10px' rowGap='10px'>
                <Grid item lg={12} sm={12} xs={12} paddingBottom='20px'>
                    <Typography style={{color:'#CC9B00',fontWeight:'700',fontSize:'18px'}}>LIBRARY RRSOURCES</Typography>
                    <Divider style={{ width: '30%', backgroundColor:'#CC9B00',height:'3px' }} />
                </Grid>
                <Grid item container lg={12} sm={12} xs={12} columnGap='10px'>
                    <Grid item lg={4} sm={3} xs={3}  paddingLeft='10px'>
                        <div style={{ height: '66px', width: '66px',borderRadius:'33px',display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'#FFF3CC' }}>
                           <img src={'/assets/icons/library.svg'} alt='workshop' style={{ height: '30px', width: '24px' }} />
                        </div>
                        
                    </Grid>
                    <Grid item lg={7} sm={8} xs={8}>
                        <Typography>EPA greenlights California truck emission rules, drawing industry outcry</Typography>
                    </Grid>
                </Grid>
                <Divider style={{ width: '100%', backgroundColor:'#CCCCCC' }} />
                <Grid item container lg={12} sm={12} xs={12} columnGap='10px'>
                    <Grid item lg={4} sm={3} xs={3}  paddingLeft='10px'>
                        <div style={{ height: '66px', width: '66px',borderRadius:'33px',display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'#FFF3CC' }}>
                           <img src={'/assets/icons/library.svg'} alt='workshop' style={{ height: '30px', width: '24px' }} />
                        </div>
                        
                    </Grid>
                    <Grid item lg={7} sm={8} xs={8}>
                        <Typography>EPA greenlights California truck emission rules, drawing industry outcry</Typography>
                    </Grid>
                </Grid>
                <Divider style={{ width: '100%', backgroundColor:'#CCCCCC' }} />
                <Grid item container lg={12} sm={12} xs={12} columnGap='10px'>
                    <Grid item lg={4} sm={3} xs={3}  paddingLeft='10px'>
                        <div style={{ height: '66px', width: '66px',borderRadius:'33px',display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'#FFF3CC' }}>
                           <img src={'/assets/icons/library.svg'} alt='workshop' style={{ height: '30px', width: '24px' }} />
                        </div>
                        
                    </Grid>
                    <Grid item lg={7} sm={8} xs={8}>
                        <Typography>EPA greenlights California truck emission rules, drawing industry outcry</Typography>
                    </Grid>
                </Grid>
                <Divider style={{ width: '100%', backgroundColor:'#CCCCCC' }} />
                <Grid item container lg={12} sm={12} xs={12} columnGap='10px'>
                    <Grid item lg={4} sm={3} xs={3}  paddingLeft='10px'>
                        <div style={{ height: '66px', width: '66px',borderRadius:'33px',display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'#FFF3CC' }}>
                           <img src={'/assets/icons/library.svg'} alt='workshop' style={{ height: '30px', width: '24px' }} />
                        </div>
                        
                    </Grid>
                    <Grid item lg={7} sm={8} xs={8}>
                        <Typography>EPA greenlights California truck emission rules, drawing industry outcry</Typography>
                    </Grid>
                </Grid>
                <Divider style={{ width: '100%', backgroundColor:'#CCCCCC' }} />
                <Grid item container lg={12} sm={12} xs={12} columnGap='10px'>
                    <Grid item lg={4} sm={3} xs={3}  paddingLeft='10px'>
                        <div style={{ height: '66px', width: '66px',borderRadius:'33px',display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'#FFF3CC' }}>
                           <img src={'/assets/icons/library.svg'} alt='workshop' style={{ height: '30px', width: '24px' }} />
                        </div>
                        
                    </Grid>
                    <Grid item lg={7} sm={8} xs={8}>
                        <Typography>EPA greenlights California truck emission rules, drawing industry outcry</Typography>
                    </Grid>
                </Grid>
               
        </Grid>        
    </>
  )
}