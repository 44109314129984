import { Grid, Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import '../Account.css';
import ProfileImgGrid from './profileImgContainer';
import ProfileDetailsGrid from './profileDetailsContainer';
import { CommonAccountLayout } from "pages/commonComponents/CommonAccountLayout";
import { getLocalStorageItem, setLocalStorageItem } from "hooks";
import { useGetUserProfile } from "query/Accounts";
//import { useRefContext } from "components/Layout";



const ProfilePage = () => {
  const [data, setData] = React.useState() 
  const { data: userData, refetch, dataUpdatedAt } = useGetUserProfile()
  const [getdata, setGetData] = useState(getLocalStorageItem('USER_DETAILS'))
  
  useEffect(() => {
    if (userData?.data) {
      setLocalStorageItem('USER_DETAILS', userData?.data)
    }
    setGetData(userData?.data)
  }, [dataUpdatedAt])

  React.useEffect(() => {

    let myData = {
      ...getdata,
      first_name: getdata?.name?.split(" ")[0],
      last_name: getdata?.name?.split(" ")[1],
      image: getdata?.profile_image,
    }

    setData(myData)
  }, [dataUpdatedAt])

  return (
    <>
      <CommonAccountLayout Content={
        <Box className=" px-5 py-2 h-[600px]" container item >
          <Typography className="p-4 font-bold">Account Details</Typography>          
          <Grid item container>
            <Grid lg={3} md={3} sm={12} xs={12}>
              <ProfileImgGrid refetch={refetch} data={getdata} image={data?.image} />
            </Grid>
            <Grid lg={8} md={8} sm={8} xs={12} style={{ paddingLeft: '10px' }}>
              <ProfileDetailsGrid />
            </Grid>
          </Grid>
        </Box>
      }
      />
    </>
  )
}
export default ProfilePage;