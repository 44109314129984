import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { localStorageKeys } from 'hooks';

import { axios } from 'utils';
import { saveData } from 'utils/localStorage';
import { QueryKey } from './queryKeys';

//api headers for all transactions
const GetTransactionList = (id, isPage) => {
  const method = 'GET';
  const url = `transaction/`;

  return axios({
    method,
    url,
    params: {
      per_page: 50,
      page: isPage,
    },
  });
};

export const useGetTransactiionData = (id, isPage) => {
  return useQuery(
    [QueryKey.GET_Transaction_DATA],
    () => GetTransactionList(id, isPage),
    {
      enabled: true,
    }
  );
};

//api headers for all Quote transactions
const GetTransactionQuoteData = (isPage) => {
  const method = 'GET';
  const url = `transaction/?type=Quotes,Refund`;

  return axios({
    method,
    url,
    params: {
      per_page: 50,
      page: isPage,
    },
  });
};

export const useGetTransactionQuoteData = (isPage) => {
  return useQuery(
    [QueryKey.GET_Transaction_QUOTE_DATA],
    () => GetTransactionQuoteData(isPage),
    {
      enabled: true,
    }
  );
};

//api headers for all Inspection transactions
const GetTransactionInspectionData = (isPage) => {
  const method = 'GET';
  const url = `transaction/?type=Inspection`;

  return axios({
    method,
    url,
    params: {
      per_page: 50,
      page: isPage,
    },
  });
};

export const useGetTransactionInspectionData = ( isPage) => {
  return useQuery(
    [QueryKey.GET_Transaction_INSPECTION_DATA],
    () => GetTransactionInspectionData(isPage),
    {
      enabled: true,
    }
  );
};
