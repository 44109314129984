import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { Grid, Box, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import '../SMRR.css';
import { CommonAccountLayout } from 'pages/commonComponents/CommonAccountLayout';
import SettingsMenu from './SettingsMenu';


const MenuItem = (props) => {

    return (

        <SettingsMenu Content={
           
                <Grid container lg={12} md={12} xs={12} style={{ maxWidth: '1050px', height: '50px'}}>
                    <Grid item lg={1} md={2} sm={3} xs={4}  >
                        <div style={{display:'flex', height: '50px',width: '50px', justifyContent: 'center', alignContent: 'center' ,borderRadius:'25px',backgroundColor:'#F5F5F5',marginTop:'10px',marginLeft:'20px'}}>
                            <img className='w-[20px]' src={`/assets/icons/${props.iconname}`} style={{justifyContent:'center',alignContent:'center'}} />
                        </div>

                    </Grid>
                    <Grid item lg={11} md={8} sm={7} xs={6}>
                          <Typography style={{paddingTop:'22px',fontSize:'16px',fontWeight:'500'}}>{props.description}</Typography>
                    </Grid>
                </Grid>
            
        } />

    )
}

export default MenuItem;
