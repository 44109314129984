import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router";
import { Button, Grid, Typography, useMediaQuery, useTheme, Box, Skeleton } from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';
import { CommonAccountLayout } from 'pages/commonComponents/CommonAccountLayout';
import DepositAmount from './DepositAmount';
import HeaderTab from './Header';
import ContentInspection from './TransactionPayment'

const TransactionSec = () => {

    const [tabValue,setTabVAlue] = useState('Quote');
    const handleTabchange= (tabValue)=> {
        setTabVAlue(tabValue);
    }

    return (
        <CommonAccountLayout
            header ={<Grid item sm={12} xs={12} md={12}>
            <HeaderTab ontabChange={handleTabchange}/>
        </Grid>}
            Content={
                <Box>
                    <Grid container spacing={2}>
                        
                        {tabValue === 'Quote' ? (
                             <Grid item sm={12} xs={12}>
                             <DepositAmount/>
                         </Grid>
                        ) : (
                            <Grid item sm={12} xs={12}>
                            <ContentInspection/>
                        </Grid>
                        )}
                        
                    </Grid>
                </Box>
            }

        />
    )
}

export default TransactionSec;