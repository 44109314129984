import TopNav from 'components/TopNav';
import Sidebar from 'components/Sidebar/Sidebar';
import Navbar from 'pages/commonComponents/Navbar';
import { useLocation } from 'react-router';
import { Dialog, DialogContent, Typography } from '@mui/material';
import { Footer } from 'pages/commonComponents/Footer';
// import { pageDetails } from '../../routes/RouteConstants';
import usePathName from '../../hooks/usePathName';
import { cloneElement, createContext, useContext, useEffect, useRef } from 'react';
import { useState } from 'react';
import { SaveFCMTokenMutation } from '../../query/Notifications';
import { getToken, onMessageListener } from '../../firebaseInit';
import { useQueryClient } from '@tanstack/react-query';

// Contexts
const refContext = createContext();

const Layout = ({ children }) => {

  const queryClient = useQueryClient();

  const pathName = usePathName();

  const headerRef = useRef(null);
  const navRef = useRef(null);

  const displayNavbar = pathName?.isNavbar;
  const displayFooter = pathName?.isFooter;
  const isSubNavbar = pathName?.isSubNavbar;
  const [filterValues, setFilterValues] = useState([]);
  const [open, setopen] = useState(false);

  const handleClose = () => {
    setopen(false);
  };
  const handleOpenSomethingWentWrongDialog = () => {
    setopen(true);
  };
  // console.log({displayNavbar,displayFooter,isSubNavbar});

  const { mutate: saveFCMTokenMutation } = SaveFCMTokenMutation();

  useEffect(() => {
    let data;

    async function FCM_TOKEN() {
      data = await getToken();
      if (data) {
        // Save the token on BE using devices API
        saveFCMTokenMutation({fcmToken: data});
      }
      return data;
    }

    FCM_TOKEN();

    onMessageListener((message) => {
      // Invalidate the useGetNotifications() GetNotifications API here
      queryClient.invalidateQueries('GET_NOTIFICATIONS');
    })

    navigator.serviceWorker.onmessage = (event) => {
      // Invalidate the useGetNotifications() GetNotifications API here
      queryClient.invalidateQueries('GET_NOTIFICATIONS');
    };

    // Auto refresh notifications list every 30 seconds
    if(window.notifications_interval_SMR) {
      window.clearInterval(window.notifications_interval_SMR);
    }
    window.notifications_interval_SMR = setInterval(() => {
      queryClient.invalidateQueries('GET_NOTIFICATIONS');
    },30000);


  }, []);

  return (
    <div>
      <div>
        <refContext.Provider
          value={{
            headerRef,
            navRef,
            handleOpenSomethingWentWrongDialog,
            filterValues,
            setFilterValues,
          }}
        >
          {displayNavbar ? (
            <Navbar
              isSubMenu={isSubNavbar}
              headerRef={headerRef}
              navRef={navRef}
            />
          ) : null}
        </refContext.Provider>
      </div>
      <div className="bg-primaryBackground grow content overflow-x-auto">
        <refContext.Provider
          value={{
            headerRef,
            navRef,
            handleOpenSomethingWentWrongDialog,
            filterValues,
            setFilterValues,
          }}
        >
          {children}
        </refContext.Provider>
        {/*{cloneElement(children, {  headerRef, navRef })}*/}
      </div>
      <div>{displayFooter ? <Footer /> : null}</div>
      <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
        <DialogContent className="text-center">
          <Typography className="py-2" variant="h2">
            OOPS!
          </Typography>
          <Typography className="py-2">Something went wrong</Typography>
          <img
            className="w-[58.5px] h-[58.5px]"
            src="/assets/icons/Oops.svg"
            alt=""
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Layout;

export const useRefContext = () => useContext(refContext);
