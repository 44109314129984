import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { axios } from 'utils';
import { FinancingQueryKey } from './financingQuery';
import { QueryKey } from 'query/queryKeys';

const GetListByStockNumber = (id) => {
  const method = 'GET';
  const url = `listing/${id}`;
  return axios({ method, url });
};

export const useGetListByStockNumberQuery = (data, state) => {

  return useQuery(
    [FinancingQueryKey.GET_LIST_BY_STOCK, data?.id],
    () => GetListByStockNumber(data?.id),
    {
     enabled: state,
   
    }
  );
};

const FetchFinancialgListing = (data) => {
  const method = 'GET';
  const url = `user-finance/?equipment_type=${data}&per_page=100&page=1`;
  return axios({
    method,
    url,
    data,
  });
};

export const useGetAllFinancialListQuery = (payload) => {
  return useQuery(
    [FinancingQueryKey.FINANCIAL_LIST, payload],
    () => FetchFinancialgListing(payload),
    {
      enabled: Boolean(payload),
    }
  );
};

const validFinanceCredentials = (data) => {
  const method = 'POST';
  const url = `finance/`;
  return axios({ method, url, data });
};

export const useFinanceQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(validFinanceCredentials, {
    onSuccess: (response) => {
      queryClient.invalidateQueries([FinancingQueryKey.FINANCIAL_LIST]);
      queryClient.invalidateQueries([QueryKey.GET_USER_PROFILE]);
      
    },
    onError: (error) => {
      // console.log('primary error is :', error)
    },
  });
};

const validDeleteAttachmentCredentials = (id) => {
  const method = 'DELETE';
  const url = `upload-attachment/${id}/`;
  return axios({ method, url });
};

export const useDeleteAttachmentCredentials = () => {
  return useMutation(validDeleteAttachmentCredentials);
};

const FinanceListDelete = (payload) => {
  const method = 'DELETE';
  let url = `/finance/${payload}/`;
  return axios({ method, url });
};

export const FinanceListDeleteAPI = () => {
  const queryClient = useQueryClient();
  return useMutation(FinanceListDelete, {
    onSuccess: (response) => {
      queryClient.invalidateQueries([FinancingQueryKey.FINANCIAL_LIST]);
      queryClient.invalidateQueries([QueryKey.GET_USER_PROFILE]);
    },
    onError: (error) => {
      // console.log('primary error is :', error)
    },
  });
};
