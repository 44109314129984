import { Grid, Typography } from '@mui/material'
import CustomButton from 'components/CustomButton/CustomButton'
import React from 'react'
import { useNavigate } from 'react-router'

export const SuccessfullMessage = ({ setforgotPassword}) => {
    const navigate= useNavigate()
    const handleBacktoLogin=()=>{
        setforgotPassword(false)
        navigate("/login")
    }
  return (
   <>
    <Grid className='text-center m-auto' md={12} xs={12} lg={12} item>
                <img className='w-[56px] h-[56px] text-center' src='/assets/Auth/Done.svg' alt="icon" />
            </Grid>
            <Grid className='!max-w-[316px]' item> 
             <Typography variant="h4" className="!font-medium !font-[Inter]">Password Reset</Typography>
            </Grid>
            <Grid  item> 
             <Typography  className=" text-gray-60 p-2">Your password has been reset successfully.</Typography>
            </Grid>
            <Grid justifyContent='center' alignItems='center' spacing={2} className='py-6' container item>
                
                <Grid  className='w-[100%]' lg={12} item>
                    <CustomButton styles='max-w-[400px]' onClick={handleBacktoLogin} >Log In</CustomButton>
                </Grid>
              
            </Grid>
            </>
  )
}
