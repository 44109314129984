import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import { Grid, Box, Typography, Snackbar } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import ReactSwitch from 'react-switch';
import '../SMRR.css';

import { useUpdateNotification } from '../../../query/settings';

const ToggleButtonComponent = (props) => {
  const [checked, setChecked] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(null);
  const [toggleButton, setToggleButton] = useState('');
  const switchcolor = '#0F5E91';
 

  useEffect(() => {
    setToggleButton(props?.status);
    if (props?.status == 'ENABLE') {
      setChecked(true);
    } else if (props?.status == 'DISABLE') {
      setChecked(false);
    }
  }, [props?.status]);

  const handleChange = (val) => {
   
    if (val) {
      setChecked(true);
      setToggleButton('ENABLE');

      updateNotifications('ENABLE', {
        onSuccess: (res) => {
          setSnackbarOpen(true);
        },
        onError: (err) => {
          // Handle error
        },
      });
    } else {
      setChecked(false);
      setToggleButton('DISABLE');

      updateNotifications('DISABLE', {
        onSuccess: (res) => {
          setSnackbarOpen(true);
        },
        onError: (err) => {
          // Handle error
        },
      });
    }
  };

  const { mutate: updateNotifications } = useUpdateNotification(toggleButton);

  return (
    <Grid style={{ textAlign: 'center' }}>
      <ReactSwitch
        checked={checked}
        onChange={handleChange}
        uncheckedIcon={false}
        checkedIcon={false}
        onColor="#FFC200"
        height={23}
        width={45}
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'top', // 'top', 'bottom'
          horizontal: 'right', // 'left', 'center', 'right'
          // top: '200px',
        }}
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={() => setSnackbarOpen(false)}
        message={
          toggleButton === 'ENABLE'
            ? 'Notifications has been enabled'
            : toggleButton === 'DISABLE'
            ? 'Notifications has been disabled'
            : ''
        }
      />
    </Grid>
  );
};

export default ToggleButtonComponent;
