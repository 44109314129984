import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { QueryKey } from './queryKeys';

const { axios } = require('utils');

const validAddPaymentCardCredentials = (data) => {
  const method = 'POST';
  const url = `payment-methods/`;
  return axios({ method, url, data });
};
export const useAddCardQuery = () => {
  return useMutation(validAddPaymentCardCredentials);
};

const validCreateCustomerAccountCredentials = () => {
  const method = 'POST';
  const url = `customer-account/`;
  return axios({ method, url });
};
export const useCreateCustomerAccountQuery = (data) => {
  return useMutation(validCreateCustomerAccountCredentials);
};

const validSetUpIntentCredentials = (data) => {
  const method = 'POST';
  const url = `setup-intent/`;
  return axios({ method, url, data });
};
export const useSetUpIntentQuery = () => {
  return useMutation(validSetUpIntentCredentials);
};

const validGetPaymentCardCredentials = () => {
  const method = 'GET';
  const url = `payment-methods/`;
  return axios({ method, url });
};
export const useGetCardQuery = () => {
  return useQuery(
    [QueryKey.GET_PAYMENT_CARD_DATA],
    () => validGetPaymentCardCredentials(),
    {
      enabled: true,
    }
  );
};
const validPaymentIntentCredentials = (data) => {
  const method = 'POST';
  const url = `payment-intent/`;
  return axios({ method, url, data });
};
export const usePaymentIntentQuery = () => {
  return useMutation(validPaymentIntentCredentials);
};

const validDeleteCardCredentials = (data) => {
  const method = 'DELETE';
  const url = `payment-methods/${data}/`;
  return axios({ method, url });
};
export const useDeleteCardQuery = () => {
  return useMutation(validDeleteCardCredentials);
};

const validEditCardCredentials = (data) => {
  const method = 'PATCH';
  const url = `payment-methods/${data.id}/`;
  return axios({ method, url, data: { is_primary: data.data } });
};
export const useEditCardQuery = () => {
  return useMutation(validEditCardCredentials);
};

const getPaymentTanCPP = () => {
  const method = 'GET';
  const url = `auth/terms-conditions/`;
  return axios({ method, url });
};
export const usePaymentTanCPPQuery = () => {
 
  return useQuery(
    [QueryKey.GET_PRIVACYPOLICY_DATA],
    () => getPaymentTanCPP(),
    {
      enabled: true,
    }
  );
};
