import { useQuery } from '@tanstack/react-query';
import { axios } from 'utils';
import { QueryKey } from './queryKeys';

const GetMilelageCredentials = (data) => {
  const method = 'GET';
  const url = `mileage/`;
  return axios({ method, url, data });
};

const GetMakeCredentials = (data) => {
  const method = 'GET';
  const url = `vehicle-brands/`;
  return axios({ method, url, data });
};

const GetSearchData = (payload, isPage) => {
  const method = 'GET';
  const url = 'algolia-search/';
  let config = { method, url };
  if (payload) {
    config = {
      ...config,
      params: {
        q: payload,
        page: isPage,
        per_page: 100,
      },
    };
  }

  return axios(config);
};

const GetTopSearchData = () => {
  const method = 'GET';
  const url = 'topsearch/';
  let config = { method, url };

  return axios(config);
};

const GetNewlyAddedInventoryCredentials = () => {
  const method = 'GET';
  const url = 'listing/last-ten/';

  let config = { method, url };

  return axios(config);
};

export const useNewlyAddedInventoryQuery = (data) => {
  return useQuery(
    [QueryKey.GET_NEWLY_ADDED, data],
    () => GetNewlyAddedInventoryCredentials(data),
    {
      enabled: true,
    }
  );
};

const GetPopularBrands = () => {
  const method = 'GET';
  const url = 'feature-categories/';

  return axios({ method, url });
};

const GetInventoryData = () => {
  const method = 'GET';
  const url = 'feature-listing/';
  return axios({ method, url });
};

export const usePopularBrands = (payload) => {
  return useQuery([QueryKey.GET_POPULAR_BRANDS], GetPopularBrands, {
    enabled: true,
  });
};

export const useInventoryData = (payload) => {
  return useQuery([QueryKey.GET_INVENTORY_DATA], GetInventoryData, {
    enabled: true,
  });
};
export const useSearchQuery = (data, isPage) => {
  return useQuery(
    ['GET_SEARCH_DATA', data, isPage],
    () => GetSearchData(data, isPage),
    {
      enabled: Boolean(data),
    }
  );
};

export const useTopSearchQuery = () => {
  return useQuery(['GET_TOP_SEARCH_DATA'], () => GetTopSearchData(), {
    enabled: true,
  });
};

export const useGetMileage = () => {
  return useQuery(['GET_MIlEAGE'], () => GetMilelageCredentials(), {
    enabled: true,
  });
};
export const useGetMake = () => {
  return useQuery(['GET_MAKE'], () => GetMakeCredentials(), {
    enabled: true,
  });
};
const GetAddressCredentials = (payload) => {
  const method = 'GET';
  const url = `get-location-information/?Latitude=${payload.latitude?.toString()}&Longitude=${payload.longitude?.toString()}`;
  return axios({ method, url });
};
export const useGetLocationAddress = (data) => {
  return useQuery(
    [QueryKey.GET_LOCATION_ADDRESS, data.longitude],
    () => GetAddressCredentials(data),
    {
      enabled: Boolean(data.latitude && data.longitude),
    }
  );
};
