import React, { useMemo, useState ,useEffect} from "react";
import { Grid,Typography, Box, Skeleton} from "@mui/material";
import EquipmentCard from '../EquipmentInfo/EquipmentInfo';
import { useGetAllQuoteData } from 'query/quote';
import NoDataFoundIcon  from 'components/NoDataFoundIcon';

const SentQuoteTab = (props) => {
    const [isPage,setIsPage]= useState(1)    
    const {data:InventoryDataResponse, refetch, isLoading}= useGetAllQuoteData(props.userId,isPage);
    const mydata = useMemo(() => InventoryDataResponse?.results || [], [InventoryDataResponse]);   
    
    useEffect(()=>{
      refetch(isPage);
    },[refetch]);

    const refetchQuote = () => {
      refetch();
    };
    
    return(
      <Box >
        {isLoading ? (
          <Grid container lg={11}>
            <Grid item lg={12} md={12} xs={12} > <Skeleton height='266px' width='100%' /> </Grid>
            <Grid item lg={12} md={12} xs={12} > <Skeleton height='266px'  width='100%' /> </Grid>
            <Grid item lg={12} md={12} xs={12} > <Skeleton height='266px' width='100%' /> </Grid>
            <Grid item lg={12} md={12} xs={12} > <Skeleton height='266px'  width='100%' /> </Grid>
          </Grid>
          ) : (
            <>
            { mydata?.length > 0 ? (
                <>
                { mydata?.filter((el) => el.status !== 'QUOTE_REMOVED').map((qouteData, index) => {
                  return  ( 
                      <Grid
                        container
                        lg={11} md={12} sm={12} xs={12} marginBottom={3}
                        className="!shadow-sm"
                        style={{ marginTop:'10px', paddingLeft:'10px'}}
                        key={index}
                      >
                        <EquipmentCard
                          id={qouteData?.id}
                          listing_id = {qouteData?.listing?.id}
                          unique_id={qouteData?.listing?.unique_id}
                          finance_id={qouteData?.finance_id}
                          description={qouteData?.description}
                          validValue={qouteData?.quote_price}
                          cancelValue={qouteData?.listing?.selling_price ? qouteData?.listing?.selling_price :'NA'}
                          loaction= {qouteData?.loaction ? qouteData?.loaction :''}
                          firstBtnVal={qouteData?.status}
                          country={qouteData?.listing?.country ? qouteData?.listing?.country :''}
                          state={qouteData?.listing?.state ? qouteData?.listing?.state :''}
                          city={qouteData?.listing?.city ? qouteData?.listing?.city :''}
                          zipcode={qouteData?.listing?.zip_code ? qouteData?.listing?.zip_code : ''}                     
                          isQuoteTab={true}
                          price ={qouteData?.quote_price}
                          title={qouteData?.listing?.title}
                          attachments={qouteData?.listing?.attachments}
                          refetchQuote={refetchQuote}
                        />                   
                      </Grid>
                  )
                  })
                }
                </>
              ):(
                <Grid container lg={11} md={12} sm={12}>
                  <Box
                    sx={{
                    margin: '0 auto',
                    display: 'table',
                    width: '100%',
                    paddingTop: '10px',
                    justifyContent: 'center',
                    }}
                  >
                    <Box className="text-center ">
                      <Typography
                          variant="h4"
                          className="text-secondary-50 py-4 !font-bold !font-[Inter]"
                      >
                          No result found
                      </Typography>
                      <NoDataFoundIcon />
                    </Box>
                  </Box>
                </Grid>
              )
            }
            </>
          )          
        }                 
      </Box>
    )
}
export default SentQuoteTab;