import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Grid,
  Modal,
  Box,
  Button,
  Link,
  Typography,
  useMediaQuery,
  CircularProgress,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CustomTextField } from '../../../../components';
import { useRefContext } from '../../../../components/Layout';

import { useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGetListByStockNumberQuery } from 'query/Financing/financing';
import { routes } from 'routes/RouteConstants';
import { useNavigate } from 'react-router';

const stockValidator = yup.object().shape({
  stock_number: yup.string().required('Stock number is required'),
});

const VehicleID = (props) => {
  const { activeStep, setActiveStep, data, setData, inputData, setInputData } =
    props;

  const theme = useTheme();
  const isXLarge = useMediaQuery(theme.breakpoints.down('xl'));
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const { headerRef, navRef } = useRefContext();
  const vinInputRef = useRef('vinInputRef');
  // States
  const [stockId, setStockId] = useState(null);
  const [shouldCalled, setShouldCalled] = useState(false);
  const {
    data: getStockListing,
    isFetched,
    isError,
    isFetching,
    isLoading,
    error,
  } = useGetListByStockNumberQuery({ id: stockId}, shouldCalled);
  // const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    setError: setstockError,
  } = useForm({
    resolver: yupResolver(stockValidator),
    defaultValues: {
      stock_number: inputData.stock_number || data?.stock_number,
    },
  });
  const navigate = useNavigate();
  // Methods:
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const addStockNumber = (formValues) => {
    setShouldCalled(true);
    // setIsLoading(true);
    setInputData({ ...inputData, stock_number: formValues.stock_number });
    setStockId(formValues.stock_number);
  };
  // console.log('inputData', inputData
  const stock_number =
    useWatch({ control, name: 'stock_number' }) ||
    inputData?.stock_number ||
    data?.stock_number;

  useEffect(() => {
    if (isFetched == true || isError == true) {
      // setIsLoading(false);
    }
  }, [isFetched, isError]);

  useEffect(() => {
    if (getStockListing?.data?.id) {
      setData({
        ...data,
        listing: getStockListing?.data?.id,
        category: getStockListing?.data?.sub_category.category,
        sub_category: getStockListing?.data?.sub_category.id,
      });
      setActiveStep(activeStep + 1);
    }
  }, [getStockListing?.data?.id,shouldCalled]);

  useEffect(() => {
    if (error?.message) {
      setstockError('stock_number', {
        type: 'custom',
        message: error?.message,
      });
    }
  }, [error?.message]);

  useEffect(() => {
    setShouldCalled(!setShouldCalled);
  }, [isFetching]);

  const handleContactUs = () => {
    navigate(routes.Help_Center.pathname);
  };
  const ContactUsIcon = () => {
    return (
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.88028 7.85335C7.57627 9.30297 8.52506 10.6616 9.72664 11.8632C10.9282 13.0648 12.2869 14.0136 13.7365 14.7096C13.8612 14.7694 13.9235 14.7994 14.0024 14.8224C14.2828 14.9041 14.627 14.8454 14.8644 14.6754C14.9313 14.6275 14.9884 14.5704 15.1027 14.4561C15.4523 14.1064 15.6271 13.9316 15.8029 13.8174C16.4658 13.3864 17.3204 13.3864 17.9833 13.8174C18.1591 13.9316 18.3339 14.1064 18.6835 14.4561L18.8783 14.6509C19.4098 15.1824 19.6755 15.4481 19.8198 15.7335C20.1069 16.301 20.1069 16.9713 19.8198 17.5389C19.6755 17.8242 19.4098 18.09 18.8783 18.6214L18.7207 18.779C18.1911 19.3087 17.9263 19.5735 17.5662 19.7757C17.1667 20.0001 16.5462 20.1615 16.088 20.1601C15.6751 20.1589 15.3928 20.0788 14.8284 19.9186C11.795 19.0576 8.93264 17.4332 6.54466 15.0452C4.15668 12.6572 2.53221 9.79483 1.67124 6.76144C1.51103 6.19699 1.43092 5.91477 1.4297 5.50182C1.42833 5.0436 1.58969 4.42311 1.81411 4.0236C2.01636 3.66357 2.28117 3.39876 2.8108 2.86913L2.96843 2.7115C3.49987 2.18006 3.7656 1.91433 4.05098 1.76999C4.61854 1.48292 5.2888 1.48292 5.85636 1.76999C6.14174 1.91433 6.40747 2.18006 6.93891 2.7115L7.13378 2.90637C7.48338 3.25597 7.65819 3.43078 7.77247 3.60655C8.20347 4.26945 8.20347 5.12403 7.77247 5.78692C7.65819 5.96269 7.48338 6.1375 7.13378 6.4871C7.01947 6.60142 6.96231 6.65857 6.91447 6.72538C6.74446 6.96281 6.68576 7.30707 6.76748 7.58743C6.79048 7.66632 6.82041 7.72866 6.88028 7.85335Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  };
  const handleChange = (val) => {
    setValue('stock_number', val?.toUpperCase(), {
      shouldValidate: true,
    });
    setInputData({ ...inputData, stock_number: val });
    setData({ ...data, stock_number: val?.toUpperCase() });
  };
  return (
    /*<div>*/

    <div
      className="ag-grid"
      style={{
        height: 'calc(100vh - ' + headerRef?.current?.clientHeight + 'px)',
      }}
    >
      <header className="content-header">
        <div className="action-bar flex justify-between p-2 relative">
          <div className=" !w-full">
            <Button
              href='/accounts/help-center'
              size='small'
              style={{width: '160px', minHeight: '48px', padding: '0px', borderRadius: `22px`, zIndex:'10000'}}
              className="cursor-pointer absolute top-0 right-0 z-1000 !text-right mt-[30px] !mr-[30px] contact-us-btn full-rounded-btn !text-white hover:!text-white !bg-[#0F5E91] hover:!bg-[#0F5E91] !border-none"
              variant='contained'
              startIcon={<ContactUsIcon />}
            >
              Contact US
            </Button>
          </div>
          <div className="actions"></div>
        </div>
      </header>
      <form onSubmit={handleSubmit(addStockNumber)}>
        <div className="main relative h-full">
          <div className="stepper-content-header py-2">
            <h3>Stock Number</h3>
          </div>
          <div className="stepper-content">
            <CustomTextField
              className="max-w-[400px] uppercase"
              type="text"
              ref={vinInputRef}
              placeholder="XXXXXX"
              // {...register('stock_number')}
              error={Boolean(errors.stock_number)}
              helperText={errors.stock_number && errors.stock_number.message}
              FormHelperTextProps={{
                className: 'animateErrorValue',
              }}
              value={stock_number}
              onChange={(e) => {
                handleChange(e.target.value);
              }}
            />

            <div className="note-container my-4">
              <h4 className="note-label text-[14px] mb-1">Note</h4>
              <Typography className="text-[12px] text-[#757575]">
                Please enter the stock number from the listing.
              </Typography>
            </div>
          </div>

          <footer className="content-footer mb-[200px] absolute bottom-5 right-5">
            <div className="stepper-content-actions-footer flex justify-between">
              <div></div>
              <div>
                {isFetching === true ? (
                  <Button
                    style={{
                      width: '275px',
                      minHeight: '44px',
                      height: '44px',
                    }}
                    variant="contained"
                  >
                    <CircularProgress size={30} className="text-white" />
                  </Button>
                ) : (
                  <Button
                    style={{
                      width: '275px',
                      minHeight: '44px',
                      height: '44px',
                    }}
                    type="submit"
                    variant="contained"
                  >
                    Continue
                  </Button>
                )}
              </div>
            </div>
          </footer>
        </div>
      </form>
    </div>
  );
};

export { VehicleID };
