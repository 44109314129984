import React, { useState } from "react";
import { Grid, Typography, Box } from '@mui/material';
import './SMRR.css';

const typoStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItem: 'center'
}
const TableGrid = (props) => {    
    //console.log('vehical info', props?.vehicalInfo?.data);
    const vehicalInfoData = props?.vehicalInfo?.data?.vehicle;
    const [seemore, setSeemore] = useState(false);
    const handleClickMore = () => {      
        setSeemore(true);
    }
    const handleClickLess = () => {    
        setSeemore(false);
    }

    return (
        <Box style={{padding:'1px'}} className="tablemb">
            <Grid container className="EquipmentInfo-grid " lg={12} md={12} sm={12} style={{borderRadius:'15px'}} >
                {props?.vehicalInfo?.data?.category?.id ===8 ? (
                    <>
                    <Grid item lg={12} md={12} sm={6}>
                        <TableGridChild 
                            input_1='Engine manufacturer' 
                            input_2={vehicalInfoData?.engine_manufacturer ? vehicalInfoData.engine_manufacturer : 'NA'} 
                            input_3='Transmission manufacturer' 
                            input_4={vehicalInfoData?.transmission_manufacturer ? vehicalInfoData.transmission_manufacturer : 'NA'}
                            input_5='Gear Type' 
                            input_6={vehicalInfoData?.gear_type ? vehicalInfoData.gear_type : 'NA'} />
                    </Grid>
                    <Grid item lg={12} md={12} sm={6}>
                        <TableGridChild 
                            input_1='Mileage' 
                            input_2={vehicalInfoData?.mileage ? vehicalInfoData.mileage : 'NA'} input_3='APU' 
                            input_4={vehicalInfoData?.apu ? vehicalInfoData.apu : 'NA'}
                            input_5='Horse Power' 
                            input_6={vehicalInfoData?.horsepower ? vehicalInfoData.horsepower : 'NA'} />
                    </Grid>
                    <Grid item lg={12} md={12} sm={6}>
                        <TableGridChild 
                            input_1='Fuel type' 
                            input_2={vehicalInfoData?.type_of_fuel ? vehicalInfoData.type_of_fuel : 'NA'} 
                            input_3='Gear ratio' input_4={vehicalInfoData?.gear_ratio ? vehicalInfoData.gear_ratio : 'NA'}
                            input_5='GVWR' 
                            input_6={vehicalInfoData?.gross_vehicle_weight_rating ? vehicalInfoData.gross_vehicle_weight_rating : 'NA'} />
                    </Grid>
                    {seemore ? (<>
                        <Grid item lg={12} md={12} sm={6}>
                            <TableGridChild 
                                input_1='Axel' 
                                input_2={vehicalInfoData?.axle_ratio ? vehicalInfoData.axle_ratio : 'NA'} 
                                input_3='Wheel Base' 
                                input_4={vehicalInfoData?.wheel_base ? vehicalInfoData.wheel_base : 'NA'}
                                input_5='Bed Count' 
                                input_6={vehicalInfoData?.bed_count ? vehicalInfoData.bed_count : 'NA'} />
                        </Grid><Grid item lg={12} md={12} sm={6}>
                            <TableGridChild 
                                input_1='Bed Size' 
                                input_2={vehicalInfoData?.bed_size ? vehicalInfoData.bed_size : 'NA'} 
                                input_3='Tire Tread' 
                                input_4={vehicalInfoData?.tire_treads ? vehicalInfoData.tire_treads : 'NA'}
                                input_5='Break Type' 
                                input_6={vehicalInfoData?.brake_type ? vehicalInfoData.brake_type : 'NA'} 
                            />
                        </Grid><Grid item lg={12} md={12} sm={6}>
                            <TableGridChild 
                                input_1='Inverter' 
                                input_2={(typeof vehicalInfoData?.inverter !== "undefined")? (vehicalInfoData?.inverter ? 'Yes' : 'No'):'NA'} 
                                input_3='Collision Mitigation System' 
                                input_4={vehicalInfoData?.collision ? vehicalInfoData.collision : 'NA'}
                            />
                        </Grid></>) : ''} 

                    </>
                ):(
                    <>
                    <Grid item lg={12} md={12} sm={6}>
                        <TableGridChild 
                            input_1='Air Ride' 
                            input_2={vehicalInfoData?.air_ride ? vehicalInfoData.air_ride : 'NA'} 
                            input_3='Scale' 
                            input_4={vehicalInfoData?.scale ? vehicalInfoData.scale : 'NA'}
                            input_5='Side Skirt' 
                            input_6={vehicalInfoData?.side_skirt ? vehicalInfoData.side_skirt : 'NA'} />
                    </Grid>
                    <Grid item lg={12} md={12} sm={6}>
                        <TableGridChild 
                            input_1='GVWR' 
                            input_2={vehicalInfoData?.gross_vehicle_weight_rating ? vehicalInfoData.gross_vehicle_weight_rating : 'NA'} 
                            input_3='Hour' 
                            input_4={vehicalInfoData?.hour ? vehicalInfoData.hour : 'NA'}
                            input_5='Trailer Length' 
                            input_6={vehicalInfoData?.trailer_length ? vehicalInfoData.trailer_length : 'NA'} />
                    </Grid>
                    <Grid item lg={12} md={12} sm={6}>
                        <TableGridChild 
                            input_1='Trailer Usage' 
                            input_2={vehicalInfoData?.trailer_usage ? vehicalInfoData.trailer_usage : 'NA'} 
                            input_3='Rim Type' 
                            input_4={vehicalInfoData?.rim_type ? vehicalInfoData.rim_type : 'NA'}
                            input_5='E Tracks' 
                            input_6={vehicalInfoData?.e_tracks ? vehicalInfoData.e_tracks : 'NA'} />
                    </Grid>
                    {seemore ? (<>
                        <Grid item lg={12} md={12} sm={6}>
                            <TableGridChild 
                                input_1='Rub Rails' 
                                input_2={vehicalInfoData?.rub_rails ? vehicalInfoData.rub_rails : 'NA'} 
                                input_3='Tire Rack' 
                                input_4={vehicalInfoData?.tire_rack ? vehicalInfoData.tire_rack : 'NA'}
                            />
                        </Grid>
                        </>) : ''} 
                    </>
                )}
                              


                <Grid item className="tableGrid-child-text border-style box" style={{ marginLeft: '40px'}} >
                    {seemore ? <Typography style={{ cursor: 'pointer', color: '#0F5E91' }} onClick={() => handleClickLess()}> See Less</Typography>

                        :
                        <Typography style={{ cursor: 'pointer', color: '#0F5E91' }} onClick={() => handleClickMore()}> See More</Typography>

                    }
                </Grid>

            </Grid>
        </Box>
    )
}

const TableGridChild = (props) => {

    return (
        <>
            <Grid container style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '15px' }}>
                <Grid item container lg={12} md={12} sm={12} style={{ backgroundColor: '#F5F5F5', borderRadius: '10px' }}>
                    <Grid item className="tableGrid-child" lg={4} md={6} sm={12}>
                        <Typography style={{ color: '#757575', fontSize: '14px',fontWeight:'400',fontFamily:'Inter' }}>{props.input_1}</Typography>
                        <Typography style={{ color: '#333333', fontSize: '14px',fontWeight:'500',fontFamily:'Inter' }}>{props.input_2}</Typography>
                    </Grid>
                    <Grid item className="tableGrid-child" lg={4} md={6} sm={12}>
                        <Typography item style={{ color: '#757575', fontSize: '14px' ,fontWeight:'400',fontFamily:'Inter'}} >{props.input_3}</Typography>
                        <Typography item style={{ color: '#333333', fontSize: '14px',fontWeight:'500',fontFamily:'Inter'}}>{props.input_4}</Typography>
                    </Grid>
                    <Grid item className="tableGrid-child" lg={4} md={6} sm={12}>
                        <Typography item style={{ color: '#757575', fontSize: '14px',fontWeight:'400',fontFamily:'Inter'}}>{props.input_5}</Typography>
                        <Typography item style={{ color: '#333333', fontSize: '14px',fontWeight:'500',fontFamily:'Inter'}}>{props.input_6}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
export default TableGrid;