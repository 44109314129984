import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { Grid, Box, Typography, useMediaQuery, useTheme, Modal, Divider } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import HeaderImg from './HeaderImg';
import Content from './Content';
import AboutImg from './AboutImg';

const AboutUs = () => {
    

    return(

        <Box>
           <Grid container lg={12} md={12} sm={12} xs={12} className='!h-[100%] '>
           <Grid item lg={12} md={12} sm={12} xs={12}>
                 <AboutImg />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                 <HeaderImg />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                 <Content />
              </Grid> 
           </Grid>
        </Box>
    )
} 

export default AboutUs;  