import React, { useState } from 'react';
import { Grid, Typography, useMediaQuery ,Popover, Slider} from '@mui/material';

import { useTheme } from '@mui/material/styles';

import { CustomMenuWithSearch } from 'components/CustomMenuWithSearch/CustomMenuWithSearch';
import {  } from '@material-ui/core';

export const Years = ({ filterValues, setFilterValues }) => {
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openYears = Boolean(anchorEl);
  const id = openYears ? 'simple-popover' : undefined;

  function generateArrayOfYears() {
    var max = new Date().getFullYear();
    var min = max - 20;
    var years = [];

    for (var i = max; i >= min; i--) {
      years.push({ label: i.toString(), value: i });
    }
    return years;
  }
  const years = generateArrayOfYears();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid item container lg={2} md={3} sm={12}>
      <Grid
        
        justifyContent="center"
        onClick={handleClick}
        className={`${
          isSmall || isXSmall
            ? 'flex justify-around !align-middle'
            : 'grid justify-start'
        }  p-2 cursor-pointer relative  min-w-[165px] homeAln`}
        item
      >
        <Typography className={`cursor-pointer `}>Year</Typography>
        {filterValues.some((el) => el.filter == 'Year') && (
          <Typography className="text-left animateFilterValue">
            {filterValues.find((el) => el.filter == 'Year')?.label}
          </Typography>
        )}
      </Grid>

      <Popover
        className="min-h[228px] !mt-5 "
        id={id}
        open={openYears}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <CustomMenuWithSearch
          handleClosePopUp={handleClose}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
          isSearch={true}
          type="Year"
          options={years ? years : []}
        />{' '}
      </Popover>
    </Grid>
  );
};
