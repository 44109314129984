import {
  Autocomplete,
  TextField,
  useMediaQuery,
  useTheme,
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  Typography,
} from '@mui/material';
import { useRefContext } from 'components/Layout';
import { nanoid } from 'nanoid';
import { useSearchQuery, useTopSearchQuery } from 'query/Home';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { routes } from 'routes/RouteConstants';
import { debounce } from 'utils/debounce';
const searchIcon = () => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.918 16.6102L12.293 12.9852M14.2513 8.27683C14.2513 11.9587 11.2665 14.9435 7.58464 14.9435C3.90274 14.9435 0.917969 11.9587 0.917969 8.27683C0.917969 4.59494 3.90274 1.61017 7.58464 1.61017C11.2665 1.61017 14.2513 4.59494 14.2513 8.27683Z"
        stroke="#757575"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

// const GroupHeader = styled('div')(({ theme }) => ({
//   position: 'sticky',
//   top: '-8px',
//   padding: '4px 10px',
//   color: theme.palette.primary.main,
//   backgroundColor:
//     theme.palette.mode === 'light'
//       ? lighten(theme.palette.primary.light, 0.85)
//       : darken(theme.palette.primary.main, 0.8),
// }));

// const GroupItems = styled('ul')({
//   padding: 0,
// });

export const Searchbar = (props) => {
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const { filterValues, setFilterValues } = useRefContext();
  const [search, setSearch] = useState('');
  const [query, setQuery] = useState('');
  const [isPage, setIsPage] = useState(1);
  const { data: searchData, isFetching } = useSearchQuery(query, isPage);
  const { data: topSearchData } = useTopSearchQuery();
  const pagination = searchData?.pagination_option;
  const handleLoadMore = () => {
    if (isPage < pagination.pages) {
      setIsPage((prev) => prev + 1);
    }
  };
  const location = useLocation();
  const [selected, setSelected] = useState();
  const navigate = useNavigate();
  const api = (val) => {
    setQuery(val);
  };

  const func = useCallback(
    debounce((string) => api(string), 800),
    []
  );

  useEffect(() => {
    search && func(search);
  }, [search]);

  const changeHandler = (e) => {
    const { value } = e.target;
    setSearch(value);
  };

  const [open, setOpen] = React.useState(false);

  const handleSelect = (e, value, reason) => {
    if (value) {
      setSelected(value);
      if (filterValues.some((item) => item.filter === 'search')) {
        const removed = filterValues.filter((el) => el.filter !== 'search');

        setFilterValues([
          ...removed,
          { key: nanoid(), label: value?.value, filter: 'search' },
        ]);
      } else {
        setFilterValues([
          ...filterValues,
          { filter: 'search', label: value?.value, key: nanoid() },
        ]);
      }
      if (location?.pathname != routes.INVENTORY_LISTING.pathname) {
        navigate(routes.INVENTORY_LISTING.pathname);
      }
    }
  };

  const searchOptions = useMemo(() => {
    // searchData.heading  = {query:'Popular search'} ;
    const data =
      searchData?.results?.length > 0
        ? searchData.results
        : topSearchData?.results || [];

    return data.map((el) => ({
      title: el.query,
      value: el.query,
      icon: data === topSearchData.results,
    }));
  }, [searchData?.results, topSearchData?.results]);


  return (
    <Box
      className={`sticky  md:pr-0 ${isLarge ? 'px-2' : 'px-4'} `}
      alignItems="center"
    >
      <Grid
        item
        className={`rounded-lg min-w-[310px] shadow-md relative `}
        style={{ backgroundColor: '#FFFFFF' }}
      >
        <Autocomplete
          sx={{
            '&.Mui-focused  .MuiOutlinedInput-notchedOutline': {
              border: 'none !important',
              borderRadius: '8px',
              padding: '0px',
            },
          }}
          className="!rounded-lg"
          // renderGroup={(params) => (
          //   <div>
          //     <Typography variant="h6">Heading</Typography>
          //     {params.children}
          //   </div>
          // )}
          ListboxProps={{
            onScroll: (event) => {
              const listboxNode = event.currentTarget;

              if (
                Math.ceil(listboxNode.scrollTop) + listboxNode.clientHeight ===
                listboxNode.scrollHeight
              ) {
                handleLoadMore();
              }
            },
            style: {
              height: '200px',
              overflow: 'auto !important',
            },
          }}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          isOptionEqualToValue={(option, value) =>
            option?.title === value?.title
          }
          groupBy={(option) => 'Popular Search'}
          options={searchOptions}
          getOptionLabel={(option) => option.title}
          renderGroup={(params) => (
            <div>
              <Typography
                style={{
                  fontSize: '14px',
                  padding: '8px',
                  fontWeight: 'bold',
                }}
              >
                Popular Search
              </Typography>
              {params.children}
            </div>
          )}
          renderOption={(props, option) => {
            return (
              // <>
              <Box {...props}>
                <Grid container>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{
                      height: '40px',
                      borderRadius: '8px',
                      border: '2px solid #F5F5F5',
                    }}
                  >
                    <Typography style={{ padding: '8px' }}>
                      {option.title}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              // </>
            );
          }}
          loading={isFetching}
          onChange={handleSelect}
          renderInput={(params) => (
            <TextField
              className={'topSearch'}
              {...params}
              // label='Popular Serach'
              onChange={changeHandler}
              placeholder={
                props.placeholder
                  ? props.placeholder
                  : 'Search Make, Model or Keyword'
              }
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isFetching ? (
                      <CircularProgress color="inherit" size={30} />
                    ) : null}
                  </>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    {' '}
                    {searchIcon()}{' '}
                  </InputAdornment>
                ),
              }}
              sx={{
                fontFamily: 'Inter',
                '& input::placeholder': {
                  color: '#757575',
                  fontSize: '14px',
                  fontWeight: '400',
                },
                border: 'none',
                fontSize: '15px',
                padding: '0px',
              }}
            />
          )}
        />
      </Grid>
    </Box>
  );
};
