import React from 'react'
import { Grid, Typography } from '@material-ui/core';
import { Button } from '@mui/material';


const JoinOurCommunity = () => {
  return (
    <Grid container lg={11} md={11} sm={11} xs={11}> 
        <Grid container lg={5} md={5} sm={5} xs={12} style={{justifyContent:"center", paddingBottom:"26px"}}>
           <Grid item>
             <Typography
             style={{fontFamily: 'Space Grotesk', color:"#0F0F0F", fontSize:"32px",fontWeight:"700"}}
             >Join Our Community</Typography>
             <Typography style={{fontSize:"18px", fontWeight:"400", color:"#545454", paddingTop:"20px"}}
                    >
                    Join a community that values simplicity and reliability. Sell My Rig is more than a marketplace; it's a trusted partner in your equipment transactions
              </Typography>
           </Grid>

        </Grid>
        <Grid container lg={2} md={2} sm={2} xs={12} style={{position:"relative", overflow:"hidden", height:"140px"}} className='JoinLine'>
            <hr style={{width:"100%",height:"2px" ,backgroundColor:"#CCCCCC",position:"absolute",top:"50%", left:"50%",transform:"translate(-50%,-50%) rotate(90deg)",transformOrigin:"50% 50%", border:"transparent"}}></hr>
        </Grid>
         <Grid lg={5} md={5} sm={5} xs={12} className='pt-2'>
         <Typography
             style={{fontFamily: 'Space Grotesk', color:"#0F0F0F", fontSize:"32px",fontWeight:"700"}}
             >Download the App:</Typography>
            <Grid container className='gap-x-3 pt-7'>
            <a href="#">
                  <img src={'/assets/android.png'} alt="logo" />
            </a>
            
            <a href="#">
                  <img src={'/assets/ios.png'} alt="logo" />
            </a>
            </Grid>
        </Grid>
       
    </Grid>
  )
}

export default JoinOurCommunity