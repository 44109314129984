import { useMutation, useQuery } from "@tanstack/react-query"
import { axios } from "utils"
import { QueryKey } from "./queryKeys";

const GetFavouritesCredentials=(isPage)=>{
    const method='GET'
    const url='wishlist/'
 
    return axios({method,url,params:{
        per_page:4,
        page:isPage
    }})
}

export const useGetFavourites=(isPage)=>{
    return useQuery([QueryKey.GET_FAVOURITE_DATA],
    ()=>GetFavouritesCredentials(isPage), {
        enabled: true,
      })

}

const CreateFavouritesCredentials=(payload)=>{
    const method='POST'
    const url='wishlist/'
    return axios({method,url,data:payload})
}

export const useCreateFavourites=()=>{
    return useMutation(CreateFavouritesCredentials,{
        
    })

}


const DeleteFavouritesCredentials=(payload)=>{
    const method='DELETE'
    const url=`wishlist/${payload.id}/`
 
    return axios({method,url})
}

export const useDeleteFavourites=()=>{
    return useMutation(DeleteFavouritesCredentials)

}
