import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { localStorageKeys } from 'hooks';

import { axios } from 'utils';
import { saveData } from 'utils/localStorage';
import { QueryKey } from './queryKeys';

// headers for api requests are here

const AddQuoteCredentials = (data) => {
  const method = 'POST';
  const url = `quotes/`;

  return axios({ method, url, data });
};
export const useAddQuoteQuery = () => {
  return useMutation(AddQuoteCredentials);
};

const GetAllQuoteData = (id, isPage) => {
  const method = 'GET';
  const url = `user/${id}/quote/`;

  return axios({
    method,
    url,
    params: {
      per_page: 50,
      page: isPage,
    },
  });
};

export const useGetAllQuoteData = (id, isPage) => {
  return useQuery(
    [QueryKey.GET_PRODUCT_LIST_DATA],
    () => GetAllQuoteData(id, isPage),
    {
      enabled: true,
    }
  );
};

//delete quote
const validDeletentQuote = (id) => {
  const method = 'DELETE';
  const url = `quotes/${id}/`;
  return axios({ method, url });
};
export const useDeleteQuote = () => {
  return useMutation(validDeletentQuote);
};



