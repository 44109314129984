import {
  Box,
  Grid,
  Skeleton,
  Typography,
  Button,
  CircularProgress,
  Checkbox
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CardBlock } from './CardBlock';
import { useCreateCustomerAccountQuery, useGetCardQuery, usePaymentIntentQuery,usePaymentTanCPPQuery } from 'query/payment';
import { Add } from '@mui/icons-material';
import { CardFormMain } from './CardFormMain';
import { getLocalStorageItem, setLocalStorageItem } from 'hooks';
import { currencyFormat } from "utils";
import PaymentMsgModal from './PaymentMsgModal';
import { useQueryClient } from '@tanstack/react-query';
import { FinancingQueryKey } from 'query/Financing/financingQuery';

export const BillingCardsSelection = ({ payment_type, total, quote_id, data, list_id }) => {
  
  const [open, setOpen] = useState(false);
  const [modelData, setModelData] = useState({});  
  const [termsAccepted, setTermsAccepted] = useState(false)
  const { data: tanCPPData } = usePaymentTanCPPQuery();
  const tanC = tanCPPData?.data ? tanCPPData?.data[0]?.terms_and_conditions : '';
  const pPolicy = tanCPPData?.data ? tanCPPData?.data[0]?.privacy_policy : '';
  
  const { data: responseCards, refetch, isLoading: gettingCards, dataUpdatedAt,  } = useGetCardQuery();
  const cards = responseCards?.results;

  const { mutate: createCustomerAccount } = useCreateCustomerAccountQuery();
  const { mutate: makePayment, isLoading: makingPayment } = usePaymentIntentQuery()
  const [customer_id, setCustomerId] = useState(getLocalStorageItem('CUSTOMER_ID'));
  const [AddNewCard, setAddNewCard] = useState(false);
  const [cardFormLoading, setCardFormLoading] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  


  
  const handleAddNewCard = () => {
    setCardFormLoading(true);
    if (!customer_id) {
      createCustomerAccount(
        {},
        {
          onSuccess: (res) => {
            setAddNewCard(true);
            setLocalStorageItem('CUSTOMER_ID', res?.data?.id);
            setCustomerId(res?.data?.id);
            setTimeout(() => {
              setCardFormLoading(false);              
            }, 1000)
            
          },
          onError: (err) => {
            setCardFormLoading(false);

          },
        }
      );
      return;
    } else {
      setCardFormLoading(false);
      setAddNewCard(true);
    }
  };
  const onSuccess = (res) => {
    setAddNewCard(false);
    refetch();
  };
  const onError = (err) => {
    setAddNewCard(false);
    refetch();
  };
  const handleCancel = (err) => {
    setAddNewCard(false);    
  };

  

  useEffect(() => {    
    if(cards?.length<1){

      handleAddNewCard();
    } else {
      setSelectedCard(
        cards?.find((el) => {
          if (el.is_primary) {
            return el;
          }
        })?.id || null
      );
    }
  }, [cards]);

  const handleSelectCard = (id) => () => {
    setSelectedCard(id);
    /*if (selectedCard === id) {
      setSelectedCard(null);
    } else {
      setSelectedCard(id);
    }*/
  };

  const handleChangePolicies = (e) => {    
    setTermsAccepted(e.target.checked);
  }
  const queryClient = useQueryClient();
  const handlePayment = async () => {
    makePayment(
      {
        payment_method_id: selectedCard,
        listing_id: list_id,
        quote_id: quote_id,
        payment_for: payment_type || 'Quotes',
      },
      {
        onSuccess: (res) => {
          if (res?.status_code === 200) {
            setOpen(true);
            queryClient.invalidateQueries([FinancingQueryKey.FINANCIAL_LIST]);
            setModelData({
              type: 'success',
              first_message: 'Payment Successful',
              second_message: `Thank you, Support team will contact you`,
            });
          } else {
            setOpen(true);
            setModelData({
              type: 'error',
              first_message: 'Payment Failed',
              second_message: `Payment has been failed due to ${res?.message}`,
            });
          }
        },
        onError: (err) => {
          setOpen(true);
          setModelData({
            type: 'error',
            first_message: 'Payment Failed',
            second_message: `Payment has been failed due to ${err?.message}`,
          });
        },
      }
    );
  };
  return (
    <Box className="w-[90%] m-auto">
      <Grid container spacing={2} className="!mt-10">
        <Grid item lg={12} md={12} xs={12}>
        {AddNewCard ?
          <Typography className="p-4 font-bold">Add Payment Method</Typography>
        :        
          <Typography className="p-4 font-bold">Choose Payment Method</Typography>
        }
        
        {AddNewCard ? (
          <>
            {cardFormLoading ? (
              <>
              <Grid item lg={8} md={8} xs={12} justifyContent="center">
                <Skeleton width='100%' height='500px' />
              </Grid>
              </>
            ) : (
              <>
              <Grid item lg={8} md={8} xs={12}>                
                <CardFormMain
                  data={responseCards}
                  refetch={refetch}
                  onError={onError}
                  onSuccess={onSuccess}
                  handleCancel={handleCancel}
                />
              </Grid>
              </>
            )
            }
          </>
        ) : (
          <Grid item lg={8} md={8} xs={12}>
            {cards?.map((el) => {
              return ( <>
                  <CardBlock
                    refetch={refetch}
                    onClick={handleSelectCard}
                    type={el?.card_type}
                    number={el?.last_4_digits}
                    expiry={el?.exp_year}
                    expiryMonth={el?.exp_month}
                    selectedCard={selectedCard}
                    isPrimary={el?.is_primary}
                    uniqueId={el?.id}
                  />
              </>               
              );
            })}
            <Typography
              onClick={handleAddNewCard}
              className="text-secondary-50 flex align-center  cursor-pointer"
            >
              <span>
                <Add />
              </span>
              <span className="px-2"> Add new payment method</span>
            </Typography>
            <Typography className='py-2'>
              <span>
                <Checkbox
                  checked={termsAccepted}
                  onChange={handleChangePolicies}
                />
              </span>
              <span>
                By proceeding, I express my consent to accept the{' '}
                <a href={tanC ? tanC : '#'} rel="noreferrer" target="_blank" style={{textDecoration: 'none'}}><span className="text-secondary-50">Terms and Conditions</span></a>{' '}
                & <a href={pPolicy ? pPolicy : '#'} rel="noreferrer" target="_blank" style={{textDecoration: 'none'}}><span className="text-secondary-50">Privacy policy</span></a> for
                this transaction.
              </span>
            </Typography>
            <Grid sm={12} className='py-2 text-right pb-20' item>
              {makingPayment ? <Button disabled={termsAccepted && selectedCard ? false : true} variant='contained' className='paynowBtn'>
                <CircularProgress className='text-black text-sm' />
              </Button> : <Button onClick={handlePayment} disabled={termsAccepted && selectedCard ? false : true} variant='contained' className='paynowBtn'>
                Pay {currencyFormat(total)}
              </Button>}
            </Grid>
          </Grid>
        )}
      </Grid>
      </Grid>
      <PaymentMsgModal
        open={open}
        setClose={setOpen}
        modelData={modelData}        
      />
    </Box>
  );
};
