import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { Grid, Box, Typography,useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import '../SMRR.css';
import PushNotificationContent from './PushNotificatoinContent';
import {useGetNotification} from '../../../query/settings';


const useStyles = makeStyles((theme)=>({
    container: {
       width : '100%',
       [theme.breakpoints.up('md')]:{
         width :315
       }
    },
}))

const PushNotifications = () => {

    const theme = useTheme();
    
    const isMedium = useMediaQuery(theme.breakpoints.down('md'));
    const {data,refetch,isLoading} = useGetNotification();
   
    useEffect(()=>{
        refetch();
    },[refetch]);
  
  
    const classes = useStyles();
    return (
        <Box padding='10px'>
            <Grid container lg={12} md={12} xs={12}  paddingTop='30px'>
                <Grid item lg={12} md={12} xs={12} >
                   <PushNotificationContent status={data?.data?.notifications} />
                </Grid>
                
            </Grid>
        </Box>
    )
}

export default PushNotifications;