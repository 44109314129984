import React from 'react';
import {
  Box,
  Grid,
  Skeleton,
  Typography,
} from '@mui/material';
import { currencyFormat } from "utils";

export const BillingDetail = ({ data, total, serviceTax, isLoading }) => {
  
  return (
    <Box className="w-[90%] m-auto">
      <Grid
        spacing={2}
        container
        sx={{ border: '1px solid #F5F5F5' }}
        className=" p-2 !mt-10"
      >
        {isLoading ? (
          <Grid lg={12} md={12} sm={12}>
            <Skeleton width={'100%'} height="186px" />
          </Grid>
        ) : (
          <>
            {' '}
            <Grid container lg={9} md={9} sm={12} item>
              <Grid lg={3} md={12} sm={12} className=" p-2  text-center" item>
                {' '}
                <img
                  width={'100%'}
                  className="rounded-md w-[220px] h-[186px] text-center"
                  src={data?.data?.attachments[0]?.attachment}
                  alt="Your Rig"
                />
              </Grid>
              <Grid
                item
                className="max-w-[462px] pl-5"
                justifyContent="center"
                lg={7}
                md={12}
                sm={12}
              >
                <Typography style={{fontSize:'18px',fontWeight:'500', color:'#333333'}} className="py-2">{`${
                  data?.data?.title
                } ${
                  data?.data?.vehicle?.engine_manufacturer != null
                    ? data?.data?.vehicle?.engine_manufacturer
                    : ''
                }`}</Typography>
                <Typography className="py-2" style={{fontSize:'24px',fontWeight:'700', color:'#333333'}}>
                  {currencyFormat(data?.data?.selling_price)}                 
                </Typography>
                <Grid className="flex py-2 " item>
                  <img
                    className="w-[18px] h-[22.5px]"
                    src={'/assets/location.svg'}
                    alt="location"
                  />
                  <Typography className="px-2" style={{fontSize:'14px',fontWeight:'500', color:'#757575'}}>
                  {data?.data?.city && (
                    <>
                     {data?.data?.city}, {data?.data?.state}, {data?.data?.zip_code}
                    </>
                  )}
                    
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              spacing={2}
              justifyContent="center"
              lg={3}
              md={12}
              sm={12}
              item
            >
              <Grid item>
                <Typography className="p-2 flex justify-between pb-6" style={{fontSize:'16px',fontWeight:'500', color:'#333333'}}>
                  
                  {data?.data?.quotes?.quote_price ? (
                    <>
                    <span>Deposit Amount </span>
                    <span>{currencyFormat((data?.data?.quotes?.quote_price) * 0.025)}</span>
                    </>
                  ):(
                    <>
                    <span>Inspection Fees</span>
                    <span>{currencyFormat(data?.data?.inspection_fee)}</span>
                    </>
                  )}                  
                </Typography>
              </Grid>
              <Grid item>
                <Typography className="p-2 flex justify-between" style={{fontSize:'16px',fontWeight:'500', color:'#333333'}}>
                  <span>Service Tax</span>
                  <span>{currencyFormat(serviceTax)}</span>
                </Typography>
              </Grid>
              <Typography className="text-left p-2 pb-4" style={{fontSize:'12px',fontWeight:'400', color:'#333333'}}>
                This amount is non-refundable 
              </Typography>
              <Grid item className="text-center bg-primary-40 p-4">
                <Typography className="w-full flex justify-between" style={{fontSize:'18px',fontWeight:'500', color:'#333333'}}>
                  <span>Total Payable Amount</span>
                  <span>{currencyFormat(total)}</span>
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};
