import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { Grid, Box, Typography, useMediaQuery, useTheme, Modal, Divider } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    titleHeading: {
        fontSize: '44px',
        color: '#03131D',
        fontWeight: '700'
    },
    subTtile: {
        fontSize: '24px',
        color: '#333333',
        fontWeight: '400'
    },
    publishedYear: {
        fontSize: '16px',
        color: '#757575',
        fontWeight: '400'
    },
    imgDec: {
        fontSize: '14px',
        color: '#545454',
        fontWeight: '400'
    },

}));

const ImgSection = () => {
    const classes = useStyles();

    const img = process.env.PUBLIC_URL + '/truckNews.png';


    return (

        <>
            <Grid container lg={12} sm={12} xs={12} >
                <Grid item lg={12} sm={12} xs={12} paddingTop='10px' paddingBottom='10px'>
                    <Typography className={classes.titleHeading}>The top trucking trends of 2023</Typography>
                </Grid>
                <Grid item lg={12} sm={12} xs={12} paddingTop='10px' paddingBottom='10px'>
                    <Typography className={classes.subTtile}>Driver recruitment, economic concerns,
                        investment in equipment and EVs were top of mind over the year</Typography>
                </Grid>
                <Grid item lg={12} sm={12} xs={12} paddingTop='10px' paddingBottom='10px'>
                    <Typography className={classes.publishedYear}> Published Dec. 29, 2023</Typography>
                </Grid>
                <Grid item lg={12} sm={12} xs={12} paddingTop='10px' paddingBottom='10px'>
                    <SecHeader />
                </Grid>
                <Grid item lg={12} sm={12} xs={12} paddingTop='10px' paddingBottom='10px'>
                    <img src={img} style={{ height: '345px', width: '100%', borderRadius: '2px' }} />
                </Grid>
                <Grid item lg={12} sm={12} xs={12} paddingTop='10px' paddingBottom='10px'>
                    <Typography className={classes.imgDec}> A truck travels on the open road. Courtesy of Navistar</Typography>
                </Grid>
                <Grid item lg={12} sm={12} xs={12} paddingTop='10px' paddingBottom='10px'>
                    <Typography style={{ fontSize: '18px', fontWeight: '400', color: '#545454', paddingTop: '10px', paddingBottom: '10px' }}>
                        In recent years, the classic truck sals indusrty has undergone a significant transformation, thanks in large part
                        to the rise of online market place . These disital plateformshave made it easiar than ever for buyers ans sellers to connect,
                        negotiate deals, and complete transsactions, all form the confort of thier own homes. Here are some of the ways that online marketplace
                        are changing the classic truck sales industry.
                    </Typography>
                    <Typography style={{ fontSize: '28px', fontWeight: '700', color: '#03131D', paddingTop: '10px', paddingBottom: '10px' }}>
                        "The Effets of the Pamdemic on the Classic Trucks Industry: A Market Update"
                    </Typography>
                    <Typography style={{ fontSize: '18px', fontWeight: '400', color: '#545454', paddingTop: '10px', paddingBottom: '10px' }}>
                        Overall. the pandemic has had mixed impact on the classic truck industry,with change in demand, supply chain disruptions, shifts in sales channels,
                        are impacts on prices. As the pandemic continues to evolve,we can expect to see further changes and adaptations in the classic truck market.
                    </Typography>
                    <Typography style={{ fontSize: '18px', fontWeight: '400', color: '#545454', paddingTop: '10px', paddingBottom: '10px' }}>
                        Impact on prices: The pandemic  has had mixed imapct on prices on the classic truck market. While some models have seen an increase in value due to increase in demand,
                        others may see a decline in value due to economic uncertainity and supply chain disruptions.
                    </Typography>
                    <Typography style={{ fontSize: '18px', fontWeight: '400', color: '#545454', paddingTop: '10px', paddingBottom: '10px' }}>
                        Shifts in sales channels: The pandemic has led to a shift in how classic trucks are bought and sold. with social distancing meaures in place,
                        online marketplace have become more important than ever for connecting buyers and sellers. In-person auction s and shows have been cancelled or
                        moved online, which has impacted the ability to view and inspect vehicals in person.

                    </Typography>
                </Grid>
                <Divider style={{ width: '100%', }} />
                <Grid item lg={12} sm={12} xs={12} paddingTop='10px' paddingBottom='10px'>
                    <DividerContent />
                </Grid>
                <Divider style={{ width: '97%', }} />

                <Grid item lg={12} sm={12} xs={12} paddingTop='10px' paddingBottom='10px'>
                    <DividerContent />
                </Grid>
                <Divider style={{ width: '100%', }} />

                <Grid item lg={12} sm={12} xs={12} paddingTop='10px' paddingBottom='10px'>
                    <DividerContent />
                </Grid>
                <Divider style={{ width: '100%', }} />

            </Grid>
        </>
    )
}

export default ImgSection;

const SecHeader = () => {

    const bloggerDec = {
        border: '1px solid',
        borderBottomColor: '#0F5E91',
        borderTopColor: '#FFFFFF',
        borderLeftColor: '#FFFFFF',
        borderRightColor: '#FFFFFF',
        fontSize: '14px',
        fontWeight: '400',
        color: '#333333'
    }
    const avatar = process.env.PUBLIC_URL + '/Avatar.png';
    return (

        <>
            <Grid container lg={12} sm={12} xs={12} className='flex' style={{ justifyContent: 'space-between', }}>
                <Grid item container lg={2} sm={2} xs={2} className=' flex ' columnGap='20px' >
                    <Grid item lg={3} sm={5} xs={5} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <img src={avatar} style={{ borderRadius: '20px', height: '40px', width: '40px', borderRadius: '20px' }} />
                    </Grid>
                    <Grid item lg={6} sm={5} xs={5} >
                        <Typography style={bloggerDec}>Larry Avila</Typography>
                        <Typography style={{ fontSize: '14px', color: '#B0B0B0', fontWeight: '400' }}>Editor</Typography>
                    </Grid>
                </Grid>
                <Grid item container lg={3} sm={2} xs={2} columnGap={2}>
                    <Grid item lg={2} sm={3} xs={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={'/assets/icons/fbnotfilled.svg'} alt='facebook' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '23px', width: '24px' }} />
                    </Grid>
                    <Grid item lg={2} sm={3} xs={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                        <img src={'/assets/icons/twiternotfilled.svg'} alt='facebook' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '23px', width: '24px' }} />
                    </Grid>
                    <Grid item lg={2} sm={3} xs={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={'/assets/icons/instgram.svg'} alt='facebook' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '23px', width: '24px' }} />
                    </Grid>
                    <Grid item lg={2} sm={3} xs={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={'/assets/icons/linkdinnotfilled.svg'} alt='facebook' style={{ height: '23px', width: '24px' }} />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

const DividerContent = () => {

    return (

        <>
            <Grid container lg={12} sm={12} xs={12} paddingTop='10px' paddingBottom='10px'>
                <Grid item lg={12} sm={12} xs={12}>
                    <Typography style={{ fontSize: '28px', color: '#03131D', fontWeight: '700' }}>
                        White House credits apprenticeship, outreach for trucker employment gains
                    </Typography>
                </Grid>
                <Grid item lg={12} sm={12} xs={12} paddingTop='10px' paddingBottom='10px'>
                    <Typography style={{ fontSize: '14px', color: '#757575', fontWeight: '400' }}>
                        By Colin Campbell April 4,2023
                    </Typography>
                </Grid>
                <Grid item lg={12} sm={12} xs={12} paddingTop='10px' paddingBottom='10px'>
                    <Typography style={{ fontSize: '16px', color: '#545454', fontWeight: '400' }}>
                        Domino's, Frito-Lay, Pepsico, Albertsons and UPS are among more than 100 employers that have launched programs to
                        improve driver retention since December.
                    </Typography>
                </Grid>
                <Grid item container lg={12} sm={12} xs={12} paddingTop='10px' paddingBottom='10px'>
                    <Grid item lg={2} sm={3} xs={3} >
                        <Typography style={{ fontSize: '14px', color: '#093857', fontWeight: '700', display: 'flex', justifyContent: 'left', alignItems: 'left' }}>
                            Read the full article
                        </Typography>
                    </Grid>
                    <Grid item lg={1} sm={1} xs={1} >
                        <img src='/assets/icons/Arrow (2).svg' style={{ height: '12px', width: '16px' }} />
                    </Grid>

                </Grid>
            </Grid>
        </>
    )
}