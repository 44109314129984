import { Box, Divider, Grid, Typography, IconButton } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Slider from '@mui/material/Slider';
import CloseIcon from '@mui/icons-material/Close';
import { routes } from 'routes/RouteConstants';
import { useNavigate } from 'react-router';
import CustomButton from 'components/CustomButton/CustomButton';
import LabelledSelectField from 'components/LabelledSelectField';
import CustomTextField from 'components/CustomTextField';

import { currencyFormat } from 'utils';

const tenureLists = [
  { id: 24, label: '24 Months' },
  { id: 36, label: '36 Months' },
  { id: 48, label: '48 Months' },
  { id: 60, label: '60 Months' },
  { id: 72, label: '72 Months' },
];

export const EmiCalculator = ({ data, handleClose }) => {
  const navigate = useNavigate();
  const [downPayment, setDownPayment] = useState(null);
  const [tenure, setTenure] = useState({ id: 24, label: '24 Months' });
  const [creditScore, setCreditScore] = useState(null);
  const [Interest, setIntrest] = useState(null);
  const [rawInterestInput, setRawInterestInput] = useState('');

  const [indicativeEMI, setIndicativeEMI] = useState(0);
  const [totalAmount, setTotalAmount] = useState(
    data?.selling_price + Interest
  );
  useEffect(() => {
    setTotalAmount(data?.selling_price + Interest);
  }, [Interest]);

  function calculateEMI(
    totalAmount = data?.selling_price || 0,
    downPayment = 0,
    interestRate = 0,
    loanTermInMonths = 0
  ) {
    const principalAmount = totalAmount - downPayment;
    const monthlyInterestRate = interestRate / 12 / 100;
    const numerator =
      principalAmount *
      monthlyInterestRate *
      Math.pow(1 + monthlyInterestRate, loanTermInMonths?.id);
    const denominator =
      Math.pow(1 + monthlyInterestRate, loanTermInMonths?.id) - 1;
    const emiMonthly = numerator / denominator;
    setIndicativeEMI(emiMonthly.toFixed(2));
  }

  const handleRequestFinancing = () => {
    navigate(routes.REQUEST_FINANCING.pathname, {
      state: { stock_number: data?.unique_id },
    });
  };

  const handleChange = (name) => (event, newVal) => {
    if (name === 'down_payment') {
      setDownPayment(newVal);
    } else if (name === 'interest') {
      setIntrest(newVal);
    }
  };

  const handleChangeValue = (field, value) => {
    if (field === 'tenure') {
      setTenure(value);
    }
    if (field === 'creditScore') {
      setCreditScore(value);
    }
  };

  useEffect(() => {
    calculateEMI(data?.selling_price, downPayment, Interest, tenure);
  }, [downPayment, Interest, tenure]);

  return (
    <Grid className="!p-2 w-full" spacing={0} item container>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
      >
        <Typography style={{ color: '#000' }}>EMI Calculator</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Grid sx={{ padding: '0px 20px' }} lg={8} md={8} sm={12} item>
        <LabelledSelectField
          label=""
          placeholder="Select tenure"
          options={tenureLists}
          value={tenure}
          onChange={(event, value) => {
            handleChangeValue('tenure', value);
          }}
        />
        <Typography className="pt-5">
          <span className="emiCalLable">Credit Score</span>
          <CustomTextField
            placeholder="Credit Score"
            onChange={(e) =>
              handleChangeValue(
                'creditScore',
                e?.target?.value?.trimStart().replace(/\D/, '')
              )
            }
            maxLength={3}
            className=""
            value={creditScore}
          />
        </Typography>

        <Typography className="pt-6">
          <span className="emiCalLable">Down Payment Offered</span>
          {/* <span className="emiCalLable">{currencyFormat(downPayment)}</span> */}
          <CustomTextField
            placeholder="Down Payment Offered"
            onChange={(e) => {
              const val = Number(e.target.value); // convert string input to number
              if (val >= 0 && val <= data?.selling_price) {
                // validate the input
                setDownPayment(val);
              }
            }}
            maxLength={10} // Adjust maxLength accordingly; 3 may be too short for down payment amounts
            value={downPayment}
          />
        </Typography>
        {/* <Typography className="">
          <Slider
            sx={{
              color: '#b4bcca',
              height: '6px',
              '& .MuiSlider-track': {
                border: 'none',
                height: '6px',
                backgroundColor: '#0F5E91',
              },
              '& .MuiSlider-thumb': {
                height: 16,
                width: 16,
                backgroundColor: '#fff',
                border: '2px solid #0F5E91',
                '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                  boxShadow: 'inherit',
                },
                '&:before': {
                  display: 'none',
                },
              },
              '& .MuiSlider-valueLabel': {
                lineHeight: 10,
                fontSize: 12,
                background: 'unset',
                padding: 0,
                width: 32,
                height: 32,
                borderRadius: '50% 50% 50% 0',
                backgroundColor: '#0F5E91',
                transformOrigin: 'bottom=== left',
                transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
                '&:before': { display: 'none' },
                '&.MuiSlider-valueLabelOpen': {
                  transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
                },
              },
            }}
            min={0}
            max={data?.selling_price}
            aria-label="pretto slider"
            value={downPayment}
            onChange={handleChange('down_payment')}
          />
        </Typography> */}

        <Typography className="pt-5">
          <span className="emiCalLable">Interest Rate</span>
          {/* <span className="emiCalLable">{Interest}%</span> */}
          <CustomTextField
            placeholder="Interest Rate"
            onChange={(e) => {
              const val = e.target.value;

              // First, always update the raw input:
              setRawInterestInput(val);

              // Then, try to convert it to a number and validate:
              const numVal = Number(val);
              if (!isNaN(numVal) && numVal >= 2 && numVal <= 30) {
                setIntrest(numVal);
              }
            }}
            maxLength={5}
            value={rawInterestInput}
          />
        </Typography>
        {/* <Typography className="">
          <Slider
            sx={{
              color: '#b4bcca',
              height: '6px',
              '& .MuiSlider-track': {
                border: 'none',
                height: '6px',
                backgroundColor: '#0F5E91',
              },
              '& .MuiSlider-thumb': {
                height: 16,
                width: 16,
                backgroundColor: '#fff',
                border: '2px solid #0F5E91',
                '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                  boxShadow: 'inherit',
                },
                '&:before': {
                  display: 'none',
                },
              },
              '& .MuiSlider-valueLabel': {
                lineHeight: 10,
                fontSize: 12,
                background: 'unset',
                padding: 0,
                width: 32,
                height: 32,
                borderRadius: '50% 50% 50% 0',
                backgroundColor: '#0F5E91',
                transformOrigin: 'bottom=== left',
                transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
                '&:before': { display: 'none' },
                '&.MuiSlider-valueLabelOpen': {
                  transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
                },
              },
            }}
            min={2}
            max={30}
            aria-label="pretto slider"
            value={Interest}
            onChange={handleChange('interest')}
          />
        </Typography> */}
      </Grid>

      <Grid
        sx={{ minWidth: '320px', padding: '0px 20px' }}
        spacing={5}
        lg={4}
        item
        md={4}
        sm={12}
      >
        <Grid className="!p-3" item>
          <div className="w-full emiCalResLable pt-3">
            Total Amount (Principal + Interest)
          </div>
          <div className="py-2 emiCalRes pt-1">
            {currencyFormat(totalAmount)}
          </div>
        </Grid>
        <Divider variant="middle" />
        <Grid className="!p-3" item>
          <div className="w-full emiCalResLable pt-6">Est. Monthly Payment</div>
          <div className="py-2 emiCalRes pt-1">
            {currencyFormat(indicativeEMI)}
          </div>
        </Grid>
        <Grid item className="pt-7">
          <CustomButton onClick={handleRequestFinancing}>
            Request Financing
          </CustomButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
