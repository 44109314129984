import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { RadioButtonUncheckedOutlined } from '@mui/icons-material';
import './PaymentMethod.css';

import {
  Typography,
  Checkbox,
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  Button,
  CircularProgress,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { RiMastercardFill } from 'react-icons/ri';
import { useDeleteCardQuery, useEditCardQuery } from 'query/payment';
import Loader from 'react-js-loader';

export const CardBlock = ({
  onClick,
  type,
  number,
  expiry,
  expiryMonth,
  isPrimary,
  selectedCard,
  uniqueId,
  refetch,
}) => {
  const [selected, setSelected] = useState(selectedCard === uniqueId);
  const [openDeletePromt, setOpenDeletePromt] = useState(false);
  const { mutate: DeleteCard, isLoading: deletingCard } = useDeleteCardQuery();
  const { mutate: EditCard, isLoading: EditingCard } = useEditCardQuery();
  
  useEffect(() => {
    setSelected(selectedCard === uniqueId);
  }, [selectedCard]);

  const selectedStyle = {
    background: '#F5F5F5',
    border: '1px solid #0F5E91',
    borderRadius: '8px',
  };
  const commonStyle = {
    background: '#fff',
    border: '1px solid #B0B0B0',
    borderRadius: '8px',
  };
  const handleOperation = (name) => (e) => {
    e.stopPropagation();
    if (name === 'set_as_default') {
      EditCard(
        { data: true, id: uniqueId },
        {
          onSuccess: (res) => {
            refetch();
          },
          onError: (err) => {

          },
        }
      );    
    } else if (name === 'checkbox') {
      const cbox = e.target.checked;
      if(cbox){
        isPrimary=true;
        EditCard(
          { data: true, id: uniqueId },
          {
            onSuccess: (res) => {
              refetch();
            },
            onError: (err) => {
  
            },
          }
        ); 
      }
      
    } else if (name === 'remove') {
      setOpenDeletePromt(true);
    }
  };
  const handleCloseDeletePrompt = (name) => (e) => {
    e.stopPropagation();
    if (name === 'yes') {
      DeleteCard(uniqueId, {
        onSuccess: (res) => {
          setOpenDeletePromt(false);
          refetch();
        },
        onError: (err) => {
          setOpenDeletePromt(false);
          refetch();
        },
      });
    } else {
      setOpenDeletePromt(false);
    }
  };
  const expiryClass = (name) => {
   if(name){
    return "flex expBlu";
   } else {
    return "flex expGry";
   }
  };
  
  return (
    <div
      style={selected ? selectedStyle : commonStyle}      
      className="box !h-20 pt-2 px-4 mb-4 pb-4 max-w-[928px] relative"
    >
      <div className="flex w-full ">
        <div>
          <img
            className="max-w-12 max-h-8"
            src={`/assets/icons/card/${type}.svg`}  alt="Icon"
          />
        </div>
        <div className="px-2 w-full ">
          <Typography className="flex">
            <span className="textCaps">{type}</span>&nbsp;ending in {number}            
          </Typography>
          <Typography className={expiryClass(isPrimary)}>Expiry {expiryMonth}/{expiry}</Typography>
        </div>
      </div>
      {!isPrimary && (
        <div className="flex pt-2">
          <Typography
            className="text-gray-600 px-2 !font-[500] cursor-pointer"
            onClick={!EditingCard && handleOperation('set_as_default')}
          >
            {EditingCard ? (
              <Loader
                type="bubble-loop"
                bgColor={'#000'}
                color={'#fff'}
                size={30}
              />
            ) : (
              'Set as default'
            )}
          </Typography>          
          <Typography
            className="text-red-700 px-2 !font-[500] cursor-pointer"
            onClick={handleOperation('remove')}
          >
            Remove
          </Typography>
        </div>
      )}
      

      <Checkbox
        sx={{
          '&:hover': { backgroundColor: 'none !important' },
          position: 'absolute',
          right: '0',
          top: '0',
        }}
        checked={isPrimary}
        checkedIcon={<CheckCircleIcon />}
        icon={<RadioButtonUncheckedIcon />}
        onClick={handleOperation('checkbox')}
      />
      <Dialog
        onClose={handleCloseDeletePrompt}
        maxWidth="sm"
        fullWidth
        open={openDeletePromt}
      >
        <DialogContent dividers>
          <Box className="text-center p-2">
            <img className="max-w-[48px] max-h-[48px] py-2" src="/assets/icons/DeletePromtIcon.svg" alt='' />
            <Typography className="py-2">Are you sure?</Typography>
            <Typography className="py-2">
              You want to remove this card
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          {deletingCard ? (
            <Button
              className="!bg-red-700 w-full text-white "
              autoFocus
              onClick={handleCloseDeletePrompt('yes')}
            >
              <CircularProgress size={30} className="text-white" />
            </Button>
          ) : (
            <Button
              className="!bg-red-700 w-full text-white"
              autoFocus
              onClick={handleCloseDeletePrompt('yes')}
            >
              Yes
            </Button>
          )}
          <Button
            className="w-full"
            variant="outlined"
            autoFocus
            onClick={handleCloseDeletePrompt('no')}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};