import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CardBlock } from './CardBlock';
import { useCreateCustomerAccountQuery } from 'query/payment';
import { Add } from '@mui/icons-material';
import { getLocalStorageItem, setLocalStorageItem } from 'hooks';


export const ListCards = ({ data, setShowCardForm, refetch }) => {

  
  const { mutate: createCustomerAccount } = useCreateCustomerAccountQuery();
  const [customer_id, setCustomerId] = useState(getLocalStorageItem('CUSTOMER_ID'));
  const [selectedCard, setSelectedCard] = useState(null);

  useEffect(() => {
    setSelectedCard(
      data?.results?.find((el) => {
        if (el.is_primary) {
          return el;
        }
      })?.id || null
    );
  }, [data]);

  const handleAddNewCard = () => {
    if (!customer_id) {
      createCustomerAccount(
        {},
        {
          onSuccess: (res) => {
            setShowCardForm(true);
            setLocalStorageItem('CUSTOMER_ID', res?.data?.id);
            setCustomerId(res?.data?.id);
          },
          onError: (err) => {
            setShowCardForm(false);
          },
        }
      );
      return;
    } else {
      setShowCardForm(true);
    }
  };

  const handleSelectCard = (id) => () => {
    setSelectedCard(id);
    /*if (selectedCard === id) {
      setSelectedCard(null);
    } else {
      setSelectedCard(id);
    }*/
  };

  return (
    <Box className="w-[90%] p-4">
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} xs={12}>
          <Typography className="p-4 font-bold">Choose Payment Method</Typography>
        <Grid
          item
          lg={8}
          md={8}
          xs={12}
          className="max-h-[100vh] pl-4"
        >
          {data?.results?.map((el) => {
            return (
              <>
              <CardBlock
                refetch={refetch}
                onClick={handleSelectCard}
                type={el?.card_type}
                number={el?.last_4_digits}
                expiry={el?.exp_year}
                expiryMonth={el?.exp_month}
                selectedCard={selectedCard}
                isPrimary={el?.is_primary}
                uniqueId={el?.id}
              />
              </>
            );
          })}
          <Typography
            onClick={handleAddNewCard}
            className="text-secondary-50 pb-10 flex align-center cursor-pointer"
          >
            <span>
              <Add />
            </span>
            <span className="px-2">Add new payment method</span>
          </Typography>
        </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
