import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router';
import {
  Button,
  Grid,
  Select,
  styled,
  FormControl,
  MenuItem,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { routes } from 'routes/RouteConstants';
import { getLocalStorageItem } from 'hooks';
import { useLogoutQuery } from 'query/Auth';
import { useRefContext } from 'components/Layout';

export const Profile = ({ text }) => {
  const navigation = useNavigate();
  const profileValue = '';
  const handleChangeProfileValue = (name) => {};
  const { mutate: logout, isLoading: isLoggingOut } = useLogoutQuery();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { handleOpenSomethingWentWrongDialog } = useRefContext();

  const handlepageNav = (path) => {
    if (path == 'log_out') {
      logout(
        {},
        {
          onSuccess: (res) => {
            localStorage.clear();
            window.location = window.location.href+'?eraseCache=true';
            navigation(routes.LOGIN.pathname);
          },
          onError: (err) => {
            if (err.status_code == 500) {
              handleOpenSomethingWentWrongDialog();
            }
          },
        }
      );
    } else {
      navigation(`${path}`);
    }
    //  console.log('path',path);
  };
  const getdata = getLocalStorageItem('USER_DETAILS');

  const [data, setData] = React.useState();

  React.useEffect(() => {
    let myData = {
      name: getdata?.name.split(' '),
      image: getdata?.profile_image?.attachment,
    };
    setData(myData);
  }, []);

  return (
    <>
      <Select
        sx={{
          '.MuiOutlinedInput-notchedOutline': { border: 0 },
          '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            border: 0,
          },
          '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
              border: 0,
            },
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        disableUnderline={true}
        value={profileValue}
        onChange={handleChangeProfileValue}
        displayEmpty
        inputProps={{
          'aria-label': 'Without label',
          IconComponent: (props) => (
            <KeyboardArrowDownIcon
              sx={{
                transition: 'transform .3s linear',
                color: ` ${text ? 'white !important' : 'black !important'}`,
              }}
              {...props}
            />
          ),
        }}
      >
        <MenuItem className="!hidden " value="">
          <Box justifyContent="center" alignItems="center" item container>
            <Typography
              className={`flex items-center ${
                text ? 'text-white' : 'text-black'
              }`}
            >
              Welcome Back, {data?.name[0]}
              <span>
                <img
                  className=" rounded-[50%] text-center !w-[40px] h-[40px] mx-2 "
                  src={
                    data?.image ||
                    'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png'
                  }
                />
              </span>
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem
          value={'profile'}
          onClick={() => handlepageNav(routes.PROFILE.pathname)}
        >
          Profile
        </MenuItem>
        {/* <br /> */}
        <MenuItem
          value={20}
          onClick={() => handlepageNav(routes.Equipment_Info.pathname)}
        >
          Equipment Info
        </MenuItem>
        <MenuItem
          value={30}
          onClick={() => handlepageNav(routes.Transactions.pathname)}
        >
          Transactions
        </MenuItem>
        <MenuItem
          value={30}
          onClick={() => handlepageNav(routes.PAYMENT_METHODS.pathname)}
        >
          Payments
        </MenuItem>

        <MenuItem
          value={'help_center'}
          onClick={() => handlepageNav(routes.Help_Center.pathname)}
        >
          Help Center
        </MenuItem>
        <MenuItem
          value={'Settings'}
          onClick={() => handlepageNav(routes.Settings.pathname)}
        >
          Settings
        </MenuItem>
        <MenuItem value={'log_out'} onClick={() => handlepageNav('log_out')}>
          Logout
        </MenuItem>
      </Select>
    </>
  );
};
