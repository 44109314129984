import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import {
  Grid,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Modal,
  Divider,
} from '@mui/material';
import { makeStyles } from '@material-ui//styles';

const HeaderImg = () => {
  const img = process.env.PUBLIC_URL + '/aboutusbackgroundImg.png';
  const maskGroup = process.env.PUBLIC_URL + '/Maskgroup.png';
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));
  const isBelowMedium = useMediaQuery(theme.breakpoints.down('md'));
  const isBelowSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const container = {
    // height: isBelowMedium ? '350px' : '655px',
    // backgroundImage: `url(${img})`,
    borderRadius: '6px',
    Offset: '0px, 2px',
    Effect: 'Drop shadow',
    boxShadow: 'rgba(0, 0, 0, 0.08)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    justifyContent: 'center',
    borderTop:"8px solid",
    borderColor:"#0f5e91",
    paddingBottom:"50px",
  };

  return (
    <>
      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className="abutImg"
        style={container}
        marginTop={isBelowSmall ? 2 : 2}
      >
        {/* <Grid item container lg={12} md={12} sm={12} xs={12} style={container}> */}
        <Grid
          item
          lg={11}
          md={11}
          sm={12}
          xs={12}
          paddingLeft={isBelowMedium ? 1 : 8}
          paddingTop={isBelowMedium ? 2 : 8}
        >
          <Typography
            style={{
              fontSize: isBelowMedium ? '10px' : '20px',
              fontWeight: '700',
              color: '#0F0F0F',
              textAlign:"center",
            }}
          >
            Welcome to
          </Typography>
          <Typography
            style={{
              fontSize: isBelowMedium ? '25px' : '59px',
              fontWeight: '600',
              color: '#0F5E91',
              fontFamily: 'Europa Grotesk SH',
              textAlign:"center",
            }}
          >
            SELL MY{' '}
            <span
              style={{
                fontSize: isBelowMedium ? '25px' : '55px',
                fontWeight: '600',
                color: '#FFC200',
                fontFamily: 'Europa Grotesk SH',
              }}
            >
              RIG
            </span>
          </Typography>
          <Typography
            style={{
              fontSize: isBelowMedium ? '13px' : '21px',
              fontWeight: '500',
              color: '#757575',
            }}
          >
          Welcome to Sell My Rig, your preferred marketplace for commercial-titled equipment in the transportation industry. We're proud to offer a trusted platform that simplifies buying and selling, now available on both iOS and Android apps for your convenience.
          </Typography>
        </Grid>
      </Grid>
      {/* </Grid> */}
    </>
  );
};

export default HeaderImg;
