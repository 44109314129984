export const filterRefactor = (el, data) => {
  switch (el.filter) {
    case 'category':
      {
        if (data['category__name__in']) {
          data['category__name__in'] = [
            ...data['category__name__in'],
            el.label,
          ];
        } else {
          data['category__name__in'] = [el.label];
        }
      }
      break;
    case 'search':
      {
        data['search'] = [el.label];
      }
      break;
    case 'Year':
      {
        if (data['vehicle__year__in']) {
          data['vehicle__year__in'] = [...data['vehicle__year__in'], el.label];
 
        } else {
          data['vehicle__year__in'] = [el.label];
        }
      }
      break;
    case 'price_min':
      {
        if (data['price_min']) {
          data['price_min'] = [...data['price_min'], el.label];
        } else {
          data['price_min'] = [el.label];
        }
      }
      break;
    case 'price_max':
      {
        if (data['price_max']) {
          data['price_max'] = [...data['price_max'], el.label];
        } else {
          data['price_max'] = [el.label];
        }
      }
      break;
    case 'trailer_manufacturer':
      {
        if (data['vehicle__trailer_manufacturer__in']) {
          data['vehicle__trailer_manufacturer__in'] = [
            ...data['vehicle__trailer_manufacturer__in'],
            el.label,
          ];
        } else {
          data['vehicle__trailer_manufacturer__in'] = [el.label];
        }
      }

      break;
    case 'sub_category':
      {
        if (data['sub_category__name__in']) {
          data['sub_category__name__in'] = [
            ...data['sub_category__name__in'],
            el.label,
          ];
        } else {
          data['sub_category__name__in'] = [el.label];
        }
      }
      break;
    case 'brand':
      {
        if (data['vehicle__make__in']) {
          data['vehicle__make__in'] = [...data['vehicle__make__in'], el.label];
        } else {
          data['vehicle__make__in'] = [el.label];
        }
      }
      break;
    case 'model':
      {
        if (data['vehicle__model__in']) {
          data['vehicle__model__in'] = [
            ...data['vehicle__model__in'],
            el.label,
          ];
        } else {
          data['vehicle__model__in'] = [el.label];
        }
      }
      break;
    case 'engine_manufacturer':
      {
        if (data['vehicle__engine_manufacturer__in']) {
          data['vehicle__engine_manufacturer__in'] = [
            ...data['vehicle__engine_manufacturer__in'],
            el.label,
          ];
        } else {
          data['vehicle__engine_manufacturer__in'] = [el.label];
        }
      }
      break;
    case 'transmission_manufacturer':
      {
        if (data['vehicle__transmission_manufacturer__in']) {
          data['vehicle__transmission_manufacturer__in'] = [
            ...data['vehicle__transmission_manufacturer__in'],
            el.label,
          ];
        } else {
          data['vehicle__transmission_manufacturer__in'] = [el.label];
        }
      }
      break;
      case 'mileage_min':
        {
          if (data['vehicle__mileage_min']) {
            data['vehicle__mileage_min'] = [
              ...data['vehicle__mileage_min'],
              el.label,
            ];
          } else {
            data['vehicle__mileage_min'] = [el.label];
          }
        }
      break;
      case 'mileage_max':
        {
          if (data['vehicle__mileage_max']) {
            data['vehicle__mileage_max'] = [
              ...data['vehicle__mileage_max'],
              el.label,
            ];
          } else {
            data['vehicle__mileage_max'] = [el.label];
          }
        }
      break;
    case 'horse_power':
      {
        if (data['vehicle__horsepower__in']) {
          data['vehicle__horsepower__in'] = [
            ...data['vehicle__horsepower__in'],
            el.label,
          ];
        } else {
          data['vehicle__horsepower__in'] = [el.label];
        }
      }
      break;
    case 'gear_type':
      {
        if (data['vehicle__gear_type']) {
          data['vehicle__gear_type'] = [
            ...data['vehicle__gear_type'],
            el.label,
          ];
        } else {
          data['vehicle__gear_type'] = [el.label];
        }
      }
      break;
    case 'zip_code':
      {
        if (data['zip_code']) {
          data['zip_code'] = [...data['zip_code'], el.label];
        } else {
          data['zip_code'] = [el.label];
        }
      }
      break;
    case 'hour_max':
      {
        if (data['vehicle__hour_max']) {
          data['vehicle__hour_max'] = [...data['vehicle__hour_max'], el.label];
        } else {
          data['vehicle__hour_max'] = [el.label];
        }
      }
      break;
    case 'hour_min':
      {
        if (data['vehicle__hour_min']) {
          data['vehicle__hour_min'] = [...data['vehicle__hour_min'], el.label];
        } else {
          data['vehicle__hour_min'] = [el.label];
        }
      }
      break;
    case 'Scale':
      {
        data['vehicle__scale'] = el.value;
      }
      break;
    case 'Side Skirt':
      {
        data['vehicle__side_skirt'] = el.value;
      }
      break;
    case 'Air Ride':
      {
        data['vehicle__air_ride'] = el.value;
      }

      break;
    default: {
      return;
    }
  }
};
