import { Box, Grid, useMediaQuery } from '@material-ui/core';
import React from 'react';
import Shipment from './Shipment';
import { useTheme } from '@mui/material/styles';
import FinanceCard from './FinanceCard';

export const YourShipment = () => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box className="m-auto mt-0 z-99 max-w-[1700px] ">
      <Box style={{ zIndex: 1 , display:"none"}}>
        <Grid
          container
          justifyContent={isMedium ? 'center' : 'flex-start'}
          className="  bg-white py-5 grid-flow-col lg:!grid md:!inline-flex px-6 max-sm:!inline-flex"
        >
          <Grid item>
            <FinanceCard
              border={true}
              icon={'/assets/landingPage/CreditApplication.svg'}
              heading="Credit Application"
              details={`A credit application for equipment financing is a form that borrowers fill out to request financing for the equipment they want to purchase, providing important financial and personal information needed to assess their eligibility and determine loan terms.`}
            />
          </Grid>

          <Grid item>
            <FinanceCard
              border={true}
              icon={'/assets/landingPage/Search.svg'}
              heading="Underwriting"
              details={`Underwriting is the evaluation process that lenders use to assess the financial situation and risk of borrowers helping them decide whether to approve a loan application and determine suitable terms.`}
            />
          </Grid>

          <Grid item>
            <FinanceCard
              border={false}
              icon={'/assets/landingPage/Verified.svg'}
              heading="Approval"
              details={`Our goal is to provide prompt and dependable approvals to our customers, ensuring a smooth and efficient financing process that enables them to acquire the equipment they need with confidence.`}
              isApproval
            />
          </Grid>
        </Grid>
      </Box>
      <Grid
        item
        container
        className={`py-10 grid-rows-4   ${
          isMedium === true || isMedium === true ? 'gap-24' : 'gap-20'
        } lg:gap-48 `}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item>
          <Shipment
            icon={'/assets/landingPage/Shop.png'}
            text="Shop"
            theme="blue"
          />
        </Grid>
        <Grid item>
          <Shipment
            icon={'/assets/landingPage/research-svgrepo-com 1 (1).svg'}
            text="Research"
            theme="white"
          />
        </Grid>
        <Grid item>
          <Shipment
            icon={'/assets/landingPage/news-report-newspaper-svgrepo-com 1.svg'}
            text="News"
            theme="white"
          />
        </Grid>
        <Grid item>
          <Shipment
            icon={'/assets/landingPage/truck-svgrepo-com 1.svg'}
            text="Sell"
            theme="white"
          />
        </Grid>
      </Grid>
    </Box>
  );
};
