import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { Grid, Box, Typography, useMediaQuery, useTheme, Modal, Divider } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import HeaderImg from './HederImg';
import Content from './ContentSec'
// import backgroundImage from '../../../public/truck-grey.png';

const Blogs = () => {
    const img = process.env.PUBLIC_URL + '/Vehical.png';
    return (
       <>
          <>
             <Box className='flex !h-[100%] !w-[100%]'>
                <Grid container lg={12} md={12} sm={12} xs={12} rowGap={6}>
                     <Grid item lg={12} md={12} sm={12} xs={12}>
                        <HeaderImg />
                     </Grid>
                     <Grid item container lg={12} md={12} sm={12} xs={12} className='flex' justifyContent='center' alignItems='center'>
                         <Grid item container lg={10} md={10} sm={11} xs={10} spacing={4} >
                            <Grid item lg={4} md={4} sm={12} xs={12} spacing={5}>
                              <Content />
                            </Grid>  
                            <Grid item lg={4} md={4} sm={12} xs={12} spacing={5}>
                              <Content />
                            </Grid> 
                            <Grid item lg={4} md={4} sm={12} xs={12} spacing={5}>
                              <Content />
                            </Grid>  
                            <Grid item lg={4} md={4} sm={12} xs={12} spacing={5}>
                              <Content />
                            </Grid>  
                            <Grid item lg={4} md={4} sm={12} xs={12} spacing={5}>
                              <Content />
                            </Grid> 
                            <Grid item lg={4} md={4} sm={12} xs={12} spacing={5}>
                              <Content />
                            </Grid> 
                         </Grid>
                     </Grid>
                </Grid>
             </Box>
          </>
       </>
    )
}

export default Blogs;