import { useGetFinanceDetails } from 'query/productDetails';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Grid, Box,Popover,MenuList,MenuItem,Typography, Divider } from '@mui/material';
import ImagSliderComponent from 'pages/SMRRInfo/EquipmentInfo/Imagslider';
import SliderContentFinancingDetail from './SliderContentFinancingDetail';
import { FinanceDocumentDetail } from 'pages/commonComponents/FinanceDocumentDetail/FinanceDocumentDetail';
import { docsMapSchema } from './docsMapSchema';
import { axios } from '../../utils';
import { QueryKey } from '../../query/queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCreateFavourites, useDeleteFavourites } from 'query/favourites';
import { getLocalStorageItem } from 'hooks';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';

const shareOptions = [
  { label: 'Email', icon: 'assets/icons/share_mail.svg', value: 'mail' },
  { label: 'Twitter', icon: 'assets/icons/twitter.svg', value: 'twitter' },
  { label: 'Instagram', icon: 'assets/icons/instagram.svg', value: 'instagram' },
  { label: 'LinkedIn', icon: 'assets/icons/linkedin.svg', value: 'linkedin' },
  { label: 'Facebook', icon: 'assets/icons/facebook.svg', value: 'facebook' },
  { label: 'Copy Link', icon: 'assets/icons/link.svg', value: 'copy_link' },
];

const FinancingDetail = () => {

  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const productId = searchParams.get('id');
  const { data: InventoryDetails } = useGetFinanceDetails(productId);
  const [docsMap, setDocsMap] = useState();
  const [currentShareLinkDetailData, setCurrentShareLinkDetailData] = useState(null);
  // Queries
  const patchFinanceFn = (newId) => {

    const docs = [...InventoryDetails.data.documents].map(item => item.id);
    // eslint-disable-next-line no-unused-expressions
    docs.push[newId];

    // Need these headers 'content-type': 'multipart/form-data',
    const data = {
      documents: docs,
      financeId: productId
    };
    const method = 'PATCH';
    const url = `finance/${productId}/`;
    return axios({
      method,
      url,
      data
    });
  };

  const { mutate: patchFinance, isLoading: isPatchFinanceLoading } = useMutation(patchFinanceFn, {
    onSuccess: (res) => {
      queryClient.invalidateQueries([QueryKey.GET_FINANCE_DETALS_DATA]);
    },
    onError: (err) => {
      queryClient.invalidateQueries([QueryKey.GET_FINANCE_DETALS_DATA]);
    }
  });

  // Methods
  const handleFreshUpload = (docId, index) => { 
    patchFinance(docId)   
  }
  useEffect(() => {
    setLikedStatus(InventoryDetails?.data?.listing?.is_favourite);
  }, [InventoryDetails]);

  const [likeStatus, setLikedStatus] = useState(false);
  const { mutate: AddToFavourites } = useCreateFavourites();
  const { mutate: RemoveFromFavourites } = useDeleteFavourites();
  const isAuth = getLocalStorageItem('AUTH_TOKEN');
  const navigate = useNavigate();

  const handleHeart = (action, id) => (e) => {
    e.stopPropagation();
    if (!isAuth || isAuth == 'undefined') {
      navigate('/login');
      return;
    }
    // console.log({action,id})
    if (action === 'like') {
      AddToFavourites(
        {
          listing: id,
        },
        {
          onSuccess: (res) => {
            setLikedStatus(true);
            queryClient.invalidateQueries([QueryKey.GET_FINANCE_DETALS_DATA]);
            queryClient.invalidateQueries([QueryKey.GET_FAVOURITE_DATA]);
          },
          onError: (err) => {},
        }
      );
    } else if (action === 'unlike') {
      RemoveFromFavourites(
        {
          id: id,
        },
        {
          onSuccess: (res) => {
            setLikedStatus(false);
            queryClient.invalidateQueries([QueryKey.GET_FINANCE_DETALS_DATA]);
            queryClient.invalidateQueries([QueryKey.GET_FAVOURITE_DATA]);
       
          },
          onError: (err) => {},
        }
      );
    }
  };
  const [copiedSuccess, setCopySuccess] = useState(false);
  const [OpenShare, setOpenShare] = React.useState(null);
  const handleShare = (event, data) => {
    event.stopPropagation();
    setOpenShare(event.currentTarget);
    setCurrentShareLinkDetailData(data);
  };

  const handleCloseShare = () => {
    setOpenShare(null);
  };
  const OpenShareList = Boolean(OpenShare);
  function getMailtoUrl(to, subject, body) {
    const args = [];
    if (typeof subject !== 'undefined') {
      args.push('subject=' + encodeURIComponent(subject));
    }
    if (typeof body !== 'undefined') {
      args.push('body=' + encodeURIComponent(body));
    }
    let url = 'mailto:' + encodeURIComponent(to);
    if (args.length > 0) {
      url += '?' + args.join('&');
    }
    return url;
  }
  const handleSharing = (el) => (e) => {
    e.stopPropagation();
    let link =
      process.env.REACT_APP_URL +
      'inventory-details?id=' +
      currentShareLinkDetailData.id;
    const imageUrl = currentShareLinkDetailData.attachments?.[0]?.attachment;
    const copyLinkAndImage = async () => {
      try {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.src = imageUrl + '?not-from-cache-please';

        img.onload = async () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);

          // Convert canvas image to blob (PNG format)
          canvas.toBlob(async (pngBlob) => {
            const data = [
              new ClipboardItem({
                'text/plain': new Blob([link], { type: 'text/plain' }),
                'image/png': pngBlob,
              }),
            ];

            await navigator.clipboard.write(data);
            console.log('Link and image copied successfully!');
          }, 'image/png');
        };

        img.onerror = (errorEvent) => {
          console.error('Error loading the image.', errorEvent);
        };
      } catch (error) {
        console.error('Error copying to clipboard', error);
      }
    };

    switch (el.value) {
      case 'mail':
        {
          window.location.href = getMailtoUrl(
            'no-reply@example.com',
            'Check this Rig out',
            link
          );
        }
        break;
      case 'twitter':
        {
          var url =
            'https://twitter.com/share?url=' +
            encodeURIComponent(link) +
            '&t=Share on Twitter';
          window.open(url, '_blank');
        }
        break;
      case 'instagram':
        {
          var url =
            'https://www.instagram.com/?url=' + encodeURIComponent(link);
          window.open(url, '_blank');
        }
        break;
      case 'linkedin':
        {
          var url =
            'https://www.linkedin.com/share?url=' +
            encodeURIComponent(link) +
            '&t=Share on Linked In';
          window.open(url, '_blank');
        }
        break;
      case 'facebook':
        {
          var url =
            'https://www.facebook.com/sharer/sharer.php?u=' +
            encodeURIComponent(link) +
            '&t=Share on Facebook';
          window.open(url, '_blank');
        }
        break;
      case 'copy_link': {
        copyLinkAndImage();
        setCopySuccess(true);

        setTimeout(() => {
          setCopySuccess(false);
        }, 2000);
      }
    }
  };

  useEffect(() => {
    if(InventoryDetails?.data?.documents) {
      const baseMap = [...docsMapSchema];
      let newData = [...InventoryDetails.data.documents];


      baseMap.forEach(baseItem => {
        baseItem._isUsed = false;
        // Cases to handle
        // document_type && document_side
        //
        const { document_type, document_side } = baseItem;


        // Find the match for base iteration and set the values
        let matched = null;
        newData.forEach((item, index) => {
          if (item.document_type === document_type && item.document_side === document_side) {
            matched = item;
            Object.assign(baseItem, item);
          }
        });

        // remove the matched item from newData
        if(matched){
          newData = newData.filter((nD) => {
            return nD.id != matched.id
          });
        }
      });

      // handle the missed items
      baseMap.forEach((baseItem) => {
        newData.forEach((item, index) => {
          if(baseItem.document_type === item.document_type && !baseItem.id) {
            const mathedItems = baseMap.filter((baseItem, index) => {
              return baseItem.document_type === item.document_type;
            });
            if (mathedItems.length > 0) {
              Object.assign(mathedItems[0], item);
              newData.splice(index, 1);
            }
          }
        })
      })
      setDocsMap(baseMap);
    }    
  }, [InventoryDetails?.data?.documents])

  const handleEmail = () => {
    let link =
      process.env.REACT_APP_URL +
      'inventory-details?id=' +
      InventoryDetails?.data?.id;
    // Using window.open to open the mailto link in a new window/tab
    window.open(
      getMailtoUrl('no-reply@example.com', 'Check this Rig out', link)
    );
  };


  return (
    <Box sx={{ padding: '10px' }}>
      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          overflowY: 'scroll',
          height: '100%',
          marginTop: '50px',
        }}
      >
        <Grid item container lg={10} md={12} style={{ paddingBottom: '50px' }}>
          <Grid item>
            <h1
              className="font-Inter"
              style={{
                fontFamily: 'Inter !important',
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '16px',
                color: '#545454',
              }}
            >
              Financing Documents
            </h1>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          overflowY: 'scroll',
          height: '100%',
          paddingTop: '50px',
          borderTop: '1px solid rgba(0,0,0,0.05)',
        }}
      >
        <Grid
          item
          container
          lg={10}
          md={12}
          className="shadow-md "
          style={{ paddingBottom: '20px', borderRadius: '15px' }}
        >
          <Grid item lg={5} md={12} sm={12}>
            <ImagSliderComponent vehicalInfo={InventoryDetails} />
          </Grid>
          <Grid
            item
            lg={6}
            md={12}
            sm={12}
            style={{ marginTop: '6px', marginLeft: '-10px' }}
          >
            <SliderContentFinancingDetail
              vehicalInfo={InventoryDetails}
              tabType="productDetails"
              EMI="Listing"
              EquipmentType={InventoryDetails?.data?.equipment_type}
            />
          </Grid>
          {InventoryDetails?.data?.equipment_type === 'SMR' ? (
            <Grid
              item
              container
              lg={1}
              md={2}
              sm={1}
              xs={1}
              justifyContent="right"
              gap={2}
            >
              <Grid item style={{ paddingTop: '7px' }}>
                <img
                  src={'/assets/shareicon.svg'}
                  style={{ cursor: 'pointer' }}
                  alt="share"
                  onClick={(e) => handleShare(e, InventoryDetails?.data)}
                />
              </Grid>

              <Grid item style={{ paddingTop: '7px' }}>
                {likeStatus ? (
                  <span
                    onClick={handleHeart(
                      'unlike',
                      InventoryDetails?.data?.listing?.id
                    )}
                    style={{ cursor: 'pointer' }}
                  >
                    <FavoriteIcon className="text-[#0F5E91]" />
                  </span>
                ) : (
                  <span
                    onClick={handleHeart(
                      'like',
                      InventoryDetails?.data?.listing?.id
                    )}
                    style={{ cursor: 'pointer' }}
                  >
                    <FavoriteBorderIcon className="text-[#0F5E91]" />
                  </span>
                )}
              </Grid>
              <Popover
                open={OpenShareList}
                anchorEl={OpenShare}
                onClose={handleCloseShare}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <MenuList>
                  {shareOptions?.map((el, index) => {
                    return (
                      <>
                        <MenuItem
                          onClick={handleSharing(el)}
                          justifyContent="left"
                          className=" h-10 !w-[189px] flex !items-center !text-left "
                        >
                          <img className="w-6 h-5 pr-2" src={el.icon} alt="" />
                          <Typography className="!items-center flex align-center !m-0 text-left">
                            {el.value === 'copy_link' && copiedSuccess ? (
                              <span className="text-green-500">
                                Link Copied!
                              </span>
                            ) : (
                              el.label
                            )}
                          </Typography>
                        </MenuItem>
                        {index !== shareOptions.length - 1 && (
                          <Divider className="!p-0 !m-0 !bg-gray-10" />
                        )}
                      </>
                    );
                  })}
                </MenuList>
              </Popover>
            </Grid>
          ) : null}
          <Grid item lg={10} md={12} sm={12}>
            <div className="p-4 mb-4">
              <h2 className="text-lg font-bold mb-4 !font-Inter">
                Seller Details
              </h2>

              <div className="grid grid-cols-2 gap-4">
                {InventoryDetails?.data?.user?.name && (
                  <>
                    <span className="text-gray-600">Name:</span>
                    <span>{InventoryDetails?.data?.user?.name}</span>
                  </>
                )}

                {InventoryDetails?.data?.user?.email && (
                  <>
                    <span className="text-gray-600">E-mail:</span>
                    <div className="cursor-pointer" onClick={handleEmail}>
                      <p className="text-blue-600 underline">
                        {InventoryDetails?.data?.user?.email}
                      </p>
                    </div>
                  </>
                )}
                {InventoryDetails?.data?.user?.phone && (
                  <>
                    <span className="text-gray-600">Phone Number:</span>
                    <span>{InventoryDetails?.data?.user?.phone}</span>
                  </>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      
      </Grid>
      {InventoryDetails?.data?.status === 'UNDER_PROCESS' && (
        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            overflowY: 'scroll',
            height: '100%',
            marginTop: '32px',
          }}
        >
          <Grid container lg={10}>
            <div className="bg-[#FFEDB2]  w-full p-3 rounded-md">
              <div className="flex gap-2 items-center">
                <img
                  className="w-[32px] h-[32px] px-2"
                  src="/assets/icons/notes.svg"
                  alt="Notes"
                />
                <Typography
                  className="font-[500] text-[#4D3A00] landing-[24px]"
                  style={{
                    fontSize: '18px',
                    fontFamily: 'Inter',
                    fontWeight: '500',
                  }}
                >
                  Your Application is Under Review
                </Typography>
              </div>

              <div className="my-2 px-2">
                <Typography
                  style={{ fontSize: '12px', fontFamily: 'Poppins' }}
                  className="font-[400] landing-[16px] text-[#997400]"
                >
                  Thank you for completing your applications. A member of Slate
                  Financial LLC will contact you <br />
                  shortly.
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      )}
      {InventoryDetails?.data?.status === 'DENIED' && (
        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            overflowY: 'scroll',
            height: '100%',
            marginTop: '32px',
          }}
        >
          <Grid container lg={10}>
            <div className="bg-[#FDF3F2]  w-full p-3 rounded-md">
              <div className="flex gap-2 items-center">
                <img
                  className="w-[32px] h-[32px] px-2 "
                  src="/assets/icons/notes_deny.svg"
                  alt="Notes"
                  style={{ color: '#FDF3F2' }}
                />
                <Typography
                  className="font-[500] text-[#DC2804] landing-[24px]"
                  style={{
                    fontSize: '18px',
                    fontFamily: 'Inter',
                    fontWeight: '500',
                  }}
                >
                  Your Application is Denied
                </Typography>
              </div>

              <div className="my-2 px-2">
                <Typography
                  style={{ fontSize: '12px', fontFamily: 'Poppins' }}
                  className="font-[400] landing-[16px] text-[#DC2804]"
                >
                  Unfortunately,we regret to inform you that your application
                  has been <br />
                  denied
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      )}
      {InventoryDetails?.data?.status === 'COMPLETE' && (
        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            overflowY: 'scroll',
            height: '100%',
            marginTop: '32px',
          }}
        >
          <Grid container lg={10}>
            <div className="bg-[#BBE2CE]  w-full p-3 rounded-md">
              <div className="flex gap-2 items-center">
                <img
                  className="w-[32px] h-[32px] px-2"
                  src="/assets/icons/notesg.svg"
                  alt="Notes"
                />
                <Typography
                  className="font-[500] text-[#095F3C] landing-[24px]"
                  style={{
                    fontSize: '18px',
                    fontFamily: 'Inter',
                    fontWeight: '500',
                  }}
                >
                  Your Application is Successful
                </Typography>
              </div>

              <div className="my-2 px-2">
                <Typography
                  style={{ fontSize: '12px', fontFamily: 'Poppins' }}
                  className="font-[400] landing-[16px] text-[#095F3C]"
                >
                  We are pleased to inform you that your application has been
                  successfully reviewed and <br /> approved.
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      )}
   

      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          overflowY: 'scroll',
          height: '100%',
          marginTop: '32px',
          marginBottom: '100px',
        }}
      >
        <Grid container lg={10} md={12} sm={12}>
          {docsMap?.map((doc, index) => {
            return (
              <Grid item container lg={3} rowSpacing={2} columnSpacing={2}>
                <Grid item lg={12} md={12} sm={12}></Grid>
                {/*<Grid item lg={12} md={12} sm={12}>
                  <p></p>
                </Grid>*/}
                <Grid item lg={12} md={12} sm={12}>
                  <div>
                    <p
                      className="mb-2"
                      style={{
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        fontSize: '14px',
                        color: '#545454',
                      }}
                    >
                      {doc?.document_type?.replaceAll('_', ' ')}
                      {doc?.document_side && doc?.document_side != 'NONE' ? (
                        <span> ({doc?.document_side})</span>
                      ) : null}
                    </p>
                  </div>
                  <FinanceDocumentDetail
                    documentDetail={doc}
                    financeId={productId}
                    myIndex={index}
                    handleFreshUpload={handleFreshUpload}
                  />
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Box>
  );
};

export default FinancingDetail;
