const formatUrlParams = (payload) => {
  const params = new URLSearchParams();

  Object.entries(payload).forEach((item) => {
    const [key, value] = item;
    if (value !== undefined) {
      try {
        value.forEach((element) => {
          params.append(key.toLowerCase(), element);
        });
      } catch (e) {
        params.append(key.toLowerCase(), value);
      }
    }
  });
  return params;
};

export const formatUrlParamsComma = (payload) => {
  const params = new URLSearchParams();

  Object.entries(payload).forEach((item) => {
    const [key, value] = item;
    if (Array.isArray(value)) {
      params.append(key, value.join(','));
    }else{
      params.append(key,value)
    }
  });
  
  return params;
};

export default formatUrlParams;
