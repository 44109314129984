import React from "react";
import { useLocation } from "react-router";
import MyEquipDetailsPage from "./MyEquipDetailPage"; 

const MainComponent = (props) => {
    const loaction = useLocation();
    const keyVal = loaction.state;
   
    return (        
        <>
            <MyEquipDetailsPage keyVal={keyVal}/>
        </>
    )
}

export default MainComponent;


