import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { localStorageKeys } from 'hooks';
import { QueryKey } from './queryKeys';

import { axios } from 'utils';

const contactUsapi = (data) => {
  const method = 'POST';
  const url = 'auth/contact-us/';
  return axios({ method, url, data });
};

export const useContactUs = () => {
  return useMutation(contactUsapi);
};

const getPrivacyPolicyData = () => {
  const method = 'GET';
  const url = 'auth/terms-conditions/';
  return axios({ method, url });
};

export const useGetPrivacyData = () => {
  return useQuery([QueryKey.GET_PRIVACYPOLICY_DATA], () =>
    getPrivacyPolicyData()
  );
};
